import * as z from 'zod'
import { processDatadogRumAction } from '../datadog/datadog-actions'
import { raiseEvent } from '../gtm/gtm'

const createSchema = z.object({
    league: z.string(),
    sports: z.string(),
    match_id: z.number(),
    video_type: z.enum(['Match', 'Event']),
    has_pass: z.boolean(),
    isLive: z.boolean(),
})
export type CreateClipSchema = z.infer<typeof createSchema>

export function clipCreateEvent({
    props,
    gtm = true,
    datadog = true,
}: {
    props: CreateClipSchema
    gtm?: boolean
    datadog?: boolean
}) {
    const eventName = 'clip.createButton'
    if (gtm) {
        raiseEvent({
            type: eventName,
            payload: createSchema.parse(props),
        })
    }

    if (datadog) {
        processDatadogRumAction(eventName, { ...props })
    }
}

const downloadSchema = z.object({
    league: z.string(),
    clip_id: z.number(),
    clip_name: z.string(),
})
export type DownloadClipSchema = z.infer<typeof downloadSchema>

export function clipDownloadEvent({
    props,
    gtm = true,
    datadog = true,
}: {
    props: DownloadClipSchema
    gtm?: boolean
    datadog?: boolean
}) {
    const eventName = 'clip.download'
    if (gtm) {
        raiseEvent({
            type: eventName,
            payload: downloadSchema.parse(props),
        })
    }

    if (datadog) {
        processDatadogRumAction(eventName, { ...props })
    }
}

const setTimeSchema = z.object({
    league: z.string(),
    type: z.enum(['start', 'end']),
    method: z.enum(['button', 'input']),
    time: z.string(),
    clip_name: z.string().optional(),
})
export type SetClipTimeSchema = z.infer<typeof setTimeSchema>

export function clipSetTimeEvent({
    props,
    gtm = true,
    datadog = true,
}: {
    props: SetClipTimeSchema
    gtm?: boolean
    datadog?: boolean
}) {
    const eventName = 'clip.setTime'
    if (gtm) {
        raiseEvent({
            type: eventName,
            payload: setTimeSchema.parse(props),
        })
    }

    if (datadog) {
        processDatadogRumAction(eventName, { ...props })
    }
}

const saveSchema = z.object({
    league: z.string(),
    clip_name: z.string(),
    clip_id: z.number(),
    mode: z.enum(['save', 'unsave']),
    user: z.boolean(),
})
export type SaveClipSchema = z.infer<typeof saveSchema>

export function clipSaveEvent({
    props,
    gtm = true,
    datadog = true,
}: {
    props: SaveClipSchema
    gtm?: boolean
    datadog?: boolean
}) {
    const eventName = 'clip.save'
    if (gtm) {
        raiseEvent({
            type: eventName,
            payload: saveSchema.parse(props),
        })
    }

    if (datadog) {
        processDatadogRumAction(eventName, { ...props })
    }
}

const submitSchema = z.object({
    league: z.string(),
    clip_name: z.string().optional(),
    type: z.enum(['save', 'cancel']),
})
export type SubmitClipSchema = z.infer<typeof submitSchema>

export function clipSubmitEvent({
    props,
    gtm = true,
    datadog = true,
}: {
    props: SubmitClipSchema
    gtm?: boolean
    datadog?: boolean
}) {
    const eventName = 'clip.submit'
    if (gtm) {
        raiseEvent({
            type: eventName,
            payload: submitSchema.parse(props),
        })
    }

    if (datadog) {
        processDatadogRumAction(eventName, { ...props })
    }
}

const clickSchema = z.object({
    clipName: z.string().optional(),
    matchID: z.union([z.string(), z.number()]).optional(),
    league: z.string().optional(),
    clubName: z.string().optional(),
    message: z.string().optional(),
})

export type ClickClipSchema = z.infer<typeof clickSchema>

export function clipClickEvent({
    props,
    gtm = true,
    datadog = true,
}: {
    props: ClickClipSchema
    gtm?: boolean
    datadog?: boolean
}) {
    const eventName = 'clip.click'

    if (gtm) {
        raiseEvent({
            type: eventName,
            payload: clickSchema.parse(props),
        })
    }

    if (datadog) {
        processDatadogRumAction(eventName, { ...props })
    }
}

const shareSchema = z.object({
    clipName: z.string().optional(),
    clubName: z.string().optional(),
    playerName: z.string().optional(),
    message: z.string().optional(),
})

export type ShareClipSchema = z.infer<typeof shareSchema>

export function clipShareEvent({
    props,
    gtm = true,
    datadog = true,
}: {
    props: ShareClipSchema
    gtm?: boolean
    datadog?: boolean
}) {
    const eventName = 'clip.share'

    if (gtm) {
        raiseEvent({
            type: eventName,
            payload: shareSchema.parse(props),
        })
    }

    if (datadog) {
        processDatadogRumAction(eventName, { ...props })
    }
}
