// styled system
import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const InputWrapper = styled.div`
    display: flex;
    align-items: center;
`

export const PrefixInput = styled.div`
    display: flex;
    align-items: center;
    width: 100%;
    background: #e5e5e5;
    border: 1px solid #a0a0a0;
    border-radius: 4px;
    padding-left: 0.5rem;
    overflow: hidden;

    &:focus-within {
        border-color: #777;
    }

    span {
        font-weight: 300;
        font-size: 14px;
        color: #999;
    }

    input {
        flex-grow: 1;
        font-size: 14px;
        background: transparent;
        border: none;
        outline: none;
        padding: 0.5rem 0.5rem 0.5rem 0.1rem;
        color: #000;
    }
`

export const CopyWrapper = styled.a`
    cursor: pointer;
    margin-left: 10px;
`
