import { composeWithDevTools } from '@redux-devtools/extension'
import { createWrapper } from 'next-redux-wrapper'
import { applyMiddleware, createStore } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from 'redux/reducers/rootReducer'

const makeStore = () => {
    const middleware = [thunk]
    const middleWareEnhancer = applyMiddleware(...middleware)
    const store = createStore(
        rootReducer,
        composeWithDevTools(middleWareEnhancer),
    )

    return store
}

export const wrapper = createWrapper(makeStore)

export type AppStore = ReturnType<typeof makeStore>
export type AppState = null | ReturnType<AppStore['getState']>
export type AppDispatch = AppStore['dispatch']
