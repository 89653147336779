import React from 'react'
// type
import { IconProps } from 'types/components/Icon'

// -----------------------------------------------
const DownIcon: React.FC<IconProps> = ({
    iColor = 'white',
    iSize = { x: 14, y: 8 },
}) => {
    return (
        <svg
            width={iSize.x}
            height={iSize.y}
            viewBox="0 0 14 8"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M7.92907 6.81801L13.968 0.384188C13.9838 0.37216 13.9946 0.354793 13.9985 0.335351C14.0023 0.315909 13.9989 0.295732 13.9889 0.278613C13.9789 0.261495 13.963 0.248613 13.9442 0.242391C13.9254 0.236169 13.905 0.237035 13.8867 0.244827H0.113253C0.0950305 0.237035 0.0745892 0.236169 0.0557726 0.242391C0.0369559 0.248613 0.0210601 0.261495 0.011074 0.278613C0.001088 0.295732 -0.0023004 0.315909 0.00154573 0.335351C0.00539187 0.354793 0.0162077 0.37216 0.0319596 0.384188L6.07093 6.81801C6.19034 6.94455 6.33435 7.04536 6.49411 7.11426C6.65387 7.18316 6.82602 7.21869 7 7.21869C7.17399 7.21869 7.34614 7.18316 7.5059 7.11426C7.66566 7.04536 7.80966 6.94455 7.92907 6.81801V6.81801Z"
                fill={iColor}
            />
        </svg>
    )
}
export default DownIcon
