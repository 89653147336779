import { useMutation, useSubscription } from '@apollo/client'
import { Button } from 'components/Button'
import { Col, Row } from 'components/Layout'
import { PreviousModal } from 'components/Modal'
import ArchiveModal from 'components/Modal/ArchiveModal'
import { Table } from 'components/Table'
import { Text } from 'components/Text'
import { mutate, subscribe } from 'graphql/match'
import _ from 'lodash'
import { LeagueContext } from 'pages/league/[league_slug]/admin'
import { useContext, useEffect, useState } from 'react'
import { BsPlus } from 'react-icons/bs'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { combineEventsAndMatches } from 'utils/common-helper'
import { isServer } from 'utils/helper'
import { datetimeToLocal } from 'utils/helper-date'
import { DisplayWrapper } from './previous.style'

const PreviousSection = (props: { clubInfo: any }) => {
    const { clubInfo } = props
    const league = useContext(LeagueContext)
    const [show, setShow] = useState<boolean>(false)
    const [endPoint, setEndPoint] = useState('')
    const [uploadId, setUploadId] = useState('')
    const [tableData, setTableData] = useState<any>([])
    const [matchData, setMatchData] = useState<any>()
    const [eventData, setEventData] = useState<any>()
    const [streamId, setStreamId] = useState(0)
    const [showArchiveModal, setShowArchiveModal] = useState(false)
    const [buttonLoading, setButtonLoading] = useState(false)

    const [unarchiveStream] = useMutation(mutate.UPDATE_ARCHIVE_MATCH, {
        onCompleted(data) {
            data && setButtonLoading(false)
            data && toast.success('Unarchived Successfully')
        },
        onError() {
            setButtonLoading(false)
            toast.error(`Something went wrong. Please try again later.`)
        },
    })

    useSubscription(subscribe.SUB_MATCHES, {
        skip: !league.id,
        variables: {
            where: {
                league_id: { _eq: league.id },
                stream_info: {
                    status: { _eq: 'completed' },
                },
            },
            order_by: { start_datetime: 'desc' },
        },
        onData: ({ data: { data, loading } }) => {
            !loading && data.matches && setMatchData(data?.matches)
        },
    })

    useSubscription(subscribe.SUB_EVENT_STREAMS, {
        skip: !league.id || isServer,
        variables: {
            where: {
                league_id: { _eq: league.id },
                stream_info: {
                    status: { _eq: 'completed' },
                    is_historic: { _eq: false },
                },
            },
            order_by: { start_datetime: 'desc' },
        },
        onData: ({ data: { data, loading } }) => {
            !loading && data.event_streams && setEventData(data?.event_streams)
        },
    })

    const data = combineEventsAndMatches(eventData, matchData)

    useEffect(() => {
        setTableData(
            data?.map((match: any) => ({
                Date: datetimeToLocal(match.start_datetime, {
                    hideTime: true,
                    options: { month: 'short' },
                }),
                Time: datetimeToLocal(match.start_datetime, {
                    hideDate: true,
                }),
                League: match.league.name,
                'Round Name':
                    match.__typename === 'matches'
                        ? match.round_name
                        : match.name,
                'Home Club': match?.home_team?.club?.name,
                'Home Team': match?.home_team?.name,
                'Opposition Club': match?.custom_opponent
                    ? match?.custom_opponent?.club_name
                    : match?.away_team?.club?.name,
                'Opposition Team': match?.custom_opponent
                    ? match?.custom_opponent?.club_name
                    : match?.away_team?.name,
                Scoring: match.ext_scoring ? 'External' : 'Managed',
                Stream: match.ext_managed ? 'External' : 'Managed',
                'Stream Key': match.stream_info.stream_key,
                '': (
                    <Button
                        loading={
                            buttonLoading && streamId === match?.stream_info?.id
                        }
                        disabled={
                            buttonLoading && streamId === match?.stream_info?.id
                        }
                        onClick={(e: any) => {
                            e.stopPropagation()
                            handleArchiveUnArchive(
                                match?.stream_info?.id,
                                match?.stream_info?.archived,
                            )
                        }}
                    >
                        {match?.stream_info?.archived
                            ? 'Unarchived'
                            : 'Archive'}
                    </Button>
                ),
            })),
        )
    }, [eventData, matchData])

    const handleArchiveUnArchive = (
        stream_info_id: number,
        archived: boolean,
    ) => {
        stream_info_id && setButtonLoading(true)

        if (stream_info_id && archived === false) {
            setStreamId(stream_info_id)
            setShowArchiveModal(true)
        }

        if (stream_info_id && archived === true) {
            unarchiveStream({
                variables: {
                    where: {
                        id: {
                            _eq: stream_info_id,
                        },
                    },
                    _set: {
                        archived: false,
                    },
                },
            })
        }
    }

    const onModal = async (flag: boolean) => {
        setShow(flag)

        const response = await fetch('/api/mediaGallery/upload', {
            method: 'POST',
        })

        const data = await response.json()
        setEndPoint(data?.data?.url)
        setUploadId(data?.data?.id)
    }

    const onHandleClick = (e: { [x: string]: any }) => {
        const selectedRowData = _.filter(data, {
            stream_info: { stream_key: e['Stream Key'] },
        })

        selectedRowData[0].__typename === 'matches' &&
            window.open(`/match/${selectedRowData[0].id}`, '_blank')

        selectedRowData[0].event_type === 'league' &&
            window.open(
                `/event/${selectedRowData[0].id}/${selectedRowData[0].slug}`,
                '_blank',
            )

        selectedRowData[0].event_type === 'club' &&
            window.open(
                `/event/${selectedRowData[0].id}/${selectedRowData[0].slug}`,
                '_blank',
            )
    }
    return (
        <>
            <ArchiveModal
                show={showArchiveModal}
                setShow={setShowArchiveModal}
                stream_info_id={streamId}
                setButtonLoading={setButtonLoading}
            />
            <DisplayWrapper>
                <Row flexDirection="column" gap={20}>
                    <Col item={24}>
                        <Row justifyContent="space-between" alignItems="center">
                            <Text fSize={0.9375} fColor="gray.200">
                                {
                                    'Add any past matches with replays available to appear on your Club Page.'
                                }
                            </Text>
                            <Button
                                bColor="primary"
                                bSize="small"
                                icon={<BsPlus />}
                                onClick={() => onModal(true)}
                            >
                                {'Add Past Match'}
                            </Button>
                        </Row>
                    </Col>
                    <Col item={24}>
                        <Table data={tableData} onHandleClick={onHandleClick} />
                    </Col>
                </Row>
                <PreviousModal
                    uploadId={uploadId}
                    endPoint={endPoint}
                    show={show}
                    handleClose={() => onModal(false)}
                />
            </DisplayWrapper>
        </>
    )
}
const mapStateToProps = (state: { club: { info: any } }) => ({
    clubInfo: state.club.info,
})

// @ts-ignore
export default connect(mapStateToProps)(PreviousSection)
