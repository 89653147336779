import * as t from 'redux/types/club'

const clubReducer = (
    state = {
        info: {} /** TODO: infer type */,
        isFanPassModalShown: false,
    },
    action: any,
) => {
    switch (action.type) {
        case t.SET_CLUB_INFO:
            return {
                ...state,
                info: action.payload,
            }
        case t.SET_FAN_PASS_MODAL_SHOWN:
            return {
                ...state,
                isFanPassModalShown: action.payload,
            }
        default:
            return { ...state }
    }
}

export default clubReducer
