import { gql } from '@apollo/client'

const SUB_SAMP = gql`
    query TeamsQuery($club_slug: String!) {
        teams(where: { club: { slug: { _eq: $club_slug } } }) {
            id
            image
            name
            slug
            division
        }
    }
`

const SUB_COMMENTS = gql`
    subscription subComment($where: events_bool_exp) {
        events(where: $where, order_by: { video_time: desc }) {
            id
            event_id
            match_id
            player_id
            team_id
            video_time
            comment
            score_id
            game_time_value
            game_time_q_number
            game_time_q_prefix
            home_score
            away_score
            event_stream {
                league {
                    name
                }
            }
            match {
                away_team {
                    club {
                        id
                    }
                }
                custom_opponent
            }
            event_collection {
                id
                event_name
                scoring
            }
            team {
                id
                name
                club {
                    id
                    display_name
                }
            }
            players_detail {
                id
                user {
                    id
                    first_name
                    last_name
                }
            }
        }
    }
`

const SUB_MATCH = gql`
    subscription SubMatch($where: matches_bool_exp = {}) {
        matches(where: $where) {
            id
            stream_info_id
            start_datetime
            round_name
            ext_scoring
            league_id
            sports_id
            custom_opponent
            sport {
                name
            }
            stream_info {
                id
                is_historic
                status
                stream_id
                stream_key
                video_asset_id
                archived
            }
            sport {
                id
                name
            }
            league {
                id
                name
                logo
                slug
                league_pass_id
                league_pass {
                    id
                    active
                    name
                    description
                    price
                    recur
                    type
                }
                sports {
                    name
                }
            }
            home_team {
                id
                name
                players {
                    id
                    user {
                        first_name
                        last_name
                    }
                }
                club {
                    id
                    name
                    logo
                    display_name
                    slug
                    abbr_name
                    pass {
                        id
                        name
                        price_id
                        price
                        checkout_url
                    }
                }
            }
            away_team {
                id
                name
                players(where: { user_id: { _is_null: false } }) {
                    id
                    user {
                        first_name
                        last_name
                    }
                }
                club {
                    id
                    name
                    logo
                    display_name
                    slug
                    abbr_name
                    pass {
                        id
                        name
                        price_id
                        price
                        checkout_url
                    }
                }
            }
            ads_setting {
                id
                name
                vast_url
                position
            }
        }
    }
`

const SUB_MATCH_TIME = gql`
    subscription GetMatchTime($where: match_time_log_bool_exp) {
        match_time_log(where: $where) {
            period_start
            period_end
            period
        }
    }
`
const SUB_MATCH_SCORE = gql`
    subscription MySubscription($where: score_bool_exp) {
        score(where: $where) {
            a_score_1
            a_score_2
            a_score_final
            h_score_1
            h_score_2
            h_score_final
            match_id
            video_time
        }
    }
`
const SUB_EVENT_STREAMS = gql`
    subscription EventStreamsSub($where: event_streams_bool_exp = {}) {
        event_streams(where: $where, order_by: { start_datetime: asc }) {
            id
            stream_id
            ext_managed
            club_id
            start_datetime
            name
            slug
            league_id
            is_private
            event_type
            image
            stream_info {
                id
                is_historic
                network_status
                static_file_status
                status
                stream_id
                stream_key
                video_asset_id
                archived
            }
            club {
                id
                name
                logo
                display_name
                slug
                abbr_name
                sport {
                    id
                    name
                }
                teams {
                    id
                    name
                    slug
                    league_id
                    club_id
                }
                club_admins {
                    id
                    user_id
                    status
                    name
                    email_address
                    is_accepted
                    is_declined
                    user {
                        id
                        first_name
                        last_name
                    }
                }
                pass {
                    id
                    name
                    price_id
                    price
                    checkout_url
                }
            }
            league {
                id
                name
                logo
                slug
                league_pass_id
                league_pass {
                    id
                    active
                    name
                    description
                    price
                    recur
                    type
                }
                sports {
                    name
                    id
                }
            }
        }
    }
`

const SUB_ARCHIVED_INFO = gql`
    subscription MySubscription($where: streams_bool_exp = {}) {
        streams(where: $where) {
            archived
        }
    }
`

// ---------
export default {
    SUB_SAMP,
    SUB_COMMENTS,
    SUB_MATCH,
    SUB_MATCH_TIME,
    SUB_MATCH_SCORE,
    SUB_EVENT_STREAMS,
    SUB_ARCHIVED_INFO,
}
