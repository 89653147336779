import React from 'react'
// type

// -----------------------------------------------
const HandIcon: React.FC = () => {
    return (
        <svg
            width="30"
            height="30"
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle
                cx="15.2266"
                cy="15.3895"
                r="14"
                fill="#FA3737"
                fillOpacity="0.07"
                stroke="#ED1C24"
                strokeWidth="0.8"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <g clipPath="url(#clip0_339_447)">
                <path
                    d="M12.5612 15.232C12.2952 15.8205 11.4125 15.7765 11.1627 15.1622L10.3807 13.2408C10.1646 12.7096 9.55047 12.4319 9.00886 12.6205C8.51084 12.7938 8.24083 13.3063 8.38161 13.8116L9.98084 19.5511C10.4832 20.7853 11.4658 21.8527 12.8097 22.4604C15.4971 23.6755 18.6113 22.5914 19.7654 20.0391L21.9943 15.1095C22.2251 14.5989 21.9766 13.9882 21.439 13.7451C20.9015 13.5021 20.2788 13.7189 20.0479 14.2294L18.7941 17.0023L20.8838 12.3808C21.1146 11.8703 20.866 11.2595 20.3285 11.0165C19.791 10.7734 19.1682 10.9902 18.9374 11.5007L17.1264 15.506L17.405 14.8898L19.4946 10.2683C19.7255 9.7578 19.4769 9.14703 18.9394 8.90398C18.4018 8.66094 17.7791 8.87772 17.5482 9.38824L15.4586 14.0097L15.18 14.6259L17.2696 10.0044C17.5005 9.49392 17.2519 8.88316 16.7143 8.64011C16.1768 8.39706 15.5541 8.61385 15.3232 9.12437L13.2336 13.7459L12.5612 15.232Z"
                    stroke="white"
                    strokeWidth="0.8"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                />
            </g>
            <defs>
                <clipPath id="clip0_339_447">
                    <rect
                        width="17.7964"
                        height="16.9065"
                        fill="white"
                        transform="translate(10.6016 4.02094) rotate(24.3304)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
export default HandIcon
