import React, { useEffect, useState } from 'react'
// assets
import { Button } from 'components/Button'
// types
import { EditProps } from 'types/components/Modal'
// common
import { BsPencil } from 'react-icons/bs'
import { ImCancelCircle } from 'react-icons/im'

import { CustomeInput } from '../index.style'

import { useMutation, useQuery } from '@apollo/client'
import { Form } from 'antd'
import { ADMINQL, HomeQL } from 'graphql/club'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { ModalWrapper } from '../../common.style'

const Admin_U_Modal: React.FC<EditProps> = ({
    show = false,
    mid,
    handleClose,
}) => {
    const [form] = Form.useForm()
    const [fullName, setFullName] = useState('')
    const [disabled, setDisabled] = useState(false)
    const [email, setEmail] = useState('')
    const {
        query: { club_slug },
    } = useRouter()
    const {
        query: { league_slug },
    } = useRouter()

    const { data } = useQuery(ADMINQL.GET_LEAGUE, {
        variables: {
            where: {
                slug: { _eq: league_slug },
            },
        },
    })

    const league = data?.leagues[0]

    const [formData, setFormData] = useState<any>(
        league?.league_admins
            ? league.league_admins.find(
                  (admin: { id: number }) => mid === admin.id,
              )
            : [],
    )

    useEffect(() => {
        setFormData(
            league?.league_admins
                ? league.league_admins.find(
                      (admin: { id: number }) => mid === admin.id,
                  )
                : [],
        )
    }, [mid])

    useEffect(() => {
        form.setFieldsValue({
            name: formData?.name,
            email: formData?.email_address,
        })
        setFullName(formData?.name)
        setEmail(formData?.email_address)
    }, [formData])

    const onFullNameChange = (e: {
        target: { value: React.SetStateAction<string> }
    }) => {
        setFullName(e.target.value)
    }

    const onEmailChange = (e: {
        target: { value: React.SetStateAction<string> }
    }) => {
        setEmail(e.target.value)
    }

    useEffect(() => {
        if (fullName === formData?.name && email === formData?.email_address) {
            setDisabled(true)
        } else {
            setDisabled(false)
        }
    }, [fullName, email])

    const [updateInvitedLeagueAdmins] = useMutation(
        HomeQL.UPDATE_INVITED_LEAGUE_ADMINS,
    )

    const updateAdminDetails = async () => {
        const response = await updateInvitedLeagueAdmins({
            variables: {
                where: {
                    id: { _eq: mid },
                },
                set: {
                    name: fullName,
                    email_address: email.toLowerCase(),
                },
            },
        })
        if (response?.data?.update_admin_details) {
            toast.success('Updated Successfully!')
        }
    }

    const onFinish = () => {
        updateAdminDetails()
        form.resetFields()
        handleClose && handleClose()
    }

    const _handleClose = () => {
        form.resetFields()
        handleClose && handleClose()
    }

    return (
        <ModalWrapper
            open={show}
            closable={false}
            title="Edit Admin Details"
            width={400}
            footer={[
                <Button
                    bColor="primary"
                    key={'btn-cncl'}
                    bSize="small"
                    icon={<ImCancelCircle />}
                    type="button"
                    onClick={_handleClose}
                >
                    {'Cancel'}
                </Button>,
                <Button
                    bColor="primary"
                    key={'btn-save'}
                    bSize="small"
                    type="submit"
                    icon={<BsPencil />}
                    onClick={onFinish}
                    disabled={disabled}
                >
                    {'Update'}
                </Button>,
            ]}
        >
            <div>
                <Form
                    style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                    }}
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Form.Item
                        name="name"
                        rules={[
                            { required: true, message: 'Name is required.' },
                        ]}
                        label={
                            <label style={{ color: 'white' }}>{'Name'}</label>
                        }
                    >
                        <CustomeInput
                            onChange={onFullNameChange}
                            placeholder="Name"
                        />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                        ]}
                        label={
                            <label style={{ color: 'white' }}>
                                {'Email Address'}
                            </label>
                        }
                    >
                        <CustomeInput
                            onChange={onEmailChange}
                            placeholder="Email"
                        />
                    </Form.Item>
                </Form>
            </div>
        </ModalWrapper>
    )
}

export default Admin_U_Modal
