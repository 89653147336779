import { gql } from '@apollo/client'

const GET_ALL_MATCH_IDS = gql`
    query GetMatches($where: matches_bool_exp = {}) {
        matches(where: $where) {
            id
            stream_info {
                id
                video_asset_id
            }
        }
    }
`

const GET_MATCHES = gql`
    query GetMatches(
        $where: matches_bool_exp = {}
        $limit: Int = 10
        $offset: Int = 10
    ) {
        matches(
            where: $where
            limit: $limit
            offset: $offset
            order_by: { start_datetime: desc }
        ) {
            id
            round_name
            start_datetime
            home_club {
                id
                name
            }
            away_team {
                club {
                    name
                }
            }
            stream_info {
                id
                video_asset_id
            }
        }
    }
`

const GET_ALL_EVENTS_IDS = gql`
    query EventQuery($where: event_streams_bool_exp = {}) {
        event_streams(where: $where) {
            id
            stream_info {
                id
                video_asset_id
            }
        }
    }
`

const GET_EVENTS = gql`
    query EventQuery($where: event_streams_bool_exp = {}) {
        event_streams(where: $where, order_by: { start_datetime: desc }) {
            id
            name
            start_datetime
            stream_info {
                id
                video_asset_id
            }
        }
    }
`

// ---------
export default {
    GET_MATCHES,
    GET_ALL_MATCH_IDS,
    GET_EVENTS,
    GET_ALL_EVENTS_IDS,
}
