import { Button } from 'components/Button'
import { Text } from 'components/Text'
import styled from 'styled-components'
import { defaultTheme } from 'theme'

export const PrevMenuWrapper = styled.div`
    position: absolute;
    display: flex;
    width: 100vw;
    height: 6vh;
    background-color: rgb(0, 0, 0, 0.75);
    z-index: 30;
`

export const PrevieMenuContainer = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem;
`

export const StyledText = styled(Text)`
    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        font-size: 1rem;
    }
`

export const ButtonWrapper = styled.div`
    display: flex;
`

export const ChangesButton = styled(Button)`
    white-space: nowrap;

    @media screen and (max-width: ${defaultTheme.mediaSize.lg}px) {
        height: 36px;
        width: 110px;
        font-size: 0.875rem;
        padding: 14px 8px;
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        height: 33px;
        width: 110px;
        font-size: 0.813rem;
        padding: 12px 7px;
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        height: 26px;
        width: 95px;
        font-size: 0.688rem;
        padding: 10px 6px;
    }
`

export const PublishButton = styled(Button)`
    white-space: nowrap;

    @media screen and (max-width: ${defaultTheme.mediaSize.lg}px) {
        height: 36px;
        width: 100px;
        font-size: 0.875rem;
        padding: 14px 8px;
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        height: 33px;
        width: 90px;
        font-size: 0.813rem;
        padding: 12px 7px;
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        height: 26px;
        width: 60px;
        font-size: 0.688rem;
        padding: 10px 6px;
    }
`
