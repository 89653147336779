import { Input } from 'components/Input'
import { Col, Row } from 'components/Layout'
import { ComponentType, ReactNode } from 'react'
import { InputHocProps } from 'types/components/Input'

const useInputHOC = (IconWrapper: ComponentType<{ children: ReactNode }>) => {
    // eslint-disable-next-line react/display-name
    return ({ inputIcon, ...props }: InputHocProps) => {
        return (
            <Row>
                <Col flex="1">
                    <Input {...props} />
                </Col>
                <Col>
                    <IconWrapper>{inputIcon}</IconWrapper>
                </Col>
            </Row>
        )
    }
}

export default useInputHOC
