import { themeGet } from '@styled-system/theme-get'
import React, { Fragment } from 'react'
import styled, { css, keyframes } from 'styled-components'
import { border, compose, layout, space, variant } from 'styled-system'
import { defaultTheme } from 'theme'
import {
    ButtonContainerProps,
    ButtonItemProps,
    StyledProps,
} from 'types/components/Button'

type Ref = HTMLButtonElement
// ----------------------------------------------------

const StyledButton = styled.button<StyledProps>(
    (props) =>
        css({
            margin: '0 5px',
            px: '20px',
            py: '20px',
            fontSize: 'regular',
            cursor: props.disabled ? 'not-allowed' : 'pointer',
            transition:
                'all 0.3s ease, color 300ms ease-in-out, background-color 300ms ease-in-out',
        }),
    {
        zIndex: 15,
        appearance: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexShrink: 0,
        textAlign: 'center',
        height: '38px',
        textDecoration: 'none',
        borderRadius: '5px',
        '&:focus': {
            outline: 'none',
        },
        '&:disabled': {
            filter: 'brightness(0.8)',
        },
        '&:active': {
            transform: 'scale(0.98)',
            filter: 'brightness(0.6)',
        },
    },
    variant({
        variants: {
            default: {
                color: 'white',
                bg: 'transparent',
                border: 'solid white 1px',
                '&:hover': {
                    borderColor: 'gray.100',
                    color: 'gray.100',
                },
            },
            /** TODO: set primary as red */
            primary: {
                color: 'white',
                bg: 'transparent',
                border: 'solid white 1px',
                '&:hover': {
                    borderColor: 'gray.100',
                    color: 'gray.100',
                },
            },
            _primary: {
                color: 'white',
                bg: 'red.100',
                border: 'none',
                '&:hover': { bg: 'red.hover' },
            },
            _primary2: {
                color: 'white',
                bg: 'transparent',
                border: 'solid 3px rgb(34 33 35)',
                '&:hover': { bg: 'red.hover' },
            },
            gray: {
                color: 'white',
                bg: 'gray.300',
                border: 'none',
            },
            outlined: {
                color: 'white',
                border: `1px solid white`,
                borderRadius: '20px',
                bg: 'transparent',
                '&:hover': { bg: 'white', color: 'red.regular' },
            },
            warning: {
                color: 'white',
                bg: 'red.100',
                border: 'none',
            },
        },
    }),
    variant({
        prop: 'size',
        variants: {
            big: {
                height: '58px',
                fontSize: 16,
                px: 21,
                py: 21,
            },
            normal: {
                borderRadius: 0,
                height: '42px',
                width: '150px',
                fontSize: 12,
            },
            medium: { height: '42px', fontSize: 16, px: 10, py: 10 },
            small: { height: '33px', fontSize: 16, px: 12, py: 10 },
            iconsize: {
                height: 'auto',
                fontSize: 10,
                px: 0,
                py: 0,
                border: 'none',
            },
            bigOutlined: {
                borderRadius: '8px',
                height: '52px',
                width: 'auto',
                border: '2px solid #FFFFFF',
                padding: '16px 40px',
                display: 'flex',
                alignItems: 'center',
                fontSize: '16px',
                fontWeight: '700',
                lineHeight: '20px',
                flexWrap: 'nowrap',
                textAlign: 'center',
                marginTop: '1rem',
            },
            large: {
                height: '52px',
                fontSize: 15,
                px: 36,
                py: 12,
                borderRadius: '8px',
            },
        },
    }),
    compose(border, space, layout),
)

export const SlideArrow = styled.div<{
    position: string
    backgroundColor?: string
}>`
    width: 45px;
    height: 100%;
    display: block;
    position: absolute;
    padding: 0 16px;
    ${({ position, backgroundColor }) => {
        if (position == 'right') {
            return `
                animation: slideArrowSlideLeftIn 0.2s ease-out;
                right: -10px; background: linear-gradient(90deg, rgba(0,0,0,0) 0%, ${
                    backgroundColor ? backgroundColor : 'rgba(19, 18, 20, 1)'
                } 80%);`
        }
        return `
            animation: slideArrowSlideRightIn 0.2s ease-out;
            left: -10px; background: linear-gradient(-90deg, rgba(0,0,0,0) 0%, ${
                backgroundColor ? backgroundColor : 'rgba(19, 18, 20, 1)'
            } 80%);`
    }};
    text-align: center;
    z-index: 5;
    cursor: pointer;

    svg {
        transition-duration: 0.2s;
        opacity: 0;
        position: absolute;
        left: 0px;
        transform: scale(0.75);
    }

    &:hover svg {
        transform: scale(0.85);
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.lg}px) {
        height: 100%;
        display: block;
        position: absolute;
        ${({ position }) => {
            if (position == 'right') return `right: -4px;`
            return `left: -10px;`
        }};
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        width: 35px;
        svg {
            transform: scale(0.8);
        }
    }

    @keyframes slideArrowSlideLeftIn {
        0% {
            opacity: 0;
            right: -40px;
        }
        100% {
            opacity: 1;
        }
    }

    @keyframes slideArrowSlideRightIn {
        0% {
            opacity: 0;
            left: -40px;
        }
        100% {
            opacity: 1;
        }
    }
`

const rotate = keyframes`
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
`

const Spinner = styled.div`
    width: 18px;
    height: 18px;
    margin-left: 10px;
    border: 3px solid #ffffff;
    border-top: 3px solid ${themeGet('primary.regular', '#009E7F')};
    border-radius: 50%;
    transition-property: transform;
    animation-name: ${rotate};
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
`
const ButtonInner = styled.div`
    display: flex;
    align-items: center;
    & > *:not(:last-child) {
        margin-right: 5px;
    }
    * {
        font-weight: bold;
    }
`
// eslint-disable-next-line react/display-name
const Button = React.forwardRef<Ref, ButtonItemProps>(
    (
        { ariaLabel, children, disabled, icon, loading = false, ...props },
        ref,
    ) => {
        // Fix crash when passing style prop to styled components
        delete props.style
        const buttonIcon = icon || Fragment
        return (
            <StyledButton
                type="button"
                ref={ref}
                disabled={disabled}
                aria-label={`${ariaLabel || 'warehouse'}`}
                {...props}
            >
                <ButtonInner>
                    {loading && <Spinner />}
                    {!loading && buttonIcon}
                    {children && <div>{children}</div>}
                </ButtonInner>
            </StyledButton>
        )
    },
)

// eslint-disable-next-line react/display-name
const ButtonContainer = React.forwardRef<Ref, ButtonContainerProps>(
    ({ children, bColor = 'default', bSize = 'small', ...props }, ref) => (
        <Button ref={ref} variant={bColor} size={bSize} {...props}>
            {children}
        </Button>
    ),
)

export default ButtonContainer
