import styled from 'styled-components'

export const Status = styled.div`
    display: flex;
    border-radius: 5px;
    align-content: center;
    justify-content: center;
    height: 2.4em;
    margin-top: 0.5em;
`

export const FollowStatus = styled(Status)`
    background-color: #20272d;
    width: 6em;
`
export const DigitalFanStatus = styled(Status)`
    background-color: #33241a;
    width: 7em;
`
