// apollo
import { gql } from '@apollo/client'

const SUB_LEAGUES = gql`
    subscription LeagueSubscription($where: leagues_bool_exp) {
        leagues(where: $where) {
            id
            logo
            name
            slug
            status
            clubs {
                id
                name
            }
        }
    }
`

const SUB_LEAGUE_REPLAYS = gql`
    subscription MyReplaysSub($where: matches_bool_exp) {
        matches(where: $where, order_by: { start_datetime: desc }) {
            id
            name
            start_datetime
            video_asset_id
            round
            round_name
            start_datetime
            thumbnail_url
            league_id
            custom_opponent
            is_event
            stream_info {
                id
                is_historic
                status
                stream_id
                stream_key
                video_asset_id
            }
            away_team {
                id
                image
                name
                division
                club {
                    id
                    logo
                    name
                    display_name
                    slug
                }
            }
            home_team {
                id
                image
                name
                division
                club {
                    id
                    logo
                    name
                    display_name
                    slug
                }
            }
            league {
                id
                logo
                name
                slug
            }
        }
    }
`

const SUB_LEAGUE_EVENT_REPLAY = gql`
    subscription EventStreamsSub($where: event_streams_bool_exp = {}) {
        event_streams(where: $where, order_by: { start_datetime: desc }) {
            id
            stream_id
            ext_managed
            club_id
            start_datetime
            name
            slug
            league_id
            is_private
            event_type
            image
            stream_info {
                id
                is_historic
                network_status
                static_file_status
                status
                stream_id
                stream_key
                video_asset_id
            }
            league {
                name
                slug
                logo
            }
            club {
                id
                logo
                name
                display_name
                slug
            }
        }
    }
`

export default {
    SUB_LEAGUES,
    SUB_LEAGUE_REPLAYS,
    SUB_LEAGUE_EVENT_REPLAY,
}
