import themeGet from '@styled-system/theme-get'
import styled from 'styled-components'

export const HeaderWrapper = styled.div`
    @media only screen and (min-width: 768px) {
        position: -webkit-sticky !important;
        position: sticky;
        top: 0;
        z-index: 50;
        background-color: ${themeGet('colors.black.200')};
    }
`
