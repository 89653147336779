import React from 'react'
// type
import { IconProps } from 'types/components/Icon'

// --------------------------------
const CartIcon: React.FC<IconProps> = ({ iSize = { x: 24, y: 21 } }) => {
    return (
        <svg
            width={iSize.x}
            height={iSize.y}
            viewBox="0 0 24 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_23:3191)">
                <path
                    d="M10.8945 14.998C9.46001 14.998 8.35156 16.1717 8.35156 17.5409C8.35156 18.9102 9.52521 20.0838 10.8945 20.0838C12.3289 20.0838 13.4374 18.9102 13.4374 17.5409C13.4374 16.1717 12.2637 14.998 10.8945 14.998Z"
                    fill="#1A1818"
                />
                <path
                    d="M18.1972 14.998C16.7627 14.998 15.6543 16.1717 15.6543 17.5409C15.6543 18.9754 16.8279 20.0838 18.1972 20.0838C19.6316 20.0838 20.7401 18.9102 20.7401 17.5409C20.7401 16.1717 19.5664 14.998 18.1972 14.998Z"
                    fill="#1A1818"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M23.5432 4.82694C23.4128 4.69653 23.2172 4.56613 23.0216 4.56613H7.30781L6.06896 0.458373C5.93856 0.197563 5.74295 0.00195312 5.41694 0.00195312H1.56999C1.17878 0.00195312 0.917969 0.262763 0.917969 0.653973C0.917969 1.04518 1.17878 1.30599 1.56999 1.30599H4.96052L6.19937 5.41375L8.35105 12.5208C8.41625 12.7816 8.67706 12.9772 9.00308 12.9772H20.9351C21.1959 12.9772 21.4567 12.7816 21.5872 12.5208L23.6084 5.41375C23.6736 5.21814 23.6736 5.02253 23.5432 4.82693L23.5432 4.82694ZM20.4787 11.6732H9.45949L7.69902 5.87018H22.1088L20.4787 11.6732Z"
                    fill="#1A1818"
                />
            </g>
            <defs>
                <clipPath id="clip0_23:3191">
                    <rect
                        width="22.7333"
                        height="20.0828"
                        fill="white"
                        transform="translate(0.917969 0.00195312)"
                    />
                </clipPath>
            </defs>
        </svg>
    )
}
export default CartIcon
