import { datadogRum } from '@datadog/browser-rum'
import { DataLayerEventNames } from '../gtm/event-types'

export function processDatadogRumAction(
    type: DataLayerEventNames,
    context: any,
) {
    if (!datadogRum) return

    /*
    addAction:(name: string, context?: object | undefined) => void;
    */

    if (type === 'user.auth') {
        if (context.userId) {
            datadogRum.setUser(context)
        } else {
            datadogRum.removeUser()
        }
        return
    }
    datadogRum.addAction(type, context)
}
