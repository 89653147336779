import { gql } from '@apollo/client'

const SUB_ALL_LEAGUES = gql`
    subscription SubAllLeagues($where: leagues_bool_exp = {}) {
        leagues(order_by: { order: asc }, where: $where) {
            id
            logo
            name
            slug
            status
        }
    }
`

const SUB_CLUB_FILTER = gql`
    subscription SUB_CLUB_FILTER(
        $where: clubs_bool_exp
        $limit: Int
        $whereuserpass: user_passes_bool_exp
    ) {
        clubs(where: $where, limit: $limit, order_by: { name: asc }) {
            pass {
                id
                name
                price
                recur
                price_id
                user_passes(where: $whereuserpass) {
                    id
                }
            }
            logo
            id
            display_name
            name
            slug
        }
    }
`

const SUB_USER_PASS = gql`
    subscription UserPasses($where: user_passes_bool_exp = {}) {
        user_passes(where: $where) {
            id
            user_id
            pass_id
            status
            pass {
                id
                active
                name
                description
                price
                recur
                type
            }
        }
    }
`

export default {
    SUB_ALL_LEAGUES,
    SUB_CLUB_FILTER,
    SUB_USER_PASS,
}
