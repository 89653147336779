import * as t from 'redux/types/tab'

const tabReducer = (
    state = {
        selectedTab: '',
    },
    action: any,
) => {
    switch (action.type) {
        case t.SET_SELECTED_TAB:
            return {
                ...state,
                selectedTab: action.payload,
            }

        default:
            return { ...state }
    }
}

export default tabReducer
