import { userIdEvent } from 'lib/tracking/events/userId'
import { useEffect } from 'react'

const useUpdateGTMUserId = (userId?: number) => {
    useEffect(() => {
        if (window.dataLayer) {
            if (userId) {
                const userIdExists = window.dataLayer.some(
                    (data) => data.user_id === userId,
                )
                if (!userIdExists) {
                    const userIdEventValue = {
                        user_id: userId,
                    }
                    userIdEvent({
                        input: userIdEventValue,
                        gtm: true,
                        datadog: true,
                    })
                }
            } else {
                const userIdExists = window.dataLayer.some(
                    (data) => data.user_id !== undefined,
                )
                if (!userIdExists) {
                    const userIdEventValue = {
                        user_id: 'guest user',
                    }
                    userIdEvent({
                        input: userIdEventValue,
                        gtm: true,
                        datadog: true,
                    })
                }
            }
        }
    }, [userId])
}

export default useUpdateGTMUserId
