import { useMutation } from '@apollo/client'
import { Form } from 'antd'
import photo from 'assets/images/player/default-player-image.png'
import { Avatar } from 'components/Avatar'
import { Button } from 'components/Button'
import { Col, Row } from 'components/Layout'
import ButtonLoading from 'components/Loading/ButtonLoading'
import { ImageCrop_Modal } from 'components/Modal'
import { Text } from 'components/Text'
import { ADMINQL } from 'graphql/club'
import _ from 'lodash'
import {
    ClubAdminContext,
    ClubLeagueContext,
} from 'pages/club/[club_slug]/admin'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { BsSave } from 'react-icons/bs'
import { ImCancelCircle } from 'react-icons/im'
import { toast } from 'react-toastify'
import { EditProps } from 'types/components/Modal'
import { s3UploadFile } from 'utils/s3-helper'
import SportsDropDown from '../components/SportsDropDown'
import {
    CustomSelect,
    CustomText,
    CustomeInput,
    ModalBody,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalWrapper,
} from '../index.style'
const { Option } = CustomSelect

const Team_U_Modal: React.FC<EditProps> = ({
    show = false,
    mid,
    leagueClubInfo,
    leagueInfo,
    handleClose,
}) => {
    const league = useContext(ClubLeagueContext) ?? leagueInfo
    const club = useContext(ClubAdminContext) ?? leagueClubInfo

    const leagueData = Array.isArray(league)
        ? league.map((item: any) => ({ label: item.name, value: item.id }))
        : []

    const [formData, setFormData] = useState<any>(
        club?.teams ? club.teams.find((item) => mid === item.id) : [],
    )
    // useState
    const [form] = Form.useForm()
    const [meta, setMeta] = useState<any>(null)
    const [file, setFile] = useState<any>(null)
    const [flag, setFlag] = useState<boolean>(false)
    const [croppedImage, setCroppedImage] = useState<any>(photo)
    const [isSubmit, setSubmiting] = useState<boolean>(false)
    // useRef
    const fileInputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        setFormData(
            club?.teams ? club.teams.find((item) => mid === item.id) : [],
        )
    }, [mid, leagueClubInfo])

    useEffect(() => {
        form.setFieldsValue({
            name: formData?.name,
            league_id: formData?.league_id,
            sports_id: formData?.sport?.id,
            players: formData?.players?.map((data: { id: any }) => data.id),
        })
        setCroppedImage(formData?.image ? formData?.image : photo)
    }, [formData, leagueClubInfo])

    const [update, { error: error1 }] = useMutation(ADMINQL.UPDATE_TEAM_BY_ID)

    const [updatePlayer, { error: error2 }] = useMutation(
        ADMINQL.UPDATE_PLAYERS_TEAM,
    )

    const onTargetClick = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef?.current.click()
        }
    }

    const onFileInputChange = (e: any) => {
        setMeta(e)
        setFlag(true)
    }

    const onFinish = async (values: any) => {
        const { league_id, name, players, sports_id } = values

        setSubmiting(true)
        const slug: string = formData.slug
        let image: string | null = null

        if (!_.isNull(file)) {
            const s3res: any = await s3UploadFile('Teams', slug, file)
            image = s3res.location
        } else {
            if (!_.isEmpty(formData.image)) {
                image = formData.image
            }
        }

        const playersBefore = formData?.players.map(
            (data: { id: any }) => data.id,
        )
        const playersAfterEdit = players
        const playersRemoved = _.difference(playersBefore, playersAfterEdit)

        try {
            await updatePlayer({
                variables: {
                    where: {
                        id: {
                            _in: players,
                        },
                    },
                    _set: {
                        team_id: mid,
                    },
                },
            })

            await updatePlayer({
                variables: {
                    where: {
                        id: {
                            _in: playersRemoved,
                        },
                        team_id: {
                            _eq: mid,
                        },
                    },
                    _set: {
                        team_id: null,
                    },
                },
            })

            await update({
                variables: {
                    id: mid,
                    _set: {
                        image,
                        slug,
                        league_id,
                        name,
                        sports_id,
                    },
                },
            })
            if (!error1 && !error2) {
                toast.success('Team updated')
                setSubmiting(false)
                setFile(null)
                form.resetFields()
                setCroppedImage(photo)
                handleClose && handleClose()
            }
        } catch (error) {
            toast.error('Error Happened.')
            setSubmiting(false)
        }
    }

    const _handleClose = () => {
        setFile(null)
        form.resetFields()
        setCroppedImage(photo)
        handleClose && handleClose()
    }

    const saveImage = async (file: File, imageSrc: any) => {
        setFile(file)
        setCroppedImage(imageSrc)
    }

    return (
        <ModalWrapper open={show} closable={false}>
            <Form
                name="basic"
                form={form}
                onFinish={onFinish}
                layout="vertical"
                style={{ color: 'white' }}
            >
                <ModalContent show={show}>
                    <ModalHeader>
                        <CustomText strong style={{ fontSize: '22px' }}>
                            {'Edit Team'}
                        </CustomText>
                    </ModalHeader>
                    <ModalBody>
                        <Row flexDirection="column" gap={30}>
                            <Row flexDirection="row" gap={50}>
                                <Col item={12}>
                                    <Row
                                        flexDirection="column"
                                        justifyContent="flex-start"
                                        gap={10}
                                    >
                                        <Form.Item
                                            name="name"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Team Name is required.',
                                                },
                                            ]}
                                            label={
                                                <label
                                                    style={{ color: 'white' }}
                                                >
                                                    {'Team Name'}
                                                </label>
                                            }
                                        >
                                            <CustomeInput placeholder="Team Name" />
                                        </Form.Item>

                                        <Form.Item
                                            name="league_id"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'League is required.',
                                                },
                                            ]}
                                            label={
                                                <label
                                                    style={{ color: 'white' }}
                                                >
                                                    {'League'}
                                                </label>
                                            }
                                        >
                                            <CustomSelect
                                                placeholder="League"
                                                options={leagueData}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            name="sports_id"
                                            rules={[
                                                {
                                                    required: true,
                                                    message:
                                                        'Sport is required.',
                                                },
                                            ]}
                                            label={
                                                <label
                                                    style={{ color: 'white' }}
                                                >
                                                    {'Sports'}
                                                </label>
                                            }
                                        >
                                            <SportsDropDown />
                                        </Form.Item>
                                        <Form.Item
                                            name="players"
                                            label={
                                                <label
                                                    style={{ color: 'white' }}
                                                >
                                                    {'Player(s)'}
                                                </label>
                                            }
                                        >
                                            <CustomSelect
                                                placeholder="Add Players"
                                                mode="multiple"
                                            >
                                                {club?.players
                                                    ? club.players.map(
                                                          (player: any) => (
                                                              <Option
                                                                  value={
                                                                      player.id
                                                                  }
                                                                  key={`players-opt-${player.id}`}
                                                              >
                                                                  {_.isNull(
                                                                      player.user,
                                                                  )
                                                                      ? player.email_address
                                                                      : `${player.user.first_name}  ${player.user.last_name}`}
                                                              </Option>
                                                          ),
                                                      )
                                                    : []}
                                            </CustomSelect>
                                        </Form.Item>

                                        <Form.Item
                                            name="admins"
                                            label={
                                                <label
                                                    style={{ color: 'white' }}
                                                >
                                                    {'Team Admin(s)'}
                                                </label>
                                            }
                                        >
                                            <CustomSelect
                                                placeholder="Add Team Admins"
                                                mode="multiple"
                                            >
                                                {club?.team_admin
                                                    ? club?.team_admin.map(
                                                          (admin: any) => (
                                                              <Option
                                                                  key={
                                                                      admin.email_address +
                                                                      '/' +
                                                                      admin.id
                                                                  }
                                                              >
                                                                  {_.isNull(
                                                                      admin.user,
                                                                  )
                                                                      ? admin.email_address
                                                                      : `${admin.user.first_name}  ${admin.user.last_name}`}
                                                                  {' / ' +
                                                                      admin.id}
                                                              </Option>
                                                          ),
                                                      )
                                                    : []}
                                            </CustomSelect>
                                        </Form.Item>
                                    </Row>
                                </Col>
                                <Col item={12}>
                                    <Row flexDirection="column" gap={30}>
                                        <CustomText>{'Team Photo'}</CustomText>
                                        <Row
                                            flexDirection="column"
                                            alignItems="center"
                                            gap={15}
                                        >
                                            <Avatar
                                                src={croppedImage}
                                                mode="big"
                                                radius="small"
                                            />
                                            <Button
                                                bColor="primary"
                                                bSize="small"
                                                type="button"
                                                disabled={isSubmit}
                                                onClick={onTargetClick}
                                            >
                                                {'Upload Photo'}
                                            </Button>
                                            <input
                                                onChange={onFileInputChange}
                                                onClick={(event: any) => {
                                                    event.target.value = null
                                                }}
                                                ref={fileInputRef}
                                                type="file"
                                                style={{ display: 'none' }}
                                                accept="image/png, image/jpeg"
                                            />
                                        </Row>
                                        <Row flexDirection="column" gap={10}>
                                            <Text
                                                fSize={1}
                                                fWeight={700}
                                                mode="p"
                                            ></Text>
                                            <CustomText
                                                style={{
                                                    fontSize: '15px',
                                                    fontWeight: 700,
                                                }}
                                            >
                                                {'Photo Guidelines:'}
                                            </CustomText>
                                            <ul>
                                                <li>
                                                    <CustomText>
                                                        {
                                                            'Accepted file formats:JPG, PNG, SVG'
                                                        }
                                                    </CustomText>
                                                </li>
                                                <li>
                                                    <CustomText>
                                                        {
                                                            'Maximum file size: 25MB'
                                                        }
                                                    </CustomText>
                                                </li>
                                                <li>
                                                    <CustomText>
                                                        {
                                                            'Minimum dimensions: 300 x 300px'
                                                        }
                                                    </CustomText>
                                                </li>
                                            </ul>
                                        </Row>
                                    </Row>
                                </Col>
                            </Row>
                            <Row>
                                <ul>
                                    <li>
                                        <CustomText>
                                            {
                                                'Team Admin will be notified by email.'
                                            }
                                        </CustomText>
                                    </li>
                                    <li>
                                        <CustomText>
                                            {
                                                'If no Streamer account exists for this email, an invite to Sign Up will be sent to this email.'
                                            }
                                        </CustomText>
                                    </li>
                                    <li>
                                        <CustomText>
                                            {
                                                'Team Admin will be able to add / modify / remove Players, Matches, Results and Team Details for this team. Club Admin is administrator for this team by default.'
                                            }
                                        </CustomText>
                                    </li>
                                </ul>
                            </Row>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            bColor="primary"
                            bSize="small"
                            icon={<ImCancelCircle />}
                            onClick={_handleClose}
                            disabled={isSubmit}
                            type="button"
                        >
                            {'Cancel'}
                        </Button>
                        <Button
                            bColor="primary"
                            bSize="small"
                            type="submit"
                            disabled={isSubmit}
                            icon={isSubmit ? <ButtonLoading /> : <BsSave />}
                        >
                            {'Save'}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Form>
            <ImageCrop_Modal
                show={flag}
                meta={meta}
                saveImage={saveImage}
                handleClose={() => setFlag(false)}
            />
        </ModalWrapper>
    )
}

export default Team_U_Modal
