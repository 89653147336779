import { useMutation } from '@apollo/client'
import { Col, Row } from 'components/Layout'
import { Text } from 'components/Text'
import admin from 'graphql/club/admin'
import _ from 'lodash'
import {
    LeagueContext,
    LeagueDetailsContext,
} from 'pages/league/[league_slug]/admin'
import React, { useContext } from 'react'
import { toast } from 'react-toastify'
import ReactToggle from 'react-toggle'
import { s3UploadFile } from 'utils/s3-helper'
import { ApplyButton, PrivacyWrapper } from './privacy.style'

const PrivacySection: React.FC = ({ leagueInfo }: any) => {
    const { leagueDetails } = useContext(LeagueDetailsContext)
    const league = useContext(LeagueContext)
    const [updateLeague] = useMutation(admin.UPDATE_LEAGUE_BY_ID, {
        onCompleted() {
            window.location.reload()
            const newUrl = `/league/${leagueDetails.slug}/admin`
            if (window.location.pathname === newUrl) {
                window.location.reload()
            } else {
                window.location.href = newUrl
            }
        },
        onError(e) {
            toast.error('Error happened.')
        },
    })
    const applyChanges = async () => {
        const slug: string = league?.slug ?? ''
        let image: string | null = null
        let banner_image: string | null = null
        const now = Date.now()

        if (!_.isNull(leagueDetails.file) && leagueDetails.file !== undefined) {
            const s3res: any = await s3UploadFile(
                'Club',
                `${slug}-${now}`,
                leagueDetails.file,
            )
            image = s3res.location
        } else {
            image = leagueInfo?.logo
        }

        if (leagueDetails.banner_image === 'remove') {
            banner_image = ''
        } else if (
            !_.isNull(leagueDetails.banner_file) &&
            leagueDetails.banner_file !== undefined
        ) {
            const s3res2: any = await s3UploadFile(
                'Club',
                `${slug}-banner-${now}`,
                leagueDetails.banner_file,
            )
            banner_image = s3res2.location
        } else {
            banner_image = leagueInfo?.banner_image
        }

        delete leagueDetails.file
        delete leagueDetails.banner_file

        await updateLeague({
            variables: {
                id: league?.id,
                _set: {
                    ...leagueDetails,
                    logo: image,
                    banner_image: banner_image,
                },
            },
        })
    }

    return (
        <Col item={24}>
            <Row
                alignItems="flex-end"
                justifyContent="flex-end"
                padding="20px 0 0 0"
            >
                <ApplyButton bColor="warning" onClick={applyChanges}>
                    Apply Changes
                </ApplyButton>
            </Row>
        </Col>
    )

    // temp remove of privacy

    return (
        <PrivacyWrapper>
            <Row flexDirection="column" gap={20}>
                <Col item={24}>
                    <Text fSize={1.5} fWeight={700} mode="p" fColor="gray.600">
                        {'Privacy'}
                    </Text>
                    <Text
                        fSize={0.9375}
                        fWeight={600}
                        mode="p"
                        fColor="gray.600"
                        padding="10px 0"
                    >
                        {'Private Profile'}
                    </Text>
                </Col>

                <Col item={24}>
                    <Row gap={10} flexDirection="column">
                        <Row justifyContent="space-between">
                            <Col>
                                <Text
                                    fSize={0.875}
                                    fWeight={500}
                                    fColor="gray.300"
                                >
                                    {
                                        'Only people who you share your profile domain with will be able to access your content.'
                                    }
                                </Text>
                            </Col>
                            <Col item={1}>
                                <ReactToggle
                                    onChange={() => console.log(`1`)}
                                />{' '}
                                {/* Convert to andt swtich */}
                            </Col>
                        </Row>
                        <Row justifyContent="space-between">
                            <Col>
                                <Text
                                    fSize={0.875}
                                    fWeight={500}
                                    fColor="gray.300"
                                >
                                    {'Placeholder.'}
                                </Text>
                            </Col>
                            <Col item={1}>
                                <ReactToggle
                                    onChange={() => console.log(`2`)}
                                />{' '}
                                {/* Convert to andt swtich */}
                            </Col>
                        </Row>
                    </Row>
                </Col>

                <Col item={24}>
                    <Row
                        alignItems="flex-end"
                        justifyContent="flex-end"
                        padding="20px 0 0 0"
                    >
                        <ApplyButton bColor="warning" onClick={applyChanges}>
                            Apply Changes
                        </ApplyButton>
                    </Row>
                </Col>
            </Row>
        </PrivacyWrapper>
    )
}

export default PrivacySection
