import styled from 'styled-components'

export const StyledTd = styled.td`
    padding: 25px 0 0 20px;
    flex: 1;
`

export const StyledTBody = styled.tbody`
    overflow: auto;
    overflow-x: hidden;
    display: block;
    width: 100%;
    height: 48vh;

    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 100vw;
    }
`
