import { useCallback, useEffect, useState } from 'react'

// components
// views
import { useRouter } from 'hooks'
import { getSiteSettings } from 'hooks/settings'
import PreviewMenu from 'sections/club/home/PreviewMenu'
import { isAfter } from 'utils/helper-date'
import { DeskHeader } from './DeskTop/Header'
import GlobalHeader from './GlobalHeader'
import { HeaderWrapper } from './Header.style'
import { MobileHeader } from './Mobile/Header'

// -------------------------------------------------------------------
const useMediaQuery = (width: number) => {
    const [targetReached, setTargetReached] = useState(false)

    const updateTarget = useCallback((e: { matches: any }) => {
        if (e.matches) {
            setTargetReached(true)
        } else {
            setTargetReached(false)
        }
    }, [])

    useEffect(() => {
        const media = window.matchMedia(`(max-width: ${width}px)`)
        media.addListener(updateTarget)

        // Check on mount (callback is not called until a change occurs)
        if (media.matches) {
            setTargetReached(true)
        }

        return () => media.removeListener(updateTarget)
    }, [])

    return targetReached
}

const Header = (props: { menu: any }) => {
    const { query } = useRouter()
    const isBreakpoint = useMediaQuery(768)
    const showBHPLogo =
        isAfter('2023-09-02T04:00:00.000+08:00') ||
        getSiteSettings().global.override_show_bhp_logo
    return (
        <HeaderWrapper id="headerSticky">
            <GlobalHeader />
            {isBreakpoint ? (
                <MobileHeader menu={props.menu} showBHPLogo={showBHPLogo} />
            ) : (
                <DeskHeader menu={props.menu} showBHPLogo={showBHPLogo} />
            )}
            {query.preview && <PreviewMenu clubSlug={query.club_slug} />}
        </HeaderWrapper>
    )
}

export default Header
