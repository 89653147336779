import { datadogRum } from '@datadog/browser-rum'

export const initialiseDatadogSdk = () => {
    datadogRum.init({
        applicationId: process.env.NEXT_PUBLIC_DATADOG_APPLICATION_ID as string,
        clientToken: process.env.NEXT_PUBLIC_DATADOG_CLIENT_TOKEN as string,
        site: 'datadoghq.com',
        service: 'streamer',
        env: process.env.NEXT_PUBLIC_ENVIRONMENT_MODE,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 0,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
        //specify a custom attribute that is used to name the action
        actionNameAttribute: 'datadog-rum-name',
        beforeSend: (event) => {
            // Get rid of the noise wall caused by GPT ad errors
            const message = (event.error as any)?.message
            if (
                message &&
                (message.toString().includes('setTargeting') ||
                    message.toString().includes('defineSlot'))
            ) {
                return false
            }
        },
    })

    datadogRum.startSessionReplayRecording()
}
