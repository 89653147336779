import React from 'react'
// type
import { IconProps } from 'types/components/Icon'

// -----------------------------------------------
const StopIcon: React.FC<IconProps> = ({
    iColor = '#1890FF',
    iSize = { x: 20, y: 21 },
}) => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={iSize.x}
            height={iSize.y}
            viewBox="0 0 455 455"
            xmlSpace="preserve"
        >
            <g>
                <g>
                    <g>
                        <path
                            d="M227.5,0C101.761,0,0,101.75,0,227.5C0,353.239,101.75,455,227.5,455C353.24,455,455,353.25,455,227.5
               C455,101.761,353.25,0,227.5,0z M227.5,425C118.598,425,30,336.402,30,227.5S118.598,30,227.5,30S425,118.598,425,227.5
               S336.403,425,227.5,425z"
                            fill={iColor}
                        />
                        <path
                            d="M289.139,142.359H165.862c-12.959,0-23.502,10.543-23.502,23.502v123.278c0,12.959,10.543,23.502,23.502,23.502h123.277
               c12.96,0,23.503-10.543,23.503-23.502V165.861C312.642,152.902,302.099,142.359,289.139,142.359z M282.642,282.642H172.36
               V172.359h110.282V282.642z"
                            fill={iColor}
                        />
                    </g>
                </g>
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
        </svg>
    )
}
export default StopIcon
