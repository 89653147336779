import { Row } from 'components/Layout'
import styled, { css } from 'styled-components'
import { defaultTheme } from 'theme'

export const StyledModal = styled.div<{ show: boolean }>`
    position: fixed;
    display: flex;
    z-index: 16;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.6);
    ${({ show }) =>
        show
            ? css`
                  visibility: visible;
              `
            : css`
                  visibility: hidden;
              `};
`

export const ModalContent = styled.div`
    border-radius: 20px;
    border: 3px solid rgb(64 63 63);
    background-color: rgb(76 75 75);
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 25%;
    width: 50%;
    max-width: 450px;
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        width: 90%;
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        width: 95%;
    }
`

export const ModalHeader = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 2.3;
    width: 90%;
`

export const ModalFooter = styled(Row)`
    justify-content: center;
    align-items: flex-start;
    flex: 0.8;
    transition: 0;
`
