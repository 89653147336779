import React from 'react'
import { ResponsivedTextProps } from 'types/components/Text'
import {
    AnchorWrapper,
    Header2Wrapper,
    Header3Wrapper,
    HeaderWrapper,
    TextSpanWrapper,
    TextWrapper,
} from './Text.style'

const Text: React.FC<ResponsivedTextProps> = ({
    mode = 'p',
    children,
    ...props
}) => {
    let Wrapper
    // Fix crash when passing style prop to styled components
    delete props.style
    switch (mode) {
        case 'p':
            Wrapper = TextWrapper
            break
        case 'span':
            Wrapper = TextSpanWrapper
            break
        case 'h1':
            Wrapper = HeaderWrapper
            break
        case 'h2':
            Wrapper = Header2Wrapper
            break
        case 'h3':
            Wrapper = Header3Wrapper
            break
        case 'a':
            Wrapper = AnchorWrapper
            break
        default:
            Wrapper = TextWrapper
    }

    return <Wrapper {...props}>{children}</Wrapper>
}
export default Text
