import { gql } from '@apollo/client'

const GET_ALL_LEAGUES = gql`
    query GetAllLeagues {
        leagues(order_by: { order: asc }) {
            id
            logo
            name
            slug
            status
        }
    }
`

const GET_SUB_LEAGUES = gql`
    query LeagueSubscription($where: leagues_bool_exp) {
        leagues(where: $where) {
            id
            logo
            name
            slug
            status
        }
    }
`

const GET_LEAGUE_ADMINS_DETAILS = gql`
    query SubLeague($where: leagues_bool_exp) {
        leagues(order_by: { name: asc }, where: $where) {
            id
            logo
            name
            slug
            status
            league_pass_id
            sports {
                id
                name
                icon
            }
            league_pass {
                id
                active
                name
                description
                price
                recur
                type
            }
            community_members {
                community {
                    id
                    name
                    display_name
                    slug
                    status
                }
            }
            league_admins {
                id
                is_accepted
                is_declined
                name
                status
                email_address
                league_id
                user {
                    active
                    first_name
                    id
                    last_name
                }
            }
        }
    }
`

export default {
    GET_ALL_LEAGUES,
    GET_SUB_LEAGUES,
    GET_LEAGUE_ADMINS_DETAILS,
}
