import React from 'react'

import { WithContainer } from 'components/Container'
import DisplaySection from './Display'

const AnalyticsView: React.FC = () => {
    return (
        <>
            <WithContainer SectionView={DisplaySection}></WithContainer>
        </>
    )
}

export default AnalyticsView
