import { useMutation } from '@apollo/client'
import { Button } from 'components/Button'
import { Text } from 'components/Text'
import { CLIPS } from 'graphql/club'
import { useState } from 'react'
import { toast } from 'react-toastify'
import { defaultTheme } from 'theme'
import {
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalWrapper,
} from './rejectClipModal.style'

const RejectClipModal = (props: {
    handleShow: (arg0: { show: boolean; clipId: number }) => void
    handleShowPlayer: (arg0: boolean) => void
    setSelectedClip: (arg0: null) => void
    clipId: any
    show: boolean
}) => {
    const [buttonLoading, setButtonLoading] = useState(false)
    const [rejectClip] = useMutation(CLIPS.UPDATE_CLIP_ASSET, {
        onCompleted() {
            setButtonLoading(false)
            props.handleShow({ show: false, clipId: 0 })
            props.handleShowPlayer(false)
            props.setSelectedClip(null)
            toast.success('Clip rejected.')
        },
        onError(e) {
            setButtonLoading(false)
            props.handleShow({ show: false, clipId: 0 })
            props.handleShowPlayer(false)
            props.setSelectedClip(null)
            toast.error('Something went wrong.')
        },
    })

    const handleReject = () => {
        setButtonLoading(true)
        rejectClip({
            variables: {
                where: { id: { _eq: props.clipId } },
                set: { approval_status: 'rejected' },
            },
        })
    }

    return (
        <>
            <ModalWrapper show={props.show}>
                <ModalContent>
                    <ModalHeader>
                        <Text
                            fSize={1.5}
                            fWeight={600}
                            tAlign={'center'}
                            style={`
                                @media screen and (max-width: ${defaultTheme.mediaSize.xs}px) {
                                    font-size: 1.2rem;
                                }
                            `}
                        >
                            Confirm Rejection
                        </Text>
                        <Text
                            fSize={0.9}
                            fWeight={600}
                            tAlign={'center'}
                            style={`
                  color: gray;
                 margin-top: 1em;
                  @media screen and (max-width: ${defaultTheme.mediaSize.xs}px) {
                    font-size: .75rem;
                    margin-top: 0;
                `}
                        >
                            Are you sure you want to reject publishing this
                            clip? This clip will still be accessible by the user
                            who created it, however will not be posted to your
                            Club Page or the Streamer Home Page.
                        </Text>
                    </ModalHeader>
                    <ModalFooter>
                        <Button
                            onClick={() =>
                                props.handleShow({ show: false, clipId: 0 })
                            }
                            style={`
                                border: 0;
                                color: gray;
                                background-color: rgb(43 39 42);
                                width: 20%;
                                min-width: 5em;
                                transition: none;
                                @media screen and (max-width: ${defaultTheme.mediaSize.xs}px) {
                                    font-size: 0.8rem;
                                }
                            `}
                        >
                            Cancel
                        </Button>
                        <Button
                            loading={buttonLoading}
                            disabled={buttonLoading}
                            onClick={() => handleReject()}
                            variant="warning"
                            style={`
                                background-color: rgb(199 33 35);
                                margin-left: 20px;
                                width: 20%;
                                min-width: 5em;
                                transition: none;
                                @media screen and (max-width: ${defaultTheme.mediaSize.xs}px) {
                                    font-size: 0.8rem;
                                }
                            `}
                        >
                            Confirm
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </ModalWrapper>
        </>
    )
}

export default RejectClipModal
