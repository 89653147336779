import { useLazyQuery } from '@apollo/client'
import { ADMINQL } from 'graphql/club'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { MULTI_SPORT } from 'utils/constData'
import { CustomSelect } from './dropdown.style'

const { Option } = CustomSelect

function TeamFilterdSelect({ club_id, sport_name, ...rest }: any) {
    const [data, setData] = useState<Partial<Array<any>>>([])
    const router = useRouter()
    const { league_slug } = router.query

    const [pullData] = useLazyQuery(ADMINQL.GET_ALL_TEAMS, {
        onCompleted(data) {
            data.teams && setData(data.teams)
        },
    })

    const sportsId =
        sport_name === MULTI_SPORT
            ? {}
            : {
                  sport: {
                      name: {
                          _eq: sport_name,
                      },
                  },
              }
    const leagueSlug = league_slug
        ? { league: { slug: { _eq: league_slug } } }
        : {}

    useEffect(() => {
        pullData({
            variables: {
                where: {
                    club_id: { _eq: club_id },
                    ...sportsId,
                    ...leagueSlug,
                },
            },
        })
    }, [club_id, sport_name])

    return (
        <CustomSelect
            {...rest}
            showSearch
            placeholder="Opposition Team"
            allowClear
            filterOption={(inputValue, option) =>
                option!.children
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
            }
        >
            {club_id &&
                data.map((team, i) => (
                    <Option value={team.id} key={`dd-team-fuzzy-${i}`}>
                        {team.name}
                    </Option>
                ))}
        </CustomSelect>
    )
}

export default TeamFilterdSelect
