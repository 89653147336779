import { Progress } from 'antd'
import axios from 'axios'
import { Button } from 'components/Button/index'
import { Text } from 'components/Text'
import fileDownload from 'js-file-download'
import { clipDownloadEvent } from 'lib/tracking/events/clip'
import { useEffect, useState } from 'react'
import { FiDownload } from 'react-icons/fi'
import { getClipBasics } from 'restful-client/clips'
import { ClipBasics } from 'restful-client/types/clips'
import str from 'string-sanitizer'
import styled from 'styled-components'
import { defaultTheme } from 'theme'

interface Props {
    playbackId: string | undefined
}

export const Container = styled.div`
    display: flex;
    align-items: center;
`

export const BtnText = styled.span`
    font-size: 1.1em;
    &::after {
        content: '';
    }
    @media screen and (min-width: ${defaultTheme.mediaSize.md}px) {
        &::after {
            content: 'Download Clip';
        }
    }
`

export const ProgressWrapper = styled.div`
    margin-right: 10px;
    .ant-progress-inner:not(.ant-progress-circle-gradient)
        .ant-progress-circle-path {
        stroke: red;
    }
    .ant-progress-circle .ant-progress-text {
        color: white;
    }
`

const PreparingText = () => (
    <Text fSize={0.8} fWeight={100}>
        {' '}
        Preparing File for Download.{' '}
    </Text>
)

function FeatureClipsDownloadButton(props: {
    playbackId: string
    clipId: number
    clipName: string
    league: string
}) {
    const { playbackId, league, clipId, clipName } = props

    /** To prevent loading the subscription if playbackId is undefined */
    const [progress, setProgress] = useState(0)
    const [clipData, setClipData] = useState<ClipBasics[] | undefined>(
        undefined,
    )

    useEffect(() => {
        async function getData() {
            if (playbackId) {
                const data = await getClipBasics(playbackId)
                if (data?.clip_assets) {
                    setClipData(data.clip_assets)
                }
            }
        }
        getData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [playbackId])

    if (!playbackId) {
        return <></>
    }

    if (!clipData || clipData?.length === 0) {
        return (
            <Container>
                <PreparingText />
            </Container>
        )
    }

    const clip = clipData[0]
    if (!clip?.static_file_status) {
        return <></>
    }

    const downloadHandler = async () => {
        if (clip.static_file_status === 'ready') {
            clipDownloadEvent({
                props: { league: league, clip_id: clipId, clip_name: clipName },
            })

            try {
                const res = await axios.get(
                    `https://stream.mux.com/${playbackId}/high.mp4`,
                    {
                        responseType: 'blob',
                        onDownloadProgress: (progressEvent: any) => {
                            const percentCompleted =
                                (progressEvent?.total &&
                                    Math.round(
                                        (progressEvent.loaded * 100) /
                                            progressEvent.total,
                                    )) ||
                                0
                            setProgress(
                                percentCompleted < 100 ? percentCompleted : 0,
                            )
                        },
                    },
                )

                if (res && res?.data)
                    fileDownload(
                        res.data,
                        `${clip.name ? str.addDash(clip.name) : 'video'}.mp4`,
                    )
            } catch (e) {
                // Try download Medium Resolution if High is not available

                try {
                    const res = await axios.get(
                        `https://stream.mux.com/${playbackId}/medium.mp4`,
                        {
                            responseType: 'blob',
                            onDownloadProgress: (progressEvent) => {
                                const percentCompleted =
                                    (progressEvent?.total &&
                                        Math.round(
                                            (progressEvent.loaded * 100) /
                                                progressEvent.total,
                                        )) ||
                                    0
                                setProgress(
                                    percentCompleted < 100
                                        ? percentCompleted
                                        : 0,
                                )
                            },
                        },
                    )

                    if (res && res?.data)
                        fileDownload(
                            res.data,
                            `${
                                clip.name ? str.addDash(clip.name) : 'video'
                            }.mp4`,
                        )
                } catch (e) {
                    // Try download Low Resolution if Medium is not available

                    const res = await axios.get(
                        `https://stream.mux.com/${playbackId}/low.mp4`,
                        {
                            responseType: 'blob',
                            onDownloadProgress: (progressEvent) => {
                                const percentCompleted =
                                    (progressEvent?.total &&
                                        Math.round(
                                            (progressEvent.loaded * 100) /
                                                progressEvent.total,
                                        )) ||
                                    0
                                setProgress(
                                    percentCompleted < 100
                                        ? percentCompleted
                                        : 0,
                                )
                            },
                        },
                    )

                    if (res && res?.data)
                        fileDownload(
                            res.data,
                            `${
                                clip.name ? str.addDash(clip.name) : 'video'
                            }.mp4`,
                        )
                }
            }
        }
    }

    return (
        <>
            {clip.static_file_status === 'preparing' && <PreparingText />}

            {!!progress && (
                <ProgressWrapper>
                    <Progress type="circle" percent={progress} width={35} />
                </ProgressWrapper>
            )}

            {!progress && clip.static_file_status === 'ready' && (
                <Button
                    onClick={downloadHandler}
                    bColor="warning"
                    bSize="small"
                    icon={<FiDownload />}
                    className="actionBtn"
                >
                    <BtnText />
                </Button>
            )}
        </>
    )
}

export default FeatureClipsDownloadButton
