import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

interface DivContainerInterface {
    direction?: 'row' | 'column'
    justify?:
        | 'space-around'
        | 'space-evenly'
        | 'space-between'
        | 'center'
        | 'flex-start'
        | 'flex-end'
    maxWidth?: string
    width?: string
    align?: 'flex-start' | 'flex-end' | 'center' | 'baseline' | 'stretch'
    w?: string | number
    h?: string | number
    maxw?: string | number
    padding?: string | number
    margin?: string
    content?:
        | 'flex-start'
        | 'flex-end'
        | 'center'
        | 'space-between'
        | 'space-around'
        | 'stretch'
}

const CommonDiv = styled.div<DivContainerInterface>`
    display: flex;
    flex-direction: ${(props) => props.direction ?? 'column'};
    justify-content: ${(props) => props.justify ?? 'flex-start'};
    align-items: ${(props) => props.align ?? 'flex-start'};
    width: ${(props) => props.w ?? `auto`};
    height: ${(props) => props.h ?? `auto`};
    max-width: ${(props) => props.maxw ?? `100%`};
    padding: ${(props) => props.padding ?? `0px`};
    margin: ${(props) => props.margin ?? `0px`};
    align-content: ${(props) => props.content ?? `center`};
`

export default CommonDiv
