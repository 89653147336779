import { gql } from '@apollo/client'

const ADD_LEAGUE = gql`
    mutation AddNewLegue($objects: [leagues_insert_input!] = {}) {
        insert_leagues(objects: $objects) {
            returning {
                id
                name
            }
        }
    }
`

export default {
    ADD_LEAGUE,
}
