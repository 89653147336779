import fetchRetry from 'fetch-retry'
export interface HttpResponse<T> extends Response {
    parsedBody?: T
}

/*
default behavior of retry is to only retry requests on network issues, but it can be configured to be retry on specific HTTP Status code like 503
by passing retryOn: [503] in the config
*/
const fetchWithRetry = fetchRetry(fetch, { retries: 3, retryDelay: 1000 })

export async function parsedRequest<T>(
    request: Request,
    parseRequest = true,
): Promise<HttpResponse<T>> {
    const response: HttpResponse<T> = await fetchWithRetry(request)
    if (response.ok || response.status === 200 || response.status === 201) {
        response.parsedBody = parseRequest ? await response.json() : {}
        return response
    }

    throw new Error(response.statusText)
}

export async function get<T>(
    path: string,
    args: RequestInit = { method: 'get' },
): Promise<HttpResponse<T>> {
    return await parsedRequest<T>(new Request(path, args))
}

export async function post<T>(
    path: string,
    body: any,
    args: RequestInit = {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
    },
): Promise<HttpResponse<T>> {
    return await parsedRequest<T>(new Request(path, args))
}
