import React from 'react'
import { defaultTheme } from 'theme'
// styled component
import styled from 'styled-components'
// type
type Props = {
    children: React.ReactNode
}

// ----------------------------------------------------------
const ContainerContent = styled.div`
    padding: 0 38px;
    margin: 0 auto;
    max-width: none;
    @media screen and (min-width: ${defaultTheme.mediaSize.md}px) {
        padding: 0 40px;
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        padding: 0 5px;
    }
`

const Container: React.FC<Props> = ({ children }) => {
    return (
        <ContainerContent className="containerContent">
            {children}
        </ContainerContent>
    )
}

export default Container
