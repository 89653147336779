import * as t from 'redux/types/watch'

const matchReducer = (
    state = {
        live: false,
        adIsPlaying: false,
        isFullScreen: false,
    },
    action: any,
) => {
    switch (action.type) {
        case t.SET_LIVE_WATCH:
            return { ...state, live: action.payload }
        case t.SET_AD_IS_PLAYING:
            return { ...state, adIsPlaying: action.payload }
        case t.SET_IS_FULLSCREEN:
            return { ...state, isFullScreen: action.payload }
        default:
            return { ...state }
    }
}

export default matchReducer
