import React from 'react'
// import { FiInbox, FiFlag, FiUsers, FiCalendar, FiGlobe, FiAward, FiFileText } from "react-icons/fi";
import { Text } from 'components/Text'
import * as Icon from 'react-icons/fi'
import { commonItem } from 'types/common/common'
import {
    AsideWrapper,
    DeskMenuText,
    MenuItem,
    MobileMenuText,
} from './aside.style'

const AsideView: React.FC<{
    menudata: commonItem[]
    select: string
    onHandleSelect: any
}> = ({ menudata, select, onHandleSelect }) => {
    const onHandleClick = (path: string) => {
        onHandleSelect(path)
    }

    const IconDynamic = (props: { icon: string }) => {
        const IconComponent = (Icon as any)[props.icon]
        return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <IconComponent />
            </div>
        )
    }

    return (
        <AsideWrapper>
            {menudata.map((item: commonItem, index: number) => {
                return (
                    <MenuItem
                        key={index}
                        onClick={() => onHandleClick(item.path)}
                        select={select === item.path}
                    >
                        <IconDynamic icon={item.icon ?? ''} />
                        <DeskMenuText>
                            <Text
                                fWeight={600}
                                fSize={1.25}
                                hoverStyle={
                                    select !== item.path
                                        ? { fColor: 'gray.200' }
                                        : {}
                                }
                            >
                                {item.title}
                            </Text>
                        </DeskMenuText>
                        <MobileMenuText>
                            <Text
                                fWeight={600}
                                fSize={1.25}
                                hoverStyle={
                                    select !== item.path
                                        ? { fColor: 'gray.200' }
                                        : {}
                                }
                            >
                                {item.title.split(' ')[0]}
                            </Text>
                        </MobileMenuText>
                    </MenuItem>
                )
            })}
        </AsideWrapper>
    )
}

export default AsideView
