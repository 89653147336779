import React from 'react'
// type
import { IconProps } from 'types/components/Icon'

// -----------------------------------------------
const RewindButtonIcon: React.FC<IconProps> = ({
    onClick = () => onClick,
    iColor = 'white',
    iSize = { x: 40, y: 40 },
}) => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width={iSize.x}
            height={iSize.y}
            onClick={onClick}
            viewBox="0 0 1600.000000 1600.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g
                transform="translate(0.000000,1600.000000) scale(0.100000,-0.100000)"
                fill={iColor}
                stroke="none"
            >
                <path
                    d="M7690 15359 c-826 -23 -1663 -198 -2450 -511 -749 -298 -1435 -714
   -2066 -1255 -265 -227 -652 -624 -884 -907 l-65 -79 -5 1109 -5 1109 -24 45
   c-32 61 -86 116 -146 148 -43 23 -62 27 -140 27 -78 0 -97 -4 -140 -27 -60
   -32 -114 -87 -146 -148 l-24 -45 -3 -1632 -2 -1633 1620 0 c1617 0 1619 0
   1675 21 74 28 146 100 174 174 61 161 -11 336 -168 409 -46 21 -49 21 -1115
   26 l-1069 5 125 150 c1300 1552 3229 2428 5243 2382 699 -17 1325 -124 1966
   -337 846 -281 1613 -721 2294 -1314 143 -124 459 -439 588 -586 900 -1020
   1463 -2262 1626 -3585 40 -323 46 -433 46 -855 -1 -444 -10 -583 -61 -940
   -233 -1626 -1056 -3102 -2319 -4160 -954 -798 -2096 -1308 -3325 -1484 -357
   -51 -496 -60 -940 -61 -422 0 -532 6 -855 46 -1311 162 -2543 716 -3560 1603
   -143 124 -459 439 -588 586 -902 1023 -1462 2264 -1626 3605 -26 214 -40 404
   -50 675 -9 266 -18 298 -107 379 -65 59 -129 83 -221 82 -123 -1 -227 -71
   -280 -188 -24 -52 -25 -62 -24 -256 3 -507 74 -1069 201 -1592 473 -1950 1728
   -3618 3478 -4622 1894 -1086 4197 -1261 6242 -473 920 354 1764 897 2479 1595
   724 707 1299 1568 1678 2509 686 1707 693 3656 20 5366 -504 1281 -1344 2380
   -2447 3202 -1137 847 -2495 1344 -3900 1428 -239 14 -422 16 -700 9z"
                />
                <path
                    d="M5710 10533 c0 -81 -23 -237 -46 -313 -25 -84 -89 -200 -145 -263
   -162 -182 -480 -286 -964 -315 l-120 -7 -3 -307 -2 -308 640 0 640 0 2 -1747
   3 -1748 318 -3 317 -2 0 2540 0 2540 -320 0 -320 0 0 -67z"
                />
                <path
                    d="M9085 10589 c-410 -49 -758 -223 -979 -487 -267 -322 -410 -750 -468
   -1402 -18 -201 -18 -1207 0 -1385 40 -399 109 -684 229 -935 229 -483 613
   -755 1179 -836 160 -23 478 -23 629 0 774 118 1213 610 1369 1537 42 244 57
   450 63 839 13 885 -70 1423 -287 1861 -74 149 -151 259 -262 374 -161 168
   -337 279 -564 354 -192 65 -319 83 -594 87 -129 1 -271 -2 -315 -7z m585 -649
   c466 -95 690 -447 774 -1216 47 -421 40 -1064 -14 -1454 -112 -805 -405 -1110
   -1065 -1110 -378 0 -629 92 -804 295 -66 76 -161 268 -200 402 -36 124 -76
   359 -93 548 -15 181 -15 986 1 1200 45 606 179 988 409 1170 118 94 302 162
   497 185 128 14 374 5 495 -20z"
                />
            </g>
        </svg>
    )
}
export default RewindButtonIcon
