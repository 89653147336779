import bannerPlaceholder from 'assets/images/placeholder/banner-preview-placeholder.png'
import { Button } from 'components/Button'
import { SportsDropdown } from 'components/Dropdown'
import { Input, LinkInput } from 'components/Input'
import { Row } from 'components/Layout'
import { ImageCrop_Modal } from 'components/Modal'
import { Text } from 'components/Text'
import { ClubDetailsContext } from 'pages/club/[club_slug]/admin'
import React, {
    createContext,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { connect } from 'react-redux'
import { ClubInfo } from 'types/common/club'
import { ContentWrapper, HeadingWrapper } from '../components/DivWrappers'
import FormItemWrapper from '../components/FormItemWrapper'
import { CommonBrowser } from './CommonBrowser'
import { ImageBroswer } from './ImageBrowser'
import { AdminGroupLogo } from './profile.style'

export const ProfileImageContext = createContext<any>({})

const Profile: React.FC<{ clubInfo: ClubInfo }> = ({ clubInfo }) => {
    // const club = useContext(ClubAdminContext);
    const { clubDetails, setClubDetails } = useContext(ClubDetailsContext)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [files, setFiles] = useState([])
    const [image, setImage] = useState<any>(null)
    const [flag, setFlag] = useState<boolean>(false)
    const [croppedImage, setCroppedImage] = useState<any>(null)

    const bannerFileInputRef = useRef<HTMLInputElement>(null)
    const [bannerFiles, setBannerFiles] = useState([])
    const [bannerImage, setBannerImage] = useState<any>(null)
    const [bannerFlag, setBannerFlag] = useState<boolean>(false)
    const [bannerCroppedImage, setBannerCroppedImage] = useState<any>(null)

    const saveImage = async (file: any, imageSrc: any) => {
        setFiles(file)
        setCroppedImage(imageSrc)
        setClubDetails({ ...clubDetails, file: file })
    }

    const saveBannerImage = async (file: any, imageSrc: any) => {
        setBannerFiles(file)
        setBannerCroppedImage(imageSrc)
        setClubDetails({ ...clubDetails, banner_file: file })
    }

    const removeBannerImage = () => {
        setBannerCroppedImage(bannerPlaceholder)
        setClubDetails({ ...clubDetails, banner_image: 'remove' })
    }

    useEffect(() => {
        if (clubInfo?.banner_image === '') {
            setBannerCroppedImage(bannerPlaceholder)
        } else {
            setBannerCroppedImage(clubInfo?.banner_image)
        }

        setCroppedImage(clubInfo?.logo)

        setClubDetails({
            ...clubDetails,
            banner_image: clubInfo.banner_image,
            logo: clubInfo.logo,
            name: clubInfo.name,
            slug: clubInfo.slug,
            sport_id: clubInfo.sport_id,
        })
    }, [clubInfo])

    const onHandleCopy = async (link: string) => {
        await navigator.clipboard.writeText(link)
    }

    const values = {
        image,
        setImage,
        fileInputRef,
        files,
        setFiles,
        flag,
        setFlag,

        bannerImage,
        setBannerImage,
        bannerFileInputRef,
        bannerFiles,
        setBannerFiles,
        bannerFlag,
        setBannerFlag,
    }

    return (
        <ProfileImageContext.Provider value={values}>
            <ContentWrapper>
                <HeadingWrapper>
                    <Text fSize={1.5} fWeight={700} mode="h2" fColor="gray.600">
                        Profile Details
                    </Text>
                    <Text fSize={0.875} fWeight={500} fColor="#E5E5E5">
                        This information will appear on your Club Portal.
                    </Text>
                </HeadingWrapper>

                <div>
                    <FormItemWrapper label="Club Logo">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '8px',
                                paddingTop: '8px',
                            }}
                        >
                            {croppedImage && (
                                <AdminGroupLogo
                                    src={croppedImage}
                                    width={160}
                                    height={160}
                                    alt=""
                                />
                            )}
                            <CommonBrowser />
                        </div>
                    </FormItemWrapper>

                    <FormItemWrapper label="Club Banner Image">
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                gap: '8px',
                                paddingTop: '8px',
                            }}
                        >
                            {bannerCroppedImage && (
                                <AdminGroupLogo
                                    src={bannerCroppedImage}
                                    oFit={'contain'}
                                    width={500}
                                    height={200}
                                    alt={'No Banner Image'}
                                />
                            )}
                            <Row
                                style={{
                                    marginTop: 15,
                                    justifyContent: 'center',
                                    display: 'flex',
                                    gap: '10px',
                                }}
                            >
                                <Button onClick={removeBannerImage}>
                                    Remove
                                </Button>
                                <ImageBroswer />
                            </Row>
                        </div>
                    </FormItemWrapper>

                    <FormItemWrapper label="Club Name">
                        <Input
                            iColor="primary"
                            iSize="small"
                            iFont="normal"
                            iRadius="small"
                            placeholder="Club Name ..."
                            defaultValue={clubDetails.name}
                            onChange={(e: { target: { value: any } }) =>
                                setClubDetails({
                                    ...clubDetails,
                                    name: e.target.value,
                                })
                            }
                        />
                    </FormItemWrapper>

                    <FormItemWrapper label="Club Domain">
                        <LinkInput
                            onChange={(e) =>
                                setClubDetails({
                                    ...clubDetails,
                                    slug: e.target.value,
                                })
                            }
                            onCopyLink={onHandleCopy}
                            value={clubDetails.slug}
                            prefix="www.streamer.com.au/club"
                        />
                    </FormItemWrapper>

                    <FormItemWrapper label="Sport">
                        <SportsDropdown
                            value={clubDetails.sport_id}
                            onChange={(e) => {
                                setClubDetails({
                                    ...clubDetails,
                                    sport_id: e,
                                })
                            }}
                        />
                    </FormItemWrapper>
                </div>
            </ContentWrapper>
            <ImageCrop_Modal
                show={flag}
                meta={image}
                cropShape="rect"
                saveImage={saveImage}
                handleClose={() => setFlag(false)}
            />
            <ImageCrop_Modal
                show={bannerFlag}
                meta={bannerImage}
                saveImage={saveBannerImage}
                handleClose={() => setBannerFlag(false)}
                cropShape="rect"
                aspect={3}
            />
        </ProfileImageContext.Provider>
    )
}

const mapStateToProps = (state: { club: { info: any } }) => ({
    clubInfo: state.club.info,
})

const mapDispatchToProps = {}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(Profile)
