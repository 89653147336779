import _ from 'lodash'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/store'
import { USER_ROLE } from 'utils/constData'
export const useAdminAccess = (
    league_slug?: string,
    club_slug?: string,
): {
    showLeagueAdminLink: boolean
    showClubAdminLink: boolean
} => {
    const userInfo = useSelector((state: AppState) => state?.user)

    const clubInfo = useSelector((state: AppState) => state?.club.info)

    const isSuperAdmin = userInfo.user_role_id === USER_ROLE.ADMIN

    //check if user is the league admin with current club path
    const isLeagueAdminWithClubPath =
        !_.isEmpty(clubInfo) &&
        userInfo.league_admin_details.some(
            (leagueElement: any) =>
                leagueElement.league_id === clubInfo.teams[0].league_id,
        )
    //check if user is league admin with current league path
    const showLeagueAdminLink =
        isSuperAdmin ||
        userInfo.league_admin_details.some(
            (leagueElement: any) => leagueElement.league.slug == league_slug,
        )
    //check if user is the club admin with current club path
    const showClubAdminLink =
        isSuperAdmin ||
        isLeagueAdminWithClubPath ||
        userInfo.club_admin_details.some(
            (clubElement: any) => clubElement.club.slug === club_slug,
        )

    return {
        showLeagueAdminLink,
        showClubAdminLink,
    }
}
