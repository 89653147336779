import styled, { css } from 'styled-components'
import { defaultTheme } from 'theme'

export const ModalWrapper = styled.div<{ show: boolean }>`
    position: fixed;
    flex-direction: column;
    display: flex;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    padding: 30px;

    ${({ show }) =>
        show
            ? css`
                  visibility: visible;
              `
            : css`
                  visibility: hidden;
              `};
`

export const ModalHeader = styled.div`
    display: flex;
    flex: 1;
    top: 0%;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        flex-direction: column;
        height: 15vw;
    }
`

export const ModalBody = styled.div`
    display: flex;
    margin-top: 20px;
    flex-direction: column;
    flex: 10;
    justify-content: center;
`
