import { useUser } from 'hooks'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { ClubAdminDetails } from 'types/common/user'
import { USER_ROLE } from 'utils/constData'

const useMediaGalleryAccess = (
    club_slug: string,
    club_id: number,
    away_club_id: number,
    home_club_id: number,
    club_admin_details: ClubAdminDetails[],
) => {
    const [mediaGalleryAccess, setShowMediaGallery] = useState(false)
    const [isAdminOfClub, setIsAdminOfClub] = useState(false)
    const { user, isLeagueAdmin } = useUser()

    useEffect(() => {
        function checkMediaGalleryAccess() {
            const isSuperAdmin: boolean = USER_ROLE.ADMIN === user?.user_role_id

            const clubAdminClubId = club_admin_details?.map((c) => c.club_id)

            const isAdminOfTheClub: boolean = club_id
                ? clubAdminClubId?.includes(club_id)
                : clubAdminClubId?.includes(away_club_id) ||
                  clubAdminClubId?.includes(home_club_id)

            const isClubAdmin: boolean = isSuperAdmin || isAdminOfTheClub

            const isPlayerClubMember = _.isEmpty(user?.players_details)
                ? false
                : _.every(
                      user?.players_details as any,
                      ({ club: { slug } }) => slug === club_slug,
                  )

            setIsAdminOfClub(isAdminOfTheClub)
            setShowMediaGallery(
                isSuperAdmin ||
                    isClubAdmin ||
                    isPlayerClubMember ||
                    isLeagueAdmin,
            )
        }

        checkMediaGalleryAccess()
    }, [club_slug, user])

    return { mediaGalleryAccess, isAdminOfClub }
}

export default useMediaGalleryAccess
