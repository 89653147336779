import { gql } from '@apollo/client'

const GET_SAVED_MATCHES = gql`
    query GET_SAVED_MATCHES($id: Int!) {
        saved_matches(where: { user_id: { _eq: $id } }) {
            match_id
            id
        }
    }
`

const GET_SAVED_MATCH = gql`
    query GET_SAVED_MATCH($userId: Int!, $matchId: Int!) {
        saved_matches(
            where: { user_id: { _eq: $userId }, match_id: { _eq: $matchId } }
        ) {
            match_id
            id
        }
    }
`

const SITEMAP_MATCHES = gql`
    query SITEMAP_MATCHES($where: matches_bool_exp = {}) {
        matches {
            id
            updated_at
            stream_info {
                updated_at
            }
        }
    }
`

const SITEMAP_EVENTS = gql`
    query SITEMAP_EVENTS($where: matches_bool_exp = {}) {
        event_streams {
            id
            slug
            updated_at
            stream_info {
                updated_at
            }
        }
    }
`

const GET_MATCH_INFO = gql`
    query MatchInfonQuery($where: matches_bool_exp = {}) {
        matches(where: $where) {
            id
            status
            home_team {
                id
                image
                name
                division
                club {
                    id
                    logo
                    name
                    display_name
                    slug
                }
            }
            away_team {
                id
                image
                name
                division
                club {
                    id
                    logo
                    name
                    display_name
                    slug
                }
            }
        }
    }
`

const GET_FILTERED_MATCHES = gql`
    query GET_FILTER_MATCHES(
        $where: matches_bool_exp = {}
        $eventWhere: event_streams_bool_exp
        $offset: Int = 10
        $limit: Int = 10
        $order_by: [matches_order_by!] = {}
        $event_order_by: [event_streams_order_by!]
    ) {
        matches(
            order_by: $order_by
            where: $where
            offset: $offset
            limit: $limit
        ) {
            away_club_id
            away_club_name
            away_team_id
            away_team_name
            club_id
            created_at
            home_team_id
            home_team_name
            id
            league_id
            league_name
            name
            round
            round_name
            start_datetime
            thumbnail_url
            updated_at
            url
            ext_managed
            ext_scoring
            custom_opponent
            is_event
            stream_info {
                id
                is_historic
                status
                stream_id
                stream_key
                video_asset_id
            }
            away_team {
                id
                image
                name
                division
                club {
                    id
                    logo
                    name
                    display_name
                    slug
                }
            }
            home_team {
                id
                image
                name
                division
                sport {
                    name
                }
                club {
                    id
                    logo
                    name
                    display_name
                    slug
                }
            }
            league {
                logo
                name
                slug
            }
        }

        event_streams(
            where: $eventWhere
            offset: $offset
            limit: $limit
            order_by: $event_order_by
        ) {
            id
            ext_managed
            image
            league_id
            club_id
            created_at
            is_private
            event_type
            name
            slug
            start_datetime
            stream_id
            stream_info {
                id
                is_historic
                network_status
                static_file_status
                status
                stream_id
                stream_key
                video_asset_id
            }
            league {
                id
                logo
                name
                slug
            }
            club {
                id
                logo
                name
                display_name
                slug
            }
        }
    }
`

const GET_FILTERED_EVENTS = gql`
    query GET_FILTER_EVENTS(
        $eventWhere: event_streams_bool_exp
        $eventlimit: Int
        $event_order_by: [event_streams_order_by!]
        $offset: Int
    ) {
        event_streams(
            where: $eventWhere
            limit: $eventlimit
            order_by: $event_order_by
            offset: $offset
        ) {
            id
            ext_managed
            image
            league_id
            club_id
            created_at
            is_private
            event_type
            name
            slug
            start_datetime
            stream_id
            stream_info {
                id
                is_historic
                network_status
                static_file_status
                status
                stream_id
                stream_key
                video_asset_id
            }
            league {
                id
                logo
                name
                slug
            }
            club {
                id
                logo
                name
                display_name
                slug
            }
        }
    }
`

// ---------
export default {
    GET_SAVED_MATCHES,
    GET_SAVED_MATCH,
    GET_MATCH_INFO,
    GET_FILTERED_MATCHES,
    GET_FILTERED_EVENTS,
    SITEMAP_MATCHES,
    SITEMAP_EVENTS,
}
