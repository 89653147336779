// apollo
import { gql } from '@apollo/client'
// ---------------------------------------------------

const ADD_TEAM = gql`
    mutation AddTeams($objects: [teams_insert_input!]!) {
        insert_teams(objects: $objects) {
            affected_rows
        }
    }
`

const ADD_PLAYER = gql`
    mutation AddPlayers($objects: [players_details_insert_input!]!) {
        insert_players_details(objects: $objects) {
            affected_rows
        }
    }
`

const ADD_USER_PLAYER = gql`
    mutation InsertUserPlayer($objects: [players_details_insert_input!] = {}) {
        insert_players_details(objects: $objects) {
            affected_rows
        }
    }
`

const SUB_ALL_CLUBS = gql`
    subscription SubAllClubs($where: clubs_bool_exp = {}) {
        clubs(where: $where) {
            name
            banner_image
            logo
            id
            slug
            display_name
        }
    }
`

/** gql
 * TODO: transfer to common query file
 * */
const SUB_CLUB = gql`
    subscription MyClubSubscription($club_slug: String!) {
        clubs(where: { slug: { _eq: $club_slug } }) {
            name
            banner_image
            logo
            id
            slug
            address_1
            address_2
            city
            created_at
            postcode
            sport_id
            state
            pass {
                id
            }
            teams {
                id
                image
                name
                slug
                division
                league_id
                players {
                    id
                    user {
                        first_name
                        last_name
                    }
                }
                sport {
                    id
                    name
                }
            }
            players {
                id
                image
                name
                last_name
                user_id
                user {
                    first_name
                    last_name
                }
                positions
                team_id
                club_id
                slug
                is_professional
                is_accepted
                is_declined
                email_address
                team {
                    id
                    name
                }
                positions
                team_id
                club_id
                slug
                is_professional
                team {
                    name
                }
            }
        }
    }
`

const SUB_ALL_TEAMS = gql`
    subscription SubAllTeams($where: teams_bool_exp = {}) {
        teams(where: $where) {
            id
            image
            name
            slug
            division
        }
    }
`

const SUB_ALL_LEAGUES = gql`
    subscription SubAllLeagues {
        leagues {
            id
            logo
            name
            sports_id
        }
    }
`

const SUB_LEAGUES = gql`
    subscription SubAllLeagues($where: leagues_bool_exp = {}) {
        leagues(where: $where) {
            id
            created_at
            banner_image
            logo
            name
            slug
            status
            sports_id
            sports {
                id
                name
            }
            pass {
                id
            }
            league_admins {
                id
                email_address
                is_accepted
                is_declined
                name
                status
                user {
                    first_name
                    id
                    last_name
                }
                user_id
            }
            teams {
                id
                name
                league_id
                sports_id
                image
                players {
                    id
                    image
                    slug
                    is_accepted
                    is_declined
                    email_address
                    name
                    last_name
                    team {
                        name
                        slug
                    }
                    user {
                        first_name
                        last_name
                    }
                }
                sport {
                    id
                    name
                }
                club {
                    id
                    name
                    slug
                }
            }
        }
    }
`

const GET_LEAGUE = gql`
    query GetLeagues($where: leagues_bool_exp = {}) {
        leagues(where: $where) {
            id
            created_at
            banner_image
            logo
            name
            slug
            status
            sports_id
            sports {
                id
                name
            }
            pass {
                id
            }
            league_admins {
                id
                email_address
                is_accepted
                is_declined
                name
                status
                user {
                    first_name
                    id
                    last_name
                }
                user_id
            }
            teams {
                id
                name
                league_id
                sports_id
                image
                players {
                    id
                    image
                    slug
                    is_accepted
                    is_declined
                    email_address
                    name
                    last_name
                    team {
                        name
                        slug
                    }
                    user {
                        first_name
                        last_name
                        photo
                    }
                }
                sport {
                    id
                    name
                }
                club {
                    id
                    name
                    slug
                }
            }
        }
    }
`

const UPDATE_PLAER_BY_ID = gql`
    mutation EditPlayer($id: Int!, $object: players_details_set_input = {}) {
        update_players_details_by_pk(pk_columns: { id: $id }, _set: $object) {
            updated_at
        }
    }
`

const UPDATE_PLAYER_USER_BY_ID = gql`
    mutation EditPlayer($id: Int!, $object: users_set_input = {}) {
        update_users_by_pk(pk_columns: { id: $id }, _set: $object) {
            updated_at
        }
    }
`

const UPDATE_TEAM_BY_ID = gql`
    mutation UpdateTeamById($id: Int!, $_set: teams_set_input = {}) {
        update_teams_by_pk(pk_columns: { id: $id }, _set: $_set) {
            updated_at
        }
    }
`

const UPDATE_CLUB_BY_ID = gql`
    mutation UpdateClubById($id: Int!, $_set: clubs_set_input = {}) {
        update_clubs_by_pk(pk_columns: { id: $id }, _set: $_set) {
            updated_at
        }
    }
`

const UPDATE_LEAGUE_BY_ID = gql`
    mutation UpdateClubById($id: Int!, $_set: leagues_set_input = {}) {
        update_leagues_by_pk(pk_columns: { id: $id }, _set: $_set) {
            updated_at
        }
    }
`

const GET_ALL_LEAGUES = gql`
    query GetAllLeagues($where: leagues_bool_exp) {
        leagues(where: $where) {
            id
            logo
            slug
            name
            sports_id
            sports {
                name
            }
        }
    }
`

const GET_ALL_CLUB_TEAMS = gql`
    query ClubTeamsQuery(
        $where: clubs_bool_exp = {}
        $teamsWhere: teams_bool_exp = {}
    ) {
        clubs(where: $where) {
            teams(where: $teamsWhere) {
                division
                id
                image
                league_id
                name
                sports_id
            }
        }
    }
`

const GET_ALL_TEAMS = gql`
    query GetAllTeams($where: teams_bool_exp = {}) {
        teams(where: $where) {
            id
            image
            name
            slug
            division
        }
    }
`

const GET_ALL_CLUBS = gql`
    query GetAllClubs($where: clubs_bool_exp = {}) {
        clubs(where: $where) {
            name
            banner_image
            logo
            id
            slug
            display_name
        }
    }
`

const GET_ALL_ADS = gql`
    query AdsQeury($where: ads_settings_bool_exp = {}) {
        ads_settings(where: $where) {
            id
            name
            vast_url
            position
            description
        }
    }
`

const GET_CLUB_ADMIN_EMAIL = gql`
    query GetClubAdminEmail($where: club_admin_details_bool_exp) {
        club_admin_details(where: $where) {
            email_address
        }
    }
`

const GET_LEAGUE_ADMIN_EMAIL = gql`
    query GetClubAdminEmail($where: admin_details_bool_exp) {
        admin_details(where: $where) {
            email_address
        }
    }
`

const UPDATE_MATCH = gql`
    mutation MatchMutation(
        $where: matches_bool_exp = {}
        $_set: matches_set_input = {}
    ) {
        update_matches(where: $where, _set: $_set) {
            affected_rows
        }
    }
`

const DELETE_MATCH = gql`
    mutation MyMutation($where: matches_bool_exp = {}) {
        delete_matches(where: $where) {
            affected_rows
        }
    }
`

const DELETE_STREAM = gql`
    mutation DeleteStream($where: streams_bool_exp = {}) {
        delete_streams(where: $where) {
            returning {
                id
                stream_id
            }
        }
    }
`

const INSERT_EVENT = gql`
    mutation AddEventStreams($objects: [event_streams_insert_input!] = {}) {
        insert_event_streams(objects: $objects) {
            returning {
                id
            }
        }
    }
`

const UPDATE_EVENT = gql`
    mutation UpdateEventMutaion(
        $where: event_streams_bool_exp = {}
        $_set: event_streams_set_input = {}
    ) {
        update_event_streams(where: $where, _set: $_set) {
            returning {
                id
            }
        }
    }
`

const GET_SLIDE_REF_STREAM_COUNT = gql`
    query MyQuery($where: slide_title_details_bool_exp = {}) {
        slide_title_details_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`

const UPDATE_PLAYERS_TEAM = gql`
    mutation UpdatePlayersTeam(
        $where: players_details_bool_exp = {}
        $_set: players_details_set_input = {}
    ) {
        update_players_details(where: $where, _set: $_set) {
            affected_rows
        }
    }
`

const UPDATE_STREAM_INFO = gql`
    mutation UpdateStreamInfo(
        $where: streams_bool_exp = {}
        $_set: streams_set_input = {}
    ) {
        update_streams(where: $where, _set: $_set) {
            affected_rows
        }
    }
`

const INSERT_PAST_MATCH_STREAM = gql`
    mutation InsertPastMatchStream($objects: [streams_insert_input!]!) {
        insert_streams(objects: $objects) {
            affected_rows
        }
    }
`

const SUB_PAST_MATCH_STREAM = gql`
    subscription SubPastMatchStream($where: streams_bool_exp) {
        streams(where: $where) {
            id
            video_asset_id
        }
    }
`

const GET_CLUB_WITH_ADMIN_DETAILS = gql`
    query ClubSubscription($where: clubs_bool_exp) {
        clubs(where: $where) {
            name
            banner_image
            logo
            id
            slug
            sport_id
            account_name
            sport {
                id
                name
                icon
            }
            club_admins {
                id
                user_id
                status
                name
                email_address
                is_accepted
                is_declined
                user {
                    id
                    first_name
                    last_name
                }
            }
            teams {
                id
                image
                name
                slug
                division
                sports_id
                league_id
                league {
                    slug
                }
            }
            players {
                id
                image
                slug
                is_accepted
                is_declined
                name
                last_name
                team {
                    name
                    slug
                }
                user {
                    first_name
                    last_name
                    photo
                }
            }
            club_partners {
                partner {
                    id
                    logo
                    name
                }
            }
            club_sponsors {
                sponsor {
                    id
                    logo
                    name
                }
            }
            pass {
                id
                name
                price_id
                checkout_url
                pass_type_id
            }
            community_members {
                community {
                    id
                    name
                    display_name
                    slug
                    status
                }
            }
        }
    }
`

// ---------
export default {
    ADD_TEAM,
    ADD_PLAYER,
    ADD_USER_PLAYER,
    INSERT_EVENT,
    INSERT_PAST_MATCH_STREAM,

    SUB_CLUB,
    SUB_ALL_CLUBS,
    SUB_ALL_TEAMS,
    SUB_ALL_LEAGUES,
    SUB_LEAGUES,

    GET_ALL_LEAGUES,
    GET_ALL_CLUB_TEAMS,
    GET_ALL_TEAMS,
    GET_ALL_CLUBS,
    GET_ALL_ADS,
    GET_LEAGUE,
    GET_LEAGUE_ADMIN_EMAIL,
    GET_CLUB_ADMIN_EMAIL,
    GET_SLIDE_REF_STREAM_COUNT,
    SUB_PAST_MATCH_STREAM,
    GET_CLUB_WITH_ADMIN_DETAILS,

    UPDATE_PLAER_BY_ID,
    UPDATE_PLAYER_USER_BY_ID,
    UPDATE_TEAM_BY_ID,
    UPDATE_CLUB_BY_ID,
    UPDATE_LEAGUE_BY_ID,
    UPDATE_MATCH,
    UPDATE_EVENT,
    UPDATE_PLAYERS_TEAM,
    UPDATE_STREAM_INFO,

    DELETE_MATCH,
    DELETE_STREAM,
}
