import { useLazyQuery } from '@apollo/client'
import { Text } from 'components/Text'
import { query } from 'graphql/user'
import _ from 'lodash'
import { ClubAdminContext } from 'pages/club/[club_slug]/admin'
import { useContext, useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { setUserDataCSVExport } from 'redux/actions/user'
import styled from 'styled-components'
import UserStatus from './UserStatus'
import { StyledTBody } from './tableBody.style'

const TableBody = (props: { setUserDataCSVExport: (arg0: any) => void }) => {
    const club = useContext(ClubAdminContext)
    const [usersData, setUsersData] = useState<any>([])

    const [getUsers] = useLazyQuery(query.GET_USER_INFO, {
        onCompleted(data) {
            data?.users &&
                setUsersData(
                    data?.users?.map(
                        (user: {
                            first_name: any
                            last_name: any
                            email: any
                            user_club_follows: any
                            user_passes: any[]
                        }) => ({
                            userName:
                                user.first_name && user.last_name
                                    ? `${user.first_name ?? ''}` +
                                      ' ' +
                                      `${user.last_name ?? ''}`
                                    : getUsernameFromEmail(user.email),
                            // email: user.email,
                            status:
                                getUserStatus(
                                    user?.user_club_follows,
                                    user?.user_passes,
                                ) ?? '',
                            expires:
                                user?.user_passes.filter(
                                    (pass: {
                                        pass_id: number | undefined
                                        pass: { pass_type: { name: string } }
                                    }) =>
                                        pass?.pass_id === club?.pass?.id &&
                                        pass?.pass?.pass_type?.name ===
                                            'digital-fan',
                                ).length > 0
                                    ? capitalizeFirstLetter(
                                          user?.user_passes.filter(
                                              (pass: {
                                                  pass_id: number | undefined
                                                  pass: {
                                                      pass_type: {
                                                          name: string
                                                      }
                                                  }
                                              }) =>
                                                  pass?.pass_id ===
                                                      club?.pass?.id &&
                                                  pass?.pass?.pass_type
                                                      ?.name === 'digital-fan',
                                          )[0]?.status,
                                      )
                                    : '',
                        }),
                    ),
                )
        },
    })

    useEffect(() => {
        getUsers({ variables: { where: { auth_id: { _is_null: false } } } })
    }, [])

    useEffect(() => {
        props.setUserDataCSVExport(
            usersData.filter((user: { status: string }) => {
                return (
                    user.status === 'Follower' ||
                    user.status === 'Digital Fan' ||
                    user.status === 'Digital Fan & Follower'
                )
            }),
        )
    }, [usersData])

    const capitalizeFirstLetter = (string: string | any[]) => {
        if (string) return string[0]?.toUpperCase() + string.slice(1)
    }

    const getUserStatus = (followedClubID: any[], userFanPasses: any[]) => {
        const clubID = followedClubID.map(
            (club: { club_id: any }) => club?.club_id,
        )
        const digitalFan = userFanPasses.filter(
            (pass: {
                pass_id: number | undefined
                pass: { pass_type: { name: string } }
                status: string
            }) =>
                pass?.pass_id === club?.pass?.id &&
                pass?.pass?.pass_type?.name === 'digital-fan' &&
                pass?.status === 'active',
        )

        if (_.includes(clubID, club.id) && digitalFan.length > 0)
            return 'Digital Fan & Follower'

        if (digitalFan.length > 0) return 'Digital Fan'

        if (_.includes(clubID, club.id)) return 'Follower'
    }

    const getUsernameFromEmail = (email: string) => {
        const newUsername = email.split('@')

        return newUsername[0]
    }

    return (
        <StyledTBody>
            {usersData &&
                usersData.map(
                    (user: { status: string; userName: any; expires: any }) =>
                        user.status === 'Follower' ||
                        user.status === 'Digital Fan' ||
                        user.status === 'Digital Fan & Follower' ? (
                            <TableRow key={user.userName}>
                                <TableDataUser>
                                    <Text
                                        fSize={0.9}
                                        fWeight={600}
                                        fColor="white"
                                    >
                                        {user.userName}
                                    </Text>
                                </TableDataUser>
                                <TableDataStatus>
                                    <UserStatus status={user.status} />
                                </TableDataStatus>

                                <TableDataExpires>
                                    <Text
                                        fSize={0.9}
                                        fWeight={600}
                                        fColor="white"
                                    >
                                        {user?.expires}
                                    </Text>
                                </TableDataExpires>
                            </TableRow>
                        ) : (
                            <></>
                        ),
                )}
        </StyledTBody>
    )
}
const mapStateToProps = (state: any) => ({})
const mapDispatchToProps = {
    setUserDataCSVExport: setUserDataCSVExport,
}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(TableBody)

const TableRow = styled.tr`
    display: flex;
    padding-left: 2em;
`

const TableDataUser = styled.td`
    height: 50px;
    flex: 2;
    min-width: 13em;
    align-items: center;
`

const TableDataStatus = styled.td`
    height: 50px;
    flex: 1;
    min-width: 16em;
`

const TableDataExpires = styled.td`
    height: 50px;
    flex: 1;
    min-width: 8em;
`
