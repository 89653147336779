import * as t from 'redux/types/club'

export const setClubInfo = (club: any) => ({
    type: t.SET_CLUB_INFO,
    payload: club,
})

export const setFanPassModalShown = (flag: boolean) => ({
    type: t.SET_FAN_PASS_MODAL_SHOWN,
    payload: flag,
})
