import { useLazyQuery } from '@apollo/client'
import { Select } from 'antd'
import { ADMINQL } from 'graphql/club'
import { useEffect, useState } from 'react'
import { CustomSelect } from './dropdown.style'

const { Option } = Select

function AdsSelect(props: any) {
    const [data, setData] = useState<Partial<Array<any>>>([])

    const [pullData] = useLazyQuery(ADMINQL.GET_ALL_ADS, {
        onCompleted(data) {
            data.ads_settings && setData(data.ads_settings)
        },
    })

    useEffect(() => {
        pullData()
    }, [])

    return (
        <CustomSelect {...props} placeholder="Select Ads" allowClear>
            {data.map((ads, i) => (
                <Option value={ads.id} key={`dd-ads-${i}`}>
                    {ads.name}
                </Option>
            ))}
        </CustomSelect>
    )
}

export default AdsSelect
