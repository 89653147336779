import _ from 'lodash'
// import ClipsView from "./Clips";
import { AntTab } from './display.style'
import { RangeSelect } from '../Components'
import EventsView from './Events'
import MatchesView from './Matches'
import { createContext, useState } from 'react'

type AnalyticsAdminContextType = {
    range: number
    setRange: any
}

export const AnalyticsAdminContext = createContext<
    Partial<AnalyticsAdminContextType>
>({})

const DisplaySection = () => {
    const [range, setRange] = useState(7)

    return (
        <AnalyticsAdminContext.Provider
            value={{
                range,
                setRange,
            }}
        >
            <AntTab
                defaultActiveKey="1"
                size={'large'}
                tabBarExtraContent={<RangeSelect />}
                items={[
                    {
                        label: 'Matches',
                        key: '1',
                        children: <MatchesView />,
                    },
                    // {
                    //   label: "Clips",
                    //   key: "2",
                    //   children: <ClipsView />,
                    // },
                    {
                        label: 'Events',
                        key: '3',
                        children: <EventsView />,
                    },
                ]}
            />
        </AnalyticsAdminContext.Provider>
    )
}

export default DisplaySection
