import { useLazyQuery, useMutation } from '@apollo/client'
import { Button } from 'components/Button'
import { DatePicker } from 'components/DateTimeInput'
import {
    ClubsSelect,
    LeagueSelect,
    TeamFilteredSelect,
    TeamSelect,
} from 'components/Dropdown'
import { Text } from 'components/Text'
import React, { useEffect, useState } from 'react'
import { BsSave } from 'react-icons/bs'
import { ImCancelCircle, ImFilesEmpty } from 'react-icons/im'

import axios from 'axios'
import { mutate } from 'graphql/match'
import { ModalProps } from 'types/components/Modal'
import { MATCH_ADS_ID, apiBaseUrl, baseUrl } from 'utils/constData'
import {
    ClubCheckbox,
    CopyIcon,
    FormItem,
    LabelInput,
    ModalWrapper,
} from './index.style'

import { connect } from 'react-redux'

import { InfoCircleOutlined } from '@ant-design/icons'
import { Form, Input, Popconfirm, Switch, Tooltip } from 'antd'
import { toast } from 'react-toastify'

import { CustomSelect } from 'components/Dropdown/dropdown.style'
import { ADMINQL } from 'graphql/club'
import _ from 'lodash'
import { useRouter } from 'next/router'
import FormItemWrapper from 'sections/club/admin/content/Information/components/FormItemWrapper'
import SportsDropDown from '../Team/components/SportsDropDown'

const defaultFieldsValues = {
    is_historic: false,
    status: 'created',
}

async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text)
    } else {
        return document.execCommand('copy', true, text)
    }
}

const UpcomingModal: React.FC<ModalProps> = ({
    show = false,
    handleClose,
    clubInfo,
    clubTeams,
    item,
}) => {
    const router = useRouter()
    const { league_slug } = router.query
    const [currentSportName, setCurrentSportName] = useState(undefined)
    const [homeClubId, setHomeClubID] = useState(undefined)
    const [awayClubId, setAwayClubId] = useState(0)
    const [inputDisabled, setInputDisabled] = useState({
        opClub: true,
        opTeam: true,
    })
    const [addCustomOpponent, setAddCustomOpponent] = useState(false)
    const [hasSlide, setHasSlide] = useState(false)

    const isEdit = item && item.type === 'edit'
    const [form] = Form.useForm()

    const [addMatch] = useMutation(mutate.INSERT_MATCH, {
        onCompleted() {
            toast.success('Match saved')
            handleClose && handleClose()
        },
        onError(e) {
            toast.error('Error Happened.')
            console.log('error', e)
        },
    })
    const [updateStreamInfo] = useMutation(ADMINQL.UPDATE_STREAM_INFO)
    const [updateMatch] = useMutation(ADMINQL.UPDATE_MATCH, {
        onCompleted() {
            toast.success('Match updated!')
            handleClose && handleClose()
        },
        onError(e) {
            toast.error('Error Happened.')
        },
    })

    const [deleteMatch] = useMutation(ADMINQL.DELETE_STREAM, {
        onCompleted() {
            toast.success('Match Deleted!')
            handleClose && handleClose()
        },
        onError(e) {
            toast.error('Delete Error Happened.')
        },
    })

    // check slide_title_details table, if the match/event is referenced
    const [checkSlide] = useLazyQuery(ADMINQL.GET_SLIDE_REF_STREAM_COUNT, {
        onCompleted(data) {
            const hasSlide =
                data?.slide_title_details_aggregate?.aggregate?.count > 0

            if (data && hasSlide) {
                setHasSlide(true)
            }
            if (data && !hasSlide) {
                setHasSlide(false)
            }
        },
        onError(e) {
            toast.error('Error Happened.')
        },
    })

    const handleDelete = () => {
        item?.id &&
            checkSlide({
                variables: { where: { match_id: { _eq: item?.id } } },
            })
    }

    useEffect(() => {
        form.resetFields()

        !isEdit &&
            form.setFieldsValue({
                start_datetime: new Date(),
                ext_managed: true,
                ext_scoring: true,
            })

        if (isEdit) {
            onHomeTeamChange(item.home_team_id)
            setInputDisabled({ ...inputDisabled, opClub: false, opTeam: false })
            setAwayClubId(item.away_club_id)
            setTimeout(() => {
                form.setFieldsValue({ ...item })
            }, 200)
        }

        !show &&
            setInputDisabled({ ...inputDisabled, opClub: true, opTeam: true })
    }, [show])

    useEffect(() => {
        if (item.custom_opponent) {
            setAddCustomOpponent(true)
            setTimeout(() => {
                form.setFieldsValue({
                    away_team_id: null,
                    custom_club_name: item.custom_opponent.club_name,
                    custom_club_display_name:
                        item.custom_opponent.club_display_name,
                })
            }, 300)
        } else {
            setAddCustomOpponent(false)
        }
        setHomeClubID(undefined)
    }, [show])

    const copyData = (data: string) => {
        copyTextToClipboard(data)
            .then(() => {
                toast.success('Copied')
            })
            .catch((err) => {
                toast.error('Error Copied.')
            })
    }

    const onHomeTeamChange = (home_team_id: any) => {
        // const team = _.find(clubTeams, ['id', home_team_id])
        // team && team.sports_id && setCurrentSportId(team.sports_id)
        form.setFieldsValue({
            away_club_id: undefined,
            away_team_id: undefined,
        })

        setInputDisabled({ ...inputDisabled, opClub: false, opTeam: true })
    }

    const onHomeClubChange = (club_id: React.SetStateAction<undefined>) => {
        setHomeClubID(club_id)
    }

    const onOpClubChange = (club_id: React.SetStateAction<number>) => {
        setAwayClubId(club_id)
        form.setFieldsValue({ away_team_id: undefined })
        setInputDisabled({ ...inputDisabled, opClub: false, opTeam: false })
    }

    const onLeagueChange = (
        league_id: any,
        league_info: { sport_name: React.SetStateAction<undefined> },
    ) => {
        form.setFieldValue('home_team_id', undefined)
        form.setFieldValue('sports_id', undefined)

        league_info.sport_name && setCurrentSportName(league_info.sport_name)
    }

    const submitHandler = (values: any) => {
        const fieldValues = { ...values }
        const { custom_club_name, custom_club_display_name, ...rest } =
            fieldValues
        const valuesObj = addCustomOpponent ? rest : fieldValues
        const custom_opponent = addCustomOpponent
            ? {
                  custom_opponent: {
                      club_name: values.custom_club_name,
                      club_display_name: values.custom_club_display_name,
                  },
                  away_team_id: 1,
                  away_club_id: null,
              }
            : { custom_opponent: null }
        const watermark = fieldValues.ext_managed
            ? {}
            : {
                  input: [
                      {
                          url: 'https://assets.streamer.com.au/logo.png',
                          overlay_settings: {
                              vertical_align: 'bottom',
                              vertical_margin: '5%',
                              horizontal_align: 'right',
                              horizontal_margin: '3%',
                              width: '13%',
                              opacity: '50%',
                          },
                      },
                  ],
              }

        /** Update Match */
        if (isEdit) {
            const ext_managedChanged =
                item.ext_managed !== fieldValues.ext_managed

            ext_managedChanged &&
                axios
                    .post(apiBaseUrl + '/mux/live-stream', {
                        playback_policy: 'Public',
                        reconnect_window: 1200,
                        new_asset_settings: {
                            playback_policy: ['public'],
                            mp4_support: 'standard',
                            ...watermark,
                        },
                    })
                    .then((res) => {
                        const {
                            id: stream_id,
                            stream_key,
                            playback_ids,
                        } = res.data
                        const video_asset_id = playback_ids[0].id
                        const url = `${baseUrl}/club/${clubInfo.slug}/live/${video_asset_id}`

                        item.stream_info.id &&
                            updateStreamInfo({
                                variables: {
                                    where: { id: { _eq: item.stream_info.id } },
                                    _set: {
                                        stream_id,
                                        stream_key,
                                    },
                                },
                            })

                        updateMatch({
                            variables: {
                                where: { id: { _eq: item.id } },
                                _set: {
                                    ...valuesObj,
                                    ...custom_opponent,
                                    url,
                                    ads_id: MATCH_ADS_ID, //_.isUndefined(values.ads_id) ? null : values.ads_id
                                },
                            },
                        })
                    })
                    .catch((err) => {
                        console.error('MUX request error:', err)
                    })

            !ext_managedChanged &&
                updateMatch({
                    variables: {
                        where: { id: { _eq: item.id } },
                        _set: {
                            ...valuesObj,
                            ...custom_opponent,
                            ads_id: MATCH_ADS_ID, //_.isUndefined(values.ads_id) ? null : values.ads_id
                        },
                    },
                })
        }

        /** Add Match */
        !isEdit &&
            axios
                .post(apiBaseUrl + '/mux/live-stream', {
                    playback_policy: 'Public',
                    reconnect_window: 1200,
                    new_asset_settings: {
                        playback_policy: ['public'],
                        mp4_support: 'standard',
                        ...watermark,
                    },
                })
                .then((res) => {
                    const { id: stream_id, stream_key, playback_ids } = res.data
                    const video_asset_id = playback_ids[0].id
                    const url = `${baseUrl}/club/${clubInfo.slug}/live/${video_asset_id}`
                    const custom_opponent = addCustomOpponent
                        ? {
                              custom_opponent: {
                                  club_name: values.custom_club_name,
                                  club_display_name:
                                      values.custom_club_display_name,
                              },
                              away_team_id: 1,
                          }
                        : {}

                    /** adds to streams data */
                    const stream_info = {
                        data: {
                            stream_id,
                            stream_key,
                        },
                    }

                    const objects = {
                        ...defaultFieldsValues,
                        ...valuesObj,
                        ...custom_opponent,
                        club_id: _.isUndefined(league_slug)
                            ? clubInfo.id
                            : values.club_id,
                        //stream_id: stream_id,
                        //stream_key: stream_key,
                        name: _.isUndefined(league_slug) ? values.name : 'Home',
                        url,
                        ads_id: MATCH_ADS_ID,
                        stream_info,
                    }

                    /** add to matches table */
                    addMatch({ variables: { objects } })
                })
                .catch((err) => {
                    console.error('MUX request error:', err)
                })
    }

    const failedSubmitHandler = (values: any) => {
        console.log('Form Error:', values)
    }

    const deleteHandler = () => {
        // item.stream_info.stream_id && axios.delete(`${apiBaseUrl}/mux/live-stream/${item.stream_info.stream_id}`).then(res => {
        //   deleteMatch({ variables: { where: { stream_id: { _eq: item.stream_info.stream_id } } } })
        // }).catch((err) => {
        //   console.error("MUX request error:", err);
        // });

        // Soft Delete
        try {
            item.stream_info.id &&
                updateStreamInfo({
                    variables: {
                        where: { id: { _eq: item.stream_info.id } },
                        _set: {
                            archived: 'true',
                        },
                    },
                })

            toast.success('Match Deleted!')
            handleClose && handleClose()
        } catch (error) {
            toast.error('Delete Error Happened.')
        }
    }

    return (
        <ModalWrapper
            open={show}
            closable={false}
            title={`${isEdit ? 'Edit' : 'Add'} Upcoming Match`}
            width={800}
            footer={null}
        >
            <Form
                layout="vertical"
                form={form}
                onFinish={submitHandler}
                onFinishFailed={failedSubmitHandler}
            >
                <Text fWeight={600} fColor="white" fSize={1.0625}>
                    {' '}
                    {'Match Details'}{' '}
                </Text>
                <div className="match-details">
                    <div className="form-section">
                        <FormItem
                            name="start_datetime"
                            label="Date & Time"
                            rules={[{ required: true, message: '' }]}
                        >
                            <DatePicker />
                        </FormItem>

                        <FormItem
                            name="sports_id"
                            label="Scoring Sport"
                            rules={[{ required: true, message: '' }]}
                        >
                            <SportsDropDown sport_name={currentSportName} />
                        </FormItem>

                        {_.isUndefined(league_slug) ? (
                            <FormItem
                                name="name"
                                label="Home/Away"
                                initialValue="Home"
                                rules={[{ required: true, message: '' }]}
                            >
                                <CustomSelect>
                                    <CustomSelect.Option value="Home">
                                        Home
                                    </CustomSelect.Option>
                                    <CustomSelect.Option value="Away">
                                        Away
                                    </CustomSelect.Option>
                                </CustomSelect>
                            </FormItem>
                        ) : (
                            <FormItem
                                name="club_id"
                                label="Home Club name"
                                rules={[{ required: true, message: '' }]}
                            >
                                <ClubsSelect
                                    sport_name={currentSportName}
                                    onChange={onHomeClubChange}
                                />
                            </FormItem>
                        )}

                        <FormItem
                            name="home_team_id"
                            label={
                                _.isUndefined(league_slug)
                                    ? 'Select Team'
                                    : `Select Home Team`
                            }
                            rules={[{ required: true, message: '' }]}
                        >
                            <TeamSelect
                                placeholder="Select Team"
                                sport_name={currentSportName}
                                homeClubId={homeClubId}
                                onChange={onHomeTeamChange}
                            />
                        </FormItem>
                    </div>

                    <div className="form-section">
                        <FormItem
                            name="league_id"
                            label="League Name"
                            rules={[{ required: true, message: '' }]}
                        >
                            <LeagueSelect onChange={onLeagueChange} />
                        </FormItem>

                        <FormItem
                            name="round_name"
                            label="Round Name"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input
                                style={{ height: 38, borderRadius: 5 }}
                                placeholder="Round Name"
                            />
                        </FormItem>

                        <ClubCheckbox
                            checked={addCustomOpponent}
                            onChange={() =>
                                setAddCustomOpponent(!addCustomOpponent)
                            }
                        >
                            Custom Opponent
                        </ClubCheckbox>

                        {addCustomOpponent ? (
                            <>
                                <FormItem
                                    name="custom_club_name"
                                    label="Opposition Club"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Input
                                        style={{ height: 38, borderRadius: 5 }}
                                        placeholder="Opposition Club Name"
                                    />
                                </FormItem>

                                <FormItem
                                    name="custom_club_display_name"
                                    label="Opposition Club Display Name"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Input
                                        style={{ height: 38, borderRadius: 5 }}
                                        placeholder="Opposition Club Display Name"
                                    />
                                </FormItem>
                            </>
                        ) : (
                            <>
                                <FormItem
                                    name="away_club_id"
                                    label={
                                        _.isUndefined(league_slug)
                                            ? 'Club name'
                                            : `Away Club name`
                                    }
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <ClubsSelect
                                        disabled={inputDisabled.opClub}
                                        sport_name={currentSportName}
                                        onChange={onOpClubChange}
                                    />
                                </FormItem>
                                <FormItem
                                    name="away_team_id"
                                    label={
                                        _.isUndefined(league_slug)
                                            ? 'Select Team'
                                            : `Select Away Team`
                                    }
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <TeamFilteredSelect
                                        disabled={inputDisabled.opTeam}
                                        sport_name={currentSportName}
                                        club_id={awayClubId}
                                    />
                                </FormItem>
                            </>
                        )}
                    </div>
                </div>

                <Text fWeight={600} fColor="white" fSize={1.0625}>
                    Stream Settings
                </Text>
                <div className="stream-settings">
                    <div>
                        <Text fSize={0.875} fColor="white" padding="0 0 7px 0">
                            {'Managed stream '}
                            <Tooltip
                                title="Turn this on if you will be using external streaming service. e.g. OBS streamlabs"
                                color={'black'}
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Text>

                        <FormItem name="ext_managed" valuePropName="checked">
                            <Switch />
                        </FormItem>
                    </div>

                    <div>
                        <Text fSize={0.875} fColor="white" padding="0 0 7px 0">
                            {'External Scoring '}
                            <Tooltip
                                title="Tunn this on if you will be overlaying your own scoring and timekeeping via a video mixer e.g. using a service such as LIGR"
                                color={'black'}
                            >
                                <InfoCircleOutlined />
                            </Tooltip>
                        </Text>
                        <FormItem name="ext_scoring" valuePropName="checked">
                            <Switch />
                        </FormItem>
                    </div>
                </div>

                <Text fWeight={600} fColor="white" fSize={1.0625}>
                    Stream Details
                </Text>

                <div className="stream-details">
                    <FormItemWrapper label="RTMP Server URL">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <LabelInput>
                                rtmp://global-live.mux.com:5222/app/
                            </LabelInput>
                            <CopyIcon
                                className="copy"
                                onClick={() =>
                                    copyData(
                                        'rtmp://global-live.mux.com:5222/app/',
                                    )
                                }
                            >
                                <ImFilesEmpty
                                    style={{ width: '25', height: '25' }}
                                />
                            </CopyIcon>
                        </div>
                    </FormItemWrapper>

                    <FormItemWrapper label="Stream key">
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <LabelInput>
                                {isEdit ? item.stream_info.stream_key : ''}
                            </LabelInput>
                            <CopyIcon
                                onClick={() =>
                                    item &&
                                    item.type === 'edit' &&
                                    item.stream_info.stream_key &&
                                    copyData(item.stream_info.stream_key)
                                }
                            >
                                <ImFilesEmpty
                                    className="copy"
                                    style={{ width: '25', height: '25' }}
                                />
                            </CopyIcon>
                        </div>
                    </FormItemWrapper>
                </div>

                <div style={{ display: 'flex', justifyContent: 'end' }}>
                    <Button
                        key={'btn-cncl'}
                        bColor="primary"
                        bSize="small"
                        icon={<ImCancelCircle />}
                        onClick={handleClose}
                        type="button"
                    >
                        {'Cancel'}
                    </Button>

                    {isEdit && (
                        <Popconfirm
                            title={
                                !hasSlide
                                    ? `Are you sure to delete this schedule`
                                    : `WARNING: The match you are deleting is currently displaying on the carousel. You will lose all data related to this match. Are you sure?`
                            }
                            okText="Yes"
                            cancelText="No"
                            onConfirm={deleteHandler}
                        >
                            <Button
                                bColor="warning"
                                bSize="small"
                                icon={<ImCancelCircle />}
                                type="button"
                                key={'btn-del'}
                                onClick={handleDelete}
                                ariaLabel="delete-upcoming-match"
                            >
                                {'Delete'}
                            </Button>
                        </Popconfirm>
                    )}

                    <Button
                        type="submit"
                        bColor="primary"
                        bSize="small"
                        icon={<BsSave />}
                        key={'btn-sav'}
                        ariaLabel="save-upcoming-match"
                    >
                        {'Save'}
                    </Button>
                </div>
            </Form>
        </ModalWrapper>
    )
}

const mapStateToProps = (state: {
    club: { info: any }
    teams: { list: any }
}) => ({
    clubInfo: state.club.info,
    clubTeams: state.teams.list,
})

// @ts-ignore
export default connect(mapStateToProps)(UpcomingModal) as any
