import React from 'react'
// component
import { Row, Col } from 'components/Layout'
import { Text } from 'components/Text'

// import styled component
import { HeadWrapper } from './head.style'
// import assets

const HeadView: React.FC = () => {
    return (
        <HeadWrapper>
            <Text fColor="red.100" fWeight={800} mode="h1">
                Club Administration
            </Text>
        </HeadWrapper>
    )
}

export default HeadView
