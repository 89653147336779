const baseColor = {
    white: '#ffffff',
    whites: {
        100: '#F8F9FA',
        200: '#f1f1f1',
    },
    black: {
        regular: '#000000',
        100: '#1B1B24',
        200: 'rgb(18 17 20)',
        300: '#1B1B25',
        400: '#020202',
        500: 'rgb(25 22 26)',
        600: '#3B3A3B',
        700: '#555555',
    },
    gray: {
        regular: '#A28888',
        normal: '#ABB1BB',
        100: '#A3A6AC',
        150: '#e3e3e3',
        200: '#868585',
        250: '#7a7a7a',
        300: '#666',
        350: '#dadada',
        400: '#f6f6f6',
        500: '#9f9f9f',
        600: '#E5E5E5',
        700: '#333333',
        800: '#1C1B1D',
        850: '#262626',
        900: '#1A1818',
    },
    brown: {
        100: '#A7956E',
    },
    transparent: 'transparent',
    primary: {
        regular: '#E31E31',
        hover: '#B81D2B',
        alternate: '#FA4C4C',
        light: '#FC7171',
        dark: '#d70023',
    },
    secondary: {
        regular: '#ff5b60',
        hover: '#FF282F',
        alternate: '#fc5c63',
    },
    yellow: {
        regular: '#FF7A00',
        hover: '#FFB369',
        alternate: '#f4c243',
    },

    green: {
        regular: '#27AE60',
        normal: '#4ECDC4',
        dark: '#166A1F',
        100: '#449d44',
    },
    pink: {
        highlight: '#FC7171',
    },
    fog: {
        regular: '#DCDCFF',
        light: '#F8F8FF',
        alternate: '#F3F3FF',
    },
    blue: {
        regular: '#36558F',
        dark: '#284376',
        light: '#2D9CDB',
        link: '#004FB9',
        100: '#1D2856',
        200: '#286090',
        300: '#0053D7',
    },
    orange: {
        regular: '#F77D0E',
        dark: '#DC6E0A',
        hover: '#f65d40',
        100: '#EE4829',
    },
    red: {
        regular: '#E31E31',
        dark: '#d70023',
        hover: '#c12525',
        active: '#FA3737',
        100: '#F93737',
        200: '#fa7070',
    },
    purple: {
        100: '#621F9F',
    },
    maroon: {
        100: '#772A50',
    },
    price: '#EF3900',
    success: {
        regular: '#3FB15C',
        dark: '#23A044',
    },
    warning: '',
    muted: '',
    highlight: '',
    background: '#F2F2F2',
}

export default baseColor
