import * as t from 'redux/types/league'

export interface LeagueStateTypes {
    info: any | null
    show_pass_modal: boolean
    list: Array<any>
}

const leagueReducer = (
    state: LeagueStateTypes = {
        info: null,
        show_pass_modal: false,
        list: [],
    },
    action: any,
) => {
    switch (action.type) {
        case t.SET_LEAGUE_INFO:
            return {
                ...state,
                info: action.payload,
            }
        case t.SET_LEAGUE_PASS_MODAL:
            return {
                ...state,
                show_pass_modal: action.payload,
            }

        case t.SET_LEAGUE_LIST:
            return {
                ...state,
                list: action.payload,
            }
            break

        default:
            return { ...state }
    }
}

export default leagueReducer
