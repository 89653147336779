import { useLazyQuery, useSubscription } from '@apollo/client'
import { getSession, withPageAuthRequired } from '@auth0/nextjs-auth0'
import { initializeApolloFromContext } from 'api/apollo'
import { SectionContainer, WithContainer } from 'components/Container'
import { Page } from 'components/Page'
import { ADMINQL, HomeQL, TEAMQL } from 'graphql/club'
import { useRouter } from 'next/router'
import React, { createContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setClubInfo } from 'redux/actions/club'
import { setUserInfo } from 'redux/actions/user'
import { ContentView, HeadView } from 'sections/club/admin'
import { ClubCtx, LeagueCtx } from 'types/common/club'
import { USER_ROLE } from 'utils/constData'
import { isServer } from 'utils/helper'
import { fetchUserInfo } from 'utils/helper-user-info'

export const ClubAdminContext = createContext<Partial<ClubCtx>>({})
export const ClubLeagueContext = createContext<Partial<Array<LeagueCtx>>>([])
export const ClubDetailsContext = createContext<any>({})

const AdminPage: React.FC = (props: any) => {
    const { title, userInfo } = props
    const {
        query: { club_slug },
    } = useRouter()

    /** Convert Context to redux */
    const [club, setClub] = useState<Partial<ClubCtx>>({})
    const [league, setLeague] = useState<Partial<Array<LeagueCtx>>>([])
    const [clubDetails, setClubDetails] = useState<any>({})

    const [getClubInfoWithAdminsDetails] = useLazyQuery(
        ADMINQL.GET_CLUB_WITH_ADMIN_DETAILS,
        {
            onCompleted(data) {
                if (data) {
                    dispatch(setClubInfo(data.clubs[0]))
                }
            },
        },
    )

    useEffect(() => {
        club_slug &&
            getClubInfoWithAdminsDetails({
                variables: {
                    where: {
                        slug: { _eq: club_slug },
                    },
                },
            })
    }, [club_slug])

    useSubscription(ADMINQL.SUB_CLUB, {
        skip: isServer || !club_slug,
        variables: {
            club_slug,
        },
        onData: ({ data: { data, loading } }) => {
            !loading && data && setClub(data.clubs[0])
        },
    })

    useSubscription(TEAMQL.SUB_LEAGUES, {
        skip: isServer,
        variables: {},
        onData: ({ data: { data, loading } }) => {
            !loading && data && setLeague(data.leagues)
        },
    })
    const dispatch = useDispatch()

    useEffect(() => {
        userInfo && dispatch(setUserInfo(userInfo))
    }, [])

    const values = {
        clubDetails,
        setClubDetails,
    }

    return (
        <ClubAdminContext.Provider value={club}>
            <ClubLeagueContext.Provider value={league}>
                <ClubDetailsContext.Provider value={values}>
                    <Page title={title}>
                        <main>
                            <SectionContainer
                                display="block"
                                elementProps={{ bgColor: 'black.200' }}
                                SectionView={HeadView}
                            />
                            <WithContainer
                                cColor="black.200"
                                SectionView={ContentView}
                            />
                        </main>
                    </Page>
                </ClubDetailsContext.Provider>
            </ClubLeagueContext.Provider>
        </ClubAdminContext.Provider>
    )
}

export const getServerSideProps = withPageAuthRequired({
    async getServerSideProps(context) {
        const apolloClient = initializeApolloFromContext(context)
        const { club_slug } = context.query
        const sess: any = getSession(context.req, context.res)
        const role_name =
            sess.user[process.env.AUTH0_CLAIMS_NAMESPACE ?? ''][
                'x-hasura-default-role'
            ]
        const user = sess?.user

        const userInfo = user && (await fetchUserInfo(apolloClient, user))

        const { data } = await apolloClient.query({
            query: HomeQL.GET_CLUB,
            variables: {
                club_slug,
            },
        })
        const club = data && data.clubs[0]

        //check if user is super admin
        const isSuperAdmin = userInfo.user_role_id === USER_ROLE.ADMIN
        //check if user is league admin
        const teamLeagueIds = club.teams.map((t: any) => t.league_id)

        const isLeagueAdmin = userInfo.league_admin_details.some(
            (league: any) =>
                teamLeagueIds.includes(league.league_id) &&
                league.is_accepted === true,
        )

        //check if user is club admin
        const isClubAdmin = userInfo.club_admin_details.some(
            (clubElement: any) =>
                clubElement.club_id === club.id &&
                clubElement.is_accepted === true,
        )

        if (!isClubAdmin && !isLeagueAdmin && !isSuperAdmin) {
            return {
                redirect: {
                    permanent: true,
                    destination: `/club/${club_slug}`,
                },
            }
        }

        return {
            props: {
                title: `${club.name} Admin`,
                userInfo: userInfo ?? null,
            },
        }
    },
})

export default AdminPage
