import { InfoCircleOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Form, Input, Popconfirm, Switch, Tooltip } from 'antd'
import axios from 'axios'
import { Button } from 'components/Button'
import { DatePicker } from 'components/DateTimeInput'
import { ClubsSelect } from 'components/Dropdown'
import { Text } from 'components/Text'
import { ADMINQL } from 'graphql/club'
import _ from 'lodash'
import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'
import { BsPlus, BsSave } from 'react-icons/bs'
import { ImCancelCircle, ImFilesEmpty } from 'react-icons/im'
import { toast } from 'react-toastify'
import FormItemWrapper from 'sections/club/admin/content/Information/components/FormItemWrapper'
import { ModalProps } from 'types/components/Modal'
import { slugifyString } from 'utils/common-helper'
import { apiBaseUrl } from 'utils/constData'
import { CopyIcon, LabelInput } from '../Upcoming/index.style'
import { CustomSelect, FormItem, ModalWrapper } from './index.style'

async function copyTextToClipboard(text: string) {
    if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text)
    } else {
        return document.execCommand('copy', true, text)
    }
}

const EventModal: React.FC<ModalProps> = ({
    clubInfo,
    leagueInfo,
    handleClose,
    item,
}) => {
    const [form] = Form.useForm()
    const [show, setShow] = useState(false)
    const [loading, setLoading] = useState(false)
    const isEdit = !_.isNull(item)
    const router = useRouter()
    const { league_slug } = router.query

    const copyData = (data: string) => {
        copyTextToClipboard(data)
            .then(() => {
                toast.success('Copied')
            })
            .catch((err) => {
                toast.error('Error Copied.')
            })
    }

    useEffect(() => {
        if (isEdit) {
            setShow(true)
            setTimeout(() => {
                form.setFieldsValue(item)
            }, 200)
        }
    }, [item])

    useEffect(() => {
        form.resetFields()
        if (_.isNull(item)) form.setFieldsValue({ start_datetime: new Date() })
    }, [show])

    const [addEvent] = useMutation(ADMINQL.INSERT_EVENT, {
        onCompleted(data) {
            toast.success('Event saved')
            closeModal()
        },
        onError(e) {
            toast.error('Error Happened.')
        },
    })

    const [updateStreamInfo] = useMutation(ADMINQL.UPDATE_STREAM_INFO)

    const [updateEvent] = useMutation(ADMINQL.UPDATE_EVENT, {
        onCompleted(data) {
            toast.success('Event saved')
            closeModal()
        },
        onError(e) {
            toast.error('Error Happened.')
        },
    })

    const [deleteEvent] = useMutation(ADMINQL.DELETE_STREAM, {
        onCompleted() {
            toast.success('Event Deleted!')
            closeModal()
        },
        onError(e) {
            toast.error('Delete Error Happened.')
        },
    })

    const closeModal = () => {
        setLoading(false)
        setShow(false)
        handleClose && handleClose()
    }

    const submitHandler = async (values: {
        ext_managed: any
        name: string
        start_datetime: any
        is_private: any
        event_type: any
        ext_manage: any
        club_id: any
    }) => {
        const watermark = values.ext_managed
            ? {}
            : {
                  input: [
                      {
                          url: 'https://assets.streamer.com.au/logo.png',
                          overlay_settings: {
                              vertical_align: 'bottom',
                              vertical_margin: '5%',
                              horizontal_align: 'right',
                              horizontal_margin: '3%',
                              width: '13%',
                              opacity: '50%',
                          },
                      },
                  ],
              }
        setLoading(true)

        if (isEdit) {
            const ext_managedChanged = item.ext_managed !== values.ext_managed

            ext_managedChanged &&
                axios
                    .post(apiBaseUrl + '/mux/live-stream', {
                        playback_policy: 'Public',
                        reconnect_window: 1200,
                        new_asset_settings: {
                            playback_policy: ['public'],
                            mp4_support: 'standard',
                            ...watermark,
                        },
                    })
                    .then((res) => {
                        const {
                            id: stream_id,
                            stream_key,
                            playback_ids,
                        } = res.data
                        // const video_asset_id = playback_ids[0].id;
                        item.stream_info.id &&
                            updateStreamInfo({
                                variables: {
                                    where: { id: { _eq: item.stream_info.id } },
                                    _set: {
                                        stream_id,
                                        stream_key,
                                    },
                                },
                            })
                    })
                    .catch((err) => {
                        console.error('MUX request error:', err)
                    })

            updateEvent({
                variables: { _set: values, where: { id: { _eq: item.id } } },
            })
            return null
        }

        const slug = slugifyString(values.name)
        /** TODO check slug if exist then post error */
        axios
            .post(apiBaseUrl + '/mux/live-stream', {
                playback_policy: 'Public',
                reconnect_window: 1200,
                new_asset_settings: {
                    playback_policy: ['public'],
                    mp4_support: 'standard',
                    ...watermark,
                },
            })
            .then((res) => {
                const { id: stream_id, stream_key, playback_ids } = res.data
                // const video_asset_id = playback_ids[0].id;
                const stream_info = {
                    data: {
                        stream_id,
                        stream_key,
                    },
                }

                const objects = {
                    name: values.name,
                    start_datetime: values.start_datetime,
                    is_private: values.is_private,
                    event_type: values.event_type,
                    ext_managed: values.ext_managed,
                    slug,
                    club_id: !_.isUndefined(league_slug)
                        ? values.club_id
                        : clubInfo?.id,
                    league_id: !_.isUndefined(league_slug)
                        ? leagueInfo?.id
                        : clubInfo?.teams[0]?.league_id,
                    stream_info,
                    // ads_id: MATCH_ADS_ID,
                }

                addEvent({ variables: { objects } })
            })
            .catch((err) => {
                console.error('MUX request error:', err)
            })
    }

    const deleteHandler = () => {
        item.stream_info.stream_id &&
            axios
                .delete(
                    `${apiBaseUrl}/mux/live-stream/${item.stream_info.stream_id}`,
                )
                .then((res) => {
                    deleteEvent({
                        variables: {
                            where: {
                                stream_id: { _eq: item.stream_info.stream_id },
                            },
                        },
                    })
                })
                .catch((err) => {
                    console.error('MUX request error:', err)
                })
    }

    return (
        <>
            <Button
                bColor="primary"
                bSize="small"
                icon={<BsPlus />}
                onClick={() => setShow(true)}
            >
                {'Create Event'}
            </Button>
            <ModalWrapper
                open={show}
                closable={false}
                title={`Add Event`}
                width={800}
                footer={null}
            >
                <Form layout="vertical" form={form} onFinish={submitHandler}>
                    <Text fWeight={600} fColor="white" fSize={1.0625}>
                        {'Event Details'}
                    </Text>
                    <div className="event-details">
                        <FormItem
                            name="name"
                            label="Event Name"
                            rules={[{ required: true, message: '' }]}
                        >
                            <Input
                                style={{ height: 38, borderRadius: 5 }}
                                placeholder="Event Name"
                            />
                        </FormItem>
                        {!_.isUndefined(league_slug) && (
                            <FormItem
                                name="club_id"
                                label="Club name"
                                rules={[{ required: true, message: '' }]}
                            >
                                <ClubsSelect
                                    sport_name={clubInfo?.sports?.name}
                                />
                            </FormItem>
                        )}
                        <FormItem
                            name="event_type"
                            label="Event Type"
                            rules={[{ required: true, message: '' }]}
                        >
                            <CustomSelect
                                options={[
                                    {
                                        value: 'club',
                                        label: 'Club',
                                    },
                                    {
                                        value: 'league',
                                        label: 'League',
                                    },
                                ]}
                            />
                        </FormItem>
                        <FormItem
                            name="start_datetime"
                            label="Event Date & Time"
                            rules={[{ required: true, message: '' }]}
                        >
                            <DatePicker />
                        </FormItem>
                    </div>
                    <div className="stream-settings">
                        <div>
                            <Text
                                fSize={0.875}
                                fColor="white"
                                padding="0 0 7px 0"
                            >
                                {'Private Event '}
                                <Tooltip
                                    title="Turn this on if you only want users with the link to view the event."
                                    color={'black'}
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </Text>

                            <FormItem name="is_private" valuePropName="checked">
                                <Switch />
                            </FormItem>
                        </div>
                        <div>
                            <Text
                                fSize={0.875}
                                fColor="white"
                                padding="0 0 7px 0"
                            >
                                {'Managed stream '}
                                <Tooltip
                                    title="Turn this on if you will be using external streaming service. e.g. OBS streamlabs"
                                    color={'black'}
                                >
                                    <InfoCircleOutlined />
                                </Tooltip>
                            </Text>

                            <FormItem
                                name="ext_managed"
                                valuePropName="checked"
                            >
                                <Switch />
                            </FormItem>
                        </div>
                    </div>
                    <Text fWeight={600} fColor="white" fSize={1.0625}>
                        Stream Details
                    </Text>

                    <div className="stream-details">
                        <FormItemWrapper label="RTMP Server URL">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <LabelInput>
                                    rtmp://global-live.mux.com:5222/app/
                                </LabelInput>
                                <CopyIcon
                                    className="copy"
                                    onClick={() =>
                                        copyData(
                                            'rtmp://global-live.mux.com:5222/app/',
                                        )
                                    }
                                >
                                    <ImFilesEmpty
                                        style={{ width: '25', height: '25' }}
                                    />
                                </CopyIcon>
                            </div>
                        </FormItemWrapper>

                        <FormItemWrapper label="Stream key">
                            <div
                                style={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <LabelInput>
                                    {isEdit ? item.stream_info.stream_key : ''}
                                </LabelInput>
                                <CopyIcon
                                    onClick={() =>
                                        item &&
                                        isEdit &&
                                        item.stream_info.stream_key &&
                                        copyData(item.stream_info.stream_key)
                                    }
                                >
                                    <ImFilesEmpty
                                        className="copy"
                                        style={{ width: '25', height: '25' }}
                                    />
                                </CopyIcon>
                            </div>
                        </FormItemWrapper>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'end' }}>
                        <Button
                            key={'btn-cncl'}
                            bColor="primary"
                            bSize="small"
                            icon={<ImCancelCircle />}
                            onClick={closeModal}
                            type="button"
                        >
                            {'Cancel'}
                        </Button>

                        {isEdit && (
                            <Popconfirm
                                title={`Are you sure to delete this event`}
                                okText="Yes"
                                cancelText="No"
                                onConfirm={deleteHandler}
                            >
                                <Button
                                    bColor="warning"
                                    bSize="small"
                                    icon={<ImCancelCircle />}
                                    type="button"
                                    key={'btn-del'}
                                >
                                    {'Delete'}
                                </Button>
                            </Popconfirm>
                        )}

                        <Button
                            type="submit"
                            bColor="primary"
                            bSize="small"
                            icon={<BsSave />}
                            key={'btn-sav'}
                            loading={loading}
                            disabled={loading}
                        >
                            {isEdit ? 'Update' : 'Save'}
                        </Button>
                    </div>
                </Form>
            </ModalWrapper>
        </>
    )
}

export default EventModal
