import { useMutation } from '@apollo/client'
import { HomeQL } from 'graphql/club'
import { toast } from 'react-toastify'
import {
    ButtonWrapper,
    ChangesButton,
    PrevieMenuContainer,
    PrevMenuWrapper,
    PublishButton,
    StyledText,
} from './PrevieMenu.style'

const PreviewMenu = (props: { clubSlug: any }) => {
    const [updateClub] = useMutation(HomeQL.UPDATE_CLUB, {
        onCompleted() {
            toast.success('Application Submitted.')
            setTimeout(() => {
                window.close()
            }, 1500)
        },
        onError(e) {
            toast.error('Something went wrong.')
        },
    })

    const handlePublish = () => {
        props.clubSlug &&
            updateClub({
                variables: {
                    where: { slug: { _eq: props.clubSlug } },
                    set: { status: 'pending' },
                },
            })
    }

    return (
        <>
            <PrevMenuWrapper>
                <PrevieMenuContainer>
                    <StyledText fSize={1.3} fWeight={700}>
                        Preview of Club Page
                    </StyledText>
                    <ButtonWrapper>
                        <ChangesButton
                            onClick={() => {
                                window.close()
                            }}
                            bColor={'gray'}
                        >
                            Make Changes
                        </ChangesButton>
                        <PublishButton
                            onClick={handlePublish}
                            bColor={'warning'}
                        >
                            Publish
                        </PublishButton>
                    </ButtonWrapper>
                </PrevieMenuContainer>
            </PrevMenuWrapper>
        </>
    )
}

export default PreviewMenu
