import React from 'react'

import { WithContainer } from 'components/Container'
//  import sections
import { default as DetailSection } from './Detail'
import { default as PrivacySection } from './Privacy'
import { default as ProfileSection } from './Profile'
const InformationView: React.FC = () => {
    return (
        <>
            <WithContainer SectionView={ProfileSection as any} />
            {/* <WithContainer SectionView={ContactSection} /> */}
            <WithContainer SectionView={DetailSection as any} />
            <WithContainer SectionView={PrivacySection} />{' '}
            {/* temporary removed convert to action section */}
        </>
    )
}

export default InformationView
