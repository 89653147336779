// styled component
import { themeGet } from '@styled-system/theme-get'
import styled from 'styled-components'
import { defaultTheme } from 'theme'
// -------------------------------------------------
import { Button } from 'components/Button'

export const ProfileWrapper = styled.div`
    height: 100%;
    width: 100%;
`

export const LogoWrapper = styled.div`
    height: 40px;
    display: flex;
    align-items: center;
`

export const SingleLogoWrapper = styled.div<{
    bottom?: number
    cursor?: string
}>`
    position: relative;
    bottom: ${(props) => props.bottom || 0}px;
    cursor: ${(props) => props.cursor || 'default'};
`

export const LogoDivider = styled.div`
    width: 1px;
    height: 24px;
    background-color: ${themeGet('colors.gray.300')};
    margin: 0 9px;
`

export const HeaderWrapper = styled.header`
    height: 69px;
    width: 100%;
    border-bottom: 1px solid ${themeGet('colors.gray.800')};
    border-top: 1px solid ${themeGet('colors.gray.800')};

    /* padding: 8px 0px; */
    .ImageWrapper {
        contain: content;
        img {
            border-radius: 50%;
        }
    }
    .DropdownWrapper {
        padding: 10px 10px;
    }
`

export const MenuItem = styled.a``

export const Border = styled.div`
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        display: none;
    }
    height: 24px;
    width: 1px;
    background-color: ${themeGet('colors.gray.300')};
`

export const NameLabel = styled.div`
    @media screen and (max-width: ${defaultTheme.mediaSize.xs}px) {
        display: none;
    }
`

export const MenuItemBody = styled.nav`
    position: absolute;
    z-index: 100;
    background: #1b1b24;
    width: 100%;
`

export const MenuItemUnorderedList = styled.ul`
    z-index: 100;
    background: #1b1b24;
    width: 100%;
`

export const MenuItemList = styled.li<{ mode: string }>`
    @media screen and (max-width: ${defaultTheme.mediaSize.lg}px) {
        width: 100%;
        font-size: 14px;
        padding: 16px;
        margin-top: 2px;
        background-color: rgba(80, 80, 80, 0.1);
    }
    @media screen and (min-width: ${defaultTheme.mediaSize.lg}px) {
        display: none;
    }
`

export const HeaderContainer = styled.div`
    z-index: -1;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
`

export const TheWestAustralianLogoWrapper = styled.a`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`
export const SandwichMenuButton = styled(Button)`
    width: 38px;
    height: 40px;
    margin-left: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border: none;
`
export const SignUpButton = styled(Button)`
    width: 70px;
    height: 38px;
    font-size: 14px;
    border-radius: 8px;
    border-width: 2px;
    padding: 0;
    margin: 0;
`
