import { ApolloProvider } from '@apollo/client'
import { UserProvider } from '@auth0/nextjs-auth0'
import '@mux/videojs-kit/dist/index.css'
import 'antd/dist/antd.css'
import { useApollo } from 'api/apollo'
import 'bitmovin-player/bitmovinplayer-ui.css'
import { CommonScripts } from 'components/Scripts'
import { GPTProvider } from 'goopubtag'
import { useRouter } from 'hooks'
import { AppLayout } from 'layouts/app-layout'
import { NextPage } from 'next'
import type { AppProps } from 'next/app'
import { Router } from 'next/router'
import NProgress from 'nprogress'
import { ReactElement, ReactNode, useEffect } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import TagManager from 'react-gtm-module'
import 'react-multi-carousel/lib/styles.css'
import 'react-phone-input-2/lib/style.css'
import 'react-toastify/dist/ReactToastify.css'
import 'react-toggle/style.css'
import { wrapper } from 'redux/store'
import { ThemeProvider } from 'styled-components'
import { defaultTheme } from 'theme'
import { GlobalStyle } from 'theme/global.state'
import { getCurrentPageName } from 'utils/common-helper'
import { GPT_NETWORK } from 'utils/constData'
import { initialiseDatadogSdk } from 'utils/datadog'
import '../styles/tailwind.css'
import './style.css'

NProgress.configure({ showSpinner: false })
type NextPageWithLayout = NextPage & {
    getLayout?: (page: ReactElement) => ReactNode
}

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout
}

function GetLayout({ Component, pageProps }: any) {
    const { apolloClient } = useApollo(pageProps.initialApolloState)
    const getLayout =
        Component.getLayout ?? ((page: any) => <AppLayout>{page}</AppLayout>)

    return (
        <ApolloProvider client={apolloClient}>
            {getLayout(<Component {...pageProps} />)}
        </ApolloProvider>
    )
}

// *************************************************************************************
// GPT Error filtering. To view in browser, enter "localStorage.debug='gpt'" into the console
// Enter "localStorage.debug=''" to stop it
var debug = require('debug')('gpt')
const originalConsoleError = console.error
const originalConsoleWarning = console.warn
// const filterConsole = (logFunction: (...data: any[]) => void, ...args: any) => {
const filterConsole = (logFunction: (...data: any[]) => void, args: any[]) => {
    let gptError = false
    const text = JSON.stringify(args)
    gptError = text.includes('setTargeting') || text.includes('defineSlot')
    if (!gptError) {
        logFunction(args)
    } else {
        debug(args)
    }
}
console.error = (args) => filterConsole(originalConsoleError, args)
console.warn = (args) => filterConsole(originalConsoleWarning, args)

//initialise datadog
initialiseDatadogSdk()

function Streamer({ Component, pageProps }: AppPropsWithLayout) {
    const { router }: any = useRouter()

    Router.events.on('routeChangeStart', () => {
        NProgress.start()
    })
    Router.events.on('routeChangeComplete', () => {
        NProgress.done()
    })
    Router.events.on('routeChangeError', () => NProgress.done())

    useEffect(() => {
        const jssStyles = document.querySelector('#jss-server-side')
        if (jssStyles?.parentElement) {
            jssStyles.parentElement.removeChild(jssStyles)
        }

        const gtmId = process.env.NEXT_PUBLIC_GTAG ?? ''
        if (!gtmId) {
            console.error('GTM id not defined')
        }

        /** Google Tag Manager */
        TagManager.initialize({ gtmId })
    }, [])

    /** Facebook Pixel */
    useEffect(() => {
        import('react-facebook-pixel')
            .then((x) => x.default)
            .then((ReactPixel) => {
                ReactPixel.init(`${process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID}`)
                ReactPixel.pageView()

                Router.events.on('routeChangeComplete', () => {
                    ReactPixel.pageView()
                })
            })
    }, [Router.events])

    const heightOfParentStickyHeader = () => {
        let width = '66%'
        if (getCurrentPageName(router?.pathname) === 'League Page') {
            width = '39%'
        } else {
            width = '66%'
        }
        return width
    }

    return (
        <UserProvider>
            <ThemeProvider theme={defaultTheme}>
                <GPTProvider networkId={GPT_NETWORK}>
                    <GetLayout Component={Component} pageProps={pageProps} />
                    <GlobalStyle
                        heightOfParentStickyHeader={heightOfParentStickyHeader()}
                    />
                </GPTProvider>
            </ThemeProvider>
            <CommonScripts />
        </UserProvider>
    )
}

export default wrapper.withRedux(Streamer)
