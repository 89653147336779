import React from 'react'
import styled from 'styled-components'

export const DisplayWrapper = styled.div`
    padding: 30px 0;
`

export const CopyButton = styled.button`
    border: solid white 1px;
    border-radius: 5px;
    padding: 5px;
    font-size: 1rem;
    font-weight: bold;
    z-index: 5;
    white-space: nowrap;
`
