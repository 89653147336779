import React from 'react'

// type
import { IconProps } from 'types/components/Icon'

const ActivityFeedPhotoIcon: React.FC<IconProps> = ({
    iColor = '#818181',
    iSize = { x: 38, y: 36 },
}) => {
    return (
        <svg
            width={iSize.x}
            height={iSize.y}
            viewBox="0 0 32 32"
            id="i-photo"
            xmlns="http://www.w3.org/2000/svg"
            fill="transparent"
            stroke={iColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
        >
            <path
                fill="transparent"
                d="M20 24 L12 16 2 26 2 2 30 2 30 24 M16 20 L22 14 30 22 30 30 2 30 2 24"
            />
            <circle cx="10" cy="9" r="3" />
        </svg>
    )
}

export default ActivityFeedPhotoIcon
