import { Button } from 'components/Button'
import { useRouter } from 'next/router'
import Script from 'next/script'
import React from 'react'
import { ImCancelCircle } from 'react-icons/im'
import {
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalWrapper,
} from './index.style'

const Screamer: React.FC<{ show: boolean; handleClose: any }> = ({
    show = true,
    handleClose,
}) => {
    const router = useRouter()

    return (
        <ModalWrapper show={show}>
            <ModalContent show={show}>
                <ModalHeader>
                    <Button
                        style={{
                            backgroundColor: '#1B1B25',
                        }}
                        bColor="primary"
                        bSize="small"
                        icon={<ImCancelCircle />}
                        onClick={handleClose}
                    />
                </ModalHeader>
                <ModalBody>
                    <Script id="show-banner" strategy="lazyOnload">
                        {`!function(){var e=document.getElementById("campaign-embed-code");e||((e=document.createElement("SCRIPT")).src="https://d1m2uzvk8r2fcn.cloudfront.net/scripts/embed-code/1640204595.min.js",e.id="campaign-embed-code",document.body.appendChild(e))}()`}
                    </Script>

                    <iframe
                        allow="autoplay"
                        sandbox="allow-modals allow-forms allow-scripts allow-same-origin allow-popups allow-top-navigation allow-top-navigation-by-user-activation allow-downloads allow-popups-to-escape-sandbox"
                        id="CPPxWF"
                        className="embed_frame"
                        loading="lazy"
                        src="https://m.shortstack.page/CPPxWF?embed=2"
                        style={{ width: '100%', minHeight: '300px' }}
                        frameBorder="0"
                        allowFullScreen
                        data-hj-allow-iframe
                    ></iframe>
                </ModalBody>

                {/* <ModalFooter></ModalFooter> */}
            </ModalContent>
        </ModalWrapper>
    )
}

export default Screamer
