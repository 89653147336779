import { themeGet } from '@styled-system/theme-get'
import styled from 'styled-components'
import { defaultTheme } from 'theme'

const LoadingContainer = styled.div`
    display: flex;
    margin: 200px;

    transition: all ease 0.5s;
    @media screen and (max-width: ${defaultTheme.mediaSize.lg}px) {
        margin: 180px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        margin: 120px;
        transform: scale(0.8);
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        margin: 50px;
        transform: scale(0.5);
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.xs}px) {
        margin: 10px;
        transform: scale(0.4);
    }

    justify-content: center;
    align-items: center;
    .wrapper {
        width: auto;
        height: auto;
    }
    ul {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        -webkit-animation: rot 16s linear infinite;
        animation: rot 16s linear infinite;
    }
    @-webkit-keyframes rot {
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes rot {
        100% {
            transform: rotate(360deg);
        }
    }

    li {
        width: 40px;
        height: 40px;
        background: ${themeGet('colors.primary.regular')};
        border-radius: 4px;
        box-shadow: 0 0 1px #fff, 0 0 5px ${themeGet('colors.primary.regular')},
            0 0 10px ${themeGet('colors.primary.regular')},
            0 0 15px ${themeGet('colors.primary.regular')},
            0 0 25px ${themeGet('colors.primary.regular')},
            0 0 55px ${themeGet('colors.primary.regular')};
        -webkit-animation: scale 0.8s linear alternate infinite;
        animation: scale 0.8s linear alternate infinite;
    }
    @-webkit-keyframes scale {
        100% {
            transform: scale(0.1);
            opacity: 0;
        }
    }
    @keyframes scale {
        100% {
            transform: scale(0.1);
            opacity: 0;
        }
    }
    li:nth-child(1) {
        z-index: 24;
    }
    li:nth-child(2) {
        z-index: 23;
    }
    li:nth-child(3) {
        z-index: 22;
    }
    li:nth-child(4) {
        z-index: 21;
    }
    li:nth-child(5) {
        z-index: 20;
    }
    li:nth-child(6) {
        z-index: 19;
    }
    li:nth-child(7) {
        z-index: 18;
    }
    li:nth-child(8) {
        z-index: 17;
    }
    li:nth-child(9) {
        z-index: 16;
    }
    li:nth-child(10) {
        z-index: 15;
    }
    li:nth-child(11) {
        z-index: 14;
    }
    li:nth-child(12) {
        z-index: 13;
    }
    li:nth-child(13) {
        z-index: 12;
    }
    li:nth-child(14) {
        z-index: 11;
    }
    li:nth-child(15) {
        z-index: 10;
    }
    li:nth-child(16) {
        z-index: 9;
    }
    li:nth-child(17) {
        z-index: 8;
    }
    li:nth-child(18) {
        z-index: 7;
    }
    li:nth-child(19) {
        z-index: 6;
    }
    li:nth-child(20) {
        z-index: 5;
    }
    li:nth-child(21) {
        z-index: 4;
    }
    li:nth-child(22) {
        z-index: 3;
    }
    li:nth-child(23) {
        z-index: 2;
    }
    li:nth-child(24) {
        z-index: 1;
    }
    li:nth-child(25) {
        z-index: 0;
    }
    li:nth-child(1) {
        -webkit-animation-delay: 0.1s;
        animation-delay: 0.1s;
    }
    li:nth-child(7) {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }
    li:nth-child(13) {
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
    }
    li:nth-child(19) {
        -webkit-animation-delay: 0.7s;
        animation-delay: 0.7s;
    }
    li:nth-child(24) {
        -webkit-animation-delay: 0.9s;
        animation-delay: 0.9s;
    }
    li:nth-child(2) {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
    }
    li:nth-child(8) {
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
    }
    li:nth-child(14) {
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
    }
    li:nth-child(20) {
        -webkit-animation-delay: 0.8s;
        animation-delay: 0.8s;
    }
    li:nth-child(3) {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }
    li:nth-child(9) {
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
    }
    li:nth-child(15) {
        -webkit-animation-delay: 0.7s;
        animation-delay: 0.7s;
    }
    li:nth-child(4) {
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
    }
    li:nth-child(10) {
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
    }
    li:nth-child(5) {
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
    }
    li:nth-child(1) {
        -webkit-animation-delay: 0.1s;
        animation-delay: 0.1s;
    }
    li:nth-child(6) {
        -webkit-animation-delay: 0.2s;
        animation-delay: 0.2s;
    }
    li:nth-child(11) {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }
    li:nth-child(16) {
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
    }
    li:nth-child(21) {
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
    }
    li:nth-child(7) {
        -webkit-animation-delay: 0.3s;
        animation-delay: 0.3s;
    }
    li:nth-child(12) {
        -webkit-animation-delay: 0.4s;
        animation-delay: 0.4s;
    }
    li:nth-child(17) {
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
    }
    li:nth-child(22) {
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
    }
    li:nth-child(13) {
        -webkit-animation-delay: 0.5s;
        animation-delay: 0.5s;
    }
    li:nth-child(18) {
        -webkit-animation-delay: 0.6s;
        animation-delay: 0.6s;
    }
    li:nth-child(23) {
        -webkit-animation-delay: 0.7s;
        animation-delay: 0.7s;
    }
    li:nth-child(19) {
        -webkit-animation-delay: 0.7s;
        animation-delay: 0.7s;
    }
    li:nth-child(24) {
        -webkit-animation-delay: 0.8s;
        animation-delay: 0.8s;
    }
    li:nth-child(25) {
        -webkit-animation-delay: 0.9s;
        animation-delay: 0.9s;
    }
`
const CustomLoading = () => {
    return (
        <LoadingContainer>
            <div className="wrapper">
                <ul>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                    <li></li>
                </ul>
            </div>
        </LoadingContainer>
    )
}

export default CustomLoading
