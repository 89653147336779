import axios from 'axios'
import { apiBaseUrl } from './constData'

const getFileExtension = (fileType: string) => {
    const mimeParts = fileType.split('/')
    if (mimeParts.length === 2) {
        const extension = mimeParts[1].split(';')[0]
        return extension
    }
    return null
}

export async function s3UploadFile(
    dirName = 'Club',
    filename = 'image',
    file: File,
) {
    const filePath = `${dirName}/${filename}.${getFileExtension(file.type)}`

    /** get upload url */
    const presignedRes = await axios.request({
        method: 'POST',
        url: `${apiBaseUrl}/assets/s3/put-signed-url`,
        headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
        },
        data: { key: filePath, contentType: file.type },
    })

    /**upload the file */
    await axios.put(presignedRes.data, file, {
        headers: { 'Content-Type': file.type },
    })
    return {
        location: `${process.env.NEXT_PUBLIC_ASSETS_BUCKET_CDN_URL}/${filePath}`,
    }
}
