// styled component
import { themeGet } from '@styled-system/theme-get'
import styled from 'styled-components'
// -------------------------------------------------
import { Menu } from 'antd'
import { BiChevronDown } from 'react-icons/bi'

export const StyledMenu = styled(Menu)`
    background-color: ${themeGet('colors.gray.900')};
    border-bottom: none;
    .ant-menu-submenu-horizontal > .ant-menu {
        margin-top: -2px;
    }
    width: 170px;
    text-align: center;
`

export const StyledItemMenu = styled(Menu.Item)`
    font-size: 12px;
    padding: 10px 20px;
    color: white;
    transition: all 0.2s ease-in-out;
    ::after {
        transition: all 0.2s ease-in-out;
        position: absolute;
        content: '';
        height: 2px;
        background-color: ${themeGet('colors.white')};
        width: 100%;
        left: 0;
        bottom: -5px;
        transform: scaleX(0);
    }
    :hover {
        background: ${themeGet('colors.gray.900')};
        font-weight: 700;
        ::after {
            transform: scaleX(1);
        }
    }
`

export const ProfileDropdownWrapper = styled.div``

export const StyledProfileDropdownLink = styled.a`
    &:hover {
        color: ${themeGet('colors.orange.hover')};
    }
`

export const ProfileDropdownContent = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
    font-size: 1rem;
`

export const StyledProfilePicture = styled.img`
    border-radius: 4px;
`

export const StyledProfileName = styled.p`
    margin-left: 6px;
`

export const StyledDownArrow = styled(BiChevronDown)`
    margin-bottom: 2px;
`
