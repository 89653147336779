import { useMutation } from '@apollo/client'
import { Tooltip } from 'antd'
import { Button } from 'components/Button'
import { Col, Row } from 'components/Layout'
import { Admin_A_Modal } from 'components/Modal/Admin'
import Admin_U_Modal from 'components/Modal/Admin/Update'
import { ModalWrapper } from 'components/Modal/common.style'
import { Table } from 'components/Table'
import { Text } from 'components/Text'
import { HomeQL } from 'graphql/club'
import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { BsArrowRepeat, BsPencil, BsPlus, BsTrash } from 'react-icons/bs'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { DetailWrapper } from './detail.style'

const DetailSection: React.FC<{ club: any }> = ({ club }) => {
    const [a_show, setAShow] = useState<boolean>(false)
    const [u_show, setUShow] = useState<boolean>(false)
    const [d_show, setDShow] = useState<boolean>(false)
    const [aid, setAid] = useState<any>(null)

    const [formData, setFormData] = useState<any>(
        club?.club_admins
            ? club.club_admins.find((admin: { id: any }) => aid === admin.id)
            : [],
    )

    useEffect(() => {
        setFormData(
            club?.club_admins
                ? club.club_admins.find(
                      (admin: { id: any }) => aid === admin.id,
                  )
                : [],
        )
    }, [aid])

    const [resendInvitation] = useMutation(HomeQL.UPDATE_INVITED_CLUB_ADMINS)
    const [deleteClubAdminDetails] = useMutation(HomeQL.DELETE_CLUB_ADMINS)

    if (!club.club_admins) {
        return <></> /** should be loader */
    }

    const deleteClubAdmin = async () => {
        const response = await deleteClubAdminDetails({
            variables: {
                deleteWhere: {
                    id: { _eq: aid },
                },
            },
        })
        if (response?.data?.delete_club_admin_details) {
            toast.success('Successfully Deleted!')
            setDShow(false)
        }
    }

    const resendInvite = async (admin: any) => {
        const response = await resendInvitation({
            variables: {
                where: {
                    id: { _eq: admin.id },
                },
                set: {
                    is_accepted: false,
                    is_declined: false,
                },
            },
        })
        if (response?.data?.update_club_admin_details) {
            toast.success('Re Sending Invitation Successful!')
        }
    }

    const _handleDelete = (aid: any) => {
        setAid(aid)
        setDShow(true)
    }

    const _handleClose = () => {
        setUShow(false)
        setDShow(false)
        setAid(null)
    }
    const onHandleEdit = (aid: any) => {
        setAid(aid)
        setUShow(true)
    }

    const data = club?.club_admins?.map(
        (v: {
            user: { first_name: any; last_name: any; email: any; mobile: any }
            name: any
            email_address: any
            status: any
            is_accepted: any
            is_declined: any
            id: any
        }) => ({
            Name: v?.user?.first_name
                ? `${v.user.first_name} ${v.user.last_name}`
                : `${v?.name}`,
            Email: v?.email_address ?? 'N/A',
            Status: v?.user
                ? v?.status && 'Active'
                : v?.is_accepted
                  ? 'Active'
                  : 'Invited',
            Action: (
                <Row justifyContent="center" alignItems="center">
                    {v?.user
                        ? v?.status && (
                              <Tooltip placement="top" title="Resend Invite">
                                  <Button
                                      disabled={true}
                                      style={{
                                          borderRadius: '50%',
                                          padding: '8px',
                                      }}
                                      icon={<BsArrowRepeat />}
                                      shape="circle"
                                  />
                              </Tooltip>
                          )
                        : (!v?.is_accepted || v.is_declined) && (
                              <Tooltip placement="top" title="Resend Invite">
                                  <Button
                                      onClick={() => resendInvite(v)}
                                      style={{
                                          borderRadius: '50%',
                                          padding: '8px',
                                      }}
                                      icon={<BsArrowRepeat />}
                                      shape="circle"
                                  />
                              </Tooltip>
                          )}
                    <Tooltip placement="top" title="Edit">
                        <Button
                            onClick={() => onHandleEdit(v?.id)}
                            style={{ borderRadius: '50%', padding: '8px' }}
                            icon={<BsPencil />}
                        />
                    </Tooltip>
                    <Tooltip placement="top" title="Delete">
                        <Button
                            onClick={() => _handleDelete(v?.id)}
                            style={{ borderRadius: '50%', padding: '8px' }}
                            icon={<BsTrash />}
                            shape="circle"
                        />
                    </Tooltip>
                </Row>
            ),
        }),
    )

    return (
        <>
            <DetailWrapper>
                <Row flexDirection="column" gap={20}>
                    <Col item={24}>
                        <Row justifyContent="space-between" alignItems="center">
                            <Col item={12}>
                                <Text
                                    fSize={1.5}
                                    fWeight={700}
                                    mode="h2"
                                    fColor="gray.600"
                                >
                                    {'Club Admin Details'}
                                </Text>
                            </Col>
                        </Row>
                    </Col>
                    <Col item={24}>
                        <Table data={data} />
                    </Col>
                    <Col item={24}>
                        <Button
                            bColor="primary"
                            bSize="small"
                            icon={<BsPlus />}
                            onClick={() => setAShow(true)}
                        >
                            <Text fSize={1} fWeight={700}>
                                {'Add another Club Admin'}
                            </Text>
                        </Button>
                    </Col>
                </Row>
            </DetailWrapper>
            <Admin_A_Modal show={a_show} handleClose={() => setAShow(false)} />
            {!_.isUndefined(aid) ? (
                <Admin_U_Modal
                    show={u_show}
                    handleClose={_handleClose}
                    mid={aid}
                />
            ) : null}

            <ModalWrapper
                open={d_show}
                closable={true}
                title="Delete Admin"
                width={400}
                footer={[
                    <Button
                        bColor="primary"
                        key={'btn-cncl'}
                        bSize="small"
                        type="button"
                        onClick={_handleClose}
                    >
                        {'Cancel'}
                    </Button>,
                    <Button
                        bColor="primary"
                        key={'btn-save'}
                        bSize="small"
                        type="submit"
                        onClick={deleteClubAdmin}
                    >
                        {'Yes'}
                    </Button>,
                ]}
            >
                <Row flexDirection="column" gap={20}>
                    <Col item={24}>
                        <Text
                            fSize={1}
                            fWeight={700}
                            mode="h2"
                            fColor="gray.600"
                        >
                            {'Are you sure you want to delete admin?'}
                        </Text>
                    </Col>
                </Row>
            </ModalWrapper>
        </>
    )
}

const mapStateToProps = (state: { club: { info: any } }) => ({
    club: state.club.info,
})

// @ts-ignore
export default connect(mapStateToProps)(DetailSection)
