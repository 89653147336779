import LogoImage from 'assets/images/layout/logo.png'
import StreamerBHPLogoImage from 'assets/images/logo/streamer_bhp_stacked.png'
import { Image } from 'components/Image'
import { Text } from 'components/Text'
import { useRouter } from 'hooks'
import { getSiteSettings } from 'hooks/settings'
import Link from 'next/link'
import { SocialIcon } from 'react-social-icons'
import { getThisYear, isAfter } from 'utils/helper-date'
import {
    CopyRightSectionWrapper,
    LinkColumnContainer,
    LinkColumnHeader,
    LinksContainer,
    SocialIconsContainer,
    StreamerLogoWrapper,
    StreamerSectionWrapper,
} from './copyrightsection.style'

// ------------------------------------------------------

function FooterLink({ children, ...rest }: any) {
    return (
        <Text
            mode="a"
            style={{ cursor: 'pointer' }}
            hoverStyle={{ fColor: 'orange.hover' }}
            {...rest}
        >
            {children}
        </Text>
    )
}

const CopyRightSection = () => {
    const { move, asPath }: any = useRouter()
    const showBHPLogo =
        isAfter('2023-09-02T04:00:00.000+08:00') ||
        getSiteSettings().global.override_show_bhp_logo

    const handleMenuClick = (to: any) => {
        move(to)
    }

    return (
        <CopyRightSectionWrapper>
            <LinksContainer>
                <LinkColumnContainer>
                    <LinkColumnHeader>ACCOUNT</LinkColumnHeader>
                    <FooterLink
                        onClick={() =>
                            handleMenuClick(
                                `/api/auth/signup?returnTo=${asPath}`,
                            )
                        }
                    >
                        {'Sign Up'}
                    </FooterLink>

                    <FooterLink
                        mode="a"
                        style={{ cursor: 'pointer' }}
                        onClick={() =>
                            handleMenuClick(
                                `/api/auth/login?returnTo=${asPath}`,
                            )
                        }
                    >
                        {'Log In'}
                    </FooterLink>

                    <FooterLink
                        mode="a"
                        style={{ cursor: 'pointer' }}
                        href={'/terms-of-use'}
                    >
                        {'Terms Of Use'}
                    </FooterLink>
                </LinkColumnContainer>

                <LinkColumnContainer>
                    <LinkColumnHeader>COMPANY</LinkColumnHeader>
                    <FooterLink
                        mode="a"
                        style={{ cursor: 'pointer' }}
                        href={'/about'}
                    >
                        {'About Us'}
                    </FooterLink>
                    <FooterLink
                        mode="a"
                        style={{ cursor: 'pointer' }}
                        href={
                            'https://advertising.sevenwestmedia.com.au/digital/streamer/'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {'Advertise with Us'}
                    </FooterLink>
                    <FooterLink
                        mode="a"
                        style={{ cursor: 'pointer' }}
                        href={'/dfp-about'}
                    >
                        {'Digital Fan Pass'}
                    </FooterLink>
                    <FooterLink
                        mode="a"
                        style={{ cursor: 'pointer' }}
                        href={'/faq'}
                    >
                        {'FAQs'}
                    </FooterLink>
                    <FooterLink
                        mode="a"
                        style={{ cursor: 'pointer' }}
                        href={
                            'https://www.sevenwestmedia.com.au/privacy-policies/privacy/'
                        }
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {'Privacy Policy'}
                    </FooterLink>
                </LinkColumnContainer>
                <div>
                    <LinkColumnHeader>CONNECT WITH US</LinkColumnHeader>
                    <SocialIconsContainer>
                        <SocialIcon
                            fgColor="#000000"
                            bgColor="#ffffff"
                            style={{ height: 35, width: 35 }}
                            url="https://www.facebook.com/streamersports"
                            target="_blank"
                            rel="noopener noreferrer"
                        />
                        <SocialIcon
                            fgColor="#000000"
                            bgColor="#ffffff"
                            style={{ height: 35, width: 35 }}
                            url="https://www.instagram.com/streamersports"
                            target="_blank"
                            rel="noopener noreferrer"
                        />
                        <SocialIcon
                            fgColor="#000000"
                            bgColor="#ffffff"
                            style={{ height: 35, width: 35 }}
                            url="https://www.tiktok.com/@streamersports"
                            target="_blank"
                            rel="noopener noreferrer"
                        />
                    </SocialIconsContainer>
                </div>
            </LinksContainer>
            <StreamerSectionWrapper>
                <StreamerLogoWrapper>
                    <Link href="/" legacyBehavior>
                        <Image
                            src={showBHPLogo ? StreamerBHPLogoImage : LogoImage}
                            height={showBHPLogo ? 84 : 70}
                            width={241}
                            alt="Streamer: Watch community sport live streams and replays"
                        />
                    </Link>
                </StreamerLogoWrapper>
                <Text fColor="white" fSize={0.9375}>
                    © West Australian Newspapers Limited {getThisYear()}.
                </Text>
            </StreamerSectionWrapper>
        </CopyRightSectionWrapper>
    );
}
export default CopyRightSection
