import React, { useState } from 'react'
// assets
import { Button } from 'components/Button'
// types
import { ModalProps } from 'types/components/Modal'
// common
import { BsPlus } from 'react-icons/bs'
import { ImCancelCircle } from 'react-icons/im'

import { CustomeInput } from '../index.style'

import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { Form } from 'antd'
import { ADMINQL } from 'graphql/club'
import { INSERT_LEAGUE_ADMINS } from 'graphql/club/home'
import { useRouter } from 'next/router'
import { toast } from 'react-toastify'
import { ModalWrapper } from '../../common.style'

const Admin_A_Modal: React.FC<ModalProps> = ({ show = false, handleClose }) => {
    const [form] = Form.useForm()
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')

    const router = useRouter()
    const { league_slug } = router.query

    const { data } = useQuery(ADMINQL.GET_LEAGUE, {
        variables: {
            where: {
                slug: { _eq: league_slug },
            },
        },
    })

    const league = data?.leagues[0]

    const onFullNameChange = (e: {
        target: { value: React.SetStateAction<string> }
    }) => {
        setFullName(e.target.value)
    }

    const onEmailChange = (e: {
        target: { value: React.SetStateAction<string> }
    }) => {
        setEmail(e.target.value)
    }

    const [inviteAdmin] = useMutation(INSERT_LEAGUE_ADMINS)
    const [checkDuplicateEmail] = useLazyQuery(ADMINQL.GET_LEAGUE_ADMIN_EMAIL, {
        onCompleted(data) {
            if (data?.admin_details.length > 0) {
                toast.error('User is already a club admin.')
            } else {
                sendInvite()
            }
        },
    })

    const sendInvite = async () => {
        const response = await inviteAdmin({
            variables: {
                objects: {
                    league_id: league.id,
                    name: fullName,
                    email_address: email.toLowerCase(),
                    status: true,
                    is_accepted: false,
                    is_declined: false,
                },
            },
        })
        if (response?.data?.insert_admin_details?.affected_rows) {
            toast.success('Invited Successfully!')
            router.reload()
        }
    }

    const onFinish = () => {
        checkDuplicateEmail({
            variables: {
                where: {
                    email_address: { _eq: email },
                    league_id: { _eq: league?.id },
                },
            },
        })
        // sendInvite();
        form.resetFields()
        handleClose && handleClose()
    }

    const _handleClose = () => {
        form.resetFields()
        handleClose && handleClose()
    }

    return (
        <ModalWrapper
            open={show}
            closable={false}
            title="Add League Admin"
            width={400}
            footer={[
                <Button
                    bColor="primary"
                    key={'btn-cncl'}
                    bSize="small"
                    icon={<ImCancelCircle />}
                    type="button"
                    onClick={_handleClose}
                >
                    {'Cancel'}
                </Button>,
                <Button
                    bColor="primary"
                    key={'btn-save'}
                    bSize="small"
                    type="submit"
                    icon={<BsPlus />}
                    onClick={onFinish}
                >
                    {'Send Invite'}
                </Button>,
            ]}
        >
            <div>
                <Form
                    style={{
                        display: 'flex',
                        width: '100%',
                        flexDirection: 'column',
                    }}
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                >
                    <Form.Item
                        name="name"
                        rules={[
                            { required: true, message: 'Name is required.' },
                        ]}
                        label={
                            <label style={{ color: 'white' }}>{'Name'}</label>
                        }
                    >
                        <CustomeInput
                            onChange={onFullNameChange}
                            placeholder="Name"
                        />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                        ]}
                        label={
                            <label style={{ color: 'white' }}>
                                {'Email Address'}
                            </label>
                        }
                    >
                        <CustomeInput
                            onChange={onEmailChange}
                            placeholder="Email"
                        />
                    </Form.Item>
                </Form>
            </div>
        </ModalWrapper>
    )
}

export default Admin_A_Modal
