import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { defaultTheme } from 'theme'
import { Modal } from 'antd'

export interface SectionWrapperProps {
    display?: 'grid' | 'flex' | 'block' | 'none'
    flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
    justifyContent?:
        | 'center'
        | 'flex-start'
        | 'flex-end'
        | 'initial'
        | 'space-around'
        | 'space-between'
        | 'stretch'
    alignItems?: 'center' | 'flex-start' | 'flex-end' | 'initial' | 'stretch'
    padding?: string
    gap?: string
}

export const ModalWrapperAnt = styled(Modal)`
    .ant-modal-content {
        background: transparent;
        padding: 0px 10px;

        ul > li {
            color: white;
            list-style: disc;
        }

        .ant-modal-body {
            & > div:first-child {
                display: flex;
                flex-direction: column !important;
                @media screen and (min-width: ${defaultTheme.mediaSize.sm}px) {
                    flex-direction: row;
                }
            }
        }

        .ant-modal-header {
            background: #1b1b25;
            border: none;
            .ant-modal-title {
                color: white;
                font-size: 1.6em;
                font-weight: 700;
            }
        }

        .ant-modal-footer {
            border: none;
            display: flex;
            padding-bottom: 20px;
            justify-content: flex-end;
        }
    }
`

export const ModalWrapper = styled.div<{ show: boolean }>`
    position: fixed;
    z-index: 16;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    ${({ show }) =>
        show
            ? css`
                  visibility: visible;
              `
            : css`
                  visibility: hidden;
              `};
`

export const ModalContent = styled.div<{ show: boolean }>`
    border-radius: 4px;
    transition: all ease 0.5s;
    ${({ show }) =>
        show
            ? css`
                  display: block;
              `
            : css`
                  display: none;
              `};
    padding: 0;
    position: fixed;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 500px;

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        width: 400px;
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        width: 300px;
    }
`

export const ModalBody = styled.div`
    padding: 5px 50px 5px 50px;
    background-color: ${themeGet('colors.gray.900')};
`

export const SectionWrapper = styled.div<SectionWrapperProps>`
    display: ${({ display = 'flex' }) => display};
    flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
    justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
    align-items: ${({ alignItems = 'flex-start' }) => alignItems};
    padding: ${({ padding }) => padding || '0'};
    gap: ${({ gap }) => gap || '0'};
`

export const ModalHeader = styled.div`
    padding-bottom: 10px;
    display: flex;
    justify-content: right;
`

export const ModalFooter = styled.div`
    padding: 20px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    background: ${themeGet('colors.black.300')};
    .footer-text {
        font-size: 24px;
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        padding: 15px;
        .footer-text {
            font-size: 20px;
        }
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        padding: 10px;
        .footer-text {
            font-size: 14.5px;
        }
    }
`

export const IconWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 6rem;
    height: 6rem;
    border-radius: 9999px;
`
