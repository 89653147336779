import { useRouter } from 'next/router'
import React, { useEffect, useState } from 'react'

declare global {
    interface Window {
        dm: any
        DotMetricsObj: any
    }
}

// IPSOS section IDS
const ipsosSecIds = {
    home: 13538,
    other: 13539,
}

function CommonScripts() {
    const router = useRouter()
    const isProduction =
        process.env.NEXT_PUBLIC_SETTINGS_FILE === 'settings.json'
    const [url, setUrl] = useState('/')
    const [sectionID, setSectionID] = useState(
        url === '/' ? ipsosSecIds.home : ipsosSecIds.other,
    )

    const handleRouteChange = (url: React.SetStateAction<string>) => {
        // Trigger a callback function when the route changes, such as updating the page title
        // console.log(`Route changed to: ${url}`)
        setUrl(url)
        setSectionID(url === '/' ? ipsosSecIds.home : ipsosSecIds.other)
        setTimeout(window.dm?.AjaxEvent('pageview', null, sectionID), 100)
    }

    useEffect(() => {
        router.events?.on('routeChangeComplete', handleRouteChange)
    }, [router.events])

    useEffect(() => {
        // Dynamically load the IPSOS script
        const script = document.createElement('script')
        script.src = `https://au-script.dotmetrics.net/door.js?id=${sectionID}`
        script.async = true
        script.setAttribute('strategy', 'afterInteractive')
        script.onload = () => {
            window.dm = window.dm || { AjaxData: [] }
            window.dm.AjaxEvent = function (
                et: any,
                d: any,
                ssid: any,
                ad: any,
            ) {
                window.dm.AjaxData.push({ et: et, d: d, ssid: ssid, ad: ad })
                if (typeof window.DotMetricsObj != 'undefined') {
                    window.DotMetricsObj.onAjaxDataUpdate()
                }
            }
        }

        isProduction && window.document.body.appendChild(script)

        //cleanUp
        return () => {
            if (!isProduction) return
            const door = window.document.querySelector(
                `script[src="https://au-script.dotmetrics.net/door.js?id=${sectionID}"]`,
            )
            door?.parentNode?.removeChild(door)
        }
    }, [sectionID])

    return <></>
}

export default CommonScripts
