import styled, { css } from 'styled-components'

export const Container = styled.div<{ isError: boolean }>`
    margin-bottom: 18px;
    max-width: 400px;
    label {
        font-size: 1rem;
        ${({ isError }) =>
            isError
                ? css`
                      color: red;
                  `
                : css`
                      color: #e5e5e5;
                  `};
    }
`

function FormItemWrapper(arg: any) {
    const { children, label = '', isError = false, ...rest } = arg
    return (
        <Container isError={isError} {...rest}>
            <label>{label}</label>
            {children}
        </Container>
    )
}

export default FormItemWrapper
