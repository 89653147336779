import { useMutation } from '@apollo/client'
import { Form, Input } from 'antd'
import axios from 'axios'
import { Button } from 'components/Button'
import { FlexContainer } from 'components/Container'
import { ModalWrapper } from 'components/Modal/common.style'
import { Text } from 'components/Text'
import { HomeQL } from 'graphql/club'
import { mutation } from 'graphql/dfp'
import { useUser } from 'hooks'
import { isNull } from 'lodash'
import { useState } from 'react'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { RootState } from 'redux/reducers/rootReducer'
import styled from 'styled-components'
import { defaultTheme } from 'theme'
import { ClubInfo } from 'types/common/club'
import { apiBaseUrl } from 'utils/constData'

const FormItem = styled(Form.Item)`
    margin-bottom: 10px;
    .ant-form-item-label {
        & > label {
            color: #fff;
        }
    }
`

const FormInput = styled(Input)`
    background-color: transparent;
    border-color: #666666;
    border-radius: 8px;
    font-size: 18px;
    padding: 10px 10px;
    color: #fff;

    &.ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
        ),
    &.ant-input-status-error:not(.ant-input-disabled):not(
            .ant-input-borderless
        ):hover {
        background: transparent;
        background-color: transparent;
    }
`

const List = styled.ul`
    li {
        font-size: 16px;
    }
`

const BtnTxt = styled.span`
    &::before {
        content: 'Activate Digital Fan Passes';
        @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
            content: 'Activate DFP';
        }
    }
`

/** NOTE:  Only Show this button if fan pass has been made for this club */
const DFPButton = (props: { clubInfo: ClubInfo }) => {
    const [form] = Form.useForm()
    const [show, setShow] = useState(false)
    const [update] = useMutation(HomeQL.UPDATE_CLUB)
    const [activateDfp] = useMutation(mutation.UPDATE_PASSES)
    const [loading, setLoading] = useState(false)
    const { isSuperAdmin } = useUser()

    const submitHandler = async () => {
        setLoading(true)

        /** get form values */
        const data = form.getFieldsValue()

        try {
            let where: any

            /**
             * if pass is existing dont create product get pass infor instead
             * generate where caluse
             */

            if (props?.clubInfo?.pass?.id) {
                where = { id: { _eq: props.clubInfo.pass.id } }
            } else {
                /** Production creation */
                const res = await axios.post(
                    `${apiBaseUrl}/passes/dfp/create-product`,
                    {
                        id: props.clubInfo.id,
                        price_value: parseInt(data.pass_price ?? 5),
                    },
                )
                where = { price_id: { _eq: res.data.price_id } }
            }

            /** update passes status */
            activateDfp({ variables: { where, _set: { active: true } } })

            /** Update club info */
            await update({
                variables: {
                    where: {
                        id: { _eq: props.clubInfo.id },
                    },
                    set: {
                        account_name: data.account_name,
                        account_bsb: data.account_bsb,
                        account_number: data.account_number,
                    },
                },
            }).then((res) => {
                toast.success('Digital Fan Pass has been activated.')
                setLoading(false)
                setTimeout(() => setShow(false), 1000)
            })
        } catch (error) {
            console.log(error)
            setLoading(false)
            setShow(false)
        }
    }

    if (!(isNull(props.clubInfo.pass) || !props.clubInfo.account_name)) {
        return <></>
    }

    return (
        <>
            <Button
                onClick={() => setShow(true)}
                bColor="warning"
                bSize="small"
            >
                <BtnTxt />
            </Button>

            <ModalWrapper footer={false} closable={false} open={show} centered>
                <FlexContainer
                    direction="column"
                    margin={'0px 0px 20px 0px'}
                    smDirection="column"
                >
                    <Text mode="h1" fSize={1.6} fColor="white">
                        Boost your club's revenue with Digital Fan Passes
                    </Text>
                    <List style={{ marginBottom: 15 }}>
                        <li>
                            Activate Digital Fan Passes to give followers and
                            supporters of your club the option to pay just $5 a
                            month to support your team
                        </li>
                        <li>
                            With 70% of the revenue going directly to your club,
                            you can use those funds to cover the cost of
                            streaming and invest in other important initiatives
                        </li>
                        <li>
                            Digital Fans can create and submit clips that you
                            can approve as a club admin, making them feel like a
                            valuable part of the team
                        </li>
                    </List>
                    <Text fColor="white" mode="h1" fSize={1.4}>
                        Bank Details{' '}
                    </Text>
                    <Text fSize={1} fColor="white">
                        Please enter details for the bank account you wish
                        toreceive Digital Fan Pass payouts to.
                    </Text>
                </FlexContainer>

                <Form
                    name="dfp-create-form"
                    layout="vertical"
                    style={{ width: '100%' }}
                    form={form}
                    onFinish={submitHandler}
                >
                    <FormItem
                        name="account_name"
                        label="Account Name"
                        rules={[
                            {
                                required: true,
                                message: 'Account Name is required.',
                            },
                        ]}
                    >
                        <FormInput placeholder="Account Name" />
                    </FormItem>

                    <FormItem
                        name="account_bsb"
                        label="BSB"
                        rules={[
                            {
                                required: true,
                                pattern: /^[0-9]{1,6}$/,
                                message: 'Please enter a valid BSB.',
                            },
                        ]}
                    >
                        <FormInput placeholder="BSB" />
                    </FormItem>

                    <FormItem
                        name="account_number"
                        label="Account Number"
                        rules={[
                            {
                                required: true,
                                pattern: /^[0-9]{6,10}$/,
                                message: 'Please enter a valid Account Number',
                            },
                        ]}
                    >
                        <FormInput placeholder="Account Number" />
                        {/* <Text fColor="red.100" mode="span" > {form.getFieldError('account_number')}</Text> */}
                    </FormItem>

                    {isSuperAdmin ? (
                        <FormItem
                            name="pass_price"
                            label="Digital Fan Pass Price ($)"
                        >
                            <FormInput type="number" defaultValue="5" min="0" />
                        </FormItem>
                    ) : null}

                    <FlexContainer
                        direction="row"
                        justify="space-around"
                        margin={'40px 0px 00px 0px'}
                    >
                        <Button
                            onClick={() => setShow(false)}
                            style={{ minWidth: 150 }}
                            bSize="medium"
                        >
                            Cancel
                        </Button>
                        <Button
                            loading={loading}
                            type="submit"
                            style={{ minWidth: 150 }}
                            bColor="warning"
                            bSize="medium"
                        >
                            Activate
                        </Button>
                    </FlexContainer>
                </Form>
            </ModalWrapper>
        </>
    )
}

const mapStateToProps = (state: RootState) => ({
    clubInfo: state.club.info,
})

const mapDispatchToProps = {}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(DFPButton)
