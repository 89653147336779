import React from 'react'
// type
import { IconProps } from 'types/components/Icon'

// --------------------------------
const UserIcon: React.FC<IconProps> = ({
    iColor = 'white',
    iSize = { x: 15, y: 15 },
}) => {
    return (
        <svg
            width={iSize.x}
            height={iSize.y}
            viewBox="0 0 11 11"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.78589 0.702148C4.57729 0.702148 3.59839 1.68105 3.59839 2.88965C3.59839 4.09824 4.57729 5.07715 5.78589 5.07715C6.99448 5.07715 7.97339 4.09824 7.97339 2.88965C7.97339 1.68105 6.99448 0.702148 5.78589 0.702148ZM6.93445 2.88971C6.93445 2.25533 6.42038 1.74127 5.78601 1.74127C5.15163 1.74127 4.63757 2.25533 4.63757 2.88971C4.63757 3.52408 5.15163 4.03814 5.78601 4.03814C6.42038 4.03814 6.93445 3.52408 6.93445 2.88971ZM9.12194 7.81158C9.12194 7.46158 7.41023 6.66314 5.78601 6.66314C4.16179 6.66314 2.45007 7.46158 2.45007 7.81158V8.41314H9.12194V7.81158ZM1.41089 7.81152C1.41089 6.35684 4.32573 5.62402 5.78589 5.62402C7.24605 5.62402 10.1609 6.35684 10.1609 7.81152V9.45215H1.41089V7.81152Z"
                fill={iColor}
                fillOpacity="0.9"
            />
        </svg>
    )
}
export default UserIcon
