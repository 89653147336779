import Head from 'next/head'
import React from 'react'
import { PageProps } from 'types/components/Page'

const Page: React.FC<PageProps> = ({
    title,
    socialTitle,
    description,
    type = 'website',
    canonical,
    image,
    verification,
    children,
    video,
}) => {
    const finalImg =
        image ??
        `${process.env.NEXT_PUBLIC_ASSETS_BUCKET_CDN_URL}/default-streamer.jpg`
    const finalTitle =
        title ?? 'Streamer: Watch community sport live streams and replays'
    const finalDesc =
        description ??
        'See it on Streamer, where we give you the tools to watch or set up your own live streaming program. Currently featuring AFL, hockey, surfing, volleyball and more.'

    return (
        <>
            <Head>
                <title>{finalTitle}</title>
                <meta
                    key="viewport"
                    name="viewport"
                    content="width=device-width,maximum-scale=5,initial-scale=1"
                />
                <meta key="title" name="title" content={finalTitle} />
                <meta
                    key="description"
                    name="description"
                    content={finalDesc}
                />
                <meta key="og:type" property="og:type" content={type} />
                <meta
                    key="og:site_name"
                    property="og:site_name"
                    content="Streamer"
                />
                <meta
                    key="og:url"
                    property="og:url"
                    content={`${canonical || 'http://streamer.com.au'}`}
                />
                <meta
                    key="og:title"
                    property="og:title"
                    content={socialTitle ?? finalTitle}
                />
                {verification && (
                    <meta
                        key="facebook-domain-verification"
                        name="facebook-domain-verification"
                        content={verification}
                    />
                )}
                <meta
                    key="og:description"
                    property="og:description"
                    content={finalDesc}
                />
                <meta key="og:image" property="og:image" content={finalImg} />
                {video && (
                    <>
                        <meta
                            key="og:video"
                            property="og:video"
                            content={canonical}
                        />
                        <meta
                            property="og:video:secure_url"
                            content={canonical}
                        />
                        <meta
                            property="og:video:type"
                            content="application/vnd.apple.mpegurl"
                        />
                        <meta property="og:video:width" content="400" />
                        <meta property="og:video:height" content="300" />
                    </>
                )}

                <meta
                    key="twitter:card"
                    property="twitter:card"
                    content="summary_large_image"
                />
                <meta
                    key="twitter:url"
                    property="twitter:url"
                    content={`${canonical || 'http://streamer.com.au'}`}
                />
                <meta
                    key="twitter:title"
                    property="twitter:title"
                    content={socialTitle ?? finalTitle}
                />
                <meta
                    key="twitter:description"
                    property="twitter:description"
                    content={finalDesc}
                />
                <meta
                    key="twitter:image"
                    property="twitter:image"
                    content={finalImg}
                />

                {canonical && <link rel="canonical" href={canonical} />}
            </Head>
            {children}
        </>
    )
}
export default Page
