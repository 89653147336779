import { gql } from '@apollo/client'

const USER_INFO = gql`
    subscription UserSub($where: users_bool_exp = {}) {
        users(where: $where) {
            id
            first_name
            last_name
            email
            photo
            user_club_follows {
                club_id
            }
        }
    }
`

const PLAYER_USER_FOLLOW = gql`
    subscription UserFollowSub($where: user_player_follows_bool_exp = {}) {
        user_player_follows(where: $where) {
            user_id
            id
            players_detail {
                id
                slug
                club {
                    slug
                }
                user {
                    photo
                    first_name
                    last_name
                }
                team {
                    name
                }
            }
        }
    }
`

const SUB_USER_LEAGUE_PASS = gql`
    subscription SubUserLeaguePass($where: user_league_pass_bool_exp = {}) {
        user_league_pass(where: $where) {
            id
            user_id
            league_pass_id
            status
            pass {
                id
                active
                name
                description
                price
                recur
                type
            }
        }
    }
`

const SUB_USER_PASS = gql`
    subscription UserPasses($where: user_passes_bool_exp = {}) {
        user_passes(where: $where) {
            id
            status
            pass {
                id
                pass_type_id
            }
        }
    }
`

const SUB_USER_CLUB_ADMIN = gql`
    subscription UserClubAdmin($where: club_admin_details_bool_exp = {}) {
        club_admin_details(where: $where) {
            club_id
            user_id
            status
        }
    }
`

const SUB_USERS_FOLLOWS = gql`
    subscription UserFollowsSub($where: users_bool_exp) {
        users(where: $where) {
            user_club_follows {
                club {
                    id
                    name
                    display_name
                    slug
                    logo
                }
            }
            user_team_follows {
                team {
                    id
                    name
                    slug
                    image
                    club {
                        id
                        name
                        display_name
                        slug
                        logo
                    }
                }
            }
            user_player_follows {
                players_detail {
                    id
                    slug
                    club {
                        slug
                    }
                    user {
                        id
                        first_name
                        last_name
                        photo
                    }
                    team {
                        id
                        name
                        slug
                        club {
                            id
                            slug
                        }
                    }
                }
            }
        }
    }
`
const SUB_PLAYER = gql`
    subscription MyPlayerQuery($where: players_details_bool_exp!) {
        players_details(where: $where) {
            active
            bio
            club_id
            debut_date
            id
            image
            name
            last_name
            is_professional
            is_upgraded
            prev_club
            slug
            positions
            club {
                name
            }
            teams {
                id
                name
            }
            updated_at
            user {
                id
                email
                photo
                first_name
                last_name
                auth_id
            }
        }
    }
`

// // ---------
export default {
    USER_INFO,
    PLAYER_USER_FOLLOW,
    SUB_USER_LEAGUE_PASS,
    SUB_USER_PASS,
    SUB_USERS_FOLLOWS,
    SUB_USER_CLUB_ADMIN,
    SUB_PLAYER,
}
