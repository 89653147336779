import React from 'react'
// type
import { IconProps } from 'types/components/Icon'

// -----------------------------------------------
const PlayIcon: React.FC<IconProps> = ({
    iColor = '#1890FF',
    iSize = { x: 20, y: 21 },
}) => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={iSize.x}
            height={iSize.y}
            viewBox="0 0 386.972 386.972"
            xmlSpace="preserve"
        >
            <path
                d="M25.99,0v386.972l334.991-193.486L25.99,0z M55.99,51.972l245.009,141.514L55.99,335V51.972z"
                fill={iColor}
            />
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
        </svg>
    )
}
export default PlayIcon
