import styled from 'styled-components'
import { themeGet } from '@styled-system/theme-get'

export const ContentWrapper = styled.div`
    border-bottom: 1px solid ${themeGet('colors.gray.300')};
    padding: 30px 0;
`

export const HeadingWrapper = styled.div`
    margin-bottom: 25px;
`
