import React, { useContext } from 'react'
import styled from 'styled-components'
import { Select } from 'antd'
import { AnalyticsAdminContext } from '../Display'

const SelectRange = styled(Select)`
    width: 130px;
    .ant-select-selector {
        background-color: transparent !important;
        border-radius: 15px !important;
        span {
            color: #fff;
        }
    }

    .anticon {
        color: #fff;
    }
`

function RangeSelect() {
    const { range, setRange } = useContext(AnalyticsAdminContext)
    return (
        <SelectRange defaultValue={range} onChange={(v) => setRange(v)}>
            <SelectRange.Option value={24}> Last 24 hours </SelectRange.Option>
            <SelectRange.Option value={7}> Last 7 Days </SelectRange.Option>
            <SelectRange.Option value={30}> Last 30 Days </SelectRange.Option>
            <SelectRange.Option value={90}> Last 90 Days </SelectRange.Option>
        </SelectRange>
    )
}

export default RangeSelect
