// apollo
import { gql } from '@apollo/client'
// ---------------------------------------------------
/** gql
 * TODO: transfer to common query file
 * */
const GET_PLAYERS = gql`
    query PlayersQuery($club_slug: String!) {
        players_details(where: { club: { slug: { _eq: $club_slug } } }) {
            id
            image
            slug
            team {
                name
            }
            user {
                first_name
                last_name
            }
        }
    }
`
/** gql
 * TODO: transfer to common query file
 * */
const SUB_PLAYER = gql`
    subscription MyPlayerQuery($club_slug: String!, $player_slug: String!) {
        players_details(
            where: {
                club: { slug: { _eq: $club_slug } }
                slug: { _eq: $player_slug }
            }
        ) {
            active
            bio
            club_id
            debut_date
            id
            image
            is_professional
            is_upgraded
            prev_club
            slug
            positions
            club {
                name
            }
            teams {
                id
                name
            }
            updated_at
            user {
                id
                photo
                first_name
                last_name
                auth_id
            }
        }
    }
`

const GET_PLAYER_BY_ID = gql`
    query MyPlayerQuery($id: Int!) {
        players_details(where: { id: { _eq: $id } }) {
            active
            bio
            club_id
            debut_date
            email
            first_name
            id
            image
            is_professional
            is_upgraded
            last_name
            mobile
            prev_club
            slug
            positions
            debut_date
            club {
                name
            }
            teams {
                name
            }
        }
    }
`

const UPDATE_PLAER_BY_ID = gql`
    mutation MyMutation(
        $id: Int!
        $data: users_insert_input = {}
        $debut_date: date!
        $positions: jsonb!
        $bio: String!
        $team_id: Int!
        $prev_club: String!
    ) {
        insert_players_details(
            on_conflict: {
                where: { id: { _eq: $id } }
                constraint: players_details_pkey
            }
            objects: {
                user: { data: $data, on_conflict: { constraint: users_pkey } }
                debut_date: $debut_date
                positions: $positions
                bio: $bio
                team_id: $team_id
                prev_club: $prev_club
            }
        ) {
            affected_rows
        }
    }
`

export const UPDATE_USER_PLAYERS = gql`
    mutation UpdatePlayerUser(
        $pid: Int!
        $po_object: players_details_set_input!
        $uid: Int!
        $user_object: users_set_input!
    ) {
        update_players_details(where: { id: { _eq: $pid } }, _set: $po_object) {
            affected_rows
        }
        update_users(where: { id: { _eq: $uid } }, _set: $user_object) {
            affected_rows
        }
    }
`

const GET_PLAYER_FEATURED_CLIPS = gql`
    {
        player_featured_clips {
            video_asset_id
            created_at
        }
    }
`

const GET_PLAYER = gql`
    query GetPlayerInfo($where: players_details_bool_exp = {}) {
        players_details(where: $where) {
            active
            bio
            club_id
            debut_date
            id
            user_id
            image
            name
            last_name
            is_professional
            is_upgraded
            prev_club
            slug
            positions
            club {
                id
                name
            }
            teams {
                id
                name
            }
            updated_at
            user {
                id
                photo
                first_name
                last_name
            }
        }
    }
`

const UPSERT_PLAYERS = gql`
    mutation UPSERT_PLAYERS(
        $objects: [players_details_insert_input!]!
        $on_conflict: players_details_on_conflict
        $delete_where: players_details_bool_exp!
    ) {
        delete_players_details(where: $delete_where) {
            returning {
                id
            }
        }

        insert_players_details(objects: $objects, on_conflict: $on_conflict) {
            returning {
                id
            }
        }
    }
`

/**
 * club_id
 * team_id
 * email_address
 * name
 * last_name
 * is_accepted = false
 * is_declined = false
 */
const INSERT_PLAYERS = gql`
    mutation INSERT_PLAYERS($objects: [players_details_insert_input!]!) {
        insert_players_details(objects: $objects) {
            returning {
                id
            }
        }
    }
`

const UPDATE_INVITED_PLAYER_DETAILS = gql`
    mutation UPDATE_INVITED_PLAYER_DETAILS(
        $set: players_details_set_input
        $where: players_details_bool_exp!
    ) {
        update_players_details(where: $where, _set: $set) {
            returning {
                id
            }
        }
    }
`

// ---------
export default {
    GET_PLAYER,
    GET_PLAYERS,
    GET_PLAYER_BY_ID,
    SUB_PLAYER,
    UPDATE_PLAER_BY_ID,
    UPDATE_USER_PLAYERS,
    GET_PLAYER_FEATURED_CLIPS,
    UPSERT_PLAYERS,
    UPDATE_INVITED_PLAYER_DETAILS,
    INSERT_PLAYERS,
}
