import dynamic from 'next/dynamic'
import { ComponentType, useState } from 'react'
import {
    AccordionPorps,
    HeaderWrapperProps,
} from 'types/components/CollapseBox'

const Collapsible = dynamic(() => import('react-collapsible'), { ssr: false })

const useCollapse = (
    CollapseHeaderWrapper: ComponentType<HeaderWrapperProps>,
) => {
    const [hide, setHide] = useState(false)
    // eslint-disable-next-line react/display-name
    return ({ title, children }: AccordionPorps) => {
        const handleOpen = () => {
            setHide(!hide)
        }
        return (
            <div>
                <CollapseHeaderWrapper
                    handleOpen={handleOpen}
                    hide={hide}
                    title={title}
                />
                <Collapsible trigger="" open={hide} transitionTime={300}>
                    {children}
                </Collapsible>
            </div>
        )
    }
}

export default useCollapse
