import { Data } from 'react-csv/lib/core'
import { connect } from 'react-redux'
import { StyledButton, StyledCSVLink } from './csvButton.style'

const CSVButton = (props: {
    userDataForCSV: string | Data | (() => string | Data)
}) => {
    return (
        <>
            <StyledCSVLink
                data={props.userDataForCSV}
                filename={'Streamer_users_status'}
            >
                <StyledButton>Export to CSV</StyledButton>
            </StyledCSVLink>
        </>
    )
}
const mapStateToProps = (state: any) => ({
    userDataForCSV: state?.user?.user_data_for_csv_export,
})
const mapDispatchToProps = {}

// @ts-ignore
export default connect(mapStateToProps, mapDispatchToProps)(CSVButton)
