import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { Select, Input, Typography, Modal } from 'antd'
const { Text } = Typography
import { defaultTheme } from 'theme'

export const ModalWrapper = styled(Modal)`
    .ant-modal-content {
        background: #1b1b25;
        padding: 0px 10px;

        ul > li {
            color: white;
            list-style: disc;
        }

        form,
        .image-area {
            width: 100%;
            @media screen and (min-width: ${defaultTheme.mediaSize.sm}px) {
                width: 50%;
            }
        }

        .ant-modal-body {
            & > div:first-child {
                display: flex;
                flex-direction: column;
                @media screen and (min-width: ${defaultTheme.mediaSize.sm}px) {
                    flex-direction: row;
                }
            }
        }

        .image-area {
            justify-content: space-between;
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0px 30px;
            min-height: 400px;
            margin-bottom: 30px;

            @media screen and (min-width: ${defaultTheme.mediaSize.sm}px) {
                min-height: 500px;
                margin-bottom: 0px;
            }
        }

        .ant-modal-header {
            background: #1b1b25;
            border: none;
            .ant-modal-title {
                color: white;
                font-size: 1.6em;
                font-weight: 700;
            }
        }
        .ant-modal-footer {
            border: none;
            display: flex;
            padding-bottom: 20px;
            justify-content: flex-end;
        }
    }
`
