import { gql } from '@apollo/client'

export const ADD_ACTIVITY_FEED = gql`
    mutation InsertActivityFeed($objects: [activity_feed_insert_input!]!) {
        insert_activity_feed(objects: $objects) {
            affected_rows
        }
    }
`

export const DELETE_ACTIVITY_FEED = gql`
    mutation DeleteActivityFeed($where: activity_feed_bool_exp = {}) {
        delete_activity_feed(where: $where) {
            affected_rows
        }
    }
`

export default {
    ADD_ACTIVITY_FEED,
    DELETE_ACTIVITY_FEED,
}
