import React, { useContext, useState } from 'react'
//  component
import { Avatar } from 'components/Avatar'
import { Col, Row } from 'components/Layout'
import { Team_A_Modal, Team_U_Modal } from 'components/Modal'
import { Table } from 'components/Table'

//  import react icons
//  styled component
import { DisplayWrapper } from './display.style'
// asset
import DefaultSrc from 'assets/images/player/default-player-image.png'
import { Text } from 'components/Text'

import { useSubscription } from '@apollo/client'
import { ADMINQL } from 'graphql/club'
import _ from 'lodash'
import {
    LeagueContext,
    LeagueListContext,
} from 'pages/league/[league_slug]/admin'
import { isServer } from 'utils/helper'

const Action: React.FC<{ count: number; tid: number; onHandleEdit: any }> = ({
    tid,
    count,
    onHandleEdit,
}) => {
    return (
        <>
            <Row justifyContent="center" alignItems="center" gap={20}>
                <Col>
                    <Text mode="p" fSize={1}>
                        {count}
                    </Text>
                </Col>
                <Col>
                    <div onClick={(e: any) => onHandleEdit(tid)}>
                        <Text
                            fSize={1}
                            bColor="primary"
                            bSize="small"
                            tDecorations="underline"
                            fColor="gray.200"
                            hoverStyle={{ fColor: 'white' }}
                        >
                            {'Edit'}
                        </Text>
                    </div>
                </Col>
            </Row>
        </>
    )
}

const DisplaySection: React.FC = () => {
    const league = useContext(LeagueContext)
    const leaguesList = useContext(LeagueListContext)
    const [clubSlug, setClubSlug] = useState('')
    const [club, setClub] = useState<Partial<Array<any>>>([])
    const [a_show, setAShow] = useState<boolean>(false)
    const [u_show, setUShow] = useState<boolean>(false)
    const [tid, setTid] = useState<any>(null)

    useSubscription(ADMINQL.SUB_CLUB, {
        skip: isServer || !clubSlug,
        variables: {
            club_slug: clubSlug,
        },
        onData: ({ data: { data, loading } }) => {
            !loading && data && setClub(data.clubs[0])
        },
    })

    const _handleClose = () => {
        setUShow(false)
        setTid(null)
    }
    const onHandleEdit = (team: {
        club: { slug: React.SetStateAction<string> }
        id: any
    }) => {
        setClubSlug(team?.club?.slug)
        setTid(team?.id)
        setUShow(true)
    }

    const datasource = () => {
        if (_.isUndefined(league?.teams)) {
            return [
                {
                    No: '',
                    Photo: '',
                    Name: '',
                    Club: '',
                    Sport: '',
                    '# of Players': '',
                },
            ]
        }

        return (
            league &&
            league.teams.map((team: any, index: number) => ({
                No: index + 1,
                Photo: (
                    <Avatar
                        src={_.isNull(team.image) ? DefaultSrc : team.image}
                        position="center"
                        radius="small"
                        mode="small"
                    />
                ),
                Name: team.name,
                Club: team.club.name,
                Sport: team?.sport?.name,
                '# of Players': (
                    <Action
                        tid={team}
                        count={team.players.length}
                        onHandleEdit={onHandleEdit}
                    />
                ),
            }))
        )
    }

    return (
        <DisplayWrapper>
            <Row flexDirection="column" gap={20}>
                {/* <Col item={24}>
          <Row justifyContent="flex-end">
            <Button
              bColor="primary"
              bSize="small"
              icon={<BsPlus />}
              onClick={() => setAShow(true)}
            >
              {"Add Team"}
            </Button>
          </Row>
        </Col> */}
                <Col item={24}>
                    <Table data={datasource()} />
                </Col>
            </Row>
            <Team_A_Modal show={a_show} handleClose={() => setAShow(false)} />
            {!_.isUndefined(tid) && leaguesList ? (
                <Team_U_Modal
                    show={u_show}
                    handleClose={_handleClose}
                    mid={tid}
                    leagueClubInfo={club}
                    leagueInfo={leaguesList}
                />
            ) : null}
        </DisplayWrapper>
    )
}

export default DisplaySection
