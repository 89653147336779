import React from 'react'
// type
import { IconProps } from 'types/components/Icon'

const UploadVideoIcon: React.FC<IconProps> = ({
    iColor = '#818181',
    iSize = { x: 38, y: 36 },
}) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            width={iSize.x}
            height={iSize.y}
            x="0"
            y="0"
            viewBox="0 0 512 512"
        >
            <g>
                <g xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="m424.754 277.809c0-61.056 0-130.865 0-191.895 0-25.201-20.502-45.703-45.704-45.703h-333.346c-25.196 0-45.704 20.497-45.704 45.703v252.926c0 25.201 20.502 45.703 45.704 45.703h272.316c5.022 48.942 46.489 87.246 96.735 87.246 53.622 0 97.246-43.624 97.246-97.246-.001-50.245-38.305-91.712-87.247-96.734zm-404.754 61.031v-205.098h31c5.523 0 10-4.478 10-10s-4.477-10-10-10h-31v-27.827c0-12.844 9.47-23.519 21.794-25.407 3.673-.563-17.466-.19 337.256-.297 14.173 0 25.704 11.531 25.704 25.704v27.827h-273.754c-5.523 0-10 4.478-10 10s4.477 10 10 10h273.754v144.065c-45.793 4.647-82.066 41.319-86.736 86.735h-272.314c-14.173.001-25.704-11.529-25.704-25.702zm394.754 112.949c-42.594 0-77.246-34.652-77.246-77.246 0-42.593 34.652-77.245 77.246-77.245 42.593 0 77.246 34.652 77.246 77.245 0 42.594-34.652 77.246-77.246 77.246z"
                        fill={iColor}
                        data-original="#000000"
                    />
                    <path
                        d="m442.943 368.161h-18.943v-18.943c0-5.522-4.477-10-10-10s-10 4.478-10 10v18.943h-18.943c-5.523 0-10 4.478-10 10s4.477 10 10 10h18.943v18.943c0 5.522 4.477 10 10 10s10-4.478 10-10v-18.943h18.943c5.523 0 10-4.478 10-10s-4.477-10-10-10z"
                        fill={iColor}
                        data-original="#000000"
                    />
                    <path
                        d="m91.025 113.742h-.025c-5.523 0-9.988 4.478-9.988 10s4.49 10 10.013 10 10-4.478 10-10-4.477-10-10-10z"
                        fill={iColor}
                        data-original="#000000"
                    />
                    <path
                        d="m337 96.977h31c5.523 0 10-4.478 10-10s-4.477-10-10-10h-31c-5.523 0-10 4.478-10 10s4.477 10 10 10z"
                        fill={iColor}
                        data-original="#000000"
                    />
                    <path
                        d="m308.276 305.505h-18.11c-3.889-13.177-16.091-22.823-30.51-22.823s-26.622 9.647-30.51 22.823h-120.256c-5.523 0-10 4.478-10 10s4.477 10 10 10h120.922c4.49 12.13 16.173 20.8 29.844 20.8s25.355-8.67 29.844-20.8h18.776c5.523 0 10-4.478 10-10s-4.477-10-10-10zm-48.62 20.8c-6.513 0-11.812-5.299-11.812-11.812s5.299-11.812 11.812-11.812 11.812 5.299 11.812 11.812-5.3 11.812-11.812 11.812z"
                        fill={iColor}
                        data-original="#000000"
                    />
                    <path
                        d="m49.395 287.401c-5.523 0-10 4.478-10 10v34.184c0 5.522 4.477 10 10 10s10-4.478 10-10v-34.184c0-5.522-4.477-10-10-10z"
                        fill={iColor}
                        data-original="#000000"
                    />
                    <path
                        d="m79.142 287.401c-5.523 0-10 4.478-10 10v34.184c0 5.522 4.477 10 10 10s10-4.478 10-10v-34.184c0-5.522-4.477-10-10-10z"
                        fill={iColor}
                        data-original="#000000"
                    />
                    <path
                        d="m206.273 280.453 57.922-37.596v.001c16.832-10.925 16.809-35.56 0-46.469l-57.921-37.596c-18.407-11.947-42.781 1.293-42.781 23.233v75.192c0 22.038 24.495 35.106 42.78 23.235zm-22.78-98.426c0-6.001 6.649-9.858 11.892-6.458l57.922 37.597c4.678 3.035 4.675 9.882 0 12.915v.001l-57.922 37.596c-5.123 3.328-11.891-.341-11.891-6.458v-75.193z"
                        fill={iColor}
                        data-original="#000000"
                    />
                </g>
            </g>
        </svg>
    )
}

export default UploadVideoIcon
