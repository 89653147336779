import { gql } from '@apollo/client'
const UPDATE_THUMBNAIL_IMAGE_URL = gql`
    mutation updateThumbnailUrl($thumbnail_url: String!, $id: Int!) {
        update_matches(
            where: { id: { _eq: $id } }
            _set: { thumbnail_url: $thumbnail_url }
        ) {
            affected_rows
        }
    }
`
const UPDATE_THUMBNAIL_IMAGE = gql`
    mutation updateImage(
        $where: event_streams_bool_exp!
        $_set: event_streams_set_input
    ) {
        update_event_streams(where: $where, _set: $_set) {
            affected_rows
        }
    }
`
export default { UPDATE_THUMBNAIL_IMAGE_URL, UPDATE_THUMBNAIL_IMAGE }
