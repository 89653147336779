import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { ParamTypes } from 'types/common/common'
import { isEmpty } from 'utils/helper-validation'

const Router = () => {
    const router = useRouter()
    const [path, setPath] = useState('')
    const [param, setParam] = useState<Partial<ParamTypes>>({})
    const { asPath, query } = router

    useEffect(() => {
        let isMounted = true
        isMounted && setParam(router.query)
        isMounted && setPath(router.pathname)
        return () => {
            isMounted = false
        }
    }, [router])

    const move = (path = '/', param: Record<string, any> = {}, mode = {}) => {
        let paramString = ''
        if (!isEmpty(param)) {
            Object.keys(param).forEach((item, index) => {
                if (index == 0) paramString += `?${item}=${param[item]}`
                else {
                    paramString += `&${item}=${param[item]}`
                }
            })
        }
        router.push(`${path}${paramString}`, undefined, { ...mode })
    }

    return { router, path, param, move, asPath, query }
}
export default Router
