import { Field } from 'formik' //TODO: Refactor please use antd form
import { useState } from 'react'
import slugify from 'slugify'
import { DateTimeSelect } from './DateTimeInput.style'

const DateTimeInput = ({ onChange, name, ...rest }: any) => {
    const [state, setstate] = useState<any>(new Date())

    const onChangeDate = (date: any) => {
        setstate(date)
        onChange(date)
    }

    return (
        <Field name={name} id={slugify(name)}>
            {({ field: { value }, form: { setFieldValue } }: any) => (
                <DateTimeSelect
                    {...rest}
                    showTimeSelect
                    selected={state}
                    value={value}
                    onChange={(dt) => {
                        onChangeDate(dt)
                        setFieldValue(name, dt)
                    }}
                    dateFormat="MMM d, yyyy h:mm aa"
                />
            )}
        </Field>
    )
}

export default DateTimeInput
