import React from 'react'
import styled from 'styled-components'

const LoaderBackdrop = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
`

const Loader = styled.div`
    display: inline-block;
    border: 8px solid red;
    border-top: 8px solid transparent;
    border-radius: 50%;
    width: 128px;
    height: 128px;
    animation: spin 1s linear infinite;

    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

const PageLoader = () => (
    <LoaderBackdrop>
        <Loader />
    </LoaderBackdrop>
)

export default PageLoader
