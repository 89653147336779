import { useLazyQuery } from '@apollo/client'
import { Text } from 'components/Text'
import { query } from 'graphql/user'
import { ClubAdminContext } from 'pages/club/[club_slug]/admin'
import { useContext, useEffect, useState } from 'react'
import { Followers } from './followers.style'

const NumberOfFollowers = () => {
    const club = useContext(ClubAdminContext)
    const [clubFollowers, setClubFollowers] = useState(0)

    const [getFollowers] = useLazyQuery(query.GET_CLUB_FOLLOWERS, {
        variables: {
            where: { id: { _eq: club?.id } },
        },
        onCompleted(data) {
            data &&
                setClubFollowers(
                    data.clubs[0]?.user_club_follows_aggregate?.aggregate.count,
                )
        },
    })

    useEffect(() => {
        getFollowers()
    }, [])

    return (
        <Followers>
            <Text fSize={0.9} fWeight={600} fColor="white">
                Number of Followers
            </Text>
            <Text fSize={1.5} fWeight={600} fColor="white">
                {clubFollowers}
            </Text>
        </Followers>
    )
}

export default NumberOfFollowers
