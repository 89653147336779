import React, { useContext } from 'react'
import { ProfileImageContext } from '..'
import { Button } from 'components/Button'

export const ImageBroswer: React.FC<any> = (props) => {
    const {
        setBannerImage,
        bannerFileInputRef,
        setBannerFiles,
        setBannerFlag,
    } = useContext(ProfileImageContext)

    const onFileInputChange = (event: any) => {
        setBannerImage(event)
        const newFiles = event.target.files
        setBannerFiles(newFiles)
        setBannerFlag(true)
    }
    const onTargetClick = () => {
        if (bannerFileInputRef && bannerFileInputRef.current) {
            bannerFileInputRef?.current.click()
        }
    }

    return (
        <div {...props}>
            <input
                onChange={onFileInputChange}
                onClick={(event: any) => {
                    event.target.value = null
                }}
                ref={bannerFileInputRef}
                type="file"
                style={{ display: 'none' }}
                accept="image/png, image/jpeg"
            />

            <Button onClick={onTargetClick}>Upload</Button>
        </div>
    )
}
