import { Button } from 'components/Button'
import styled from 'styled-components'

export const PrivacyWrapper = styled.div`
    padding: 30px 0;
`

export const ApplyButton = styled(Button)`
    padding: 20px 20px;
    font-size: 14px;
`
