import Image from 'next/legacy/image'
import React, { useEffect, useState } from 'react'
import { ImageProps } from 'types/components/Image'
import { ImageDiv, ImageInner, ImageWrapper } from './Image.style'

// ----------------------------------------------------
const ImageComponent: React.FC<ImageProps> = ({
    src,
    alt = 'No Image, Please reload.',
    width,
    height,
    mode = 'intrinsic',
    oFit = 'fill',
    borderRadius,
    placeholder,
    retryTime,
    quality = 75,
    ...props
}) => {
    const [currSrc, setCurrSrc] = useState(src)
    const [firstTry, setFirstTry] = useState(true)

    useEffect(() => {
        setFirstTry(true)
        if (src) {
            setCurrSrc(src)
        }
    }, [src])

    function handleError(error: any) {
        if (placeholder) {
            setCurrSrc(placeholder)
        }

        if (retryTime && typeof retryTime === 'number' && firstTry) {
            setTimeout(() => {
                setCurrSrc(src)
                setFirstTry(false)
            }, retryTime)
        }
    }

    if (!src && !placeholder) {
        return null
    }

    return mode === 'fill' ? (
        <ImageWrapper {...props}>
            <ImageInner>
                <Image
                    src={currSrc}
                    layout={mode}
                    alt={alt}
                    priority={true}
                    objectFit={oFit}
                    quality={quality}
                    onError={handleError}
                    {...props}
                />
            </ImageInner>
        </ImageWrapper>
    ) : (
        <ImageDiv borderRadius={borderRadius}>
            <Image
                src={currSrc}
                alt={alt}
                width={width}
                height={height}
                layout={mode}
                objectFit={oFit}
                quality={quality}
                onError={handleError}
                {...props}
            />
        </ImageDiv>
    )
}
export default ImageComponent
