import { AnalyticsAttributeType, PlaysType } from 'utils/bitmovin-analytics'
import { get } from './helpers'
const apiBaseUrl = process.env.NEXT_PUBLIC_API_URL

interface ClubAndLeagueStats {
    clubs: number
    games: number
    plays: number
}

export async function getClubAndLeagueStats() {
    try {
        const response = await get<ClubAndLeagueStats>(
            `${apiBaseUrl}/stats/webhook/clubs-and-leagues-stats`,
        )
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function getLeagueMatchAnalyticStats(
    leagueId: string,
    start: string,
    end: string,
    filter: AnalyticsAttributeType,
) {
    const params = new URLSearchParams({ leagueId, start, end, filter })
    const response = await get<{
        totalViews: PlaysType | undefined
        uniqueViews: PlaysType | undefined
    }>(`${apiBaseUrl}/stats/webhook/fetch-league-match-analytics?${params}`)

    return {
        totalViews: response.parsedBody?.totalViews,
        uniqueViews: response.parsedBody?.uniqueViews,
    }
}

export async function getLeagueEventAnalyticStats(
    leagueId: string,
    start: string,
    end: string,
    filter: AnalyticsAttributeType,
) {
    const params = new URLSearchParams({ leagueId, start, end, filter })

    const response = await get<{
        totalViews: PlaysType | undefined
        uniqueViews: PlaysType | undefined
    }>(`${apiBaseUrl}/stats/webhook/fetch-league-event-analytics?${params}`)

    return {
        totalViews: response.parsedBody?.totalViews,
        uniqueViews: response.parsedBody?.uniqueViews,
    }
}

export async function getClubMatchAnalyticStats(
    clubId: string,
    start: string,
    end: string,
    filter: AnalyticsAttributeType,
) {
    const params = new URLSearchParams({ clubId, start, end, filter })

    const response = await get<{
        totalViews: PlaysType | undefined
        uniqueViews: PlaysType | undefined
    }>(`${apiBaseUrl}/stats/webhook/fetch-club-match-analytics?${params}`)

    return {
        totalViews: response.parsedBody?.totalViews,
        uniqueViews: response.parsedBody?.uniqueViews,
    }
}

export async function getClubEventAnalyticStats(
    clubId: string,
    start: string,
    end: string,
    filter: AnalyticsAttributeType,
) {
    const params = new URLSearchParams({ clubId, start, end, filter })
    const response = await get<{
        totalViews: PlaysType | undefined
        uniqueViews: PlaysType | undefined
    }>(`${apiBaseUrl}/stats/webhook/fetch-club-event-analytics?${params}`)

    return {
        totalViews: response.parsedBody?.totalViews,
        uniqueViews: response.parsedBody?.uniqueViews,
    }
}
