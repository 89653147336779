// styled system
import styled from 'styled-components'

// ------------------------------------------------------------

export const FooterWrapper = styled.footer`
    margin-top: 50px;

    @media (max-width: 768px) {
        padding-bottom: 50px;
    }
`

export const MobileStickyFooterWrapper = styled.div`
    position: fixed;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: 200;
    background-color: #1a1818;
    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.5);
    padding: 4px;
`
