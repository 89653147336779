import { gql } from '@apollo/client'

export const GET_ALL_PLAYERS = gql`
    query AllPlayers($where: players_details_bool_exp = {}) {
        players_details(where: $where) {
            id
            slug
            club {
                id
                slug
            }
        }
    }
`

export const GET_ACTIVITY_FEED = gql`
    query GetActivityFeed($where: activity_feed_bool_exp = {}) {
        activity_feed(where: $where, limit: 8, order_by: { created_at: desc }) {
            id
        }
    }
`

const GET_GALLERY_TAG = gql`
    query GetMediGallery($where: media_gallery_players_bool_exp = {}) {
        media_gallery_players(where: $where) {
            id
            updated_at
            media_gallery {
                id
                playback_id
                url
            }
        }
    }
`

const GET_GALLERY_UPLOAD = gql`
    query GetMediaFiles($where: media_gallery_bool_exp = {}) {
        media_gallery(where: $where) {
            id
            url
            playback_id
            updated_at
        }
    }
`

const GET_CLIP_ACTIVITY = gql`
    query ClipAssets($where: clip_assets_bool_exp = {}) {
        clip_assets(where: $where) {
            id
            updated_at
            match_id
            playback_id
        }
    }
`

const GET_SCORE_EVENT = gql`
    query ScoreEvents($where: score_bool_exp = {}) {
        score(where: $where) {
            id
            updated_at
            match_id
            video_time
        }
    }
`

const GET_MATCHES_EVENT = gql`
    query GetMatches($where: matches_bool_exp = {}) {
        matches(where: $where) {
            id
            updated_at
            stream_info {
                id
                status
            }
        }
    }
`

export default {
    GET_ALL_PLAYERS,
    GET_ACTIVITY_FEED,
    GET_GALLERY_TAG,
    GET_GALLERY_UPLOAD,
    GET_CLIP_ACTIVITY,
    GET_SCORE_EVENT,
    GET_MATCHES_EVENT,
}
