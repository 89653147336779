import { gql } from '@apollo/client'

const GET_SPORTS = gql`
    query MySportsQuery($where: sports_bool_exp = {}) {
        sports(where: $where, order_by: { name: asc }) {
            id
            name
            icon
            logo
            description
        }
    }
`

// ---------
export default {
    GET_SPORTS,
}
