import { useCallback, useEffect, useRef, useState } from 'react'
import { useMutation } from '@apollo/client'

// gql
import { mutate } from 'graphql/player'

type ExecuteOptionsType = {
    player_id: number
    type: string // table name
    ref_id?: number // table id
    event_id?: string
}

const useInsertActivityFeed = () => {
    const [insertFeed] = useMutation(mutate.ADD_ACTIVITY_FEED)

    const execute = useCallback(function (
        executeOptions: Array<ExecuteOptionsType>,
    ) {
        return insertFeed({ variables: { objects: executeOptions } })
            .then(function (response) {
                return response
            })
            .catch(function (error) {
                throw error
            })
    },
    [])

    return execute
}

export default useInsertActivityFeed
