import { gql } from '@apollo/client'

const GET_ALL_LEAGUES = gql`
    query GetAllLeagues {
        leagues(order_by: { order: asc }) {
            id
            logo
            name
            slug
        }
    }
`

const GET_SPORTS = gql`
    query MySportsQuery($where: sports_bool_exp = {}) {
        sports(where: $where, order_by: { name: asc }) {
            id
            name
            icon
            logo
            description
        }
    }
`

const GET_CLUB_FILTER = gql`
    query GET_CLUB_FILTER($where: clubs_bool_exp = {}) {
        clubs(where: $where, order_by: { name: asc }) {
            pass {
                id
                name
                price
                recur
                price_id
            }
            logo
            id
            display_name
            name
            slug
        }
    }
`

export default {
    GET_ALL_LEAGUES,
    GET_SPORTS,
    GET_CLUB_FILTER,
}
