import React, { useEffect, useState } from 'react'
import { ImFilesEmpty } from 'react-icons/im'
import { toast } from 'react-toastify'

import { InputWrapper, PrefixInput } from './LinkInput.style'

const LinkInput: React.FC<{
    prefix: string
    onCopyLink?: any
    value: string
    onChange?: (e: any) => void
}> = ({ prefix = '', onCopyLink, value = '', onChange }) => {
    const [link, setLink] = useState<string>('')
    useEffect(() => {
        setLink(prefix + '/')
    }, [])

    const onHandleCopy = () => {
        const data: string = link + value
        onCopyLink(data)
        toast.success('Link copied to clipboard.')
    }
    return (
        <InputWrapper>
            <PrefixInput>
                <span>{link}</span>
                <input value={value} onChange={onChange} />
            </PrefixInput>
            <ImFilesEmpty
                color="#ccc"
                style={{
                    cursor: 'pointer',
                    width: 25,
                    height: 25,
                    marginLeft: 5,
                }}
                onClick={onHandleCopy}
            />
        </InputWrapper>
    )
}
export default LinkInput
