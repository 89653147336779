import * as t from 'redux/types/score'

const scoreReducer = (state: any = { scoreObj: {} }, action: any) => {
    switch (action.type) {
        case t.SET_SCORE_OBJ:
            return { ...state, scoreObj: action.payload }
        default:
            return { ...state }
    }
}
export default scoreReducer
