import * as z from 'zod'
import { processDatadogRumAction } from '../datadog/datadog-actions'
import {
    DataLayerEventNames,
    DfpEventNames,
    LeaguePassEventNames,
} from '../gtm/event-types'
import { raiseEvent } from '../gtm/gtm'

const scrollSchema = z.object({
    offset: z.number(),
})

export type ScrollSchema = z.infer<typeof scrollSchema>

export function dfpSearchEvent({
    searchBar,
    sport,
    league,
    page,
    gtm = true,
    datadog = true,
}: {
    searchBar?: string
    sport?: string
    league?: string
    page: string
    gtm?: boolean
    datadog?: boolean
}) {
    const eventName: DataLayerEventNames = 'dfp.search'
    const payload = { searchBar, sport, league }

    if (gtm) {
        raiseEvent({
            type: eventName,
            payload: payload,
            originator: page,
        })
    }

    if (datadog) {
        processDatadogRumAction(eventName, payload)
    }
}

export function dfpEvent({
    type,
    clubId,
    clubName,
    page,
    gtm = true,
    datadog = true,
}: {
    type: DfpEventNames
    clubId: number
    clubName: string
    page: string
    gtm?: boolean
    datadog?: boolean
}) {
    const payload = { clubId, clubName }

    if (gtm) {
        raiseEvent({
            type: type,
            payload: payload,
            originator: page,
        })
    }

    if (datadog) {
        processDatadogRumAction(type, payload)
    }
}

const subscribeLeaguePassSchema = z.object({
    leagueName: z.string().optional(),
    message: z.string().optional(),
})

export type leaguePassSubscribeSchema = z.infer<
    typeof subscribeLeaguePassSchema
>

export function leaguePassSubscribeEvent({
    props,
    gtm = true,
    datadog = true,
}: {
    props: leaguePassSubscribeSchema
    gtm?: boolean
    datadog?: boolean
}) {
    const eventName: LeaguePassEventNames = 'leaguePass.subscribe'

    if (gtm) {
        raiseEvent({
            type: eventName,
            payload: subscribeLeaguePassSchema.parse(props),
        })
    }

    if (datadog) {
        processDatadogRumAction(eventName, { ...props })
    }
}
