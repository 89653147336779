import photo from 'assets/images/home/default-bg-stacked.jpg'
import styled, { createGlobalStyle, css } from 'styled-components'
import { get } from 'styled-system'
import { defaultTheme } from 'theme'

export const GlobalStyle = createGlobalStyle<any>((props) =>
    css({
        '*, *::before, *::after': {
            boxSizing: 'border-box',
            outline: 'none !important',
        },
        '#__next': {
            width: '100%',
            height: props.heightOfParentStickyHeader,
            backgroundColor: get(props.theme, 'colors.black.200'),
        },
        html: {
            scrollBehavior: 'smooth',
            overflowX: 'hidden',
            margin: 0,
            padding: 0,
            backgroundColor: get(props.theme, 'colors.black.200'),
        },
        '.poster': {
            position: 'absolute',
            backgroundSize: 'contain',
            backgroundRepeat: 'no-repeat',
            backgroundColor: get(props.theme, 'colors.black.200'),
            backgroundPosition: 'center',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            width: '100%',
            height: '100%',
            // zIndex: 10,
            backgroundImage: `url(${photo.src})`,
        },
        body: {
            scrollBehavior: 'smooth',
            padding: 0,
            color: get(props.theme, 'colors.white'),
            margin: '0 auto',
            transition: get(props.theme, 'transition.normal'),
            fontFamily: 'HeyWow, Arial, Helvetica, sans-serif !important',
            fontWeight: '400',
            fontSize: '12px',
            lineHeight: '1.5',
            backgroundColor: get(props.theme, 'colors.black.200'),
            WebkitTextSizeAdjust: '100%',
            WebkitFontSmoothing: 'antialiased',
            MozOsxFontSmoothing: 'grayscale',
            textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',
            button: {
                '-moz-user-select': 'none',
                '-khtml-user-select': 'none',
                '-webkit-user-select': 'none',
                '-ms-user-select': 'none',
                'user-select': 'none',
            },
            '::-webkit-scrollbar': {
                width: '3px',
            },
            '::-webkit-scrollbar-track': {
                boxShadow: 'inset 0 0 5px grey',
                borderRadius: '10px',
            },
            ' ::-webkit-scrollbar-thumb': {
                background: get(props.theme, 'colors.primary.regular'),
                borderRadius: '6px',
            },
        },

        h1: {
            fontFamily: 'HeyWow, Arial, Helvetica, sans-serif',
            fontSize: '5xl',
            fontWeight: 'bolder',
            zIndex: 10,
            margin: 0,
        },
        h2: {
            fontFamily: 'HeyWow, Arial, Helvetica, sans-serif',
            fontSize: '4xl',
            fontWeight: 'bold',
            zIndex: 10,
            margin: 0,
        },
        h3: {
            fontFamily: 'HeyWow, Arial, Helvetica, sans-serif',
            fontSize: 'xl',
            fontWeight: 'bold',
            zIndex: 10,
            margin: 0,
        },
        h4: {
            fontFamily: 'HeyWow, Arial, Helvetica, sans-serif',
            fontSize: 'xl',
            fontWeight: 'regular',
            zIndex: 10,
            margin: 0,
        },
        h5: {
            fontFamily: 'HeyWow, Arial, Helvetica, sans-serif',
            fontSize: 'mm',
            fontWeight: 'regular',
            zIndex: 10,
            margin: 0,
        },
        h6: {
            fontFamily: 'HeyWow, Arial, Helvetica, sans-serif',
            fontSize: 'ms',
            fontWeight: 'medium',
            zIndex: 10,
            margin: 0,
        },

        'p,span,button,li,div': {
            fontFamily: 'HeyWow, Arial, Helvetica, sans-serif',
            margin: 0,
        },
        a: {
            fontFamily: 'HeyWow, Arial, Helvetica, sans-serif',
            textDecoration: 'none',
            ':hover': {
                color: defaultTheme.colors.orange.hover,
            },
        },
        ul: {
            margin: 0,
            padding: 0,
            listStyle: 'none',
        },
        li: {
            listStyle: 'none',
        },
        pre: {
            fontFamily: 'HeyWow, Arial, Helvetica, sans-serif',
            overflowX: 'auto',
            code: {
                color: 'inherit',
            },
        },
        code: {
            fontFamily: 'HeyWow, Arial, Helvetica, sans-serif',
            fontSize: 'inherit',
        },
        table: {
            width: '100%',
            borderCollapse: 'separate',
            borderSpacing: 0,
        },
        th: {
            textAlign: 'left',
            borderBottomStyle: 'solid',
        },
        td: {
            textAlign: 'left',
            borderBottomStyle: 'solid',
        },
        button: {
            appearance: 'none',
        },
        img: {
            maxWidth: '100%',
        },

        // custom
        '#nprogress': {
            pointerEvents: 'none',
        },
        '#nprogress .bar': {
            top: '0',
            left: '0',
            height: '2px',
            width: '100%',
            position: 'fixed',
            zIndex: 100,
            background: get(props.theme, 'colors.primary.regular'),
            boxShadow: `0 0 2px ${get(props.theme, 'colors.primary.regular')}`,
        },
        '#nprogress .peg': {
            right: '0',
            opacity: '1',
            width: '100px',
            height: '100%',
            display: 'block',
            position: 'absolute',
            transform: 'rotate(3deg) translate(0px, -4px)',
            boxShadow: `0 0 10px ${get(
                props.theme,
                'colors.primary.regular',
            )}, 0 0 5px ${get(props.theme, 'colors.primary.regular')}`,
        },

        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...props.theme.globals,
    }),
)

export const MediaBody = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 0.6%;
    max-height: 114px;
    height: 114px;
    width: 228px;
    background-color: black;

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        width: 200px;
        height: 100px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        width: 150px;
        height: 75px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.xs}px) {
        width: 100px;
        height: 50px;
    }
`

export const CardBody = styled.li`
    margin-bottom: 1rem !important;
    margin-left: 0.25rem;
    margin-right: 0.25rem;

    @media screen and (min-width: ${defaultTheme.mediaSize.md}px) {
        height: 210px;
        width: 324px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        height: 200px;
        width: 272px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        height: 150px;
        width: 222px;
    }

    a {
        margin: 0;
    }
`

// include Card style

export const CardWrapper = styled.div`
    width: 98%;
    border-radius: 6px;
    contain: content;
    cursor: pointer;
    margin: 0 5px;
`

export const CardContent = styled.div`
    @media screen and (min-width: ${defaultTheme.mediaSize.md}px) {
        height: 212px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        height: 182px;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        height: 147px;
    }
    width: 100%;
    position: relative;
`

export const CardFooter = styled.div`
    @media screen and (min-width: ${defaultTheme.mediaSize.md}px) {
        padding: 3px 6%;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        padding: 2px 6%;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        padding: 0 6%;
    }
    background-color: rgba(29, 29, 29, 0.4);
    position: absolute;
    bottom: 0px;
    width: 100%;

    p {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`

export const LiveWrapper = styled.div`
    background-color: #fa3737;
    width: 39px;
    height: 19px;
    text-align: center;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
`
