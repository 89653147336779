import { gql } from '@apollo/client'

const INSERT_MATCH = gql`
    mutation InsertMatchesMutation($objects: [matches_insert_input!]!) {
        insert_matches(objects: $objects) {
            affected_rows
        }
    }
`

const INSERT_SAVED_MATCHES_ONE = gql`
    mutation INSERT_SAVED_MATCHES_ONE(
        $object: saved_matches_insert_input = {}
    ) {
        insert_saved_matches_one(
            object: $object
            on_conflict: {
                constraint: saved_matches_user_id_match_id_key
                update_columns: []
            }
        ) {
            created_at
        }
    }
`

const DELETE_SAVED_MATCH = gql`
    mutation UnsaveMatch($where: saved_matches_bool_exp = {}) {
        delete_saved_matches(where: $where) {
            affected_rows
            returning {
                id
            }
        }
    }
`

const UPDATE_ARCHIVE_MATCH = gql`
    mutation UPDATE_ARCHIVE_MATCH(
        $where: streams_bool_exp!
        $_set: streams_set_input
    ) {
        update_streams(where: $where, _set: $_set) {
            returning {
                archived
            }
        }
    }
`
// // ---------
export default {
    INSERT_MATCH,
    INSERT_SAVED_MATCHES_ONE,
    DELETE_SAVED_MATCH,
    UPDATE_ARCHIVE_MATCH,
}
