import { useLazyQuery } from '@apollo/client'
import { Text } from 'components/Text'
import { query } from 'graphql/user'
import { ClubAdminContext } from 'pages/club/[club_slug]/admin'
import { useContext, useEffect, useState } from 'react'
import { DigitalFans } from './digitalFans.style'

const NumberOfDigitalFans = () => {
    const club = useContext(ClubAdminContext)
    const [clubFans, setClubFans] = useState(0)

    const [getDigitalFans] = useLazyQuery(query.GET_CLUB_DIGITAL_FANS, {
        variables: {
            where: {
                user_passes: {
                    pass_id: { _eq: club?.pass?.id },
                    status: { _eq: 'active' },
                    pass: { pass_type: { name: { _eq: 'digital-fan' } } },
                },
            },
        },
        onCompleted(data) {
            data && setClubFans(data?.users_aggregate?.aggregate?.count)
        },
    })

    useEffect(() => {
        club?.pass?.id && getDigitalFans()
    }, [])

    return (
        <DigitalFans>
            <Text fSize={0.9} fWeight={600} fColor="white">
                Number of Digital Fans
            </Text>
            <Text fSize={1.5} fWeight={600} fColor="white">
                {clubFans}
            </Text>
        </DigitalFans>
    )
}

export default NumberOfDigitalFans
