import { useLazyQuery } from '@apollo/client'
import { ADMINQL } from 'graphql/club'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { MULTI_SPORT } from 'utils/constData'
import { CustomSelect } from './dropdown.style'

const { Option } = CustomSelect

function ClubFuzzySearch({ sport_name, ...rest }: any) {
    const [data, setData] = useState<Partial<Array<any>>>([])
    const router = useRouter()
    const { league_slug } = router.query

    const condition =
        sport_name === MULTI_SPORT
            ? {
                  where: {
                      status: {
                          _eq: 'approved',
                      },
                  },
              }
            : {
                  where: {
                      status: {
                          _eq: 'approved',
                      },
                      teams: {
                          sport: {
                              name: { _eq: sport_name },
                          },
                      },
                  },
              }

    const [pullData] = useLazyQuery(ADMINQL.GET_ALL_CLUBS, {
        onCompleted(data) {
            data.clubs && setData(data.clubs)
        },
    })

    useEffect(() => {
        !_.isUndefined(league_slug)
            ? pullData({
                  variables: {
                      where: {
                          teams: { league: { slug: { _eq: league_slug } } },
                      },
                  },
              })
            : pullData({ variables: { ...condition } })
    }, [sport_name])

    return (
        <CustomSelect
            {...rest}
            showSearch
            placeholder={
                !_.isUndefined(league_slug)
                    ? 'Select Club'
                    : 'Select Opposition Club'
            }
            filterOption={(inputValue, option) =>
                option!.children
                    .toLowerCase()
                    .includes(inputValue.toLowerCase())
            }
        >
            {data.map((club, i) => (
                <Option value={club.id} key={`dd-club-fuzzy-${i}`}>
                    {club.name}
                </Option>
            ))}
        </CustomSelect>
    )
}

export default ClubFuzzySearch
