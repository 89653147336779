import { useMutation } from '@apollo/client'
import { Avatar } from 'components/Avatar'
import { Button } from 'components/Button'
import { Text } from 'components/Text'
import { ADMINQL } from 'graphql/club'
import React, { useContext, useRef, useState } from 'react'
import { CustomSelect, CustomeInput } from '../index.style'

import { ImageCrop_Modal } from 'components/Modal'
import {
    ClubAdminContext,
    ClubLeagueContext,
} from 'pages/club/[club_slug]/admin'
import { BsSave } from 'react-icons/bs'
import { ImCancelCircle } from 'react-icons/im'
import { ModalProps } from 'types/components/Modal'
import { readImageFile } from 'utils/common-helper'
import { ModalWrapper } from '../../common.style'

import { Form } from 'antd'
import photo from 'assets/images/player/default-player-image.png'
import _ from 'lodash'
import { toast } from 'react-toastify'
import str from 'string-sanitizer'
import { s3UploadFile } from 'utils/s3-helper'
import SportsDropDown from '../components/SportsDropDown'
const { Option } = CustomSelect

const Team_A_Modal: React.FC<ModalProps> = ({ show = false, handleClose }) => {
    const league = useContext(ClubLeagueContext)
    const club = useContext(ClubAdminContext)

    const leagueData = league
        ? league.map((item: any) => ({ label: item.name, value: item.id }))
        : []

    const [form] = Form.useForm()
    const [meta, setMeta] = useState<any>(null)
    const [flag, setFlag] = useState<boolean>(false)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const [croppedImage, setCroppedImage] = useState<any>(photo)
    const [isSubmit, setSubmiting] = useState<boolean>(false)
    const [file, setFile] = useState<any>(null)
    const formRef = useRef(form)

    const [add] = useMutation(ADMINQL.ADD_TEAM, {
        onCompleted() {
            setFile(null)
            form.resetFields()
            setCroppedImage(photo)
            toast.success('New Team Added.')
            handleClose && handleClose()
        },
        onError(e) {
            toast.error('Error Happened.')
            setSubmiting(false)
        },
    })

    const onTargetClick = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef?.current.click()
        }
    }

    const onFileInputChange = async (e: any) => {
        const imageDataUrl = await readImageFile(e.target.files[0])

        setMeta(e)
        setFlag(true)
        setCroppedImage(imageDataUrl)
    }

    const onFinish = async (values: any) => {
        setSubmiting(true)

        const { league_id, name, players, sports_id } = values
        const slug = str.addDash(name).toLocaleLowerCase()

        let image: string | null = null

        if (!_.isNull(file)) {
            const s3res: any = await s3UploadFile('Teams', slug, file)
            image = s3res.location
        }

        const playersToAdd = players?.map((id: any) => {
            return {
                id,
            }
        })

        const playersObject = playersToAdd
            ? {
                  players: {
                      data: playersToAdd,
                      on_conflict: {
                          constraint: 'players_details_pkey',
                          update_columns: ['team_id'],
                      },
                  },
              }
            : {}

        await add({
            variables: {
                objects: {
                    club_id: club.id,
                    image,
                    league_id,
                    name,
                    slug,
                    ...playersObject,
                    sports_id,
                },
            },
        })

        setSubmiting(false)
    }

    const _handleClose = () => {
        setFile(null)
        form.resetFields()
        setCroppedImage(photo)
        handleClose && handleClose()
    }

    const saveImage = async (file: File, imageSrc: any) => {
        setFile(file)
        setCroppedImage(imageSrc)
    }

    return (
        <ModalWrapper
            open={show}
            closable={false}
            title="Add Team"
            width={600}
            footer={[
                <Button
                    key={'btn-cncl'}
                    bSize="small"
                    type="button"
                    bColor="primary"
                    disabled={isSubmit}
                    onClick={_handleClose}
                    icon={<ImCancelCircle />}
                >
                    {'Cancel'}
                </Button>,
                <Button
                    key={'btn-sav'}
                    bColor="primary"
                    bSize="small"
                    type="submit"
                    disabled={isSubmit}
                    icon={<BsSave />}
                    loading={isSubmit}
                    onClick={() => {
                        formRef.current && formRef.current.submit()
                    }}
                >
                    {'Save'}
                </Button>,
            ]}
        >
            <div>
                <Form
                    layout="vertical"
                    form={form}
                    onFinish={onFinish}
                    ref={formRef}
                >
                    <Form.Item
                        name="name"
                        rules={[
                            {
                                required: true,
                                message: 'Team Name is required.',
                            },
                        ]}
                        label={
                            <label style={{ color: 'white' }}>
                                {'Team Name'}
                            </label>
                        }
                    >
                        <CustomeInput placeholder="Team Name" />
                    </Form.Item>

                    <Form.Item
                        name="league_id"
                        rules={[
                            { required: true, message: 'League is required.' },
                        ]}
                        label={
                            <label style={{ color: 'white' }}>{'League'}</label>
                        }
                    >
                        <CustomSelect
                            placeholder="League"
                            options={leagueData}
                        />
                    </Form.Item>
                    <Form.Item
                        name="sports_id"
                        rules={[
                            { required: true, message: 'Sport is required.' },
                        ]}
                        label={
                            <label style={{ color: 'white' }}>{'Sport'}</label>
                        }
                    >
                        <SportsDropDown />
                    </Form.Item>
                    <Form.Item
                        name="players"
                        label={
                            <label style={{ color: 'white' }}>
                                {'Add Player(s)'}
                            </label>
                        }
                    >
                        <CustomSelect placeholder="Add Players" mode="multiple">
                            {club?.players
                                ? club.players.map((player: any) => (
                                      <Option
                                          value={player.id}
                                          key={`player-id-${player.id}`}
                                      >
                                          {_.isNull(player.user)
                                              ? player.email_address
                                              : `${player.user.first_name}  ${player.user.last_name}`}
                                      </Option>
                                  ))
                                : []}
                        </CustomSelect>
                    </Form.Item>

                    <Form.Item
                        name="admins"
                        label={
                            <label style={{ color: 'white' }}>
                                {'Team Admin(s)'}
                            </label>
                        }
                    >
                        <CustomSelect
                            placeholder="Add Team Admins"
                            mode="multiple"
                        >
                            {club?.team_admin
                                ? club?.team_admin.map((admin: any) => (
                                      <Option
                                          key={
                                              admin.email_address +
                                              '/' +
                                              admin.id
                                          }
                                      >
                                          {_.isNull(admin.user)
                                              ? admin.email_address
                                              : `${admin.user.first_name}  ${admin.user.last_name}`}
                                          {' / ' + admin.id}
                                      </Option>
                                  ))
                                : []}
                        </CustomSelect>
                    </Form.Item>
                </Form>

                <div className="image-area">
                    <Text
                        fSize={0.9}
                        fColor="white"
                        style={{ marginBottom: 8 }}
                    >
                        {'Team Photo'}
                    </Text>
                    <Avatar src={croppedImage} mode="big" radius="small" />
                    <Button
                        bColor="primary"
                        bSize="small"
                        type="button"
                        disabled={isSubmit}
                        onClick={onTargetClick}
                    >
                        {'Upload Photo'}
                    </Button>
                    <input
                        onChange={onFileInputChange}
                        onClick={(event: any) => {
                            event.target.value = null
                        }}
                        ref={fileInputRef}
                        type="file"
                        style={{ display: 'none' }}
                        accept="image/png, image/jpeg"
                    />

                    <div>
                        <Text fSize={1} fColor="white" fWeight={700}>
                            {'Photo Guidelines:'}
                        </Text>
                        <ul>
                            <li>
                                {' '}
                                <Text fSize={0.9}>
                                    {'Accepted file formats:JPG, PNG, SVG'}
                                </Text>{' '}
                            </li>
                            <li>
                                {' '}
                                <Text fSize={0.9}>
                                    {'Maximum file size: 25MB'}
                                </Text>{' '}
                            </li>
                            <li>
                                {' '}
                                <Text fSize={0.9}>
                                    {'Minimum dimensions: 300 x 300px'}
                                </Text>{' '}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div style={{ padding: '0px 10px' }}>
                <ul>
                    <li>
                        {' '}
                        <Text fSize={0.9}>
                            {'Team Admin will be notified by email.'}
                        </Text>{' '}
                    </li>
                    <li>
                        {' '}
                        <Text fSize={0.9}>
                            {
                                'If no Streamer account exists for this email, an invite to Sign Up will be sent to this email.'
                            }
                        </Text>{' '}
                    </li>
                    <li>
                        {' '}
                        <Text fSize={0.9}>
                            {
                                'Team Admin will be able to add / modify / remove Players, Matches, Results and Team Details for this team. Club Admin is administrator for this team by default.'
                            }
                        </Text>{' '}
                    </li>
                </ul>
            </div>

            <ImageCrop_Modal
                show={flag}
                meta={meta}
                cropShape="rect"
                saveImage={saveImage}
                handleClose={() => setFlag(false)}
            />
        </ModalWrapper>
    )
}

export default Team_A_Modal
