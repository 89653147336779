import * as t from 'redux/types/user'
export interface UserStateTypes {
    user_passes: [] | null
    user_club_follows: Array<any> | null
    user_team_follows: Array<any> | null
    user_player_follows: Array<any> | null
    user_data_for_csv_export: Array<any> | null
    league_admin_details: Array<any> | null
    club_admin_details: Array<any> | null
}

const userReducer = (
    state: UserStateTypes = {
        user_passes: null,
        user_club_follows: [],
        user_team_follows: [],
        user_player_follows: [],
        user_data_for_csv_export: [],
        league_admin_details: [],
        club_admin_details: [],
    },
    action: any,
) => {
    switch (action.type) {
        case t.SET_USER_INFO:
            return {
                ...state,
                ...action.payload,
            }
        case t.SET_USER_DATA_CSV_EXPORT:
            return {
                ...state,
                user_data_for_csv_export: action.payload,
            }
        default:
            return { ...state }
    }
}

export default userReducer
