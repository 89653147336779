import React from 'react'
// type
import { IconProps } from 'types/components/Icon'

// -----------------------------------------------
const PauseIcon: React.FC<IconProps> = ({
    iColor = '#1890FF',
    iSize = { x: 20, y: 21 },
}) => {
    return (
        <svg
            version="1.1"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={iSize.x}
            height={iSize.y}
            viewBox="0 0 395 395"
            xmlSpace="preserve"
        >
            <g>
                <path
                    d="M73.75,395h102.5V0H73.75V395z M103.75,30h42.5v335h-42.5V30z"
                    fill={iColor}
                />
                <path
                    d="M218.75,0v395h102.5V0H218.75z M291.25,365h-42.5V30h42.5V365z"
                    fill={iColor}
                />
            </g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
            <g></g>
        </svg>
    )
}
export default PauseIcon
