import { gql } from '@apollo/client'

const GET_USER = gql`
    query UserQuery($where: users_bool_exp = {}) {
        users(where: $where) {
            id
            active
            auth_id
            detail_id
            email
            first_name
            last_name
            photo
            user_role_id
            stripe_customer_id
            user_league_pass {
                pass {
                    id
                    name
                }
                stripe_subscription_id
                status
            }
            role {
                name
                id
                user_permissions {
                    id
                    name
                    type
                }
            }
            user_club_follows {
                id
                club_id
            }
            user_team_follows {
                id
                team_id
            }
            club_admin_details {
                id
                club_id
                status
            }
            players_details {
                id
                active
                user_id
                slug
                club {
                    id
                    slug
                }
            }
        }
    }
`

const GET_USER_LEAGUE_PASS = gql`
    query UserLeaguePass($where: user_league_pass_bool_exp = {}) {
        user_league_pass(where: $where) {
            id
            league_pass_id
            status
            stripe_subscription_id
            user_id
        }
    }
`

const GET_USER_PASS = gql`
    query UserPasses($where: user_passes_bool_exp = {}) {
        user_passes(where: $where) {
            id
            pass_id
            status
            stripe_subscription_id
            user_id
        }
    }
`

const GET_USER_INFO = gql`
    query UserInfo($where: users_bool_exp = {}) {
        users(where: $where) {
            id
            active
            auth_id
            detail_id
            email
            first_name
            last_name
            photo
            user_role_id
            stripe_customer_id
            user_league_pass {
                pass {
                    id
                    name
                }
                stripe_subscription_id
                status
            }
            role {
                name
                id
                user_permissions {
                    id
                    name
                    type
                }
            }
            user_club_follows {
                club {
                    id
                    logo
                    name
                    slug
                }
            }
            user_team_follows {
                team {
                    id
                    image
                    club {
                        logo
                        id
                        slug
                    }
                    name
                    slug
                }
            }
            club_admin_details {
                id
                club_id
                status
                is_accepted
                club {
                    name
                    slug
                }
            }
            league_admin_details {
                id
                league_id
                status
                is_accepted
                league {
                    id
                    pass_id
                    slug
                    name
                }
            }
            user_passes {
                id
                pass_id
                user_id
                stripe_subscription_id
                status
                pass {
                    pass_type_id
                    active
                    id
                    name
                }
            }
            players_details {
                id
                active
                user_id
                slug
                club {
                    id
                    slug
                }
            }
            user_player_follows {
                id
                players_detail {
                    club {
                        slug
                    }
                    slug
                    team {
                        name
                    }
                    user {
                        first_name
                        last_name
                        id
                        photo
                    }
                }
            }
        }
    }
`

const GET_CLUB_FOLLOWERS = gql`
    query ClubFollowers($where: clubs_bool_exp) {
        clubs(where: $where) {
            user_club_follows_aggregate {
                aggregate {
                    count
                }
            }
        }
    }
`

const GET_CLUB_DIGITAL_FANS = gql`
    query ClubDigitalFans($where: users_bool_exp) {
        users_aggregate(where: $where) {
            aggregate {
                count
            }
        }
    }
`

// ---------
export default {
    GET_USER,
    GET_USER_LEAGUE_PASS,
    GET_USER_PASS,
    GET_USER_INFO,
    GET_CLUB_FOLLOWERS,
    GET_CLUB_DIGITAL_FANS,
}
