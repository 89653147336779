import { gql } from '@apollo/client'

export const SUB_ACTIVITY_FEED = gql`
    subscription SubActivityFeed($where: activity_feed_bool_exp = {}) {
        activity_feed(where: $where, limit: 8, order_by: { updated_at: desc }) {
            id
            ref_id
            event_id
            type
            action
        }
    }
`

export default {
    SUB_ACTIVITY_FEED,
}
