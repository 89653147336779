import { WithContainer } from 'components/Container'
import AdsGPT from 'components/GPT/AdsGPT'
import { siteSettings, useRouter } from 'hooks'
import useMediaQuery from 'hooks/useMediaQuery'
import { CopyRightView } from 'sections/layout/footer'
import { getPageSlugName } from 'utils/common-helper'
import { pathNameWithDynamicAds } from 'utils/constData'
import { FooterWrapper, MobileStickyFooterWrapper } from './Footer.style'

// -----------------------------------------------------

const stickyAd = (path: string) => {
    return (
        <MobileStickyFooterWrapper>
            <AdsGPT
                slot={`mobile-${getPageSlugName(path)}-sticky-banner`}
                mobileSize={[320, 50]}
                isFooter={true}
                pos={3}
            />
        </MobileStickyFooterWrapper>
    )
}

const Footer = () => {
    const isMobile = useMediaQuery(768)
    const { path } = useRouter()

    return (
        <FooterWrapper>
            <WithContainer SectionView={CopyRightView} />
            {isMobile && siteSettings('global.sticky_footer') && (
                <>
                    {!path.endsWith('/admin') &&
                        pathNameWithDynamicAds.includes(path) &&
                        stickyAd(path)}
                </>
            )}
        </FooterWrapper>
    )
}
export default Footer
