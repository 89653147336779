// apollo
import { gql } from '@apollo/client'
// ---------------------------------------------------
/** gql
 * TODO: transfer to common query file
 * */
const GET_TEAMS = gql`
    query TeamsQuery($club_slug: String!) {
        teams(where: { club: { slug: { _eq: $club_slug } } }) {
            id
            image
            name
            slug
            division
            club {
                logo
            }
        }
    }
`

const SUB_LEAGUES = gql`
    subscription MyClubSubscription($where: leagues_bool_exp) {
        leagues(where: $where) {
            id
            logo
            name
        }
    }
`

const SUB_CLUB_FILTER = gql`
    subscription SUB_CLUB_FILTER($where: clubs_bool_exp, $limit: Int) {
        clubs(where: $where, limit: $limit, order_by: { name: asc }) {
            logo
            id
            display_name
            name
            slug
        }
    }
`

export const USERS_FOLLOW_TEAM = gql`
    subscription USERS_FOLLOW_TEAM($where: user_team_follows_bool_exp = {}) {
        user_team_follows(where: $where) {
            user_id
        }
    }
`

const GET_CLUB_FILTER = gql`
    query GET_CLUB_FILTER($where: clubs_bool_exp, $limit: Int) {
        clubs(where: $where, limit: $limit, order_by: { name: asc }) {
            logo
            id
            display_name
            name
            slug
        }
    }
`

const UPSERT_TEAMS = gql`
    mutation UPSERT_TEAMS(
        $objects: [teams_insert_input!]!
        $on_conflict: teams_on_conflict
        $deleteWhere: teams_bool_exp!
    ) {
        delete_teams(where: $deleteWhere) {
            returning {
                id
            }
        }

        insert_teams(objects: $objects, on_conflict: $on_conflict) {
            returning {
                id
            }
        }
    }
`

// ---------
export default {
    GET_TEAMS,
    SUB_LEAGUES,
    SUB_CLUB_FILTER,
    USERS_FOLLOW_TEAM,
    GET_CLUB_FILTER,
    UPSERT_TEAMS,
}
