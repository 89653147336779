import { get } from './helpers/request'
import { ClipAsset, ClipBasics, ClipElement, Clips } from './types/clips'
const baseUrl = process.env.NEXT_PUBLIC_RESTFUL_BASE_URL

export async function getAllClips(limit: number) {
    try {
        const response = await get<Clips>(
            `${baseUrl}/clips/cards/search/%25/sport/%25/limit/${encodeURIComponent(
                limit,
            )}/offset/0`,
        )
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function getUserCreatedClips(
    userId: number,
    limit: number,
    offset: number,
) {
    try {
        const response = await get<{ clip_assets: ClipAsset[] }>(
            `${baseUrl}/clips/user/${encodeURIComponent(
                userId,
            )}/limit/${encodeURIComponent(limit)}/offset/${encodeURIComponent(
                offset,
            )}`,
        )
        const clips: Clips = {
            clip_asset_user_club:
                response.parsedBody?.clip_assets?.map((c) => {
                    return {
                        clip_asset: c,
                    }
                }) ?? null,
        }
        return clips
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function getUserSavedClips(
    userId: number,
    limit: number,
    offset: number,
) {
    try {
        const response = await get<{ saved_clips: ClipElement[] }>(
            `${baseUrl}/clips/saved/user/${encodeURIComponent(
                userId,
            )}/limit/${encodeURIComponent(limit)}/offset/${encodeURIComponent(
                offset,
            )}`,
        )
        const clips: Clips = {
            clip_asset_user_club: response.parsedBody?.saved_clips ?? null,
        }
        return clips
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function getFilteredClips(
    searchInput: string,
    sport: string,
    limit: number,
    offset: number,
) {
    try {
        const response = await get<Clips>(
            `${baseUrl}/clips/cards/search/%25${encodeURIComponent(
                searchInput,
            )}%25/sport/%25${encodeURIComponent(
                sport,
            )}%25/limit/${encodeURIComponent(
                limit,
            )}/offset/${encodeURIComponent(offset)}`,
        )
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function getLeagueClips(
    limit: number,
    offset: number,
    leagueId: number,
) {
    try {
        const response = await get<Clips>(
            `${baseUrl}/clips/limit/${encodeURIComponent(
                limit,
            )}/offset/${encodeURIComponent(offset)}/league/${encodeURIComponent(
                leagueId,
            )}`,
        )
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function getClubClips(
    limit: number,
    offset: number,
    clubId: number,
) {
    try {
        const response = await get<Clips>(
            `${baseUrl}/clips/limit/${encodeURIComponent(
                limit,
            )}/offset/${encodeURIComponent(offset)}/club/${encodeURIComponent(
                clubId,
            )}`,
        )
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function getTeamClips(
    limit: number,
    offset: number,
    teamId: number,
) {
    try {
        const response = await get<Clips>(
            `${baseUrl}/clips/limit/${encodeURIComponent(
                limit,
            )}/offset/${encodeURIComponent(offset)}/team/${encodeURIComponent(
                teamId,
            )}`,
        )
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function getCommunityClips(
    limit: number,
    offset: number,
    communityId: number,
) {
    try {
        const response = await get<Clips>(
            `${baseUrl}/clips/limit/${encodeURIComponent(
                limit,
            )}/offset/${encodeURIComponent(
                offset,
            )}/community/${encodeURIComponent(communityId)}`,
        )
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function getClipBasics(playbackId: string) {
    try {
        const response = await get<{ clip_assets: ClipBasics[] }>(
            `${baseUrl}/clips/basic/playback/${encodeURIComponent(playbackId)}`,
        )
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function getEventClips(eventId: number, limit: number) {
    try {
        const response = await get<Clips>(
            `${baseUrl}/clips/event/${encodeURIComponent(
                eventId,
            )}/limit/${encodeURIComponent(limit)}`,
        )
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}

export async function getMatchClips(matchId: number, limit: number) {
    try {
        const response = await get<Clips>(
            `${baseUrl}/clips/match/${encodeURIComponent(
                matchId,
            )}/limit/${encodeURIComponent(limit)}`,
        )
        return response.parsedBody
    } catch (error) {
        console.log(error)
        return undefined
    }
}
