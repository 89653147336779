import { useMutation } from '@apollo/client'
import { Form } from 'antd'
import photo from 'assets/images/layout/group.png'
import { Avatar } from 'components/Avatar'
import { Button } from 'components/Button'
import ButtonLoading from 'components/Loading/ButtonLoading'
import { ImageCrop_Modal } from 'components/Modal'
import { Text } from 'components/Text'
import { ADMINQL } from 'graphql/club'
import _ from 'lodash'
import { ClubAdminContext } from 'pages/club/[club_slug]/admin'
import { ClubListContext } from 'pages/league/[league_slug]/admin'
import React, { useContext, useRef, useState } from 'react'
import { BsSave } from 'react-icons/bs'
import { ImCancelCircle } from 'react-icons/im'
import { toast } from 'react-toastify'
import { ModalProps } from 'types/components/Modal'
import { slugifyString } from 'utils/common-helper'
import { s3UploadFile } from 'utils/s3-helper'
import { ModalWrapper } from '../../common.style'
import { CustomSelect, CustomeInput } from '../index.style'

const Player_A_Modal: React.FC<ModalProps> = ({
    show = false,
    handleClose,
    leagueClubInfo,
    leagueInfo,
}) => {
    const [form] = Form.useForm()

    const league = leagueInfo
    const clubList = useContext(ClubListContext)
    const clubAdminContext = useContext(ClubAdminContext)
    const club = clubAdminContext ?? leagueClubInfo
    const teamsData = !_.isEmpty(useContext(ClubAdminContext))
        ? club?.teams?.map((team: { name: any; id: any }) => ({
              label: team.name,
              value: team.id,
          }))
        : league?.teams?.map((team: { name: any; id: any }) => ({
              label: team.name,
              value: team.id,
          }))

    const clubsData = !_.isEmpty(useContext(ClubListContext))
        ? clubList.map((club: { name: any; id: any }) => ({
              label: club.name,
              value: club.id,
          }))
        : []

    // useState
    const [meta, setMeta] = useState<any>(null)
    const [file, setFile] = useState<any>(null)
    const [flag, setFlag] = useState<boolean>(false)
    const [isSubmit, setSubmiting] = useState<boolean>(false)
    const [croppedImage, setCroppedImage] = useState<any>(photo)
    // useRef
    const fileInputRef = useRef<HTMLInputElement>(null)
    // **
    const [add] = useMutation(ADMINQL.ADD_USER_PLAYER, {
        onCompleted() {
            setFile(null)
            form.resetFields()
            setCroppedImage(photo)
            handleClose && handleClose()
        },
        onError(e) {
            toast.error('Error Happened.')
        },
    })

    const onFinish = async (values: any) => {
        setSubmiting(true)
        const { team_id, positions, email, first_name, last_name, club_id } =
            values
        const slug = slugifyString(`${first_name} ${last_name}`)
        let image: string | null = null

        if (!_.isNull(file)) {
            const s3res: any = await s3UploadFile('Players', slug, file)
            image = s3res.location
        }

        /** TODO: send email invitation email */

        if (!_.isEmpty(clubList)) {
            /** Save to db */
            await add({
                variables: {
                    objects: {
                        club_id,
                        prev_club: '',
                        slug,
                        team_id,
                        positions: [positions],
                        is_accepted: false,
                        is_declined: false,
                        email_address: email,
                        name: first_name,
                        last_name,
                        image,
                    },
                },
            })
        } else {
            await add({
                variables: {
                    objects: {
                        club_id: club.id,
                        prev_club: '',
                        slug,
                        team_id,
                        positions: [positions],
                        is_accepted: false,
                        is_declined: false,
                        email_address: email,
                        name: first_name,
                        last_name,
                        image,
                    },
                },
            })
        }

        setSubmiting(false)
    }

    const saveImage = async (file: File, imageSrc: any) => {
        setFile(file)
        setCroppedImage(imageSrc)
    }
    const onFileInputChange = (e: any) => {
        setMeta(e)
        setFlag(true)
    }
    const _handleClose = () => {
        setFile(null)
        form.resetFields()
        setCroppedImage(photo)
        handleClose && handleClose()
    }
    const onTargetClick = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef?.current.click()
        }
    }
    return (
        <ModalWrapper
            open={show}
            closable={false}
            title="Add Player"
            width={600}
            footer={[
                <Button
                    bColor="primary"
                    key={'btn-cncl'}
                    bSize="small"
                    icon={<ImCancelCircle />}
                    type="button"
                    disabled={isSubmit}
                    onClick={_handleClose}
                >
                    {' '}
                    {'Cancel'}{' '}
                </Button>,
                <Button
                    bColor="primary"
                    key={'btn-save'}
                    bSize="small"
                    onClick={() => form.submit()}
                    disabled={isSubmit}
                    icon={isSubmit ? <ButtonLoading /> : <BsSave />}
                >
                    {' '}
                    {'Save'}{' '}
                </Button>,
            ]}
        >
            <div>
                <Form
                    name="basic"
                    form={form}
                    onFinish={onFinish}
                    layout="vertical"
                    style={{ color: 'white' }}
                >
                    <Form.Item
                        name="first_name"
                        rules={[
                            {
                                required: true,
                                message: 'First Name is required.',
                            },
                        ]}
                        label={
                            <label style={{ color: 'white' }}>
                                {'First Name'}
                            </label>
                        }
                    >
                        <CustomeInput placeholder="First Name" />
                    </Form.Item>
                    <Form.Item
                        name="last_name"
                        rules={[
                            {
                                required: true,
                                message: 'Last Name is required.',
                            },
                        ]}
                        label={
                            <label style={{ color: 'white' }}>
                                {'Last Name'}
                            </label>
                        }
                    >
                        <CustomeInput placeholder="Last Name" />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                type: 'email',
                                message: 'The input is not valid E-mail!',
                            },
                        ]}
                        label={
                            <label style={{ color: 'white' }}>
                                {'Email Address'}
                            </label>
                        }
                    >
                        <CustomeInput placeholder="Email" />
                    </Form.Item>
                    {!_.isEmpty(clubList) && (
                        <Form.Item
                            name="club_id"
                            label={
                                <label style={{ color: 'white' }}>
                                    {' '}
                                    {'Club'}
                                </label>
                            }
                        >
                            <CustomSelect
                                placeholder="Club"
                                options={clubsData}
                            />
                        </Form.Item>
                    )}
                    <Form.Item
                        name="team_id"
                        label={
                            <label style={{ color: 'white' }}>
                                {' '}
                                {'Primary Team'}
                            </label>
                        }
                    >
                        <CustomSelect
                            placeholder="Primary Team"
                            options={teamsData}
                        />
                    </Form.Item>
                    <Form.Item
                        name="positions"
                        label={
                            <label style={{ color: 'white' }}>
                                {'Position'}
                            </label>
                        }
                    >
                        <CustomeInput placeholder="Position" />
                    </Form.Item>
                </Form>

                <div className="image-area">
                    <Text
                        fSize={0.9}
                        fColor="white"
                        style={{ marginBottom: 8 }}
                    >
                        {'Player Photo'}
                    </Text>
                    <Avatar src={croppedImage} mode="medium" />
                    <Button
                        bColor="primary"
                        bSize="small"
                        type="button"
                        disabled={isSubmit}
                        onClick={onTargetClick}
                    >
                        {'Upload Photo'}
                    </Button>
                    <input
                        onChange={onFileInputChange}
                        onClick={(event: any) => {
                            event.target.value = null
                        }}
                        ref={fileInputRef}
                        type="file"
                        style={{ display: 'none' }}
                        accept="image/png, image/jpeg"
                    />

                    <div style={{ padding: '0px 10px' }}>
                        <ul>
                            <li>
                                {' '}
                                <Text fSize={0.9}>
                                    {'Accepted file formats:JPG, PNG, SVG'}{' '}
                                </Text>{' '}
                            </li>
                            <li>
                                {' '}
                                <Text fSize={0.9}>
                                    {'Maximum file size: 25MB'}{' '}
                                </Text>{' '}
                            </li>
                            <li>
                                {' '}
                                <Text fSize={0.9}>
                                    {'Minimum dimensions: 300 x 300px'}{' '}
                                </Text>{' '}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <ImageCrop_Modal
                show={flag}
                meta={meta}
                saveImage={saveImage}
                handleClose={() => setFlag(false)}
            />
        </ModalWrapper>
    )
}

export default Player_A_Modal
