/* eslint-disable react/display-name */
import { Row } from 'components/Layout'
import { Text } from 'components/Text'
import { useRouter } from 'hooks'
import { ComponentType } from 'react'
import styled from 'styled-components'
import {
    IconWrapperProps,
    LinkItemWrapperProps,
} from 'types/components/LinkItem'

const IconWrapper = styled.div<IconWrapperProps>`
    display: flex;
    justify-content: center;
    align-items: center;
    width: ${({ iconWidth }) => {
        return iconWidth ? `${iconWidth}px` : 'auto'
    }};
`

const useLinkItem = (
    LinkItemWrapper: ComponentType<LinkItemWrapperProps>,
    mode: 'title' | 'icon' = 'title',
) => {
    const { move } = useRouter()
    if (mode === 'title') {
        return ({
            title = '',
            href = '',
            icon,
            iconSlot = 'start',
            iconDirection = 'row',
            iconGaping = 6,
            iconWidth,
            alignVertical = 'center',
            alignHorizontal = 'initial',
            handleClick = () => {},
        }: LinkItemWrapperProps) => {
            const handleOnClick = () => {
                handleClick()
                if (href != '') move(href)
            }
            return (
                <LinkItemWrapper>
                    <Row
                        alignItems={alignVertical}
                        justifyContent={alignHorizontal}
                        gap={icon ? iconGaping : 0}
                        flexDirection={iconDirection}
                    >
                        {icon && iconSlot === 'start' && (
                            <IconWrapper iconWidth={iconWidth}>
                                {icon}
                            </IconWrapper>
                        )}
                        <Text
                            onClick={handleOnClick}
                            className="link_title"
                            mode="p"
                            lHeight={0}
                        >
                            {title}
                        </Text>
                        {icon && iconSlot === 'end' && (
                            <IconWrapper iconWidth={iconWidth}>
                                {icon}
                            </IconWrapper>
                        )}
                    </Row>
                </LinkItemWrapper>
            )
        }
    }
    if (mode === 'icon') {
        return ({
            href = '',
            icon,
            handleClick = () => {},
        }: LinkItemWrapperProps) => {
            const handleOnClick = () => {
                handleClick()
                if (href != '') move(href)
            }

            return (
                <LinkItemWrapper onClick={handleOnClick}>
                    {icon}
                </LinkItemWrapper>
            )
        }
    }
    return () => <></>
}

export default useLinkItem
