const CirclePlayIcon = () => {
    return (
        <svg
            width="50"
            height="50"
            viewBox="0 0 43 44"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_d_3929_8812)">
                <circle cx="21.4999" cy="18.5" r="6.88372" fill="#FA3737" />
            </g>
            <g filter="url(#filter1_b_3929_8812)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M6.7998 18.4999C6.7998 10.3857 13.3852 3.80029 21.4994 3.80029C29.6136 3.80029 36.199 10.3857 36.199 18.4999C36.199 26.6141 29.6136 33.1995 21.4994 33.1995C13.3852 33.1995 6.7998 26.6141 6.7998 18.4999ZM18.5592 13.355V23.6448C18.5592 24.2474 19.25 24.6002 19.7351 24.2327L26.5998 19.0879C26.9967 18.7939 26.9967 18.2059 26.5998 17.9119L19.7351 12.767C19.25 12.3996 18.5592 12.7523 18.5592 13.355Z"
                    fill="white"
                    fillOpacity="0.9"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_3929_8812"
                    x="0.616211"
                    y="1.61627"
                    width="41.7676"
                    height="41.7675"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="10"
                        operator="dilate"
                        in="SourceAlpha"
                        result="effect1_dropShadow_3929_8812"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_3929_8812"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_3929_8812"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_b_3929_8812"
                    x="-18.3165"
                    y="-21.316"
                    width="79.632"
                    height="79.6318"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur
                        in="BackgroundImageFix"
                        stdDeviation="12.5581"
                    />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_3929_8812"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_3929_8812"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    )
}

export default CirclePlayIcon
