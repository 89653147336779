export const dateDisplayFormat = 'DD MMMM YYYY'

export const s3Config = {
    dirName: 'Club' /** Default please override */,
    bucketName: process.env.NEXT_PUBLIC_ASSETS_BUCKET_NAME,
    region: process.env.NEXT_PUBLIC_S3_REGION,
    accessKeyId: process.env.NEXT_PUBLIC_S3_ACCESS_ID,
    secretAccessKey: process.env.NEXT_PUBLIC_S3_SECRET_KEY,
}

export const apiBaseUrl = process.env.NEXT_PUBLIC_API_URL
export const baseUrl =
    typeof window !== 'undefined'
        ? `${window.location.protocol}//${window.location.host}`
        : ''

export const muxVideoApiBaseUrl =
    process.env.NEXT_PUBLIC_MUX_VIDEO_API_BASE_URL || ''
export const muxPostAuthToken = {
    username: process.env.MUX_TOKEN_ID || '',
    password: process.env.MUX_TOKEN_SECRET || '',
}
export const muxGetAuthToken = Buffer.from(
    `${process.env.MUX_TOKEN_ID}:${process.env.MUX_TOKEN_SECRET}`,
    'utf8',
).toString('base64')

export const muxAssetBaseUrl = process.env.NEXT_PUBLIC_MUX_ASSET_BASE_URL

export const USER_ROLE = {
    ADMIN: 1,
    TEAM_ADMIN: 2,
    CLUB_ADMIN: 3,
    PLAYER: 4,
    USER: 5,
    FAN: 6,
    LEAGUE_ADMIN: 7,
}

/** defualt ads ID for match creation */
export const MATCH_ADS_ID = 1

export const GPT_NETWORK = 60035833
export const GPT_UNIT = 'WAN/streamer'

export const pathNameWithDynamicAds = [
    '/',
    '/league/[league_slug]',
    '/club/[club_slug]',
    '/club/[club_slug]/replay/[asset_id]',
    '/club/[club_slug]/match/[asset_id]',
    '/league/[league_slug]/replay/[asset_id]',
    '/league/[league_slug]/match/[asset_id]',
    '/community/[community_slug]',
    '/match/[asset_id]',
    '/event/[event_id]/[event_slug]',
]

export const MULTI_SPORT = 'Multi-sport'

// list of sports that only shows total score in the scoreboard
export const sportsList = [
    'Basketball',
    'Soccer',
    'Hockey',
    'Water Polo',
    'Baseball',
    'Multi-sport',
]

export const defaultBGImage =
    'https://assets.streamer.com.au/BackgroundImage/ThumbnailBlue.jpg'

export const encodedStreamerEmail = 'Y2x1YnNAc3RyZWFtZXIuY29tLmF1'

export const registrationSubject = 'Club Registration Request'
export const registrationBody = `I would like to set my club up on Streamer.

Club name:

League:

Contact name:

Contact number:

Contact's role at club:`
