import { Select, Tabs } from 'antd'
import { Row } from 'components/Layout'
import { Text } from 'components/Text'
import styled from 'styled-components'

interface props {
    flex?: string
}

interface MatchThumbProps {
    playbackID: string
}

export const AntTab = styled(Tabs)`
    padding: 0 25px 0 25px;
    width: 100%;
    min-height: 67vh;
    background-color: rgba(53, 50, 50, 0.27);
    border-color: #332e2e;
    border-width: 1px;
    border-radius: 15px;
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #fff;
        font-size: 1.25rem;
        font-weight: 600;
        font-family: HeyWow, Arial, Helvetica, sans-serif;
    }

    .ant-tabs-nav::before {
        border-bottom: 1px solid rgb(41 40 40) !important;
    }

    .ant-tabs-tab-btn {
        color: #8b8c91;
        font-size: 1.25rem;
        font-weight: 600;
    }

    .ant-tabs-ink-bar {
        background-color: #fa3737;
    }
    .anticon {
        color: red;
    }

    .ant-tabs > .ant-tabs-nav,
    .ant-tabs > div > .ant-tabs-nav {
        overflow: auto !important;
    }
`
export const RowItem = styled.div<props>`
    display: flex;
    flex: ${(props) => props.flex || 1};
    align-items: center;
    min-width: 6rem;
    padding-right: 0.3rem;
`

export const StyledText = styled(Text)`
    color: rgb(133 133 139);
`

export const VidThumbnail = styled.div<MatchThumbProps>`
    display: flex;
    width: 160px;
    height: 90px;
    background-color: rgb(53 52 52);
    border-radius: 4px;
    overflow: hidden;
    background-image: url(https://image.mux.com/${(props) =>
        props.playbackID || ''}/thumbnail.png);
    background-size: 160px 90px;
    align-items: center;
    justify-content: center;
`

export const TableHead = styled(Row)`
    height: 40px;
    border-radius: 9px;
    background-color: rgb(45 43 43);
    padding: 0 30px;
    min-width: 1000px;
    margin: 1rem 0;
`

export const TableBody = styled.div`
    height: 44.5vh;
    min-width: 1000px;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%;
    ::-webkit-scrollbar {
        width: 10px;
    }

    ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 100vw;
    }
`

export const SelectRange = styled(Select)`
    width: 130px;
    .ant-select-selector {
        background-color: transparent !important;
        border-radius: 15px !important;
        span {
            color: #fff;
        }
    }

    .anticon {
        color: #fff;
    }
`

export const ViewsDiv = styled.div`
    height: 80px;
    min-width: 12em;
    background-color: #332e2e;
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
`

export const UniqueViewersDiv = styled.div`
    height: 80px;
    min-width: 13em;
    background-color: #332e2e;
    color: white;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    border-radius: 7px;
    display: flex;
    flex-direction: column;
    margin-left: 1.5em;
`

export const TableRow = styled(Row)`
    width: 100%;
    height: 120px;
    border-radius: 9px;
    padding: 0 30px;
    min-width: 68em;
`
