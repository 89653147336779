import React from 'react'
// type
import { IconProps } from 'types/components/Icon'

// -----------------------------------------------
const HockeyIcon: React.FC<IconProps> = ({
    iColor = 'white',
    iSize = { x: 30, y: 30 },
}) => {
    return (
        <svg
            version="1.1"
            id="Capa_1"
            xmlns="http://www.w3.org/2000/svg"
            x="0px"
            y="0px"
            viewBox="0 0 266.186 266.186"
            width={iSize.x}
            height={iSize.y}
        >
            <g fill={iColor} stroke="none">
                <path
                    d="M258.591,110.561c-30.131-17.115-64.374-26.162-99.026-26.162h-13.32c-0.604,0-1.202,0.048-1.796,0.12
		c-5.661-0.486-11.507,1.15-16.236,5.05L73.685,134.54c-7.247,5.977-9.785,15.537-7.182,23.99L4.348,221.227
		c-5.833,5.884-5.791,15.381,0.092,21.213c2.925,2.9,6.743,4.348,10.561,4.348c3.86,0,7.72-1.481,10.652-4.439l68.102-68.697
		c0.727-0.192,1.443-0.431,2.153-0.698c1.163,0.815,2.458,1.483,3.875,1.955l46.606,15.509l6.993,16.112l-4.71,4.705l-47.257-0.051
		c-3.813,0-7.312,1.247-10.138,3.345c-4.799,3.004-8.008,8.573-8.008,14.639c0,9.363,7.618,17.231,16.981,17.231h1.143h30.021h0.603
		c1.982,0,3.885-1.035,5.29-2.434l19.685-19.714l2.81-2.87l6.909,15.885c2.454,5.654,7.973,9.016,13.768,9.016
		c1.993,0,4.02-0.408,5.965-1.252c7.599-3.299,11.086-12.137,7.787-19.736l-11.616-26.764l61.68-61.622
		c7.118,3.688,15.937,1.137,19.932-5.896C268.317,123.809,265.795,114.652,258.591,110.561z M171.241,172.327
		c-1.697-3.91-4.979-6.914-9.023-8.26l-38.198-12.711l32.825-27.072c3.259-2.688,5.542-6.108,6.859-9.819
		c22.979,0.562,45.651,5.789,66.526,15.289l-54.036,53.984L171.241,172.327z M123.567,44.918c0-14.094,11.426-25.519,25.519-25.519
		c14.094,0,25.519,11.425,25.519,25.519s-11.425,25.519-25.519,25.519C134.992,70.437,123.567,59.012,123.567,44.918z"
                />
            </g>
        </svg>
    )
}
export default HockeyIcon
