import styled from 'styled-components'
import { defaultTheme } from 'theme'

interface FlexContainerInterface {
    direction?: 'row' | 'column'
    justify?:
        | 'space-around'
        | 'space-evenly'
        | 'space-between'
        | 'center'
        | 'flex-start'
        | 'flex-end'
    maxWidth?: string
    width?: string
    order?: number
    gap?: string | number
    margin?: string | number
    padding?: string | number
    bgColor?: string
    // small / mobile view direction
    smDirection?: 'row' | 'column'
    smGap?: string | number
    align?:
        | 'space-around'
        | 'space-evenly'
        | 'space-between'
        | 'center'
        | 'flex-start'
        | 'flex-end'
}

// use section element
const FlexContainer = styled.section<FlexContainerInterface>`
    background-color: ${(props) => props.bgColor ?? 'inherit'};
    flex-direction: ${(props) => props.direction ?? 'row'};
    justify-content: ${(props) => props.justify ?? 'flex-start'};
    align-items: ${(props) => props.align ?? 'flex-start'};
    width: ${(props) => props.width ?? '100%'};
    max-width: 100%;
    gap: ${(props) => props.gap ?? '0'};
    margin: ${(props) => props.margin ?? '0'};
    padding: ${(props) => props.padding ?? '0'};
    display: flex;

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        flex-direction: ${(props) => props.smDirection ?? 'row'};
        justify-content: ${(props) => props.justify ?? 'flex-start'};
        max-width: ${(props) => props.maxWidth ?? '100%'};
        width: 100%;
        order: ${(props) => props.order ?? 1};
        gap: ${(props) => props.smGap ?? '0'};
    }
`

export default FlexContainer
