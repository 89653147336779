import { useLazyQuery } from '@apollo/client'
import { query } from 'graphql/sports'
import { useEffect, useState } from 'react'
import { MULTI_SPORT } from 'utils/constData'
import { CustomSelect } from '../index.style'

const SportsDropDown = (props: any) => {
    const { sport_name = MULTI_SPORT } = props
    const [data, setData] = useState<any>([])

    const [pullData] = useLazyQuery(query.GET_SPORTS, {
        onCompleted(data) {
            data.sports && setData(data.sports)
        },
    })

    const where =
        sport_name === MULTI_SPORT
            ? { where: {} }
            : { where: { name: { _eq: sport_name } } }

    useEffect(() => {
        sport_name && pullData({ variables: where })
    }, [sport_name])

    const sports = data?.map((item: any) => ({
        label: item.name,
        value: item.id,
    }))
    return (
        <CustomSelect placeholder="Select Sports" options={sports} {...props} />
    )
}

export default SportsDropDown
