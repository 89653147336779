// styled component
import { themeGet } from '@styled-system/theme-get'
import { Button } from 'components/Button'
import styled, { css } from 'styled-components'
import { Primary_Button_Style } from '../../../../common/styles/constant'
// -------------------------------------------------

export const ProfileWrapper = styled.div`
    height: 100%;
    width: 100%;
`

export const StreamLogoWrapper = styled.div`
    width: 126px;
    height: 36px;
    margin-bottom: 4px;
    margin-left: 10px;
    cursor: pointer;
`

export const StreamerLogoH1 = styled.h1`
    display: flex;
    flex-direction: row;
    width: auto;
    padding: 0px 10px 0px 16px;
`

export const BHPLogoWrapper = styled.div`
    position: relative;
    bottom: -3px;
`

export const ProfileDropdownWrapper = styled.div`
    margin-left: 20px;
`

export const MenuNav = styled.nav`
    display: flex;
    flex-direction: row;
    align-items: center;
`

export const TheWestAustralianLogoWrapper = styled.a`
    display: flex;
`

export const HeaderWrapper = styled.header`
    height: 100%;
    width: 100%;
    display: flex;

    .ImageWrapper {
        contain: content;
        img {
            border-radius: 50%;
        }
    }
    .DropdownWrapper {
        padding: 10px 10px;
    }
    .fitbox {
        padding-right: 15px;
    }
`

export const HeaderBarWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 69px;
    width: 100%;
    padding: 0 20px 0 0;
    border-bottom: 1px solid ${themeGet('colors.gray.800')};
    border-top: 1px solid ${themeGet('colors.gray.800')};
`

export const MenuItem = styled.a<{ mode: string }>`
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin: 0 10px;
    transition: all 0.2s ease-in-out;
    ::after {
        transition: all 0.2s ease-in-out;
        position: absolute;
        content: '';
        height: 2px;
        background-color: ${themeGet('colors', 'white')};
        width: 100%;
        left: 0;
        bottom: -5px;
        transform: scaleX(0);
    }
    :hover {
        ::after {
            transform: scaleX(1);
        }
    }
    ${({ mode }) => {
        if (mode === 'true') {
            return css`
                p {
                    color: ${themeGet('colors.white.100')};
                    font-weight: bold;
                }
                ::after {
                    transform: scaleX(1);
                }
            `
        }
    }}
`

export const Border = styled.div`
    height: 24px;
    width: 1px;
    margin: 0px 10px;
    background-color: ${themeGet('colors.gray.300')};
`

export const MenuItemBody = styled.div`
    display: flex;
`

export const HeaderLoginButton = styled(Button)`
    width: 90px;
    height: 44px;
    font-size: 14px;
    border: none;
`

export const HeaderSignInButton = styled(Button)`
    width: 110px;
    height: 44px;
    font-size: 14px;
    ${Primary_Button_Style}
`
