import { Button } from 'components/Button'
import styled from 'styled-components'
import { CSVLink } from 'react-csv'

export const StyledButton = styled(Button)`
    width: 9em;
`

export const StyledCSVLink = styled(CSVLink)`
    /* margin: auto 0 3px auto; */

    @media screen and (max-width: 610px) {
        margin-left: 1em;
    }
`
