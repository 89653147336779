import React from 'react'
// import styled component
import { Row } from 'components/Layout'
import { EmptyWrapper, StyledTable } from 'components/Table/Common/table.style'
import { AiOutlineWarning } from 'react-icons/ai'

import { Text } from 'components/Text'
import _ from 'lodash'

const editPage = ['player', 'schedule']

const Table: React.FC<any> = ({ data, onHandleClick, page, tableClick }) => {
    const _onHandleChange = (e: any) => {
        onHandleClick && onHandleClick(e)
    }

    if (_.isEmpty(data)) {
        return (
            <EmptyWrapper>
                <Row
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                >
                    <AiOutlineWarning size={100} />
                    <Text fSize={1.0625}>{'No Data'}</Text>
                </Row>
            </EmptyWrapper>
        )
    }

    return (
        <TableMarkup
            titles={_.without(Object.keys(data[0]), 'item_data')}
            data={data}
            pageName={page}
            _onClick={_onHandleChange}
            tableClick={tableClick}
        />
    )
}
const TableMarkup = ({
    titles,
    data,
    _onClick,
    pageName,
    tableClick = true,
}: any) => {
    return (
        <StyledTable>
            <table>
                <thead>
                    <tr>
                        {titles.map((title: string, index: number) => (
                            <th key={index}>{title}</th>
                        ))}
                        {titles &&
                            pageName &&
                            editPage.some((e) => e === pageName) && <th />}
                    </tr>
                </thead>
                <tbody>
                    {data.map((item: any, index: number) => (
                        <tr
                            key={index}
                            onClick={() =>
                                tableClick &&
                                _onClick({ ...item, type: 'edit' })
                            }
                        >
                            {titles.map((title: string, index: number) => (
                                <td key={index}>{item[title]}</td>
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
        </StyledTable>
    )
}

export default Table
