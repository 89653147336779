import React from 'react'
// type
import { IconProps } from 'types/components/Icon'

// -----------------------------------------------
const ForwardButtonIcon: React.FC<IconProps> = ({
    onClick = () => onClick,
    iColor = 'white',
    iSize = { x: 40, y: 40 },
}) => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width={iSize.x}
            height={iSize.y}
            onClick={onClick}
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid meet"
        >
            <g
                transform="translate(0,100) scale(0.100000,-0.100000)"
                fill={iColor}
                stroke="none"
            >
                <path
                    d="M827 954 c-4 -4 -7 -38 -7 -76 l0 -69 -56 40 c-183 131 -419 115
-574 -40 -240 -240 -127 -647 205 -735 189 -51 405 47 494 224 32 63 58 178
48 207 -13 33 -31 8 -43 -61 -16 -95 -48 -159 -114 -224 -158 -159 -402 -159
-560 0 -121 121 -153 293 -81 446 41 89 99 147 189 192 72 35 81 37 171 37 84
0 104 -4 156 -28 32 -15 75 -40 95 -55 l35 -27 -70 -5 c-53 -4 -70 -9 -70 -20
0 -12 20 -16 108 -18 l108 -3 -3 107 c-3 99 -11 128 -31 108z"
                />
                <path
                    d="M390 650 c0 -19 -40 -50 -65 -50 -16 0 -25 -6 -25 -15 0 -11 11 -15
40 -15 l40 0 0 -115 c0 -108 1 -115 20 -115 19 0 20 7 20 160 0 136 -2 160
-15 160 -8 0 -15 -4 -15 -10z"
                />
                <path
                    d="M537 649 c-33 -19 -47 -64 -47 -151 0 -128 39 -174 129 -152 51 13
71 56 71 157 0 88 -17 132 -56 147 -32 13 -74 12 -97 -1z m99 -50 c15 -16 19
-36 19 -88 0 -103 -31 -153 -83 -137 -31 10 -47 54 -47 131 0 83 18 115 65
115 15 0 35 -9 46 -21z"
                />
            </g>
        </svg>
    )
}
export default ForwardButtonIcon
