import React, { useEffect, useState } from 'react'
import { CheckIcon } from '@heroicons/react/outline'
import { useRouter } from 'next/router'
import { ImCancelCircle } from 'react-icons/im'
import _ from 'lodash'

// styled components
import {
    ModalWrapper,
    ModalWrapperAnt,
    ModalContent,
    ModalBody,
    SectionWrapper,
    IconWrapper,
    ModalHeader,
} from '../index.style'

// global components
import { Text } from 'components/Text'
import { Button } from 'components/Button'

const SuccessView: React.FC = () => {
    const router = useRouter()
    const { success, pass } = router.query
    const [showModal, setShowModal] = useState(false)

    useEffect(() => {
        function getURLPath() {
            setShowModal(!_.isEmpty(success))

            setTimeout(() => {
                setShowModal(false)
            }, 5000)
        }

        getURLPath()
    }, [success])

    const handleClose = () => {
        setShowModal(false)
    }

    return (
        <ModalWrapperAnt
            open={showModal}
            onCancel={handleClose}
            width={800}
            footer={null}
            title={null}
        >
            <ModalContent show={showModal}>
                <ModalHeader>
                    <Button
                        style={{
                            backgroundColor: '#1B1B25',
                        }}
                        bColor="primary"
                        bSize="small"
                        icon={<ImCancelCircle />}
                        onClick={handleClose}
                    />
                </ModalHeader>
                <ModalBody>
                    <SectionWrapper
                        flexDirection="column"
                        alignItems="center"
                        padding="15px 0px 0px 0px"
                        gap="15px"
                    >
                        <IconWrapper style={{ backgroundColor: '#d1fae5' }}>
                            <CheckIcon
                                aria-hidden="true"
                                style={{
                                    height: '10rem',
                                    width: '10rem',
                                    color: '#059669',
                                }}
                            />
                        </IconWrapper>
                    </SectionWrapper>

                    <SectionWrapper
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        padding="25px 0px 0px 0px"
                    >
                        <Text
                            fSize={1.5}
                            fWeight={700}
                            tAlign="center"
                            fColor="white"
                        >
                            {'Success!'}
                        </Text>
                        <Text
                            fSize={1.3}
                            fWeight={500}
                            tAlign="center"
                            fColor="white"
                        >
                            {success === 'fan-pass'
                                ? `You are now a digital fan pass holder for the '${pass}' club`
                                : 'You are now a League pass holder'}
                        </Text>
                    </SectionWrapper>

                    <SectionWrapper
                        flexDirection="column"
                        padding="35px 0px 15px 0px"
                    >
                        <Text
                            fSize={1.2}
                            fWeight={500}
                            tAlign="center"
                            fColor="white"
                        >
                            {
                                'You can manage your subscriptions by pressing your Profile Icon in the Header Menu.'
                            }
                        </Text>
                    </SectionWrapper>
                </ModalBody>
            </ModalContent>
        </ModalWrapperAnt>
    )
}

export default SuccessView
