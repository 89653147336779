import {
    ApolloClient,
    ApolloLink,
    HttpLink,
    InMemoryCache,
    NormalizedCacheObject,
    from,
    split,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { WebSocketLink } from '@apollo/client/link/ws'
import { getMainDefinition } from '@apollo/client/utilities'
import { getSession, useUser } from '@auth0/nextjs-auth0'
import fetch from 'isomorphic-unfetch'
import { GetServerSidePropsContext } from 'next'
import { useRef } from 'react'

const SERVER: string =
    process.env.NEXT_PUBLIC_CONTENT_DELIVERY_GRAPHQL_API || ''
const SOCKET: string = process.env.NEXT_PUBLIC_CONTENT_SOCKET_API || ''

function createLink(currentToken: string) {
    const errorLink = onError(({ graphQLErrors, networkError }) => {
        if (graphQLErrors)
            graphQLErrors.forEach((graphqlError) =>
                console.error('GraphQL error', graphqlError),
            )
        if (networkError) console.error('GraphQL network error', networkError)
    })

    const authLink = setContext((_, { headers }) => {
        return {
            headers: currentToken
                ? { ...headers, Authorization: `Bearer ${currentToken}` }
                : headers,
        }
    })

    const authErrorLink = from([authLink, errorLink])

    const ssrMode = !process.browser

    const httpLink: ApolloLink = new HttpLink({
        fetch,
        credentials: 'same-origin',
        uri: SERVER,
        headers: {},
    })

    if (ssrMode) {
        return authErrorLink.concat(httpLink)
    } else {
        const wsLink: WebSocketLink = new WebSocketLink({
            uri: SOCKET,
            options: {
                lazy: true,
                inactivityTimeout: 10,
                reconnect: true,
                connectionParams: () => {
                    return {
                        headers: currentToken
                            ? { Authorization: `Bearer ${currentToken}` }
                            : {},
                    }
                },
            },
        })

        return split(
            ({ query }) => {
                const definition = getMainDefinition(query)
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                )
            },
            authErrorLink.concat(wsLink),
            authErrorLink.concat(httpLink),
        )
    }
}

function createApolloClient(token: string) {
    return new ApolloClient({
        ssrMode: isServer(),
        link: createLink(token),
        cache: new InMemoryCache(),
    })
}

function isServer() {
    return typeof window === 'undefined'
}

let apolloClient: ApolloClient<NormalizedCacheObject> | undefined

type LimitedContext = Pick<GetServerSidePropsContext, 'req' | 'res'>

export function initializeApolloFromContext(context: LimitedContext) {
    const { req, res } = context
    const session = req && res && getSession(req, res)
    const token = session?.user?.token || ''
    return createApolloClient(token)
}

function initializeApollo(options: {
    currentToken: string
    initialState?: Record<string, any>
}) {
    if (isServer()) {
        // For SSG and SSR always create a new Apollo Client
        const _apolloClient = createApolloClient(options.currentToken)

        // Restore the cache using the data passed from
        // getStaticProps/getServerSideProps combined with the existing cached data
        if (options?.initialState) {
            _apolloClient.cache.restore(options.initialState)
        }

        return _apolloClient as ApolloClient<NormalizedCacheObject>
    }

    if (!apolloClient) {
        apolloClient = createApolloClient(options.currentToken)
        if (options?.initialState) {
            const existingCache = apolloClient.extract()
            // Restore the cache using the data passed from
            // getStaticProps/getServerSideProps combined with the existing cached data
            apolloClient.cache.restore({
                ...existingCache,
                ...options.initialState,
            })
        }
    }

    return apolloClient
}

// Log any GraphQL errors or network error that occurred
export function useApollo(initialState?: any) {
    const { user, isLoading } = useUser()

    // Keep a reference for storing a single apollo instance
    const currentToken = (user?.token as string) ?? ''
    const client = useRef(initializeApollo({ initialState, currentToken }))

    // Store the current token as a reference so we can reinint apollo when it changes
    const token = useRef(currentToken)
    if (currentToken && currentToken !== token.current) {
        token.current = currentToken
        client.current.stop()
        apolloClient = undefined
        client.current = initializeApollo({ initialState, currentToken })
    }

    return { apolloClient: client.current, isLoading }
}
