import { UserProfile, useUser } from '@auth0/nextjs-auth0'
import { useSelector } from 'react-redux'
import { RootState } from 'redux/reducers/rootReducer'
import { USER_ROLE } from 'utils/constData'

export interface UserPass {
    id: number
    status: 'active' | 'past_due' | 'incomplete_expired' | 'incomplete'
    pass: {
        id: number
        pass_type_id: number
    }
}
export interface UserProfileInterface extends UserProfile {
    token?: string
    user_role_id?: number
    id?: number
}

function User() {
    const club = useSelector((state: RootState) => state.club.info)
    const league = useSelector((state: RootState) => state.league.info)
    const userInfo = useSelector((state: RootState) => state.user)
    const user_passes = userInfo.user_passes
    const { user, isLoading } = useUser()

    const theUser: UserProfileInterface | undefined = user

    const clubAdmins: Array<number | unknown> = club.club_admins
        ? club.club_admins.map((v: any) => v.user_id)
        : []

    const isSuperAdmin: boolean = USER_ROLE.ADMIN === userInfo?.user_role_id

    const isAdmin: boolean =
        isSuperAdmin ||
        userInfo.league_admin_details.length !== 0 ||
        userInfo.club_admin_details.length !== 0

    const leagueAdmins: Array<number | unknown> = userInfo.league_admin_details
        ? userInfo.league_admin_details.map((v: any) => v.league_id)
        : []

    const isLeagueAdmin: boolean =
        isSuperAdmin || leagueAdmins.includes(league?.id)

    const isClubAdmin: boolean =
        isSuperAdmin || clubAdmins.includes(userInfo?.id)

    const isPlayer: boolean =
        isAdmin || USER_ROLE.PLAYER === userInfo?.user_role_id

    const isFollower: boolean =
        isSuperAdmin || USER_ROLE.USER === userInfo?.user_role_id

    const isFan: boolean =
        isSuperAdmin || USER_ROLE.FAN === userInfo?.user_role_id

    const isDigitalFan: boolean = user_passes?.some(
        (userPass: any) => userPass.pass.pass_type_id === 2,
    )

    return {
        user: theUser,
        isSuperAdmin,
        isAdmin,
        isClubAdmin,
        isPlayer,
        isFollower,
        isFan,
        isDigitalFan,
        isLeagueAdmin,
        token: user?.idToken,
        isLoading,
    }
}

export default User
