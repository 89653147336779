import { useState } from 'react'
//  component
import { Col, Row } from 'components/Layout'
import { Table } from 'components/Table'

import { connect } from 'react-redux'

//  import react icons
//  styled component
import { DisplayWrapper } from './events.style'
// asset
import { useSubscription } from '@apollo/client'
import { Button } from 'components/Button'
import { FlexContainer } from 'components/Container'
import EventModal from 'components/Modal/Events'
import { subscribe } from 'graphql/match'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { getStreamPagePath } from 'utils/common-helper'
import { isServer } from 'utils/helper'
import { datetimeToLocal } from 'utils/helper-date'

const EventsSection = (props: { clubInfo: any }) => {
    const { clubInfo } = props

    const [data, setData] = useState<any>([])
    const [item, setItem] = useState(null)
    const [tableClick, setTableClick] = useState(true)
    const baseUrl = window.location.origin

    const copyTextToClipboard = async (text: string) => {
        if ('clipboard' in navigator) {
            return await navigator.clipboard.writeText(text)
        } else {
            return document.execCommand('copy', true, text)
        }
    }

    const copyURL = (data: string) => {
        copyTextToClipboard(data)
            .then(() => {
                toast.success('Copied')
            })
            .catch((err) => {
                toast.error('Error copying.')
            })
    }

    useSubscription(subscribe.SUB_EVENT_STREAMS, {
        skip: !clubInfo.id || isServer,
        variables: {
            where: {
                club_id: { _eq: clubInfo.id },
                stream_info: {
                    status: { _neq: 'completed' },
                },
            },
        },
        onData: ({ data: { data, loading } }) => {
            !loading && data.event_streams && setData(data.event_streams)
        },
    })

    const onHandleClick = (e: { [x: string]: any }) => {
        const selectedItem = _.find(
            data,
            (event) => event.stream_info.stream_key === e['Stream Key'],
        )
        setItem(selectedItem)
    }

    const handleCloseEventModal = () => {
        setItem(null)
    }

    const tableData = data.map(
        (event: {
            name: any
            start_datetime: string
            is_private: { toString: () => any }
            ext_managed: { toString: () => any }
            stream_info: { stream_key: any }
            event_type: string
            id: any
            slug: any
        }) => {
            const streamUrl = getStreamPagePath({
                eventId: event.id,
                eventSlug: event.slug,
            })
            return {
                'Event Name': event.name,
                'Event Date': datetimeToLocal(event.start_datetime, {
                    hideTime: true,
                    options: { month: 'short' },
                }),
                'Event Time': datetimeToLocal(event.start_datetime, {
                    hideDate: true,
                    hideTimezone: true,
                }),
                'Private Event': event.is_private.toString(),
                Managed: event.ext_managed.toString(),
                'Stream Key': event.stream_info.stream_key,
                'Event URL': streamUrl,
                '': (
                    <Button
                        onMouseOver={() => setTableClick(false)}
                        onMouseOut={() => setTableClick(true)}
                        onClick={() => copyURL(streamUrl)}
                        style={`
                white-space: nowrap;
                `}
                    >
                        COPY URL
                    </Button>
                ),
            }
        },
    )

    return (
        <DisplayWrapper>
            <FlexContainer justify="flex-end">
                <EventModal
                    clubInfo={clubInfo}
                    item={item}
                    handleClose={handleCloseEventModal}
                />
            </FlexContainer>

            <Row flexDirection="column" gap={20}>
                <Col item={24}>
                    <Table
                        data={tableData}
                        page="event-schedule"
                        onHandleClick={(e: any) => onHandleClick(e)}
                        tableClick={tableClick}
                    />
                </Col>
            </Row>
        </DisplayWrapper>
    )
}

const mapStateToProps = (state: { club: { info: any } }) => ({
    clubInfo: state.club.info,
})

// @ts-ignore
export default connect(mapStateToProps)(EventsSection)
