import { gql } from '@apollo/client'

// const USER_FOLLOW_CLUB = gql`mutation UserClubFollowInsert($objects: [user_club_follows_insert_input!]!) {
//     insert_user_club_follows(objects: $objects, on_conflict: {constraint: user_club_follows_user_id_club_id_key, update_columns: []}) {
//         affected_rows
//       }
//   }`;

export default {
    // USER_FOLLOW_CLUB
}
