import { useLazyQuery } from '@apollo/client'
import { Select } from 'antd'
import { ADMINQL } from 'graphql/club'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { MULTI_SPORT } from 'utils/constData'
import { CustomSelect } from './dropdown.style'

const { Option } = Select

function TeamsSelect(props: any) {
    const { sport_name, homeClubId } = props
    const router = useRouter()
    const { club_slug, league_slug } = router.query

    const [data, setData] = useState<Partial<Array<any>>>([])

    const [pullData] = useLazyQuery(ADMINQL.GET_ALL_CLUB_TEAMS, {
        onCompleted(data) {
            data.clubs[0] && setData(data.clubs[0].teams)
        },
    })
    const [pullLeagueData] = useLazyQuery(ADMINQL.GET_ALL_TEAMS, {
        onCompleted(data) {
            data.teams && setData(data.teams)
        },
    })
    const teamsWhere =
        sport_name === MULTI_SPORT
            ? {}
            : {
                  sport: {
                      name: {
                          _eq: sport_name,
                      },
                  },
              }

    useEffect(() => {
        _.isUndefined(league_slug)
            ? sport_name &&
              pullData({
                  variables: {
                      where: {
                          slug: { _eq: club_slug },
                          status: {
                              _eq: 'approved',
                          },
                      },
                      teamsWhere: teamsWhere,
                  },
              })
            : pullLeagueData({
                  variables: {
                      where: {
                          league: { slug: { _eq: league_slug } },
                          club: { id: { _eq: homeClubId } },
                      },
                  },
              })
    }, [sport_name, homeClubId])

    return (
        <CustomSelect {...props}>
            {data.map((team, i) => (
                <Option value={team.id} key={`dd-team-${i}`}>
                    {team.name}
                </Option>
            ))}
        </CustomSelect>
    )
}

export default TeamsSelect
