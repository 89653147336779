import React from 'react'

import { WithContainer } from 'components/Container'
//  import sections
import { default as DisplaySection } from './Display'
import { Tabs } from 'antd'

const ApprovalsView: React.FC = () => {
    return (
        <>
            <WithContainer SectionView={DisplaySection}></WithContainer>
        </>
    )
}

export default ApprovalsView
