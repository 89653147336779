import styled, { css } from 'styled-components'
import { defaultTheme } from 'theme'

export const AsideWrapper = styled.div`
    background-color: #28272799;
    width: 25%;
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        display: flex;
        position: fixed;
        justify-content: center;
        padding-right: 60px; // This is to avoid putting buttons under the intercom widget
        width: calc(100vw);
        height: 60px;
        bottom: 0;
        left: 0;
        z-index: 100;
        overflow-x: auto;
        overflow-y: hidden;
        background-color: #282727;
    }
`

export const Scrollbar = styled.div``

export const MenuItem = styled.div<{ select: boolean }>`
    padding: 10px 0 10px 20px;
    cursor: pointer;
    align-items: center;
    svg {
        font-size: 1.25rem;
    }
    ${({ select }) => {
        if (select) {
            return css`
                background-color: #00000042;
                color: red;
                svg {
                    // color: yellow;
                }
            `
        }
    }};
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        padding: 8px 1px;
        text-align: center;
        flex: 1;
        p {
            padding-left: 20px;
            padding: 8px 0;
            font-size: 0.7rem;
        }
    }
    @media screen and (min-width: ${defaultTheme.mediaSize.md}px) {
        display: flex;
        p {
            padding: 8px 20px 0 20px;
        }
    }
`

export const MobileMenuText = styled.div`
    @media screen and (min-width: ${defaultTheme.mediaSize.md}px) {
        display: none;
    }
`

export const DeskMenuText = styled.div`
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        display: none;
    }
`
