import * as t from 'redux/types/clip'

const clipReducer = (
    state = {
        clip_list: [] as any,
        playbackId: '',
    },
    action: any,
) => {
    switch (action.type) {
        case t.SET_CLIP_LIST:
            return {
                ...state,
                clip_list: action.payload,
            }
        case t.SET_CURRENT_CLIP_PLAYBACKID:
            return {
                ...state,
                playbackId: action.payload,
            }
        default:
            return { ...state }
    }
}

export default clipReducer
