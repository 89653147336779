import Icon from '@ant-design/icons'
import Avatar from 'antd/lib/avatar/avatar'
import React from 'react'
import styled from 'styled-components'

const UserSvg = () => (
    <svg
        viewBox="0 0 535 518"
        width="1em"
        height="1em"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M241.771 1.98116C214.85 7.65716 192.142 19.9112 173.12 39.0262C146.525 65.7522 132.853 98.8992 132.711 137C132.571 174.198 146.282 207.236 172.989 234.056C190.913 252.056 210.616 263.428 235.528 270.151C247.232 273.31 247.97 273.382 268.5 273.382C288.904 273.382 289.829 273.293 301.109 270.245C330.687 262.251 354.216 247.268 372.947 224.5C393.611 199.38 404.004 170.165 403.997 137.215C403.992 114.784 400.217 96.9922 391.511 78.3612C372.697 38.1012 336.424 9.79116 293.14 1.58516C281.148 -0.687839 253.418 -0.474839 241.771 1.98116ZM240 300.677C223.727 302.335 209.193 304.388 198.815 306.494C137.048 319.034 87.9962 345.506 52.1182 385.663C23.3202 417.895 5.27318 461.929 1.46718 509.25L0.763184 518H267.882H535V509.099C535 498.686 532.181 481.074 527.999 465.365C518.425 429.4 501.955 400.326 476.042 373.647C436.312 332.743 381.637 308.735 311.778 301.52C298.403 300.139 250.772 299.579 240 300.677Z"
        />
    </svg>
)

const UserIcon = (props: any) => <Icon component={UserSvg} {...props} />

interface StyledProps {
    size?: any
}

interface AvatarProps extends StyledProps {
    src: any
}

const AvatarWrapper = styled.div`
    .ant-avatar > img {
        object-fit: contain;
    }
`

const StyledAvatar = styled(Avatar)`
    background-color: '#fff';
    border: 'solid .5px #878787';
`

const PlayerAvatar: React.FC<AvatarProps> = ({
    src,
    size = { xs: 90, sm: 100, md: 100, lg: 120, xl: 120, xxl: 120 },
}) => {
    return (
        <AvatarWrapper>
            <StyledAvatar
                size={size}
                src={src || null}
                icon={<UserIcon style={{ color: '#878787' }} />}
            />
        </AvatarWrapper>
    )
}

export default PlayerAvatar
