import React from 'react'
// type
import { IconProps } from 'types/components/Icon'

// -----------------------------------------------
const TWALogo: React.FC<IconProps> = ({
    iColor = '#FFFFFF',
    iSize = { x: 'auto', y: 'auto' },
}) => {
    return (
        <svg
            width={iSize.x}
            height={iSize.y}
            xmlns="http://www.w3.org/2000/svg"
            version="1.2"
            viewBox="484.99 521 1711.91 148"
        >
            <title>The West Australian</title>
            <g fill={iColor}>
                <path d="m1271.6 567.9v70.9l8 7.9 8.8-8.7 2.3 1.5-27 27.1-16.7-16.7-8 8.7-2.4-2.3 10.4-9.6v-78.8h-19.9l7.9-12.7h12v-13.5l25.4-19.1-0.8 32.6h21.5l-7.9 12.7z" />
                <path
                    fillRule="evenodd"
                    d="m1224.7 552l-31 44.6 23-7.2v55.7l-31 23.9c-1.6-3.2-4-5.6-7.1-8-12.5-8.6-29.6-5.7-38.5 6.5l34.5-48.6-18.3 5.5v-53.3c7.1 0 27.8-19.1 27.8-19.1 6.4 10.4 26.3 12 40.6 0zm-85.1 116.2q0.2-0.4 0.5-0.7zm52.5-54.1l-12.8 4-27.5 39.3c8.9-12.4 25.3-17.8 40.3-13.1zm-40.6 43.8l0.3-0.5q-0.1 0.3-0.3 0.5zm61.8-94.3c-7.2 10.1-19.8 14.7-31.6 11.5v25.5l7.2-2.4zm0.3-0.4l-0.3 0.4q0.1-0.2 0.3-0.4z"
                />
                <path
                    fillRule="evenodd"
                    d="m1142.8 633.2l-33.5 32.6-19.8-19.9-8.8 8.8-2.4-2.4 10.4-10.4v-54.9l34.2-34.2 25.4 26.3-35 35.8v20.7l10.4 11.1 15.1-15.1zm-29.5-21.5l16-15.9-16-15.9z"
                />
                <path
                    fillRule="evenodd"
                    d="m1079.1 639.5l-29.4 29.5-23.1-23.1c-19.1 29.4-63.7 27.9-83.5 4-20.7 25.4-61.3 22.3-80.4 0l-3.2-4.8c-19.1-25.4-4.8-55.7 41.4-93.1-15.9 0-22.3-4.7-32.6-4.7-6.4 0-11.2 1.5-11.2 6.3 0 4.8 2.4 6.4 12 9.6-12 0-17.5-4-17.5-15.9 0-12 10.3-19.9 24.6-19.9 12.8 0 22.3 5.5 33.4 6.3 7.2 0 13.6-3.2 18.3-7.9v96.2l-27 24.7v-91.5c-31 27.9-32.6 58.1-17.5 78 13.5 17.5 37.4 22.3 56.5 12.7-19.1-25.4-4.8-55.7 41.3-93.1-15.9 0-22.2-4.7-32.6-4.7-6.3 0-11.1 1.5-11.1 6.3 0 4.8 2.4 6.4 11.9 9.6-11.9 0-17.5-4-17.5-15.9 0-12 10.4-19.9 24.7-19.9 11.9 0 22.3 5.5 33.4 6.3 7.2 0 13.5-3.2 18.3-7.9v23.8l24.7-24.6 21.4 21.5 8.8-8.8 2.4 1.6-11.2 10.3v81.2l14.4 14.3 8.7-8.7zm-97.8 7.1v-91.5c-31.1 27.9-32.7 58.1-17.5 78 3.9 5.6 10.3 10.3 17.5 13.5zm44.5-4.8v-82.7l-11.1-11.1-6.4 6.3v68.5l-27 23.8c15.1 5.6 31.8 4 44.5-4.8z"
                />
                <path d="m713.1 637.2l2.4 2.3-26.2 26.3-19.9-19.9 2.4-2.4v-66l-5.6-5.6-14.3 14.3v51.8l7.9 8.7 5.6-5.6 2.4 2.4-23.1 23.1-19.9-19.9 2.4-2.4v-102.6c0-6.4-1.6-12.7-7.2-13.5 7.2-3.2 15.9-1.6 21.5 3.9 5.6-5.5 12.7-7.9 20.7-7.1-5.6 1.6-9.5 6.3-10.3 11.9v45.4l28.6-28.7 15.9 15.9 8.8-8.7 2.4 1.6-10.4 10.3v65.3l8 8.7z" />
                <path
                    fillRule="evenodd"
                    d="m569.9 559.2v54.1l-28.6 27.8c3.9 2.4 8.7 3.2 13.5 4 27.8 3.2 53.3-16.7 56.5-44.5 0.8 64.4-59.7 77.9-93.1 59.6-28.6-17.5-38.2-54.9-21.5-83.5-11.1-7.2-19.9-25.5 0.8-43.8 10.4-8.7 35-14.3 65.3-1.6 42.9 17.5 50.1-8.7 50.1-8.7 0 28.6-15.1 42.2-43 36.6zm-39.8-11.9c-18.3-3.2-33.4 0.8-38.2 7.1-4.7 6.4 0 14.3 6.4 19.9 8-11.9 19.1-20.7 31.8-27zm10.4 93.8v-89.9c-2.4-1.6-4.8-2.4-7.2-3.1-34.2 29.4-27.8 76.3 1.6 90.7 1.6 0.7 3.2 1.5 5.6 2.3z"
                />
                <path
                    fillRule="evenodd"
                    d="m752.9 614.1v20.7l10.3 11.1 15.2-15.1 2.4 2.4-33.5 32.6-19.9-19.9-8.7 8.8-2.4-2.4 10.3-10.4v-54.9l34.3-34.2 26.2 26.3zm14.3-18.3l-15.9-15.9v31.8z"
                />
                <path d="m1783.2 638l1.6 2.3-26.2 26.3-16.7-16.7-8.8 8.7-1.6-2.3 9.6-9.6v-64.4l-8.8-8.8-7.9 8.8-2.4-2.4 26.2-26.3 18.3 17.5 15.9-16.7 19.1 19.1-18.3 17.5-16.7-16.7v64.5l8 7.9z" />
                <path
                    fillRule="evenodd"
                    d="m1885.1 638l1.6 2.3-26.3 26.3-17.5-16.7v-11.9l-28.6 28.6c-24.7-20.7-23.1-53.3 15.9-73.2l-20.7-21.5c-4-3.2-4.8-9.5-1.6-13.5 3.2-4.8 10.3-6.4 15.1-3.2 4.8 3.2 6.4 10.4 3.2 15.1-4 4 5.6 7.2 15.1-2.4l11.9-11.9 14.4 13.5 8.7-8.7 1.6 2.4-8.7 8.7v66.9l7.9 7.9zm-41.4-37.4v-18.3l-7.9-7.9c-6.4 3.1-13.5 3.1-19.1 0zm0.8 32.6v-27.9l-9.6-9.5c-11.1 4-23 29.4-3.1 50.1z"
                />
                <path d="m1943.1 637.2l1.6 1.6-26.2 26.2-17.5-16.7-8.8 8.8-1.6-1.6 9.6-9.6v-104.2c0-6.4-1.6-12.7-7.2-13.5 7.2-3.2 15.9-1.6 21.5 3.9 5.6-5.5 12.7-7.9 20.7-7.1-5.6 1.6-9.6 6.3-10.4 11.9v101.1l8.8 8.7z" />
                <path
                    fillRule="evenodd"
                    d="m1480.9 638.8l-28.6 28.6-20.7-21.5-3.2-12.7-33.4 33.4c-12.8-16.7-25.5-27.8-44.6-27.8-7.1-0.8-14.3 4.7-15.1 11.9 0 7.9 10.3 15.1 19.1 15.9-11.1 0.8-23.1-4.8-23.1-15.9 0-15.1 18.3-24.7 34.2-24.7q3.6 0 7.2 0l23.1-49.3c-5.6-9.6-27.9-15.1-34.2-8.8 5.5-10.3 24.6-15.1 43.7-11.1-6.4-12.7-19.1-20.7-32.6-20.7-20.7 0-21.5 11.2-21.5 13.5 0 4 0.8 8.8 3.2 12-4-2.4-6.4-7.2-6.4-12 0.8-8.7 6.4-15.9 13.6-19.1 8.7-3.9 18.3-6.3 27.8-5.5 9.5 0 19.1 2.4 27 7.9l5.6-11.9c0.8 9.5 6.4 15.1 11.2 32.6l26.2 80.4 11.9 11.9 8.8-8.7zm-89.9-45.4h26.2l-10.3-33.4zm36.6 37.4l-4-16.7h-42.9l-5.6 11.9c14.3 1.6 28.6 8 39.8 16.7z"
                />
                <path
                    fillRule="evenodd"
                    d="m1656.7 552l-31 44.6 23.1-7.2v55.7l-31.1 23.9c-1.6-3.2-3.9-5.6-7.1-8-12.5-8.6-29.6-5.7-38.5 6.5l34.5-48.6-18.3 5.5v-53.3c7.2 0 27.8-19.1 27.8-19.1 7.2 10.4 27.1 12 40.6 0zm-85.1 116.2q0.2-0.4 0.5-0.7zm52.5-54.1l-12.7 4-27.6 39.3c8.9-12.4 25.4-17.8 40.3-13.1zm-40.6 43.8l0.3-0.5q-0.1 0.3-0.3 0.5zm62.6-94.3c-7.2 10.1-19.8 14.7-31.6 11.5v25.5l7.2-2.4zm0.3-0.4l-0.3 0.4q0.2-0.2 0.3-0.4z"
                />
                <path d="m1722 638l1.6 1.5-26.3 27.1-16.7-16.7-8.8 7.9-1.5-1.5 9.5-9.6v-78.8h-19.1l7.2-12.7h11.9v-13.5l25.5-19.1v32.6h20.6l-7.1 12.7h-13.5v70.9l7.9 7.9z" />
                <path
                    fillRule="evenodd"
                    d="m2091.1 638l1.6 2.3-26.2 26.3-17.5-16.7v-11.9l-28.7 28.6c-24.6-20.7-23.1-53.3 15.9-73.2l-20.7-21.5c-3.9-3.2-4.7-9.5-1.5-13.5 3.1-4.8 10.3-6.4 15.1-3.2 4.7 3.2 6.3 10.4 3.2 15.1-4 4 2.3 5.6 12.7-4.7l11.9-12 15.9 15.9 8.8-8.7 1.6 2.4-8.8 8.7v66.9l8 7.9zm-42.2-36.6v-19.1l-8.7-8.7c-5.6 3.9-12.8 3.9-18.3 0.8zm0.8 31.8v-27.9l-9.6-9.5c-11.1 4-23.1 29.4-3.2 50.1z"
                />
                <path d="m2196.9 640.3l-26.2 26.3-19.1-19.1v-66l-8-8.8-12.7 13.5v50.2l9.6 10.3 5.5-5.6 2.4 1.6-23.9 23.9-20.6-21.5 2.3-2.4-0.7-61.2-8-8-8.8 8-1.5-1.6 26.2-26.3 17.5 16.7v12l28.7-28.7 16.7 15.9 7.9-7.9 2.4 1.6-10.3 10.3v63.7l10.3 9.5 8-8.7z" />
                <path d="m1577.2 640.3l-26.3 26.3-16.7-16.7v-11.9l-29.4 28.6-16-16.7-8.7 8.7-2.4-1.5 10.4-10.4v-63.6l-9.6-9.6-8.7 8.8-1.6-2.4 25.4-26.3 19.1 18.3v66.9l8 7.9 13.5-12.7v-50.9l-10.3-9.6-5.6 5.6-1.6-2.4 23.1-23.1 19.1 18.3v66.9l7.9 7.9 8.8-8.7z" />
                <path d="m1998.8 638l1.6 2.3-26.2 26.3-16.7-16.7-8 7.9-2.4-1.5 8.8-9.6v-65.2l-8-8-8.7 8.8-1.6-2.4 26.2-26.3 15.9 15.9 8.8-8.7 1.6 1.6-8.8 9.5v66.9l8.8 7.9z" />
                <path d="m1951.4 537.2l12.9-12.9 13 12.9-13 13z" />
            </g>
        </svg>
    )
}
export default TWALogo
