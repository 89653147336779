import { Text } from 'components/Text'
import { useInputHOC } from 'components/hoc'
import {
    ClubAdminContext,
    ClubDetailsContext,
} from 'pages/club/[club_slug]/admin'
import React, { useContext, useEffect } from 'react'
import { ContentWrapper, HeadingWrapper } from '../components/DivWrappers'
import FormItemWrapper from '../components/FormItemWrapper'
import { Commmon } from './contact.style'

const ContactSection: React.FC = () => {
    const Input = useInputHOC(Commmon)
    const club = useContext(ClubAdminContext)
    const { clubDetails, setClubDetails } = useContext(ClubDetailsContext)
    useEffect(() => {
        setClubDetails({
            ...clubDetails,
            address_1: club?.address_1,
            address_2: club?.address_2,
            city: club?.city,
            state: club?.state,
            postcode: club?.postcode,
        })
    }, [club])

    return (
        <ContentWrapper>
            <HeadingWrapper>
                <Text fSize={1.5} fWeight={700} mode="h2" fColor="gray.600">
                    {' '}
                    {'Contact Details'}{' '}
                </Text>
                <Text fSize={0.875} fWeight={500} fColor="#E5E5E5">
                    {' '}
                    {'This information will not be shared publicly.'}{' '}
                </Text>
            </HeadingWrapper>

            <div>
                <FormItemWrapper label="Address Line 1">
                    <Input
                        iColor="primary"
                        iSize="small"
                        iFont="normal"
                        iRadius="small"
                        placeholder="Address Line 1"
                        defaultValue={club?.address_1}
                        onChange={(e: any) =>
                            setClubDetails({
                                ...clubDetails,
                                address_1: e.target.value,
                            })
                        }
                    />
                </FormItemWrapper>

                <FormItemWrapper label="Address Line 2">
                    <Input
                        iColor="primary"
                        iSize="small"
                        iFont="normal"
                        iRadius="small"
                        placeholder="Address Line 2"
                        defaultValue={club?.address_2}
                        onChange={(e: { target: { value: any } }) =>
                            setClubDetails({
                                ...clubDetails,
                                address_2: e.target.value,
                            })
                        }
                    />
                </FormItemWrapper>

                <FormItemWrapper label="City">
                    <Input
                        iColor="primary"
                        iSize="small"
                        iFont="normal"
                        iRadius="small"
                        placeholder="City"
                        defaultValue={club?.city}
                        onChange={(e: { target: { value: any } }) =>
                            setClubDetails({
                                ...clubDetails,
                                city: e.target.value,
                            })
                        }
                    />
                </FormItemWrapper>

                <FormItemWrapper label="State">
                    <Input
                        iColor="primary"
                        iSize="small"
                        iFont="normal"
                        iRadius="small"
                        placeholder="State"
                        defaultValue={club?.state}
                        onChange={(e: { target: { value: any } }) =>
                            setClubDetails({
                                ...clubDetails,
                                state: e.target.value,
                            })
                        }
                    />
                </FormItemWrapper>

                <FormItemWrapper label="PostCode">
                    <Input
                        iColor="primary"
                        iSize="small"
                        iFont="normal"
                        iRadius="small"
                        placeholder="PostCode"
                        defaultValue={club?.postcode}
                        onChange={(e: { target: { value: any } }) =>
                            setClubDetails({
                                ...clubDetails,
                                postcode: e.target.value,
                            })
                        }
                    />
                </FormItemWrapper>
            </div>
        </ContentWrapper>
    )
}

export default ContactSection
