import React from 'react'
import styled from 'styled-components'
import { AiOutlineLoading3Quarters } from 'react-icons/ai'

interface Props {
    size?: number
    color?: string
}

const Icon = styled(AiOutlineLoading3Quarters)`
    margin-left: 5px;
    display: inline-block;
    animation: spin 2s linear infinite;
    @keyframes spin {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
`

const LoadingIcon: React.FC<Props> = ({ size = 16, color = '#fff' }) => {
    return <Icon size={size} color={color} />
}

export default LoadingIcon
