import { Row } from 'components/Layout'
import { Text } from 'components/Text'
import React from 'react'
import { DigitalFanStatus, FollowStatus } from './UserStatus.style'

const UserStatus = (props: {
    status:
        | string
        | number
        | boolean
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | Iterable<React.ReactNode>
        | null
        | undefined
}) => {
    if (props.status === 'Digital Fan & Follower')
        return (
            <Row>
                <DigitalFanStatus>
                    <Text fSize={1} fWeight={600} fColor="yellow.regular">
                        Digital Fan
                    </Text>
                </DigitalFanStatus>
                <FollowStatus>
                    <Text fSize={1} fWeight={600} fColor="blue.light">
                        Follower
                    </Text>
                </FollowStatus>
            </Row>
        )

    if (props.status === 'Digital Fan')
        return (
            <DigitalFanStatus>
                <Text fSize={1} fWeight={600} fColor="yellow.regular">
                    {props.status}
                </Text>
            </DigitalFanStatus>
        )

    if (props.status === 'Follower')
        return (
            <FollowStatus>
                <Text fSize={1} fWeight={600} fColor="blue.light">
                    {props.status}
                </Text>
            </FollowStatus>
        )

    return <></>
}

export default UserStatus
