import * as t from 'redux/types/match'

const matchReducer = (
    state = {
        info: {} /** TODO: infer type */,
        replay_list: [],
        live_list: [],
        match_time: '',
        match_segment: '',
    },
    action: any,
) => {
    switch (action.type) {
        case t.SET_MATCH_INFO:
            return { ...state, info: action.payload }
        case t.SET_REPLAY_MATCHES:
            return { ...state, replay_list: action.payload }
        case t.SET_LIVE_MATCHES:
            return { ...state, live_list: action.payload }
        case t.SET_MATCH_TIME:
            return { ...state, match_time: action.payload }
        case t.SET_MATCH_SEGMENT:
            return { ...state, match_segment: action.payload }
        default:
            return { ...state }
    }
}

export default matchReducer
