import React from 'react'

// type
import { IconProps } from 'types/components/Icon'

const ActivityFeedPlayIcon: React.FC<IconProps> = ({
    iColor = '#818181',
    iSize = { x: 38, y: 36 },
}) => {
    return (
        <svg
            width={iSize.x}
            height={iSize.y}
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            fill={iColor}
        >
            <g>
                <path fill="transparent" d="M0 0h24v24H0z" />
                <path d="M3 3.993C3 3.445 3.445 3 3.993 3h16.014c.548 0 .993.445.993.993v16.014a.994.994 0 0 1-.993.993H3.993A.994.994 0 0 1 3 20.007V3.993zM5 5v14h14V5H5zm5.622 3.415l4.879 3.252a.4.4 0 0 1 0 .666l-4.88 3.252a.4.4 0 0 1-.621-.332V8.747a.4.4 0 0 1 .622-.332z" />
            </g>
        </svg>
    )
}

export default ActivityFeedPlayIcon
