import { gql } from '@apollo/client'

const SUB_ALL_LEAGUES = gql`
    subscription SubAllLeagues($where: leagues_bool_exp = {}) {
        leagues(order_by: { order: asc }, where: $where) {
            id
            logo
            name
            slug
            status
        }
    }
`

const SUB_ALL_PLAYERS = gql`
    subscription subAllPlayers($where: players_details_bool_exp = {}) {
        players_details(where: $where) {
            id
            image
            slug
            is_accepted
            is_declined
            email_address
            name
            last_name
            team {
                name
                slug
            }
            user {
                first_name
                last_name
                photo
            }
            club {
                id
                name
                slug
            }
        }
    }
`

export default {
    SUB_ALL_LEAGUES,
    SUB_ALL_PLAYERS,
}
