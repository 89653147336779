import { useQuery } from '@apollo/client'
import { query } from 'graphql/sports'
import React from 'react'
import { CustomSelect } from './dropdown.style'

const SportsDropDown: React.FC<{
    value?: string
    onChange?: (e: any) => void
    defaultValue?: string
}> = (props) => {
    const { loading, data } = useQuery(query.GET_SPORTS)
    if (loading) {
        return <></>
    }
    const sports = data.sports.map((item: any) => ({
        label: item.name,
        value: item.id,
    }))
    return (
        <CustomSelect placeholder="Select Sports" options={sports} {...props} />
    )
}

export default SportsDropDown
