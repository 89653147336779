import { themeGet } from '@styled-system/theme-get'
import { Button } from 'components/Button'
import styled, { css } from 'styled-components'
import { defaultTheme } from 'theme'

export interface SectionWrapperProps {
    display?: 'grid' | 'flex' | 'block' | 'none'
    flexDirection?: 'row' | 'column' | 'row-reverse' | 'column-reverse'
    justifyContent?:
        | 'center'
        | 'flex-start'
        | 'flex-end'
        | 'initial'
        | 'space-around'
        | 'space-between'
        | 'stretch'
    alignItems?: 'center' | 'flex-start' | 'flex-end' | 'initial' | 'stretch'
    padding?: string
    gap?: string
}

export const ModalWrapper = styled.div<{ show: boolean }>`
    position: fixed;
    z-index: 51;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6);
    ${({ show }) =>
        show
            ? css`
                  visibility: visible;
              `
            : css`
                  visibility: hidden;
              `};

    &:hover #modal-exit-button {
        color: ${themeGet('colors.gray.400')};
    }
    cursor: pointer;
`

export const ModalContent = styled.div<{ show: boolean }>`
    border-radius: 4px;
    transition: all ease 0.5s;
    ${({ show }) =>
        show
            ? css`
                  display: block;
              `
            : css`
                  display: none;
              `};
    padding: 0;
    position: fixed;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 88%;

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        width: 92.5%;
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        width: 95%;
    }
`

export const ModalBody = styled.div`
    margin: auto;
    max-width: 500px;
    padding: 10px 25px;
    background-color: ${themeGet('colors.gray.900')};

    &:hover #modal-exit-button {
        color: ${themeGet('colors.gray.100')};
    }
    cursor: default;
`

export const SectionWrapper = styled.div<SectionWrapperProps>`
    display: ${({ display = 'flex' }) => display};
    flex-direction: ${({ flexDirection = 'row' }) => flexDirection};
    justify-content: ${({ justifyContent = 'flex-start' }) => justifyContent};
    align-items: ${({ alignItems = 'flex-start' }) => alignItems};
    padding: ${({ padding }) => padding || '0'};
    gap: ${({ gap }) => gap || '0'};
`

export const ModalHeader = styled.div`
    padding-bottom: 10px;
    display: flex;
    justify-content: right;
`

export const ModalFooter = styled.div`
    padding: 20px;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    background: ${themeGet('colors.black.300')};
    .footer-text {
        font-size: 24px;
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        padding: 15px;
        .footer-text {
            font-size: 20px;
        }
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        padding: 10px;
        .footer-text {
            font-size: 14.5px;
        }
    }
`

export const ModalExitButton = styled(Button)`
    border: none;
    padding: 0;
    margin-right: auto;
    margin-left: 0;

    p {
        font-weight: 200;
    }

    color: ${themeGet('colors.gray.100')};
    &:hover,
    &:focus {
        color: ${themeGet('colors.gray.400')} !important;
    }
`

export const ModalExitButtonContents = styled.div`
    display: flex;
    gap: 5px;
    align-items: center;
`

export const SubscribeButton = styled(Button)`
    margin-bottom: 12px;
`
