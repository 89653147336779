// styled component
import styled from 'styled-components'
// -------------------------------------------------

export const GlobalHeaderWrapper = styled.div`
    background-color: #1c1b1d;
    height: 35px;
    @media screen and (max-width: 768px) {
        height: 31px;
    }
`

export const StyledTWLogoWrapHeading = styled.h2`
    & a {
        width: 100%;
        display: flex;
        justify-content: center;
    }
`

export const TheWestAustralianLogoWrapper = styled.div`
    padding: 9px;
`
