import styled from 'styled-components'
import { defaultTheme } from 'theme'

export const DisplayWrapper = styled.div`
    padding: 30px 0;
`

export const StatusStyle = styled.div<{ type: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border-radius: 5px;
    ${({ type }) => {
        if (type) return `background-color: #38a24d3b;`
        return `background-color: #fb01011f;`
    }};
`
export const AddplayerStyle = styled.div`
    @media screen and (min-width: ${defaultTheme.mediaSize.md}px) {
        position: absolute;
        top: -60px;
        width: 100%;
    }
`
