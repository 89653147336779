import { useSubscription } from '@apollo/client'
import CirclePlayIcon from 'assets/icon/circle_play'
import { Button } from 'components/Button'
import { Col, Row } from 'components/Layout'
import { Text } from 'components/Text'
import { CLIPS } from 'graphql/club'
import _ from 'lodash'
import { ClubAdminContext } from 'pages/club/[club_slug]/admin'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { FeaturedClip } from 'types/components/FeaturedClip'
import { isServer } from 'utils/helper'
import ApproveClipModal from './Modals/ApproveClipModal'
import PlayClipModal from './Modals/PlayClipModal'
import RejectClipModal from './Modals/RejectClipModal'
import { AntTab, RowItem, StyledText } from './display.style'

const DisplaySection: React.FC = () => {
    const club = useContext(ClubAdminContext)
    const [clips, setClips] = useState<null | Array<any>>(null)
    const [showPlayer, setShowPlayer] = useState(false)
    const [selectedClip, setSelectedClip] = useState<null | FeaturedClip>()
    const [approveModal, setApproveModal] = useState({ show: false, clipId: 0 })
    const [rejectModal, setRejectModal] = useState({ show: false, clipId: 0 })

    const { loading, error, data } = useSubscription(CLIPS.SUB_FEATURE_CLIPS, {
        skip: !club.id || isServer,
        variables: {
            where: {
                show_on_club: { _eq: true },
                club_id: { _eq: club.id },
                clip_asset: {
                    approval_status: { _eq: 'pending' },
                },
            },
        },
        onData: ({ data: { data, loading } }) => {
            if (data.clip_asset_user_club) {
                const formatedClips = data?.clip_asset_user_club.map(
                    (clipAsset: {
                        clip_asset: {
                            id: any
                            match: {
                                league: { name: any }
                                id: any
                                home_team: {
                                    club: { display_name: any; name: any }
                                }
                                away_team: {
                                    club: { display_name: any; name: any }
                                }
                                round_name: any
                            }
                            event_stream: { league: { name: any } }
                            playback_id: string
                            asset_id: any
                            static_file_status: any
                            name: any
                            user: { first_name: any; last_name: any }
                        }
                        club_id: any
                    }) => ({
                        id: clipAsset.clip_asset.id,
                        leagueName: clipAsset?.clip_asset?.match
                            ? clipAsset?.clip_asset?.match?.league?.name
                            : clipAsset?.clip_asset?.event_stream?.league?.name,
                        clubId: clipAsset.club_id,
                        playbackId: clipAsset.clip_asset.playback_id,
                        assetId: clipAsset.clip_asset.asset_id,
                        matchId: clipAsset.clip_asset.match?.id,
                        staticFileStatus:
                            clipAsset.clip_asset.static_file_status,
                        backgroundImage: {
                            src:
                                'https://image.mux.com/' +
                                clipAsset.clip_asset.playback_id +
                                '/thumbnail.png?width=500',
                            height: 314,
                            width: 178,
                        },
                        title: clipAsset.clip_asset.name,
                        desc: _.isNull(clipAsset.clip_asset.match)
                            ? ''
                            : clipAsset.clip_asset.match?.home_team.club
                                    .display_name ===
                                clipAsset.clip_asset.match?.away_team.club
                                    .display_name
                              ? `${clipAsset.clip_asset.match?.home_team.club.display_name} - ${clipAsset.clip_asset.match?.round_name}`
                              : `${clipAsset.clip_asset.match?.home_team.club.display_name} vs ${clipAsset.clip_asset.match?.away_team.club.display_name} - ${clipAsset.clip_asset.match?.round_name}`,
                        homeClubName: _.isNull(clipAsset.clip_asset.match)
                            ? ''
                            : clipAsset?.clip_asset.match?.home_team?.club
                                  ?.name,
                        awayClubName: _.isNull(clipAsset.clip_asset.match)
                            ? ''
                            : clipAsset?.clip_asset.match?.away_team?.club
                                  ?.name,
                        submittedBy: clipAsset?.clip_asset?.user
                            ? `${clipAsset?.clip_asset?.user?.first_name} ${clipAsset?.clip_asset?.user?.last_name}`
                            : 'N/A',
                    }),
                )

                setClips(formatedClips)
            }
        },
    })

    const handleClipClick = (item: FeaturedClip) => {
        setSelectedClip(item)
        setShowPlayer(true)
    }

    return (
        <>
            <AntTab
                defaultActiveKey="1"
                size={'large'}
                items={[
                    {
                        label: `Clips`,
                        key: '1',
                        children: (
                            <>
                                <div style={{ overflow: 'auto' }}>
                                    <HeaderRow>
                                        <RowItem>
                                            <StyledText fSize={1}>
                                                Clip
                                            </StyledText>
                                        </RowItem>
                                        <RowItem>
                                            <StyledText fSize={1}>
                                                Clip Name
                                            </StyledText>
                                        </RowItem>
                                        <RowItem>
                                            <StyledText fSize={1}>
                                                Submitted by
                                            </StyledText>
                                        </RowItem>
                                        <RowItem>
                                            <StyledText fSize={1}>
                                                Action
                                            </StyledText>
                                        </RowItem>
                                    </HeaderRow>

                                    <div>
                                        {!_.isNull(clips) && clips.length > 0
                                            ? clips.map(
                                                  (item: FeaturedClip) => (
                                                      <Col key={item.id}>
                                                          <DataRow>
                                                              <RowItem>
                                                                  <ClipThumbnail
                                                                      playbackId={
                                                                          item.playbackId
                                                                      }
                                                                      onClick={() =>
                                                                          handleClipClick(
                                                                              item,
                                                                          )
                                                                      }
                                                                  >
                                                                      <CirclePlayIcon />
                                                                  </ClipThumbnail>
                                                              </RowItem>
                                                              <RowItem>
                                                                  <Text
                                                                      fColor="white"
                                                                      fSize={1}
                                                                      responsive={{
                                                                          480: {
                                                                              fSize: 0.875,
                                                                          },
                                                                      }}
                                                                  >
                                                                      {
                                                                          item.title
                                                                      }
                                                                  </Text>
                                                              </RowItem>
                                                              <RowItem>
                                                                  <Text
                                                                      fColor="white"
                                                                      fSize={1}
                                                                      responsive={{
                                                                          480: {
                                                                              fSize: 0.875,
                                                                          },
                                                                      }}
                                                                  >
                                                                      {
                                                                          item.submittedBy
                                                                      }
                                                                  </Text>
                                                              </RowItem>
                                                              <RowItem>
                                                                  <ApproveButton
                                                                      onClick={() =>
                                                                          setApproveModal(
                                                                              {
                                                                                  show: true,
                                                                                  clipId: item.id,
                                                                              },
                                                                          )
                                                                      }
                                                                  >
                                                                      <Text
                                                                          fColor="white"
                                                                          fSize={
                                                                              1
                                                                          }
                                                                          responsive={{
                                                                              480: {
                                                                                  fSize: 0.875,
                                                                              },
                                                                          }}
                                                                      >
                                                                          Approve
                                                                      </Text>
                                                                  </ApproveButton>
                                                                  <ApproveButton
                                                                      onClick={() =>
                                                                          setRejectModal(
                                                                              {
                                                                                  show: true,
                                                                                  clipId: item.id,
                                                                              },
                                                                          )
                                                                      }
                                                                  >
                                                                      <Text
                                                                          fColor="white"
                                                                          fSize={
                                                                              1
                                                                          }
                                                                          responsive={{
                                                                              480: {
                                                                                  fSize: 0.875,
                                                                              },
                                                                          }}
                                                                      >
                                                                          Reject
                                                                      </Text>
                                                                  </ApproveButton>
                                                              </RowItem>
                                                          </DataRow>
                                                      </Col>
                                                  ),
                                              )
                                            : !loading && (
                                                  <NoClips>
                                                      <Text
                                                          fSize={2}
                                                          fColor={'white'}
                                                          fWeight={500}
                                                      >
                                                          NO CLIPS
                                                      </Text>
                                                  </NoClips>
                                              )}
                                    </div>
                                </div>
                            </>
                        ),
                    },
                ]}
            />

            <PlayClipModal
                show={showPlayer}
                setShow={setShowPlayer}
                setSelectedClip={setSelectedClip}
                clipInfo={selectedClip}
                handleShowAcceptModal={setApproveModal}
                handleShowRejectModal={setRejectModal}
            />

            <ApproveClipModal
                show={approveModal.show}
                handleShow={setApproveModal}
                clipId={approveModal.clipId}
                handleShowPlayer={setShowPlayer}
                setSelectedClip={setSelectedClip}
            />
            <RejectClipModal
                show={rejectModal.show}
                handleShow={setRejectModal}
                clipId={rejectModal.clipId}
                handleShowPlayer={setShowPlayer}
                setSelectedClip={setSelectedClip}
            />
        </>
    )
}

export default DisplaySection

const HeaderRow = styled(Row)`
    height: 40px;
    border-radius: 9px;
    background-color: rgb(45 43 43);
    padding: 0 30px;
    min-width: 68em;
`

const DataRow = styled(Row)`
    width: 100%;
    height: 120px;
    border-radius: 9px;
    padding: 0 30px;
`

const ClipThumbnail = styled.div<{ playbackId: string }>`
    display: flex;
    width: 160px;

    display: flex;
    width: 160px;
    height: 90px;
    background-color: rgb(53, 52, 52);
    border-radius: 4px;
    overflow: hidden;
    background-image: ${({ playbackId }) =>
        `url(https://image.mux.com/${playbackId}/thumbnail.png)`};
    background-size: 160px 90px;
    align-items: center;
    justify-content: center;
`

const ApproveButton = styled(Button)`
    border: 1px solid rgba(255, 255, 255, 0.22);
    border-radius: 8.5px;
    width: 113px;
    height: 38px;
    transition: none;
`

const NoClips = styled.div`
    display: flex;
    margin-left: 40%;
`
