import defaultImg from 'assets/images/home/WAFL.png'
import axios from 'axios'
import imageExists from 'image-exists'
import _ from 'lodash'
import { StaticImageData } from 'next/legacy/image'
import slugify from 'slugify'
import { encodedStreamerEmail, s3Config } from './constData'
/**
 *
 * @param src : image url ;
 * @param fallbackImg: fallback image
 * @returns string | StaticImageData
 */
export const remoteImageSrc = (
    src: string | StaticImageData = defaultImg,
    fallbackImg: string | StaticImageData = defaultImg,
): string | StaticImageData =>
    imageExists(src, (e: any) => e) ? src : fallbackImg

export const thumbNailLink = (
    id: string,
    width = 300,
    thumbnail_url: string | null = null,
): string | StaticImageData => {
    const image_url = `https://image.mux.com/${id}/thumbnail.jpg?width=${width}`
    // NOTE: if this cant be fix leave image_url as return
    // return thumbnail_url && imageExists(thumbnail_url, (e: any)=> e)? thumbnail_url: imageExists(image_url, (e: any) => e) ? image_url : defaultImg;

    if (thumbnail_url) {
        return thumbnail_url
    }

    return image_url
}

export const getS3Config = (dirName = 'Club') => ({
    ...s3Config,
    dirName,
})

export const isLive = (date: string, status: string) => {
    const now = new Date()
    const tenMinutesBeforeStart = new Date(date)
    tenMinutesBeforeStart.setMinutes(tenMinutesBeforeStart.getMinutes() - 10)

    return now > tenMinutesBeforeStart && ['active'].includes(status)
}

export const slugifyString = (str: string): string => {
    const config = {
        lower: true,
        strict: true,
    }
    return slugify(str, config)
}

export const isMp4AssetExist = (playbackId: string | undefined) =>
    new Promise<boolean>((resolve, reject) => {
        if (_.isUndefined(playbackId)) {
            return reject(false)
        }

        axios
            .get(`https://stream.mux.com/${playbackId}/low.mp4`)
            .then((res) => {
                resolve(true)
            })
            .catch((err) => {
                reject(false)
            })
    })

export const readImageFile = (file: any) => {
    return new Promise((resolve) => {
        const reader = new FileReader()
        reader.addEventListener('load', () => resolve(reader.result), false)
        reader.readAsDataURL(file)
    })
}

export const getBackgroundImage = (sport?: string) => {
    let backgroundImageUrl = ''

    switch (sport) {
        case 'Hockey':
            backgroundImageUrl =
                'https://assets.streamer.com.au/Thumbnails/Purple+Background.png'
            break
        case 'Soccer':
            backgroundImageUrl =
                'https://assets.streamer.com.au/Thumbnails/Purple+Background.png'
            break
        case 'Rugby':
            backgroundImageUrl =
                'https://assets.streamer.com.au/Thumbnails/Red+Background.png'
            break
        default:
            backgroundImageUrl =
                'https://assets.streamer.com.au/Thumbnails/Blue+Background.png'
            break
    }

    return backgroundImageUrl
}

export const getSegmentLabel = (value: string) => {
    let segmentLabel = ''

    switch (value) {
        case '2':
            segmentLabel = 'H'
            break

        case '3':
            segmentLabel = 'T'
            break

        default:
            segmentLabel = 'Q'
            break
    }
    return segmentLabel
}

export const getCurrentPageName = (path: string) => {
    let pageName = ''

    switch (path) {
        case '/':
            pageName = 'Home Page'
            break

        case '/screamer':
            pageName = 'Screamer Page'
            break

        case '/league/[league_slug]':
            pageName = 'League Page'
            break

        case '/club/[club_slug]':
            pageName = 'Club Page'
            break

        case '/league/[league_slug]/match/[asset_id]':
            pageName = 'GameDay Page'
            break

        case '/club/[club_slug]/live':
            pageName = 'Live & Upcoming Page'
            break

        case '/club/[club_slug]/replays':
            pageName = 'Replays Page'
            break

        case '/club/[club_slug]/teams':
            pageName = 'Teams Page'
            break

        case '/club/[club_slug]/players':
            pageName = 'Players Page'
            break

        case '/club/[club_slug]/player/[player_slug]':
            pageName = 'Player Profile Page'
            break

        case '/profile':
            pageName = 'Profile Page'
            break

        default:
            pageName = path
            break
    }
    return pageName
}

/**
 * Combine arrays of events and matches, and sort in reverse chronological order
 */
export function combineEventsAndMatches(
    eventsData: any[] | undefined | null,
    matchesData: any[] | undefined | null,
) {
    const data = [...(eventsData ?? []), ...(matchesData ?? [])]

    return data.sort(
        (a, b) => Date.parse(b.start_datetime) - Date.parse(a.start_datetime),
    )
}

/**
 * objectToArray
 * @param obj const obj = {
 *   key1: "value1",
 *   key2: ["value2", "value3"],
 *   key3: "value4",
 * };
 * @returns [["key1", ["value1"]], ["key2", ["value2", "value3"]], ["key3", ["value4"]]]
 */
export const objectToArray = (obj: { [key: string]: any }) => {
    const result: ([string, string] | [string, string[]])[] = []

    for (const key in obj) {
        if (Array.isArray(obj[key])) {
            result.push([key, obj[key]])
        } else {
            result.push([key, [obj[key]]])
        }
    }

    return result
}

export const getPageSlugName = (path: string) => {
    let slotName = ''

    switch (path) {
        case '/':
            slotName = 'home-page'
            break

        case '/league/[league_slug]':
            slotName = 'league-page'
            break
        case '/match/[asset_id]':
            slotName = 'match-page'
            break
        case '/event/[event_id]/[event_slug]':
            slotName = 'match-page'
            break

        case '/club/[club_slug]/replay/[asset_id]':
            slotName = 'match-page'
            break
        case '/club/[club_slug]/match/[asset_id]':
            slotName = 'match-page'
            break
        case '/league/[league_slug]/replay/[asset_id]':
            slotName = 'match-page'
            break
        case '/league/[league_slug]/match/[asset_id]':
            slotName = 'match-page'
            break

        case '/screamer':
            slotName = 'screamer-page'
            break

        case '/club/[club_slug]':
            slotName = 'club-page'
            break

        case '/club/[club_slug]/replays':
            slotName = 'replay-page'
            break

        case '/club/[club_slug]/teams':
            slotName = 'teams-page'
            break

        case '/club/[club_slug]/players':
            slotName = 'players-page'
            break

        case '/club/[club_slug]/player/[player_slug]':
            slotName = 'player-profile-page'
            break

        case '/community/[community_slug]':
            slotName = 'community-page'
            break

        case '/profile':
            slotName = 'profile-page'
            break

        default:
            slotName = 'generic'
            break
    }
    return slotName
}

export const compareTitles = (a: { title: any }, b: { title: any }) => {
    // Extract the titles from the objects
    const titleA = a.title
    const titleB = b.title

    // Check if titles contain "- Home"
    const isHomeA = titleA.includes('- Home')
    const isHomeB = titleB.includes('- Home')

    // Sort the objects based on whether they contain "- Home" in title
    if (isHomeA && !isHomeB) {
        return -1
    } else if (!isHomeA && isHomeB) {
        return 1
    } else {
        return 0
    }
}

export const openMailTo = (emailSubject: string, emailBody: string) => {
    const decodedEmail = Buffer.from(encodedStreamerEmail, 'base64')

    const subject = encodeURI(emailSubject)

    const body = encodeURI(emailBody)

    window.location.href = `mailto:${decodedEmail}?subject=${subject}&body=${body}`
}

export const getFullEventName = (
    club: string | undefined,
    eventTitle: string | undefined,
) => {
    return `${club ?? 'General'} Event - ${eventTitle ?? 'Untitled'}`
}

export function getFullMatchName(
    home: string | undefined,
    away: string | undefined,
    roundTitle: string | undefined,
) {
    const formattedRoundTitle = roundTitle ?? 'Untitled'
    if (!home && away) {
        return `${home} (${formattedRoundTitle})`
    }
    if (home && !away) {
        return `${home} (${formattedRoundTitle})`
    }
    if (!home && !away) {
        return `Match (${formattedRoundTitle})`
    }
    return `${home} vs ${away} (${formattedRoundTitle})`
}

export function getEventDescription(club: string, matchStart: string) {
    return `Watch all the action from the ${club} event on ${matchStart}.`
}

export function getMatchDescription(
    league: string,
    home: string,
    away: string,
    round: string,
    matchStart: string,
) {
    return `Watch all the action from the ${league} game between ${home} and ${away} (${round}), on ${matchStart}`
}

export function pluralize(word: string) {
    // You never know when you'll need to pluralize 'goose' on streamer.com.au
    const exceptions: { [key: string]: string } = {
        person: 'people',
        child: 'children',
        man: 'men',
        woman: 'women',
        tooth: 'teeth',
        foot: 'feet',
        goose: 'geese',
        mouse: 'mice',
        deer: 'deer',
        sheep: 'sheep',
    }

    if (word.toLowerCase() in exceptions) {
        let plural = exceptions[word.toLowerCase()]
        if (word[0] === word[0].toUpperCase()) {
            return plural[0].toUpperCase() + plural.slice(1)
        }
        return plural
    }

    if (
        word.endsWith('s') ||
        word.endsWith('sh') ||
        word.endsWith('ch') ||
        word.endsWith('x') ||
        word.endsWith('z')
    ) {
        return word + 'es'
    } else if (
        word.endsWith('y') &&
        !'aeiou'.includes(word.charAt(word.length - 2))
    ) {
        return word.slice(0, -1) + 'ies'
    } else {
        return word + 's'
    }
}

export function getCustomStreamMetadata(id: number, type: 'MATCH' | 'EVENT') {
    let title, description, image

    if (type === 'MATCH') {
        if (id === 2059) {
            title = 'Rowing WA Corporate Challenge'
            description =
                'Roy Hill, Bellevue Gold and Rail Systems Australia take to the water to crown the 2023 champions.'
            image =
                'https://assets.streamer.com.au/thumbnails/rowing-wa-image.jpeg'
        } else if (id === 2133) {
            title = "2023 Bowra & O'Dea Women's Classic"
            description =
                "Watch all the action from Hole 16 on the final day of WA's most prestigious women's stroke play event. "
            image =
                'https://assets.streamer.com.au/thumbnails/golf-wa-event-image.png'
        }
    }

    return { title, description, image }
}

export function getStreamPagePath(
    values: { matchId: number } | { eventId: number; eventSlug: string },
) {
    return 'matchId' in values
        ? `${process.env.NEXT_PUBLIC_BASE_URL}/match/${values.matchId}`
        : `${process.env.NEXT_PUBLIC_BASE_URL}/event/${values.eventId}/${values.eventSlug}`
}
