import { useLazyQuery, useSubscription } from '@apollo/client'
import { getSession, withPageAuthRequired } from '@auth0/nextjs-auth0'
import { initializeApolloFromContext } from 'api/apollo'
import { SectionContainer, WithContainer } from 'components/Container'
import { Page } from 'components/Page'
import { ADMINQL, HomeQL, TEAMQL } from 'graphql/club'
import { query as LEAGUEQL } from 'graphql/leagues'
import { useRouter } from 'next/router'
import React, { createContext, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { setLeagueInfo } from 'redux/actions/league'
import { setUserInfo } from 'redux/actions/user'
import { ContentView, HeadView } from 'sections/league/home/admin'
import { LeagueCtx } from 'types/common/club'
import { USER_ROLE } from 'utils/constData'
import { isServer } from 'utils/helper'
import { fetchUserInfo } from 'utils/helper-user-info'

export const LeagueContext = createContext<any>({})
export const LeagueDetailsContext = createContext<any>({})
export const LeagueListContext = createContext<Partial<Array<LeagueCtx>>>([])
export const ClubListContext = createContext<any>({})

const AdminPage: React.FC = (props: any) => {
    const { title, userInfo } = props
    const {
        query: { league_slug },
    } = useRouter()

    const dispatch = useDispatch()

    /** Convert Context to redux */
    const [league, setLeague] = useState<any>({})
    const [leaguesList, setLeaguesList] = useState<any>({})
    const [clubsList, setClubsList] = useState<any>({})
    const [leagueDetails, setLeagueDetails] = useState<any>({})

    useEffect(() => {
        userInfo && dispatch(setUserInfo(userInfo))
    }, [])

    const [getLeagueInfoWithAdminsDetails] = useLazyQuery(
        LEAGUEQL.GET_LEAGUE_ADMINS_DETAILS,
        {
            onCompleted(data) {
                if (data) {
                    dispatch(setLeagueInfo(data.leagues[0]))
                    setLeague(data.leagues[0])
                }
            },
        },
    )

    useEffect(() => {
        league_slug &&
            getLeagueInfoWithAdminsDetails({
                variables: {
                    where: {
                        slug: { _eq: league_slug },
                    },
                },
            })
    }, [league_slug])

    useSubscription(ADMINQL.SUB_ALL_CLUBS, {
        skip: isServer || !league_slug,
        variables: {
            where: { teams: { league: { slug: { _eq: league_slug } } } },
        },
        onData: ({ data: { data, loading } }) => {
            !loading && data && setClubsList(data.clubs)
        },
    })

    useSubscription(TEAMQL.SUB_LEAGUES, {
        skip: isServer || !league_slug,
        variables: {
            where: {
                slug: { _eq: league_slug },
            },
        },
        onData: ({ data: { data, loading } }) => {
            !loading && data && setLeaguesList(data.leagues)
        },
    })

    const values = {
        leagueDetails,
        setLeagueDetails,
    }

    return (
        <LeagueListContext.Provider value={leaguesList}>
            <ClubListContext.Provider value={clubsList}>
                <LeagueContext.Provider value={league}>
                    <LeagueDetailsContext.Provider value={values}>
                        <Page title={title}>
                            <main>
                                <SectionContainer
                                    display="block"
                                    elementProps={{ bgColor: 'black.200' }}
                                    SectionView={HeadView}
                                />
                                <WithContainer
                                    cColor="black.200"
                                    SectionView={ContentView}
                                />
                            </main>
                        </Page>
                    </LeagueDetailsContext.Provider>
                </LeagueContext.Provider>
            </ClubListContext.Provider>
        </LeagueListContext.Provider>
    )
}

export const getServerSideProps = withPageAuthRequired({
    async getServerSideProps(context) {
        const apolloClient = initializeApolloFromContext(context)
        const { league_slug } = context.query
        const sess: any = getSession(context.req, context.res)
        const role_name = sess?.user?.role?.name
        const user = sess?.user

        const userInfo = user && (await fetchUserInfo(apolloClient, user))

        const { data } = await apolloClient.query({
            query: HomeQL.GET_LEAGUE,
            variables: {
                where: {
                    slug: { _eq: league_slug },
                },
            },
        })

        const league = data && data.leagues[0]
        //check if user is league admin
        const isLeagueAdmin = userInfo.league_admin_details.some(
            (leagueElement: any) => leagueElement.league_id === league.id,
        )
        //check if user is super admin
        const isSuperAdmin = userInfo.user_role_id === USER_ROLE.ADMIN

        if (!isLeagueAdmin && !isSuperAdmin) {
            return {
                redirect: {
                    permanent: true,
                    destination: `/league/${league_slug}`,
                },
            }
        }

        return {
            props: {
                title: `${league.name} Admin`,
                userInfo: userInfo ?? null,
            },
        }
    },
})
export default AdminPage
