import { DataLayerEvents } from './event-types'

interface GtmEvent {
    event: string
    // Variables only relevant for the current event
    eventVariables?: Record<string, unknown>
    // Variables relevant to the current page
    pageVariables?: Record<string, unknown>
    // Variables relevant across the entire session
    permanentVariables?: Record<string, unknown>
}

export interface DataLayerEvent {
    event: string
    [key: string]: unknown
}

// Pseudo-array created by GTM to push events onto
declare const dataLayer: Array<DataLayerEvent>
declare const googleTagManager: any

/**
 * GTM aggregates existing values that are included on each event
 * This function resets all variables to allow us to start clean for each page
 */
export const resetDataLayerProperties = (gtmId: string) => {
    if (typeof googleTagManager === 'undefined') {
        return
    }
    const gtm = googleTagManager[gtmId]
    if (!gtm || !gtm.dataLayer || typeof gtm.dataLayer.reset !== 'function') {
        return
    }
    gtm.dataLayer.reset()
}

/**
 * https://www.simoahava.com/gtm-tips/remember-to-flush-unused-data-layer-variables/
 *
 * Event variables should be cleared after the event is fired and the next event
 * is raised so that the data layer does not keep information from a previous
 * event.
 *
 * Page variables should be cleared when the user navigates to a new page, since
 * we're using client side routing we have to manage this manually.
 */
let previousEventVariables: Record<string, undefined> = {}
let currentPageVariables: Record<string, undefined> = {}

// // Testing usage only
// export const resetVariablesToFlush = () => {
//     previousEventVariables = {}
//     currentPageVariables = {}
// }

// clone a new variable object with the same key name but values are undefined
// eslint-disable-next-line @typescript-eslint/ban-types
const mapToUndefined = (obj: Record<string, unknown>) => {
    return Object.keys(obj).reduce(
        (newObj, key) => {
            newObj[key] = undefined
            return newObj
        },
        {} as Record<string, undefined>,
    )
}

// Process a GTM event, making sure the appropriate variables are flushed out
export function processEvent(event: GtmEvent | undefined) {
    try {
        if (!event || typeof dataLayer === 'undefined') return

        let variablesToFlush
        if (event.event === 'syntheticPage.initiated') {
            variablesToFlush = {
                ...previousEventVariables,
                ...currentPageVariables,
            }
            currentPageVariables = {}
        } else {
            variablesToFlush = { ...previousEventVariables }
        }

        const gtmEvent = {
            ...variablesToFlush,
            ...event.eventVariables,
            ...event.pageVariables,
            ...event.permanentVariables,
            event: event.event,
        }

        dataLayer.push(gtmEvent)

        console.debug(gtmEvent, 'GTM: Raised event')

        if (event.eventVariables) {
            previousEventVariables = mapToUndefined(event.eventVariables)
        }
        if (event.pageVariables) {
            currentPageVariables = {
                ...currentPageVariables,
                ...mapToUndefined(event.pageVariables),
            }
        }
    } catch (e) {
        console.log(e, 'GTM: Failed to raise event')
    }
}

/**
 * Take an application event and separate the payload variables into different
 * types so that data is either transient or persistent across page/session.
 */
export function raiseEvent(event: DataLayerEvents) {
    switch (event.type) {
        case 'user.auth':
            processEvent({
                event: event.type,
                permanentVariables: event.payload,
            })
            break
        case 'syntheticPage.completed':
        case 'syntheticPage.error':
        case 'syntheticPage.initiated':
            processEvent({
                event: event.type,
                pageVariables: event.payload,
            })
            break
        default:
            processEvent({
                event: event.type,
                eventVariables: event.payload,
            })
            break
    }
}
