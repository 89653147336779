import { useMutation, useSubscription } from '@apollo/client'
import MuxUploader from '@mux/mux-uploader-react'
import { Form } from 'antd'
import { Button } from 'components/Button'
import { DatePicker } from 'components/DateTimeInput'
import {
    ClubsSelect,
    LeagueSelect,
    TeamFilteredSelect,
    TeamSelect,
} from 'components/Dropdown'
import { CustomSelect } from 'components/Dropdown/dropdown.style'
import { Input } from 'components/Input'
import { Col, Row } from 'components/Layout'
import { Text } from 'components/Text'
import admin from 'graphql/club/admin'
import { mutate } from 'graphql/match'
import _ from 'lodash'
import { useRouter } from 'next/router'
import React, { useEffect, useRef, useState } from 'react'
import { BsSave } from 'react-icons/bs'
import { HiOutlineRefresh } from 'react-icons/hi'
import { ImCancelCircle } from 'react-icons/im'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { ModalProps } from 'types/components/Modal'
import { MATCH_ADS_ID } from 'utils/constData'
import { isServer } from 'utils/helper'
import SportsDropDown from '../Team/components/SportsDropDown'
import {
    ClubCheckbox,
    FormItem,
    ModalBody,
    ModalFooter,
    ModalWrapper,
} from './index.style'

const defaultFieldsValues = {
    is_historic: true,
    status: 'completed',
}

const PreviousModal: React.FC<ModalProps> = ({
    show = false,
    handleClose,
    endPoint,
    uploadId,
    clubInfo,
}) => {
    // functions
    const router = useRouter()
    const { league_slug } = router.query
    const [form] = Form.useForm()
    const [playback_id, setPlaybackId] = useState('')
    const [disabled, setDisabled] = useState(true)
    const [isLoading, setIsLoading] = useState(false)
    const [streamId, setStreamId] = useState()
    const [currentSportName, setCurrentSportName] = useState(undefined)
    const [homeClubId, setHomeClubID] = useState(undefined)
    const [awayClubId, setAwayClubId] = useState(0)
    const [inputDisabled, setInputDisabled] = useState({
        opClub: true,
        opTeam: true,
    })
    const [addCustomOpponent, setAddCustomOpponent] = useState(false)
    const uploaderRef = useRef(null)
    const [refreshCount, setRefreshCount] = useState(0)

    const [addMatch] = useMutation(mutate.INSERT_MATCH, {
        onCompleted() {
            toast.success('Match saved')
            refreshUploader()
            handleClose && handleClose()
        },
        onError(e) {
            toast.error('Error Happened.')
            console.log('error', e)
        },
    })

    useSubscription(admin.SUB_PAST_MATCH_STREAM, {
        skip: isServer,
        variables: {
            where: {
                video_asset_id: { _eq: playback_id },
            },
        },
        onData: ({ data: { data, loading } }) => {
            !loading && data?.streams && setStreamId(data?.streams[0]?.id)
            setIsLoading(loading)
            !loading && data?.streams[0]?.id && setDisabled(false)
        },
    })

    const [addPastStream] = useMutation(admin.INSERT_PAST_MATCH_STREAM)

    const onUploadSuccess = async () => {
        setIsLoading(true)
        const response = await fetch(`/api/mediaGallery/${uploadId}`, {
            method: 'GET',
        })

        const data = await response.json()

        const objects = {
            ...defaultFieldsValues,
            video_asset_id: data?.upload?.playback_id,
        }

        addPastStream({ variables: { objects } })

        setPlaybackId(data?.upload?.playback_id)
    }

    const refreshUploader = () => {
        uploaderRef.current = null
        setRefreshCount(refreshCount + 1)
    }

    useEffect(() => {
        form.resetFields()
        form.setFieldsValue({
            start_datetime: new Date(),
            ext_managed: false,
            ext_scoring: true,
        })

        !show &&
            setInputDisabled({ ...inputDisabled, opClub: true, opTeam: true })
    }, [show])

    const submitHandler = async (values: {
        custom_club_name: any
        custom_club_display_name: any
        club_id: any
        name: any
    }) => {
        const fieldValues = { ...values }
        const { custom_club_name, custom_club_display_name, ...rest } =
            fieldValues
        const valuesObj = addCustomOpponent ? rest : fieldValues

        const url = null
        const custom_opponent = addCustomOpponent
            ? {
                  custom_opponent: {
                      club_name: values.custom_club_name,
                      club_display_name: values.custom_club_display_name,
                  },
                  away_team_id: 1,
              }
            : {}

        const objects = {
            ...defaultFieldsValues,
            ...valuesObj,
            ...custom_opponent,
            club_id: _.isUndefined(league_slug) ? clubInfo.id : values.club_id,
            video_asset_id: playback_id,
            name: _.isUndefined(league_slug) ? values.name : 'Home',
            url,
            ads_id: MATCH_ADS_ID,
            stream_info_id: streamId,
            ext_managed: false,
            ext_scoring: true,
        }

        /** add to matches table */

        addMatch({ variables: { objects } })
    }

    const onOpClubChange = (club_id: React.SetStateAction<number>) => {
        setAwayClubId(club_id)
        form.setFieldsValue({ away_team_id: undefined })
        setInputDisabled({ ...inputDisabled, opClub: false, opTeam: false })
    }

    const onHomeTeamChange = (home_team_id: any) => {
        // const team = _.find(clubTeams, ['id', home_team_id])
        // team && team.sports_id && setCurrentSportId(team.sports_id)
        form.setFieldsValue({
            away_club_id: undefined,
            away_team_id: undefined,
        })

        setInputDisabled({ ...inputDisabled, opClub: false, opTeam: true })
    }

    const onHomeClubChange = (club_id: React.SetStateAction<undefined>) => {
        setHomeClubID(club_id)
    }

    const onLeagueChange = (
        league_id: any,
        league_info: { sport_name: React.SetStateAction<undefined> },
    ) => {
        form.setFieldValue('home_team_id', undefined)
        form.setFieldValue('sports_id', undefined)

        league_info.sport_name && setCurrentSportName(league_info.sport_name)
    }

    return (
        <ModalWrapper
            open={show}
            closable={false}
            title={`Add Past Match`}
            width={800}
            footer={null}
        >
            <Form layout="vertical" form={form} onFinish={submitHandler}>
                <ModalBody>
                    <Row flexDirection="column" gap={5}>
                        <Text fColor="white" fWeight={600} fSize={1.0625}>
                            {'Match Details'}
                        </Text>
                        <Col>
                            <FormItem
                                name="start_datetime"
                                label="Date & Time"
                                rules={[{ required: true, message: '' }]}
                            >
                                <DatePicker />
                            </FormItem>
                        </Col>
                        <Row templateCol="1fr 1fr" display="grid" gap={10}>
                            <Col>
                                <FormItem
                                    name="league_id"
                                    label="League Name"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <LeagueSelect onChange={onLeagueChange} />
                                </FormItem>
                            </Col>
                            <Col>
                                <FormItem
                                    name="round_name"
                                    label="Round Name"
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <Input
                                        style={{
                                            height: 38,
                                            borderRadius: 3,
                                            fontWeight: 'normal',
                                        }}
                                        placeholder="Round Name"
                                    />
                                </FormItem>
                            </Col>
                            <Col>
                                {_.isUndefined(league_slug) ? (
                                    <FormItem
                                        name="name"
                                        label="Home/Away"
                                        initialValue="Home"
                                        rules={[
                                            { required: true, message: '' },
                                        ]}
                                    >
                                        <CustomSelect>
                                            <CustomSelect.Option value="Home">
                                                Home
                                            </CustomSelect.Option>
                                            <CustomSelect.Option value="Away">
                                                Away
                                            </CustomSelect.Option>
                                        </CustomSelect>
                                    </FormItem>
                                ) : (
                                    <FormItem
                                        name="club_id"
                                        label="Home Club name"
                                        rules={[
                                            { required: true, message: '' },
                                        ]}
                                    >
                                        <ClubsSelect
                                            sport_name={currentSportName}
                                            onChange={onHomeClubChange}
                                        />
                                    </FormItem>
                                )}
                            </Col>
                            <Col>
                                <FormItem
                                    name="home_team_id"
                                    label={
                                        _.isUndefined(league_slug)
                                            ? 'Select Team'
                                            : `Select Home Team`
                                    }
                                    rules={[{ required: true, message: '' }]}
                                >
                                    <TeamSelect
                                        placeholder="Select Team"
                                        sport_name={currentSportName}
                                        homeClubId={homeClubId}
                                        onChange={onHomeTeamChange}
                                    />
                                </FormItem>
                            </Col>
                            <ClubCheckbox
                                checked={addCustomOpponent}
                                onChange={() =>
                                    setAddCustomOpponent(!addCustomOpponent)
                                }
                            >
                                Custom Opponent
                            </ClubCheckbox>

                            <FormItem
                                name="sports_id"
                                label="Scoring Sport"
                                rules={[{ required: true, message: '' }]}
                            >
                                <SportsDropDown sport_name={currentSportName} />
                            </FormItem>
                            {addCustomOpponent ? (
                                <>
                                    <FormItem
                                        name="custom_club_name"
                                        label="Opposition Club"
                                        rules={[
                                            { required: true, message: '' },
                                        ]}
                                    >
                                        <Input
                                            style={{
                                                height: 38,
                                                borderRadius: 3,
                                                fontWeight: 'normal',
                                            }}
                                            placeholder="Opposition Club Name"
                                        />
                                    </FormItem>

                                    <FormItem
                                        name="custom_club_display_name"
                                        label="Opposition Club Display Name"
                                        rules={[
                                            { required: true, message: '' },
                                        ]}
                                    >
                                        <Input
                                            style={{
                                                height: 38,
                                                borderRadius: 3,
                                                fontWeight: 'normal',
                                            }}
                                            placeholder="Opposition Club Display Name"
                                        />
                                    </FormItem>
                                </>
                            ) : (
                                <>
                                    <FormItem
                                        name="away_club_id"
                                        label={
                                            _.isUndefined(league_slug)
                                                ? 'Club name'
                                                : `Away Club name`
                                        }
                                        rules={[
                                            { required: true, message: '' },
                                        ]}
                                    >
                                        <ClubsSelect
                                            disabled={inputDisabled.opClub}
                                            sport_name={currentSportName}
                                            onChange={onOpClubChange}
                                        />
                                    </FormItem>
                                    <FormItem
                                        name="away_team_id"
                                        label={
                                            _.isUndefined(league_slug)
                                                ? 'Select Team'
                                                : `Select Away Team`
                                        }
                                        rules={[
                                            { required: true, message: '' },
                                        ]}
                                    >
                                        <TeamFilteredSelect
                                            disabled={inputDisabled.opTeam}
                                            sport_name={currentSportName}
                                            club_id={awayClubId}
                                        />
                                    </FormItem>
                                </>
                            )}
                        </Row>
                        <MuxUploader
                            ref={uploaderRef}
                            style={{ color: 'white' }}
                            onSuccess={onUploadSuccess}
                            endpoint={endPoint}
                            key={refreshCount}
                        />
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button
                        bColor="primary"
                        bSize="small"
                        icon={<ImCancelCircle />}
                        onClick={handleClose}
                    >
                        {'Cancel'}
                    </Button>
                    <Button
                        bColor="primary"
                        bSize="small"
                        icon={<HiOutlineRefresh />}
                        onClick={refreshUploader}
                    >
                        {'Upload New Video'}
                    </Button>
                    <Button
                        type="submit"
                        bColor="primary"
                        bSize="small"
                        icon={<BsSave />}
                        loading={isLoading}
                        disabled={disabled}
                    >
                        {'Save'}
                    </Button>
                </ModalFooter>
            </Form>
        </ModalWrapper>
    )
}

const mapStateToProps = (state: {
    club: { info: any }
    teams: { list: any }
}) => ({
    clubInfo: state.club.info,
    clubTeams: state.teams.list,
})

// @ts-ignore
export default connect(mapStateToProps)(PreviousModal) as any
