import styled from 'styled-components'

export const GPTWrapper = styled.div<{
    isFooter?: boolean
    isBillboard?: boolean
}>`
    height: 90px;

    @media screen and (max-width: 768px) {
        height: ${({ isFooter }) => (isFooter ? 50 : 250)}px;
    }
    @media screen and (min-width: 768px) {
        height: ${({ isBillboard }) => (isBillboard ? 250 : 90)}px;
    }
`
