import React from 'react'

// payment status components
import SuccessView from './success'
import FailedView from './failed'

const PaymentModal: React.FC = () => {
    return (
        <dialog>
            <SuccessView />
            <FailedView />
        </dialog>
    )
}

export default PaymentModal
