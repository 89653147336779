import React, { useState, useRef, useContext, useEffect } from 'react'

import { Button } from 'components/Button'
import { Row } from 'components/Layout'
import { ProfileImageContext } from '..'

export const CommonBrowser: React.FC<any> = (props) => {
    const { image, setImage, fileInputRef, files, setFiles, flag, setFlag } =
        useContext(ProfileImageContext)
    const onFileInputChange = (event: any) => {
        setImage(event)
        const newFiles = event.target.files

        setFiles(newFiles)
        setFlag(true)
    }

    const onTargetClick = () => {
        if (fileInputRef && fileInputRef.current) {
            fileInputRef?.current.click()
        }
    }

    return (
        <div {...props}>
            <input
                onChange={onFileInputChange}
                onClick={(event: any) => {
                    event.target.value = null
                }}
                ref={fileInputRef}
                type="file"
                style={{ display: 'none' }}
                accept="image/png, image/jpeg"
            />
            <Button onClick={onTargetClick}>Upload</Button>
        </div>
    )
}
