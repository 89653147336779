import { useState } from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { DateTimeSelect } from './DateTimeInput.style'

const DateTimeInput = (props: any) => {
    const [state, setstate] = useState<any>(new Date())

    const onChangeDate = (date: any) => {
        setstate(date)
        props.onChange(date)
    }

    return (
        <DateTimeSelect
            {...props}
            showTimeSelect
            selected={state}
            onChange={(dt) => onChangeDate(dt)}
            dateFormat="MMM d, yyyy h:mm aa"
        />
    )
}

export default DateTimeInput
