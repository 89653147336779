// styled system
import styled from 'styled-components'
// -----------------------------------------------

interface imageProps {
    borderRadius?: any
}

export const ImageInner = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`
export const ImageWrapper = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    right: 0;
`

export const ImageDiv = styled.div<imageProps>`
    border-radius: ${(props: any) => props.borderRadius};
    overflow: hidden;
`
