import { get } from './helpers/request'
import { LeagueReduxInfo, Leagues } from './types'
const baseUrl = process.env.NEXT_PUBLIC_RESTFUL_BASE_URL

export async function getAllLeagues() {
    try {
        const response = await get<Leagues>(`${baseUrl}/allleagues`)
        return response.parsedBody
    } catch (error) {
        throw new Error('Data failed to load')
    }
}

export async function getLeaguesBySearchInput(
    searchInput?: string,
    sport?: string,
) {
    try {
        const response = await get<Leagues>(
            `${baseUrl}/leagues/cards/search/%25${searchInput}%25/sport/%25${sport}%25`,
        )
        return response.parsedBody
    } catch (error) {
        throw new Error('Data failed to load')
    }
}

export async function getLeagueBySlugName(slug?: string) {
    try {
        const response = await get<LeagueReduxInfo>(
            `${baseUrl}/league/slug/${slug}`,
        )
        return response.parsedBody
    } catch (error) {
        throw new Error('Data failed to load')
    }
}
