import { useMutation, useSubscription } from '@apollo/client'
import { Tooltip } from 'antd'
import DefaultSrc from 'assets/images/layout/group.png'
import { Avatar } from 'components/Avatar'
import { Button } from 'components/Button'
import { Col, Row } from 'components/Layout'
import { Player_A_Modal, Player_U_Modal } from 'components/Modal'
import { Table } from 'components/Table'
import { Text } from 'components/Text'
import subscribe from 'graphql/leagues/subscriptions'
import { useRouter } from 'hooks'
import _ from 'lodash'
import { LeagueContext } from 'pages/league/[league_slug]/admin'
import React, { createContext, useContext, useState } from 'react'
import { BsArrowRepeat, BsEye, BsPen, BsPlus } from 'react-icons/bs'
import { toast } from 'react-toastify'
import { ClubCtx } from 'types/common/club'
import { isServer } from 'utils/helper'
import { ADMINQL, HomeQL } from '../../../../../../../graphql/club'
import { AddplayerStyle, StatusStyle } from './display.style'

const Action: React.FC<{ level: string; pid: number; onHandleEdit: any }> = ({
    level,
    pid,
    onHandleEdit,
}) => {
    return (
        <StatusStyle type={level === 'Player Pro'}>
            <Text
                mode="p"
                fSize={1}
                fColor={level == 'Player Pro' ? 'green.100' : 'red.100'}
            >
                {level}
            </Text>
            {/* <Row justifyContent="center" alignItems="center" gap={20}>
        <Col>
          <Text
            mode="p"
            fSize={1}
            fColor={level == "Player Pro" ? "green.100" : "red.100"}
          >
            {level}
          </Text>
        </Col> */}
            {/* <Col>
          <div onClick={() => onHandleEdit(pid)}>
            <Text
              fSize={1}
              bColor="primary"
              bSize="small"
              tDecorations="underline"
              fColor="gray.200"
              hoverStyle={{ fColor: "white" }}
            >
              {"Edit"}
            </Text>
          </div>
        </Col> */}
            {/* </Row> */}
        </StatusStyle>
    )
}

export const ClubAdminContext = createContext<Partial<ClubCtx>>({})

const DisplaySection: React.FC = (props: any) => {
    const { move } = useRouter()
    const league = useContext(LeagueContext)
    const [a_show, setAShow] = useState<boolean>(false)
    const [u_show, setUShow] = useState<boolean>(false)
    const [pid, setPid] = useState<any>(null)
    const [resendInvitation] = useMutation(HomeQL.UPDATE_INVITED_PLAYERS)
    const [clubSlug, setClubSlug] = useState('')
    const [club, setClub] = useState<Partial<Array<any>>>([])
    const [players, setPlayers] = useState([])

    useSubscription(ADMINQL.SUB_CLUB, {
        skip: isServer || !clubSlug,
        variables: {
            club_slug: clubSlug,
        },
        onData: ({ data: { data, loading } }) => {
            !loading && data && setClub(data.clubs[0])
        },
    })

    useSubscription(subscribe.SUB_ALL_PLAYERS, {
        skip: isServer,
        variables: {
            where: {
                team: {
                    league_id: { _eq: league?.id },
                },
            },
        },
        onData: ({ data: { data, loading } }) => {
            !loading && data && setPlayers(data?.players_details)
        },
    })

    const _handleClose = () => {
        setUShow(false)
        setPid(null)
    }

    const onHandleEdit = (player: {
        club: { slug: React.SetStateAction<string> }
        id: any
    }) => {
        setClubSlug(player?.club?.slug)
        setPid(player.id)
        setUShow(true)
    }

    const resendInvite = async (admin: any) => {
        const response = await resendInvitation({
            variables: {
                where: {
                    id: { _eq: admin.id },
                },
                set: {
                    is_accepted: false,
                    is_declined: false,
                },
            },
        })
        if (response?.data?.update_players_details) {
            toast.success('Re Sending Invitation Successful!')
        }
    }

    const onHandleClick = (item: any) => {
        move(`/club/${item?.club?.slug}/player/${item?.slug}`)
    }

    const datasource = () => {
        if (_.isUndefined(players)) {
            return [
                {
                    'Player Photo': '',
                    'Player Name': '',
                    Club: '',
                    Team: '',
                    Status: '',
                },
            ]
        }

        return players?.map((player: any, index: number) => ({
            item_data: player,
            Photo: (
                <Avatar
                    src={
                        player?.user?.photo
                            ? player?.user?.photo
                            : player?.image
                              ? player?.image
                              : DefaultSrc
                    }
                    position="center"
                    radius="circle"
                    mode="small"
                />
            ),
            'Player Name':
                player?.name && player?.last_name
                    ? `${player?.name ?? ''} ${player?.last_name ?? ''}`
                    : `${player?.user?.first_name ?? ''} ${
                          player?.user?.last_name ?? ''
                      }`,
            Club: player?.club.name,
            Team: player?.team?.name,
            Status: (
                <Action
                    pid={player?.id}
                    level={player.is_professional ? 'Player Pro' : 'Standard'}
                    onHandleEdit={onHandleEdit}
                />
            ),
            Action: (
                <Row justifyContent="center" alignItems="center">
                    {player?.is_accepted ? (
                        <Tooltip placement="top" title="Resend Invite">
                            <Button
                                disabled={true}
                                style={{ borderRadius: '50%', padding: '8px' }}
                                icon={<BsArrowRepeat />}
                                shape="circle"
                            />
                        </Tooltip>
                    ) : (
                        <Tooltip placement="top" title="Resend Invite">
                            <Button
                                onClick={() => resendInvite(player)}
                                style={{ borderRadius: '50%', padding: '8px' }}
                                icon={<BsArrowRepeat />}
                                shape="circle"
                            />
                        </Tooltip>
                    )}
                    <Tooltip placement="top" title="View Player">
                        <Button
                            onClick={() => onHandleClick(player)}
                            style={{ borderRadius: '50%', padding: '8px' }}
                            icon={<BsEye />}
                        />
                    </Tooltip>
                    {
                        <Tooltip placement="top" title="Edit Player Details">
                            <Button
                                onClick={() => onHandleEdit(player)}
                                style={{ borderRadius: '50%', padding: '8px' }}
                                icon={<BsPen />}
                                shape="circle"
                            />
                        </Tooltip>
                    }
                    {/*<Tooltip placement="top" title="Delete">*/}
                    {/*  <Button onClick={() => _handleDelete(player?.id)} style={{borderRadius:"50%" , padding:"8px"}} icon={<BsTrash />} shape="circle" />*/}
                    {/*</Tooltip>*/}
                </Row>
            ),
        }))
    }

    return (
        <>
            <Row flexDirection="column" gap={20}>
                <AddplayerStyle>
                    <Col item={24}>
                        <Row justifyContent="flex-end">
                            <Button
                                bColor="warning"
                                bSize="small"
                                icon={<BsPlus />}
                                onClick={() => setAShow(true)}
                            >
                                {'Add Player'}
                            </Button>
                        </Row>
                    </Col>
                </AddplayerStyle>
                <Col item={24}>
                    <Table data={datasource()} page="player" />
                </Col>
            </Row>
            <Player_A_Modal
                show={a_show}
                handleClose={() => setAShow(false)}
                leagueClubInfo={club}
                leagueInfo={league}
            />
            {!_.isUndefined(pid) ? (
                <Player_U_Modal
                    show={u_show}
                    handleClose={_handleClose}
                    mid={pid}
                    leagueClubInfo={club}
                />
            ) : null}
        </>
    )
}

export default DisplaySection
