import { Dropdown } from 'antd'
import ProfileImage from 'assets/images/layout/profile.png'
import { Text } from 'components/Text'
import { siteSettings, useRouter, useUser } from 'hooks'
import BillingButton from 'layouts/app-layout/Header/BillingButton'
import _ from 'lodash'
import {
    ProfileDropdownContent,
    ProfileDropdownWrapper,
    StyledDownArrow,
    StyledItemMenu,
    StyledMenu,
    StyledProfileDropdownLink,
    StyledProfileName,
    StyledProfilePicture,
} from './ProfileDropdown.style'

const ProfileDropdown = (props: any) => {
    const { isMobile } = props
    const { move, param, asPath, router, path }: any = useRouter()
    const { user } = useUser()

    const handleMenuClick = (to: any) => {
        move(to)
    }

    const paramName = !_.isUndefined(user)
        ? `${user?.first_name ?? ''} ${user?.last_name ?? user?.email}`
        : ''

    const dropdownItems = (
        <StyledMenu>
            <StyledItemMenu
                key="0"
                onClick={() =>
                    handleMenuClick(`/profile?n=${encodeURI(paramName)}`)
                }
            >
                <Text fColor="white" fSize={0.875} tAlign="center">
                    {'My Profile'}
                </Text>
            </StyledItemMenu>

            {siteSettings('header_menu.manage_billing') && (
                <StyledItemMenu key="1">
                    <BillingButton />
                </StyledItemMenu>
            )}

            <StyledItemMenu
                key="2"
                onClick={() => handleMenuClick('/api/auth/logout')}
            >
                <Text fColor="white" fSize={0.875} tAlign="center">
                    {'Logout'}
                </Text>
            </StyledItemMenu>
        </StyledMenu>
    )

    const displayName = user?.first_name
        ? `${user?.first_name}`.split('@')[0]
        : `My Profile`

    return (
        <ProfileDropdownWrapper>
            <Dropdown
                overlay={dropdownItems}
                trigger={[isMobile ? 'click' : 'hover']}
                placement={'bottomRight'}
            >
                <StyledProfileDropdownLink
                    className="ant-dropdown-link"
                    onClick={(e) => e.preventDefault()}
                >
                    <ProfileDropdownContent>
                        <StyledProfilePicture
                            src={(user?.picture || ProfileImage) as any}
                            height={isMobile ? 30 : 34}
                            width={isMobile ? 30 : 34}
                        />
                        {!isMobile && (
                            <StyledProfileName>{displayName}</StyledProfileName>
                        )}
                        <StyledDownArrow size={28} />
                    </ProfileDropdownContent>
                </StyledProfileDropdownLink>
            </Dropdown>
        </ProfileDropdownWrapper>
    )
}

export default ProfileDropdown
