import * as t from 'redux/types/league'

export const setLeagueInfo = (leagueInfo: any) => ({
    type: t.SET_LEAGUE_INFO,
    payload: leagueInfo,
})

export const setLeaguePassModal = (showPassModal: boolean) => ({
    type: t.SET_LEAGUE_PASS_MODAL,
    payload: showPassModal,
})

export const setLeagueList = (leagues: Array<any>) => ({
    type: t.SET_LEAGUE_LIST,
    payload: leagues,
})
