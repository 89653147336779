import { get } from './helpers/request'
import { ClubReduxInfo } from './types/clubs'
const baseUrl = process.env.NEXT_PUBLIC_RESTFUL_BASE_URL

export async function getClubBySlugName(clubslug?: string) {
    try {
        const response = await get<ClubReduxInfo>(
            `${baseUrl}/club/clubslug/${clubslug}`,
        )
        return response.parsedBody
    } catch (error) {
        throw new Error('Data failed to load')
    }
}
