import { gql } from '@apollo/client'

const UPDATE_PASSES = gql`
    mutation passesMutation(
        $where: passes_bool_exp = {}
        $_set: passes_set_input = {}
    ) {
        update_passes(where: $where, _set: $_set) {
            affected_rows
        }
    }
`

// // ---------
export default {
    UPDATE_PASSES,
}
