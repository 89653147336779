import { css } from 'styled-components'

const primary_state = {
    hover: '#f27043',
    focus: '#f69169',
    active: '#b4351a',
}

const secondary_state = {
    hover: '#1f1722',
    focus: '#352d38',
    active: '#19141c',
}

export const Primary_Button_Style = css`
    border: 1.5px solid #ef4623;
    background-color: #ef4623;
    border-radius: 24px;
    color: white !important;
    padding: 24px;

    :hover {
        background-color: ${primary_state.hover};
        border-color: ${primary_state.hover};
    }
    :focus {
        background-color: ${primary_state.focus};
        border-color: ${primary_state.focus};
    }
    :active {
        background-color: ${primary_state.active};
        border-color: ${primary_state.active};
    }
`
export const Secondary_Button_Style = css`
    border: 1.5px solid #ef4623;
    background-color: transparent;
    border-radius: 24px;
    color: white !important;
    padding: 24px;
    width: unset;
    :hover {
        background-color: ${secondary_state.hover};
    }
    :focus {
        background-color: ${secondary_state.focus};
    }
    :active {
        background-color: ${secondary_state.active};
    }
`
