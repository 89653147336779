import { useMutation } from '@apollo/client'
import { Button } from 'components/Button'
import { Text } from 'components/Text'
import { mutate } from 'graphql/match'
import { toast } from 'react-toastify'
import {
    ModalContent,
    ModalFooter,
    ModalHeader,
    StyledModal,
} from './index.style'

const ArchiveModal = (props: any) => {
    const [archiveStream] = useMutation(mutate.UPDATE_ARCHIVE_MATCH, {
        onCompleted() {
            props.setButtonLoading && props.setButtonLoading(false)
            props.setShow(false)
            toast.success('Archived Successfully')
        },
        onError() {
            props.setButtonLoading && props.setButtonLoading(false)
            props.setShow(false)
            toast.error(`Something went wrong. Please try again later.`)
        },
    })

    const handleArchiveStream = () => {
        props?.stream_info_id &&
            archiveStream({
                variables: {
                    where: {
                        id: {
                            _eq: props?.stream_info_id,
                        },
                    },
                    _set: {
                        archived: true,
                    },
                },
            })
    }

    return (
        <StyledModal show={props.show}>
            <ModalContent>
                <ModalHeader>
                    <Text fSize={1.4} fWeight={700} tAlign={'center'}>
                        Are you sure you want to archive this match?
                    </Text>
                    <Text
                        fSize={1.1}
                        fWeight={600}
                        tAlign={'center'}
                        style={{
                            color: 'rgb(204 204 204)',
                            marginTop: '1rem',
                        }}
                    >
                        You can unarchive this match at any time via Club Admin.
                    </Text>
                </ModalHeader>
                <ModalFooter>
                    <Button
                        onClick={() => {
                            props.setShow(false)
                            props.setButtonLoading &&
                                props.setButtonLoading(false)
                        }}
                        style={{
                            border: 0,
                            color: 'gray',
                            backgroundColor: 'rgb(87 86 87)',
                            width: '30%',
                            transition: 'none',
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        onClick={handleArchiveStream}
                        variant="warning"
                        style={{
                            marginLeft: '20px',
                            width: '30%',
                            transition: 'none',
                        }}
                    >
                        Archive
                    </Button>
                </ModalFooter>
            </ModalContent>
        </StyledModal>
    )
}

export default ArchiveModal
