import { useSubscription } from '@apollo/client'
import { Field } from 'formik' //TODO: Refactor please use antd form
import { HomeQL } from 'graphql/club'
import { useRouter } from 'next/router'
import { useState } from 'react'
import slugify from 'slugify'
import { isServer } from 'utils/helper'
import { AntSelect } from './dropdown'

const { Option } = AntSelect

function TeamsDropDown({ name = '', onChange, ...rest }: any) {
    const router = useRouter()
    const { club_slug } = router.query

    const [data, setData] = useState<Partial<Array<any>>>([])

    useSubscription(HomeQL.SUB_CLUB_TEAMS, {
        skip: isServer || !club_slug,
        variables: {
            where: {
                slug: { _eq: club_slug },
            },
        },
        onData: ({ data: { data, loading } }) => {
            !loading && data.clubs[0] && setData(data.clubs[0].teams)
        },
    })

    return (
        <Field name={name} id={slugify(name)}>
            {({ field: { value }, form: { setFieldValue } }: any) => (
                <AntSelect
                    {...rest}
                    placeholder="Select Team"
                    filterOption={(input, option) =>
                        option!.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                    }
                    onChange={(val) => {
                        setFieldValue(name, val)
                    }}
                >
                    {data.map((team, i) => (
                        <Option value={team.id} key={`dd-team-${i}`}>
                            {team.name}
                        </Option>
                    ))}
                </AntSelect>
            )}
        </Field>
    )
}

export default TeamsDropDown
