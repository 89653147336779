import * as z from 'zod'
import { processDatadogRumAction } from '../datadog/datadog-actions'
import { raiseEvent } from '../gtm/gtm'

const schema = z.object({
    user_id: z.union([z.number(), z.string()]).optional(),
})

export type UserIdSchema = z.infer<typeof schema>

const eventName = 'user.auth'

export function userIdEvent({
    input,
    gtm,
    datadog,
}: {
    input: UserIdSchema
    gtm?: boolean
    datadog?: boolean
}) {
    gtm &&
        raiseEvent({
            type: eventName,
            payload: schema.parse(input),
        })

    datadog &&
        processDatadogRumAction(eventName, {
            userId: input,
        })
}

const loginSignupAttemptSchema = z.object({
    pageName: z.string().optional(),
    message: z.string().optional(),
})

export type LoginSignupAttemptSchema = z.infer<typeof loginSignupAttemptSchema>

export function LoginSignupAttemptEvent({
    props,
    gtm,
    datadog,
}: {
    props: LoginSignupAttemptSchema
    gtm?: boolean
    datadog?: boolean
}) {
    const eventName = 'user.login_signup'
    gtm &&
        raiseEvent({
            type: eventName,
            payload: schema.parse(props),
        })

    datadog &&
        processDatadogRumAction(eventName, {
            userId: props,
        })
}

const visitHomeSchema = z.object({
    message: z.string().optional(),
})

export type VisitHomeSchema = z.infer<typeof visitHomeSchema>

export function visitHomeEvent({
    props,
    gtm,
    datadog,
}: {
    props: VisitHomeSchema
    gtm?: boolean
    datadog?: boolean
}) {
    const eventName = 'user.visit.homepage'

    gtm &&
        raiseEvent({
            type: eventName,
            payload: schema.parse(props),
        })

    datadog &&
        processDatadogRumAction(eventName, {
            userId: props,
        })
}

const visitBillingSchema = z.object({
    message: z.string().optional(),
    pageName: z.string().optional(),
})

export type VisitBillingSchema = z.infer<typeof visitBillingSchema>

export function visitBillingEvent({
    props,
    gtm,
    datadog,
}: {
    props: VisitBillingSchema
    gtm?: boolean
    datadog?: boolean
}) {
    const eventName = 'user.visit.billing'

    gtm &&
        raiseEvent({
            type: eventName,
            payload: schema.parse(props),
        })

    datadog &&
        processDatadogRumAction(eventName, {
            userId: props,
        })
}

const visitProfileSchema = z.object({
    message: z.string().optional(),
    pageName: z.string().optional(),
})

export type VisitProfileSchema = z.infer<typeof visitProfileSchema>

export function visitProfileEvent({
    props,
    gtm,
    datadog,
}: {
    props: VisitProfileSchema
    gtm?: boolean
    datadog?: boolean
}) {
    const eventName = 'user.visit.profile'

    gtm &&
        raiseEvent({
            type: eventName,
            payload: schema.parse(props),
        })

    datadog &&
        processDatadogRumAction(eventName, {
            userId: props,
        })
}
