// apollo
import { gql } from '@apollo/client'
// ---------------------------------------------------
/** gql
 * TODO: transfer to common query file
 * */
const GET_CLUB = gql`
    query ClubQuery($club_slug: String!) {
        clubs(where: { slug: { _eq: $club_slug } }) {
            name
            banner_image
            logo
            id
            slug
            display_name
            sport {
                id
                name
            }
            club_admins {
                id
                user_id
                status
            }
            teams {
                id
                image
                name
                slug
                division
                league_id
            }
            players {
                id
                image
                slug
                name
                last_name
                team {
                    name
                    slug
                }
                user {
                    first_name
                    last_name
                }
            }
            pass {
                id
                name
                price
                recur
                price_id
            }
            community_members {
                community {
                    id
                    name
                    display_name
                    slug
                    status
                }
            }
        }
    }
`
// !!! REMOVE SPORT_ID
const SUB_CLUB = gql`
    subscription ClubSubscription($club_slug: String!) {
        clubs(where: { slug: { _eq: $club_slug } }) {
            name
            banner_image
            logo
            id
            slug
            sport_id
            account_name
            sport {
                id
                name
                icon
            }
            club_admins {
                id
                user_id
                status
                name
                email_address
                is_accepted
                is_declined
                user {
                    id
                    first_name
                    last_name
                }
            }
            teams {
                id
                image
                name
                slug
                division
                sports_id
                league_id
                league {
                    slug
                }
            }
            players {
                id
                image
                slug
                is_accepted
                is_declined
                email_address
                name
                last_name
                team {
                    name
                    slug
                }
                user {
                    first_name
                    last_name
                    photo
                }
            }
            club_partners {
                partner {
                    id
                    logo
                    name
                }
            }
            club_sponsors {
                sponsor {
                    id
                    logo
                    name
                }
            }
            pass {
                id
                name
                price_id
                checkout_url
                pass_type_id
            }
            community_members {
                community {
                    id
                    name
                    display_name
                    slug
                    status
                }
            }
        }
    }
`

const SUB_CLUB_REPLAYS = gql`
    subscription MyReplaySub($club_slug: String!) {
        matches(
            where: {
                _or: [
                    {
                        custom_opponent: { _is_null: true }
                        away_team: { club: { slug: { _eq: $club_slug } } }
                    }
                    { home_team: { club: { slug: { _eq: $club_slug } } } }
                ]
                stream_info: {
                    status: { _eq: "completed" }
                    archived: { _neq: true }
                }
            }
            order_by: { start_datetime: desc }
        ) {
            id
            name
            start_datetime
            round
            round_name
            thumbnail_url
            start_datetime
            custom_opponent
            is_event
            stream_info {
                id
                is_historic
                status
                stream_id
                stream_key
                video_asset_id
            }
            away_team {
                id
                image
                name
                division
                club {
                    id
                    display_name
                    logo
                    name
                    slug
                }
            }
            home_team {
                id
                image
                name
                division
                club {
                    id
                    display_name
                    logo
                    name
                    slug
                }
            }
            league {
                logo
                name
                slug
            }
        }
    }
`

const SUB_TEAM_REPLAYS = gql`
    subscription MyReplaysSub($team_slug: String!) {
        matches(
            where: {
                _or: [
                    {
                        custom_opponent: { _is_null: true }
                        away_team: { slug: { _eq: $team_slug } }
                    }
                    { home_team: { slug: { _eq: $team_slug } } }
                ]
                stream_info: {
                    status: { _eq: "completed" }
                    archived: { _neq: true }
                }
            }
            order_by: { start_datetime: desc }
        ) {
            id
            name
            start_datetime
            round
            start_datetime
            thumbnail_url
            custom_opponent
            stream_info {
                id
                is_historic
                status
                stream_id
                stream_key
                video_asset_id
            }
            away_team {
                id
                image
                name
                division
                club {
                    id
                    logo
                    name
                    display_name
                    slug
                }
            }
            home_team {
                id
                image
                name
                division
                club {
                    id
                    logo
                    name
                    display_name
                    slug
                }
            }
            league {
                logo
                name
                slug
            }
        }
    }
`

const SUB_CLUB_TEAMS = gql`
    subscription ClubTeamsSubscription($where: clubs_bool_exp = {}) {
        clubs(where: $where) {
            teams {
                division
                id
                image
                league_id
                name
                sports_id
            }
        }
    }
`

export const SUB_LEAGUE = gql`
    subscription SubLeague($where: leagues_bool_exp) {
        leagues(where: $where, order_by: { name: asc }) {
            id
            logo
            name
            slug
            status
            league_pass_id
            sports {
                id
                name
                icon
            }
            league_pass {
                id
                active
                name
                description
                price
                recur
                type
            }
            community_members {
                community {
                    id
                    name
                    display_name
                    slug
                    status
                }
            }
        }
    }
`

export const GET_LEAGUE = gql`
    query GetLeague($where: leagues_bool_exp) {
        leagues(where: $where, order_by: { name: asc }) {
            id
            name
            status
            club_alias
            slug
            league_pass_id
            league_pass {
                id
                active
                name
                description
                price
                recur
                type
            }
            community_members {
                community {
                    id
                    name
                    display_name
                    slug
                    status
                }
            }
        }
    }
`

export const UPSERT_CLUB = gql`
    mutation UPSERTClub(
        $objects: [clubs_insert_input!]!
        $on_conflict: clubs_on_conflict
    ) {
        insert_clubs(objects: $objects, on_conflict: $on_conflict) {
            returning {
                id
            }
        }
    }
`

export const UPSERT_CLUB_ADMINS = gql`
    mutation UPSERTClubAdmins(
        $objects: [club_admin_details_insert_input!]!
        $on_conflict: club_admin_details_on_conflict
        $deleteWhere: club_admin_details_bool_exp!
    ) {
        delete_club_admin_details(where: $deleteWhere) {
            returning {
                id
            }
        }

        insert_club_admin_details(
            objects: $objects
            on_conflict: $on_conflict
        ) {
            returning {
                id
            }
        }
    }
`

/**
 * club_id
 * name
 * email_address
 * is_accepted = false
 * is_declined = false
 */
export const INSERT_CLUB_ADMINS = gql`
    mutation UPSERTClubAdmins($objects: [club_admin_details_insert_input!]!) {
        insert_club_admin_details(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`

export const INSERT_LEAGUE_ADMINS = gql`
    mutation UPSERTLeagueAdmins($objects: [admin_details_insert_input!]!) {
        insert_admin_details(objects: $objects) {
            affected_rows
            returning {
                id
            }
        }
    }
`

export const DELETE_CLUB_ADMINS = gql`
    mutation DeleteClubAdmins($deleteWhere: club_admin_details_bool_exp!) {
        delete_club_admin_details(where: $deleteWhere) {
            affected_rows
        }
    }
`

export const DELETE_LEAGUE_ADMINS = gql`
    mutation DeleteLeagueAdmins($deleteWhere: admin_details_bool_exp!) {
        delete_admin_details(where: $deleteWhere) {
            affected_rows
        }
    }
`

export const SUB_CLUB_BY_CLUB_ADMIN_USER_ID = gql`
    subscription SUB_CLUB_BY_CLUB_ADMIN_USER_ID($user_id: Int) {
        clubs(
            where: {
                status: { _neq: "approved" }
                club_admins: { user_id: { _eq: $user_id } }
            }
        ) {
            id
            name
            abbr_name
            slug
            sport_id
            logo
            banner_image
            address_1
            address_2
            city
            state
            postcode
            status
            account_name
            account_number
            account_bsb
            club_admins(
                where: {
                    _or: [
                        { user_id: { _neq: $user_id } }
                        { user_id: { _is_null: true } }
                    ]
                }
            ) {
                id
                user_id
                email_address
                name
                is_accepted
            }
            teams {
                id
                name
                league_id
                sports_id
                image
            }
            players {
                id
                team_id
                name
                last_name
                email_address
                is_accepted
                is_declined
                user {
                    id
                    first_name
                    last_name
                }
            }
        }
    }
`

const GET_CLUB_INVITE = gql`
    query GET_CLUB_INVITE($email_address: String) {
        club_admin_details(
            where: {
                is_declined: { _eq: false }
                is_accepted: { _eq: false }
                email_address: { _eq: $email_address }
                user_id: { _is_null: true }
                club: { status: { _eq: "approved" } }
            }
        ) {
            id
            club {
                id
                display_name
                name
                slug
            }
        }

        players_details(
            where: {
                is_declined: { _eq: false }
                is_accepted: { _eq: false }
                email_address: { _eq: $email_address }
                club: { status: { _eq: "approved" } }
            }
        ) {
            id
            image
            name
            last_name
            club {
                id
                display_name
                name
                slug
            }
        }
    }
`

const GET_LEAGUE_INVITE = gql`
    query GET_CLUB_INVITE($email_address: String) {
        admin_details(
            where: {
                is_declined: { _eq: false }
                is_accepted: { _eq: false }
                email_address: { _eq: $email_address }
                user_id: { _is_null: true }
                league: { status: { _eq: "approved" } }
            }
        ) {
            id
            league {
                id
                name
                slug
            }
        }
    }
`

const UPDATE_INVITED_CLUB_ADMINS = gql`
    mutation UPDATE_INVITED_CLUB_ADMINS(
        $set: club_admin_details_set_input
        $where: club_admin_details_bool_exp!
    ) {
        update_club_admin_details(where: $where, _set: $set) {
            returning {
                id
            }
        }
    }
`

const UPDATE_INVITED_LEAGUE_ADMINS = gql`
    mutation UPDATE_INVITED_LEAGUE_ADMINS(
        $set: admin_details_set_input
        $where: admin_details_bool_exp!
    ) {
        update_admin_details(where: $where, _set: $set) {
            returning {
                id
            }
        }
    }
`

const UPDATE_INVITED_PLAYERS = gql`
    mutation UPDATE_INVITED_PLAYERS(
        $set: players_details_set_input
        $where: players_details_bool_exp!
    ) {
        update_players_details(where: $where, _set: $set) {
            returning {
                id
            }
        }
    }
`

const UPDATE_CLUB = gql`
    mutation UPDATE_CLUB($set: clubs_set_input, $where: clubs_bool_exp!) {
        update_clubs(where: $where, _set: $set) {
            returning {
                id
            }
        }
    }
`

const GET_SLIDES = gql`
    query SlideQuery($where: slide_title_details_bool_exp = {}) {
        slide_title_details(where: $where, order_by: { slide_order: asc }) {
            id
            active
            clip_id
            cta_link
            cta_name
            custom_html
            heading
            image
            match_id
            slide_order
            sub_heading
            type
            background_image
        }
    }
`

const SUB_CLUB_EVENT_REPLAY = gql`
    subscription EventStreamsSub($where: event_streams_bool_exp = {}) {
        event_streams(where: $where, order_by: { start_datetime: desc }) {
            id
            stream_id
            ext_managed
            club_id
            start_datetime
            name
            slug
            league_id
            is_private
            event_type
            image
            stream_info {
                id
                is_historic
                network_status
                static_file_status
                status
                stream_id
                stream_key
                video_asset_id
            }
            club {
                id
                logo
                name
                display_name
                slug
            }
            league {
                name
                slug
                logo
            }
        }
    }
`

const SUB_MATCH_INFO = gql`
    subscription matchesSub($where: matches_bool_exp = {}) {
        matches(where: $where) {
            home_club {
                id
                name
                abbr_name
            }
            away_team {
                club {
                    id
                    name
                    abbr_name
                }
            }
            stream_info {
                id
                status
            }
            league {
                slug
                name
                id
            }
            sport {
                id
                name
            }
            custom_opponent
        }
    }
`

const GET_MATCH_INFO = gql`
    query GET_MATCH_INFO($where: matches_bool_exp = {}) {
        matches(where: $where) {
            home_club {
                id
                name
                abbr_name
            }
            away_team {
                club {
                    id
                    name
                    abbr_name
                }
            }
            stream_info {
                id
                status
            }
            league {
                slug
                name
                id
            }
            sport {
                id
                name
            }
            custom_opponent
        }
    }
`

// ---------
export default {
    SUB_CLUB,
    SUB_CLUB_REPLAYS,
    SUB_TEAM_REPLAYS,
    SUB_CLUB_TEAMS,
    SUB_LEAGUE,
    SUB_CLUB_BY_CLUB_ADMIN_USER_ID,
    SUB_CLUB_EVENT_REPLAY,
    SUB_MATCH_INFO,

    GET_CLUB_INVITE,
    GET_LEAGUE_INVITE,
    GET_CLUB,
    GET_LEAGUE,
    GET_MATCH_INFO,

    UPSERT_CLUB,
    UPSERT_CLUB_ADMINS,

    UPDATE_INVITED_CLUB_ADMINS,
    UPDATE_INVITED_LEAGUE_ADMINS,
    UPDATE_INVITED_PLAYERS,
    UPDATE_CLUB,

    INSERT_CLUB_ADMINS,
    DELETE_CLUB_ADMINS,
    DELETE_LEAGUE_ADMINS,
    GET_SLIDES,
}
