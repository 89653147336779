import { useLazyQuery } from '@apollo/client'
import { Progress } from 'antd'
import axios from 'axios'
import { Button } from 'components/Button/index'
import { Text } from 'components/Text'
import { CLIPS } from 'graphql/club'
import fileDownload from 'js-file-download'
import _ from 'lodash'
import { useEffect, useState } from 'react'
import { BiDownload } from 'react-icons/bi'
import { toast } from 'react-toastify'
import str from 'string-sanitizer'
import styled from 'styled-components'
import { isServer } from 'utils/helper'

interface Props {
    match_id?: number | undefined
    event_streams_id?: number | undefined
    mobile?: boolean
}

const Container = styled.div`
    display: flex;
    align-items: center;
`

const ProgressWrapper = styled.div`
    margin-right: 10px;
    .ant-progress-inner:not(.ant-progress-circle-gradient)
        .ant-progress-circle-path {
        stroke: red;
    }
    .ant-progress-circle .ant-progress-text {
        color: white;
    }
`
const PanelButton = styled.div`
    width: 48px;
    height: 48px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #ffffff;
    text-align: center;
    margin: 0 3px;
`

function ReplayDownloadButton({ match_id, event_streams_id, mobile }: Props) {
    /** To prevent loading the subscription if playbackId is undefined */

    const [progress, setProgress] = useState(0)
    const isEvent = event_streams_id ? true : false
    const [matchReplayData, setMatchReplayData] = useState<any>(undefined)
    const [eventData, setEventData] = useState<any>(undefined)

    const [getMatchReplayData] = useLazyQuery(CLIPS.GET_MATCH_REPLAY, {
        onCompleted(data) {
            if (data) {
                setMatchReplayData(data)
            }
        },
    })

    useEffect(() => {
        if (!Boolean(event_streams_id) && !isServer) {
            getMatchReplayData({
                variables: {
                    where: {
                        id: { _eq: match_id },
                    },
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event_streams_id, match_id])

    const [getEventStreamData] = useLazyQuery(CLIPS.GET_EVENT_STREAM_REPLAY, {
        onCompleted(data) {
            if (data) {
                setEventData(data)
            }
        },
    })

    useEffect(() => {
        if (!Boolean(match_id) && !isServer) {
            getEventStreamData({
                variables: {
                    where: {
                        id: { _eq: event_streams_id },
                    },
                },
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [event_streams_id, match_id])

    if (_.isUndefined(match_id) && _.isUndefined(event_streams_id)) {
        return <></>
    }

    if (
        match_id &&
        (_.isUndefined(matchReplayData?.matches) ||
            _.isEmpty(matchReplayData?.matches))
    ) {
        return <></>
    }

    if (
        event_streams_id &&
        (_.isUndefined(eventData?.event_streams) ||
            _.isEmpty(eventData?.event_streams))
    ) {
        return <></>
    }

    const clip = match_id
        ? matchReplayData?.matches[0]
        : eventData?.event_streams[0]

    if (_.isNull(clip?.stream_info?.static_file_status)) {
        return <></>
    }

    const downloadHandler = async () => {
        if (clip?.stream_info?.static_file_status === 'ready') {
            const fileName = isEvent
                ? `${clip.name ? str.addDash(clip.name) : 'video'}.mp4`
                : `${
                      clip.round_name ? str.addDash(clip.round_name) : 'video'
                  }.mp4`

            try {
                const res = await axios.get(
                    `https://stream.mux.com/${clip?.stream_info?.video_asset_id}/high.mp4`,
                    {
                        responseType: 'blob',
                        onDownloadProgress: (progressEvent) => {
                            const percentCompleted =
                                (progressEvent?.total &&
                                    Math.round(
                                        (progressEvent.loaded * 100) /
                                            progressEvent.total,
                                    )) ||
                                0
                            setProgress(
                                percentCompleted < 100 ? percentCompleted : 0,
                            )
                        },
                    },
                )

                if (res && res?.data) fileDownload(res.data, fileName)
            } catch (e) {
                // Try download Medium Resolution if High is not available

                try {
                    const res = await axios.get(
                        `https://stream.mux.com/${clip?.stream_info?.video_asset_id}/medium.mp4`,
                        {
                            responseType: 'blob',
                            onDownloadProgress: (progressEvent) => {
                                const percentCompleted =
                                    (progressEvent?.total &&
                                        Math.round(
                                            (progressEvent.loaded * 100) /
                                                progressEvent.total,
                                        )) ||
                                    0
                                setProgress(
                                    percentCompleted < 100
                                        ? percentCompleted
                                        : 0,
                                )
                            },
                        },
                    )

                    if (res && res?.data) fileDownload(res.data, fileName)
                } catch (e) {
                    // Try download Low Resolution if Medium is not available

                    const res = await axios.get(
                        `https://stream.mux.com/${clip?.stream_info?.video_asset_id}/low.mp4`,
                        {
                            responseType: 'blob',
                            onDownloadProgress: (progressEvent) => {
                                const percentCompleted =
                                    (progressEvent?.total &&
                                        Math.round(
                                            (progressEvent.loaded * 100) /
                                                progressEvent.total,
                                        )) ||
                                    0
                                setProgress(
                                    percentCompleted < 100
                                        ? percentCompleted
                                        : 0,
                                )
                            },
                        },
                    )

                    if (res && res?.data) fileDownload(res.data, fileName)
                }
            }
            return null
        }

        toast.warn('Preparing your download. Please wait...')
    }

    return mobile ? (
        <>
            {!!progress && (
                <PanelButton>
                    <ProgressWrapper>
                        <Progress type="circle" percent={progress} width={25} />
                    </ProgressWrapper>
                </PanelButton>
            )}
            {!progress &&
                ['ready', 'preparing'].includes(
                    clip?.stream_info?.static_file_status,
                ) && (
                    <PanelButton onClick={downloadHandler}>
                        <BiDownload size={20} style={{ marginBottom: 5 }} />
                        <Text fSize={0.75} fWeight={700} wSpace="nowrap">
                            {clip?.stream_info?.static_file_status ===
                                'ready' && 'Download'}
                            {clip?.stream_info?.static_file_status ===
                                'preparing' && 'Preparing'}
                        </Text>
                    </PanelButton>
                )}
        </>
    ) : (
        <Container>
            {!!progress && (
                <ProgressWrapper>
                    <Progress type="circle" percent={progress} width={20} />
                </ProgressWrapper>
            )}

            {!progress &&
                ['ready', 'preparing'].includes(
                    clip?.stream_info?.static_file_status,
                ) && (
                    <Button
                        onClick={downloadHandler}
                        bColor="primary"
                        bSize="small"
                        icon={<BiDownload />}
                    >
                        <Text
                            tAlign={'center'}
                            fSize={0.875}
                            fWeight={400}
                            wSpace="nowrap"
                        >
                            {clip?.stream_info?.static_file_status ===
                                'ready' && 'Download'}
                            {clip?.stream_info?.static_file_status ===
                                'preparing' && 'Preparing'}
                        </Text>
                    </Button>
                )}
        </Container>
    )
}

export default ReplayDownloadButton
