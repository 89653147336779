import styled, { css } from 'styled-components'
import { themeGet } from '@styled-system/theme-get'
import { Checkbox, Form, Modal } from 'antd'
import { defaultTheme } from 'theme'

export const ModalWrapper = styled(Modal)`
    .ant-modal-content {
        background: #1b1b25;
        padding: 0px 10px;

        ul > li {
            color: white;
            list-style: disc;
        }

        .match-details,
        .ads-settings {
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            @media screen and (min-width: ${defaultTheme.mediaSize.sm}px) {
                flex-direction: row;
            }
            .form-section {
                width: 100%;
                @media screen and (min-width: ${defaultTheme.mediaSize.sm}px) {
                    width: 48%;
                }
            }
        }

        .stream-settings {
            display: flex;
            justify-content: space-between;
            width: 100%;
            @media screen and (min-width: ${defaultTheme.mediaSize.sm}px) {
                width: 50%;
            }
        }

        .stream-details {
            .copy {
                margin: 0px 10px;
            }
        }

        .ant-modal-body {
            & > div:first-child {
                display: flex;
                flex-direction: column;
                @media screen and (min-width: ${defaultTheme.mediaSize.sm}px) {
                    flex-direction: row;
                }
            }
        }

        .ant-modal-header {
            background: #1b1b25;
            border: none;
            .ant-modal-title {
                color: white;
                font-size: 1.6em;
                font-weight: 700;
            }
        }

        .ant-modal-footer {
            border: none;
            display: flex;
            padding-bottom: 20px;
            justify-content: flex-end;
        }
    }
`

export const ModalContent = styled.div<{ show: boolean }>`
    border-radius: 4px;
    transition: all ease 0.5s;
    ${({ show }) =>
        show
            ? css`
                  display: block;
              `
            : css`
                  display: none;
              `};
    padding: 20px;
    position: fixed;
    background: ${themeGet('colors.black.300')};
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

export const ModalBody = styled.div``

export const ModalHeader = styled.div`
    padding-bottom: 20px;
`

export const ModalFooter = styled.div`
    padding-top: 30px;
    display: flex;
    gap: 20px;
    justify-content: right;
`

export const ImageContent = styled.div`
    border: 2px solid gray;
    border-radius: 4px;
    contain: content;
    position: relative;
    width: 300px;
    height: 300px;
`

export const NumberRange = styled.input`
    width: 100%;
`

export const UploadWrapper = styled.div`
    border: 1px solid ${themeGet('colors.gray.300')};
    border-radius: 4px;
    cursor: pointer;
`

export const FormItem = styled(Form.Item)`
    margin-bottom: 10px;
    .ant-form-item-explain.ant-form-item-explain-connected {
        display: none;
    }
    .ant-form-item-label {
        & > label {
            color: #fff;
        }
    }
`

export const ClubCheckbox = styled(Checkbox)`
    color: #fff;
    padding: 15px 0px 18px 0px;
`
