import React, { useState } from 'react'
// import componeent
import { Row, Col } from 'components/Layout'
import { ContainerWrapper } from 'components/Container'
//  import Sections
import Aside from './Aside'
import Information from './Information'
import Teams from './Teams'
import Billing from './Billing'
import Community from './Community'
import Players from './Players'
import Schedules from './Schedules'
import Sponsorship from './Sponsorship'
import Users from './Users'
// types
import { commonItem } from 'types/common/common'
// styled component
import { ContentWrapper, Content } from './content.style'
import AnalyticsView from './Analytics'

const menudata: commonItem[] = [
    {
        title: 'League Details',
        path: 'information',
        icon: 'FiInbox',
        component: <Information />,
    },
    {
        title: 'Teams',
        path: 'team',
        icon: 'FiFlag',
        component: <Teams />,
    },
    {
        title: 'Players',
        path: 'plyer',
        icon: 'FiUsers',
        component: <Players />,
    },
    {
        title: 'Schedules',
        path: 'schedule',
        icon: 'FiCalendar',
        component: <Schedules />,
    },
    {
        title: 'Analytics',
        path: 'analytics',
        icon: 'FiBarChart2',
        component: <AnalyticsView />,
    },
    // {
    //   title: "Users",
    //   path: "users",
    //   icon: "FiUser",
    //   component: <Users />

    // },
    // {
    //   title: "Approvals",
    //   path: "approvals",
    //   icon: "FiCheckCircle",
    //   component: <Approvals />
    // },
    // {
    //   title: "Community",
    //   path: "community",
    //   icon: "FiGlobe",
    //   component: <Community />,
    // },
    // {
    //   title: "Sponsorship & Offers",
    //   path: "sponsorship",
    //   icon: "FiAward",
    //   component: <Sponsorship />,
    // },
    // {
    //   title: "Billing",
    //   path: "bill",
    //   icon: "FiFileText",
    //   component: <Billing />,
    // },
]

const ContentView: React.FC = () => {
    const [select, setSelect] = useState<string>(menudata[0].path)

    const onHandleSelect = (item: string) => {
        setSelect(item)
    }
    return (
        <ContainerWrapper>
            <ContentWrapper>
                <Aside
                    menudata={menudata}
                    select={select}
                    onHandleSelect={onHandleSelect}
                />
                <Content>
                    {menudata.map((item: commonItem, index: number) => {
                        if (item.path === select) {
                            return <div key={index}>{item.component}</div>
                        }
                    })}
                </Content>
            </ContentWrapper>
        </ContainerWrapper>
    )
}

export default ContentView
