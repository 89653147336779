import { themeGet } from '@styled-system/theme-get'
import styled from 'styled-components'
import { defaultTheme } from 'theme'

interface BlockContainerInterface {
    position?: 'static' | 'relative' | 'absolute' | 'sticky'
    maxWidth?: number | string
    minHeight?: number | string
    padding?: number | string
    margin?: number | string
    bgColor?: string
    smPadding?: number | string
    smLeftPadding?: number | string
    smMargin?: number | string
}

// use section element
const BlockContainer = styled.section<BlockContainerInterface>`
    position: ${(props) => props.position ?? 'static'};
    padding: ${(props) => props.padding ?? '0'};

    background: ${({ bgColor }) =>
        themeGet(`colors.${bgColor}`, 'colors.black.100')};

    max-width: ${({ maxWidth }) =>
        maxWidth ? (maxWidth === 'full' ? maxWidth : `${maxWidth}`) : 'none'};
    min-height: ${({ minHeight }) =>
        minHeight
            ? minHeight === 'full'
                ? minHeight
                : `${minHeight}`
            : 'none'};

    margin: ${(props) => props.margin ?? '32px 32px'};

    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        padding: 0 40px;
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        padding: ${(props) => props.smPadding ?? '0 5px'};
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.lg}px) {
        padding-left: 10px;
        padding-left: 10px;
        margin: 16px 0;
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        margin: 16px 0;
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.lg}px) {
        padding-left: ${(props) => props.smPadding ?? '10px'};
        padding-right: ${(props) => props.smPadding ?? '10px'};
        margin: ${(props) => props.smMargin ?? '16px 0'};
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        margin: ${(props) => props.smMargin ?? '16px 0'};
    }
`

export default BlockContainer
