import { useApollo } from 'api/apollo'
import { query } from 'graphql/player'
import _ from 'lodash'
import { useCallback } from 'react'

type ActivityData = {
    player_id: number
    type: string // table name
    ref_id?: number // table id
    event_id?: string
    action?: string
}

const useActivityFeed = () => {
    const { apolloClient } = useApollo()

    const transformActivityFeed = (activities: Array<ActivityData>) => {
        return Promise.all(
            activities.map(async (activity) => {
                if (activity.type === 'media_gallery') {
                    let queryStr = query.GET_GALLERY_TAG
                    let fileType = 'photo'
                    let mediaData = {} as any

                    if (activity.action === 'upload') {
                        queryStr = query.GET_GALLERY_UPLOAD
                    }

                    const { data } = await apolloClient.query({
                        query: queryStr,
                        variables: { where: { id: { _eq: activity.ref_id } } },
                    })

                    if (_.isEmpty(data)) return

                    if (activity.action === 'upload') {
                        mediaData = data?.media_gallery[0] ?? null

                        if (data?.playback_id) {
                            fileType = 'video'
                        }

                        return {
                            id: activity.ref_id,
                            type: fileType,
                            updated_at: mediaData.updated_at,
                            action: activity.action,
                            media_gallery_id: mediaData?.id,
                        }
                    }

                    mediaData = data?.media_gallery_players[0] ?? null

                    if (mediaData?.media_gallery?.playback_id) {
                        fileType = 'video'
                    }

                    return {
                        id: activity.ref_id,
                        type: fileType,
                        updated_at: mediaData?.updated_at,
                        action: activity.action,
                        media_gallery_id: mediaData?.media_gallery?.id,
                    }
                } else if (activity.type === 'clip_assets') {
                    const { data } = await apolloClient.query({
                        query: query.GET_CLIP_ACTIVITY,
                        variables: { where: { id: { _eq: activity.ref_id } } },
                    })

                    if (_.isEmpty(data)) return

                    return {
                        id: activity.ref_id,
                        type: 'clip',
                        updated_at: data?.clip_assets[0]?.updated_at,
                        match_id: data?.clip_assets[0]?.match_id,
                        action: activity.action,
                        playback_id: data?.clip_assets[0]?.playback_id,
                    }
                } else if (activity.type === 'events') {
                    const { data } = await apolloClient.query({
                        query: query.GET_SCORE_EVENT,
                        variables: {
                            where: { id: { _eq: activity.event_id } },
                        },
                    })

                    if (_.isEmpty(data)) return

                    return {
                        id: activity.event_id,
                        type: 'score',
                        updated_at: data?.score[0]?.updated_at,
                        match_id: data?.score[0]?.match_id,
                        video_time: data?.score[0]?.video_time,
                    }
                } else if (activity.type === 'matches') {
                    const { data } = await apolloClient.query({
                        query: query.GET_MATCHES_EVENT,
                        variables: {
                            where: {
                                id: { _eq: activity.ref_id },
                                stream_info: {
                                    status: { _eq: 'completed' },
                                    archived: { _neq: true },
                                },
                            },
                        },
                    })

                    if (_.isEmpty(data)) return

                    return {
                        id: activity.ref_id,
                        type: 'replay',
                        updated_at: data?.matches[0]?.updated_at,
                        match_id: data?.matches[0]?.id,
                        action: activity.action,
                    }
                }
            }),
        )
    }

    const execute = useCallback(async function (
        activityData: Array<ActivityData>,
    ) {
        const result: any = await transformActivityFeed(activityData)
        if (!_.isEmpty(result)) {
            return result
        } else {
            throw false
        }
    }, [])

    return execute
}

export default useActivityFeed
