import React from 'react'
// component
import {
    Container,
    ColorContainer,
    ContainerWrapper,
    FlexContainer,
    BlockContainer,
} from 'components/Container'

// types
import { SectionContainerProps } from 'types/components/Container'

// -------------------------------------------------------------
const SectionContainer = ({
    cColor,
    SectionView,
    mWidth,
    sectionProps,
    display,
    elementProps,
}: SectionContainerProps) => {
    if (display === 'flex') {
        return (
            <FlexContainer {...elementProps}>
                <SectionView {...sectionProps} />
            </FlexContainer>
        )
    }

    if (display === 'block') {
        return (
            <BlockContainer {...elementProps}>
                <SectionView {...sectionProps} />
            </BlockContainer>
        )
    }

    return (
        <Container>
            <SectionView {...sectionProps} />
        </Container>
    )
}

export default SectionContainer
