import { Button } from 'components/Button'
import { Row } from 'components/Layout'
import { Text } from 'components/Text'
import dynamic from 'next/dynamic'
import { FiX } from 'react-icons/fi'
import styled from 'styled-components'
import { defaultTheme } from 'theme'
import { ModalBody, ModalHeader, ModalWrapper } from './playClipsModal.style'

const VideoPlayer = dynamic(() => import('components/Video/Bitmovin'), {
    ssr: false,
})

const PlayClipModal = (props: {
    show: boolean
    clipInfo: any
    handleShowAcceptModal: (arg0: { show: boolean; clipId: any }) => any
    handleShowRejectModal: (arg0: { show: boolean; clipId: any }) => any
    setShow: (arg0: boolean) => any
    setSelectedClip: (arg0: null) => any
    league_name?: string
}) => {
    return (
        <>
            <ModalWrapper show={props.show}>
                <ModalHeader>
                    <HeaderDiv>
                        <HeaderText fSize={2} fWeight={700}>
                            {props?.clipInfo?.title}
                        </HeaderText>
                    </HeaderDiv>
                    <ModalRow>
                        <AcceptButton
                            onClick={() =>
                                props.handleShowAcceptModal({
                                    show: true,
                                    clipId: props?.clipInfo?.id,
                                })
                            }
                            variant={'_primary'}
                        >
                            Approve
                        </AcceptButton>
                        <RejectButton
                            onClick={() =>
                                props.handleShowRejectModal({
                                    show: true,
                                    clipId: props?.clipInfo?.id,
                                })
                            }
                        >
                            Reject
                        </RejectButton>
                        <QuitButton
                            onClick={() => {
                                props.setShow(false),
                                    props.setSelectedClip(null)
                            }}
                        >
                            <FiX size={25} color={'white'} />
                        </QuitButton>
                    </ModalRow>
                </ModalHeader>
                <ModalBody>
                    <VideoPlayer
                        key={props?.clipInfo?.playbackId}
                        playback_id={props?.clipInfo?.playbackId}
                        league_name={props?.league_name}
                        video_type={'Clip'}
                        video_title={`Approval: ${props?.clipInfo?.title}`}
                    />
                </ModalBody>
            </ModalWrapper>
        </>
    )
}

export default PlayClipModal

const HeaderDiv = styled.div`
    width: 100%;
    text-align: left;
`

const HeaderText = styled(Text)`
    white-space: nowrap;
    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        font-size: 1rem;
    }
`

const ModalRow = styled(Row)`
    justify-content: right;
    padding: 10px 0;
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        justify-content: right;
    }
`

const AcceptButton = styled(Button)`
    border: 1px solid rgba(255, 255, 255, 0.22);
    border-radius: 8.5px;
    width: 105px;
    transition: none;
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        width: 85px;
        height: 30px;
        font-size: 0.8rem;
    }
`

const RejectButton = styled(Button)`
    border: 1px solid rgba(255, 255, 255, 0.22);
    border-radius: 8.5px;
    width: 95px;
    transition: none;
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        height: 30px;
        font-size: 0.8rem;
        width: 75px;
    }
`

const QuitButton = styled(Button)`
    height: 30px;
    width: 30px;
    margin-left: 20px;
`
