import styled from 'styled-components'
import { defaultTheme } from 'theme'

export const HeadWrapper = styled.div`
    padding: 20px 10px;
    h1 {
        font-size: 1.8rem;
    }

    @media screen and (min-width: ${defaultTheme.mediaSize.md}px) {
        padding: 20px 42px;
        h1 {
            font-size: 1.5rem;
        }
    }
`
