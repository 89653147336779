import styled from 'styled-components'
import { Tabs } from 'antd'

export const AntTab = styled(Tabs)`
    padding: 0 25px 0 25px;
    width: 100%;
    min-height: 67vh;
    background-color: rgba(53, 50, 50, 0.27);
    border-color: #332e2e;
    border-width: 1px;
    border-radius: 15px;
    color: white;
    .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
        color: #fff;
        font-size: 1.25rem;
        font-weight: 600;
        font-family: HeyWow, Arial, Helvetica, sans-serif;
    }

    .ant-tabs-nav::before {
        border-bottom: 1px solid rgb(41 40 40) !important;
    }

    .ant-tabs-tab-btn {
        color: #8b8c91;
        font-size: 1.25rem;
        font-weight: 600;
    }

    .ant-tabs-ink-bar {
        background-color: #fa3737;
    }
    .anticon {
        color: red;
    }
`
