import { get } from './helpers/request'
import { Slides } from './types'
const baseUrl = process.env.NEXT_PUBLIC_RESTFUL_BASE_URL

export async function getAllSlides() {
    try {
        const response = await get<Slides>(`${baseUrl}/slides`)
        return response.parsedBody
    } catch (error) {
        console.log(error)
        throw new Error('Data failed to load')
    }
}
