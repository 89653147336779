import { siteSettings } from 'hooks'
import { GetServerSidePropsContext, PreviewData } from 'next'
import { ParsedUrlQuery } from 'querystring'
import {
    EventElement,
    EventOrMatch,
    MatchElement,
} from 'restful-client/types/games'

export const toShortDate = (date: Date) => {
    return (
        date.getFullYear() +
        '-' +
        (parseInt(date.getUTCMonth().toString()) + 1) +
        '-' +
        date.getUTCDate()
    )
}
export const getThisYear = () => {
    const now = new Date()
    return now.getFullYear()
}

export const timeDifference = (current: number, previous: number) => {
    const msPerMinute = 60 * 1000
    const msPerHour = msPerMinute * 60
    const msPerDay = msPerHour * 24
    const msPerMonth = msPerDay * 30
    const msPerYear = msPerDay * 365
    const elapsed = current - previous
    let val = 0
    let str = ''
    if (elapsed < msPerMinute) {
        val = Math.round(elapsed / 1000)
        str = 'second'
    } else if (elapsed < msPerHour) {
        val = Math.round(elapsed / msPerMinute)
        str = 'minute'
    } else if (elapsed < msPerDay) {
        val = Math.round(elapsed / msPerHour)
        str = 'hour'
    } else if (elapsed < msPerMonth) {
        val = Math.round(elapsed / msPerDay)
        str = 'day'
    } else if (elapsed < msPerYear) {
        val = Math.round(elapsed / msPerMonth)
        str = 'month'
    } else {
        val = Math.round(elapsed / msPerYear)
        str = 'year'
    }
    str += `${val > 1 ? 's' : ''} ago`
    return val + ' ' + str
}

export const getTimeInSeconds = (timeInString: string) => {
    if (timeInString.split(':').length === 2) {
        const [minutes, seconds] = timeInString.split(':')
        return Number(minutes) * 60 + Number(seconds)
    }
    if (timeInString.split(':').length === 3) {
        const [hours, minutes, seconds] = timeInString.split(':')
        return Number(hours) * 60 * 60 + Number(minutes) * 60 + Number(seconds)
    }
    return 0
}

export const displaySecondsAsTime = (sec: number) => {
    // time will be in the format "1970-01-01T00:06:41.400Z"
    const time = new Date(sec * 1000).toISOString()
    // formatted will be in the format "00:06:41"
    const formatted = time.slice(11, 19)

    return formatted
}

const pollWindowMs =
    siteSettings<number>('home_page.polling_window_min') * 60_000

/**
 * Returns true if one or more of the events or matches is active or is unstarted but due to start in +/- poll_window_min
 */
export function hasActiveOrUpcomingGame(
    matches: Array<EventElement | MatchElement> | undefined,
) {
    const windowStart = new Date(Date.now() - pollWindowMs).toISOString()
    const windowEnd = new Date(Date.now() + pollWindowMs).toISOString()

    return (
        matches &&
        matches.some(
            (match) =>
                (match.stream_info?.status === 'created' &&
                    match.start_datetime > windowStart &&
                    match.start_datetime < windowEnd) ||
                match.stream_info?.status === 'active',
        )
    )
}

export const isAfter = (targetTime: string) => {
    return new Date(targetTime).getTime() - new Date().getTime() < 0
}

export const isBefore = (targetTime: string) => {
    return new Date(targetTime).getTime() - new Date().getTime() > 0
}

/**
 * Returns sorted event and matches list where live stream is promoted ahead and follow with scheduled stream
 */
export const sortLiveStream = (streams: EventOrMatch[]): EventOrMatch[] => {
    return streams.sort((a, b) => {
        const aLive = a.stream_info?.status === 'active'
        const bLive = b.stream_info?.status === 'active'

        if (aLive && !bLive) {
            return -1
        }

        if (!aLive && bLive) {
            return 1
        }

        return Date.parse(a.start_datetime) - Date.parse(b.start_datetime)
    })
}

interface FormatType {
    casual?: boolean
    hideDate?: boolean
    hideTime?: boolean
    hideTimezone?: boolean
    options?: Intl.DateTimeFormatOptions
}

export function datetimeToLocal(datetime: string, format?: FormatType) {
    const tz = Intl.DateTimeFormat().resolvedOptions().timeZone

    return datetimeToLocalHelper(datetime, tz, format)
}

export function datetimeToLocalSSR(
    datetime: string,
    pageContext?: GetServerSidePropsContext<ParsedUrlQuery, PreviewData>,
    format?: FormatType,
) {
    const tz =
        pageContext?.req.headers['cloudfront-viewer-time-zone']?.toString() ??
        'Australia/Perth'

    return datetimeToLocalHelper(datetime, tz, format)
}

function isToday(datetime: string) {
    const today = new Date()
    return (
        today.setHours(0, 0, 0, 0) === new Date(datetime).setHours(0, 0, 0, 0)
    )
}

function isTomorrow(datetime: string) {
    const tomorrow = new Date()
    tomorrow.setDate(tomorrow.getDate() + 1)
    return (
        tomorrow.setHours(0, 0, 0, 0) ===
        new Date(datetime).setHours(0, 0, 0, 0)
    )
}

function datetimeToLocalHelper(
    datetime: string,
    tz: string,
    format?: FormatType,
) {
    const date = new Date(datetime)

    let options: Intl.DateTimeFormatOptions = {
        timeZone: tz,
    }

    if (!format?.hideDate) {
        options = { ...options, year: 'numeric', month: 'long', day: 'numeric' }
    }
    if (!format?.hideTime) {
        options = {
            ...options,
            hour: 'numeric',
            minute: 'numeric',
            hour12: true,
        }

        if (!format?.hideTimezone) {
            options = { ...options, timeZoneName: 'short' }
        }
    }
    if (format?.options) {
        options = { ...options, ...format.options }
    }

    if (format?.casual) {
        if (isToday(datetime)) {
            return `Today at ${date.toLocaleTimeString('en-AU', {
                ...options,
                year: undefined,
                month: undefined,
                day: undefined,
            })}`
        } else if (isTomorrow(datetime)) {
            return `Tomorrow at ${date.toLocaleTimeString('en-AU', {
                ...options,
                year: undefined,
                month: undefined,
                day: undefined,
            })}`
        }
    }

    // en-AU = English / Australian
    const localDatetime = date.toLocaleString('en-AU', options)
    return localDatetime
}

export function showWaflVoicePage() {
    return isAfter('2024-03-18T00:01:00.000+08:00')
}

/**
 * Find the latest date in a list of dates. You must provide at least one date.
 * All dates must be in the same format (either Date, date string, or timestamp in milliseconds).
 * @param dates
 * @returns The latest date in the list as a Date object.
 */
export function latest(...dates: Date[] | string[] | number[]): Date {
    if (!dates.length) {
        throw new Error('No dates provided to find latest')
    }
    if (dates[0] instanceof Date) {
        return dates.sort().pop() as Date
    }
    return latest(...dates.map((d) => new Date(d)))
}
