import { Text } from 'components/Text'
import styled from 'styled-components'

const TableHead = () => {
    return (
        <>
            <thead>
                <TableRow>
                    <TableHeaderUser>
                        <Text fSize={1} fWeight={600} fColor="gray.200">
                            User name
                        </Text>
                    </TableHeaderUser>
                    <TableHeaderStatus>
                        <Text fSize={1} fWeight={600} fColor="gray.200">
                            Status
                        </Text>
                    </TableHeaderStatus>
                    <TableHeaderExpires>
                        <Text fSize={1} fWeight={600} fColor="gray.200">
                            Expires
                        </Text>
                    </TableHeaderExpires>
                </TableRow>
            </thead>
        </>
    )
}

export default TableHead

const TableRow = styled.tr`
    height: 40px;
    align-items: center;
    background-color: #2d2b2b;
    border-radius: 10px;
    display: flex;
    padding-left: 2em;
    margin-bottom: 1em;
`

const TableHeaderUser = styled.th`
    flex: 2;
    min-width: 13em;
`

const TableHeaderStatus = styled.th`
    flex: 1;
    min-width: 16em;
`

const TableHeaderExpires = styled.th`
    flex: 1;
    min-width: 8em;
`
