import { useLazyQuery } from '@apollo/client'
import { ADMINQL } from 'graphql/club'
import _ from 'lodash'
import { useRouter } from 'next/router'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { AppState } from 'redux/store'
import { League } from 'types/common/league'
import { CustomSelect } from './dropdown.style'
const { Option } = CustomSelect

function LeaugeSelect(props: any) {
    const [data, setData] = useState<Partial<Array<any>>>([])
    const router = useRouter()
    const { league_slug } = router.query

    const clubLeagueId = useSelector((state: AppState) =>
        state?.league.info !== null
            ? state?.league?.info?.id
            : state?.club?.info.teams[0].league_id,
    )

    const [pullData] = useLazyQuery(ADMINQL.GET_ALL_LEAGUES, {
        variables: {
            where: { status: { _eq: 'approved' } },
        },
        onCompleted(data) {
            if (data.leagues) {
                const filteredLeagues = data.leagues.filter(
                    (league: League) => league.id === clubLeagueId,
                )

                setData(filteredLeagues)
            }
        },
    })

    useEffect(() => {
        !_.isUndefined(league_slug)
            ? pullData({
                  variables: {
                      where: {
                          slug: { _eq: league_slug },
                          status: { _eq: 'approved' },
                      },
                  },
              })
            : pullData()
    }, [])

    return (
        <CustomSelect
            {...props}
            showSearch
            placeholder="League Name"
            // filterOption={(input, option) =>
            //   option!.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            // }
        >
            {data.map((league, i) => (
                <Option
                    value={league.id}
                    key={`dd-league-fuzzy-${i}`}
                    sport_name={league?.sports?.name}
                >
                    {' '}
                    {league.name}{' '}
                </Option>
            ))}
        </CustomSelect>
    )
}

export default LeaugeSelect
