import { Row } from 'components/Layout'
import styled, { css } from 'styled-components'
import { defaultTheme } from 'theme'

export const ModalWrapper = styled.div<{ show: boolean }>`
    position: fixed;
    display: flex;
    z-index: 200;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    background-color: Rgb(1, 0, 0, 0.7);
    backdrop-filter: blur(12px);
    ${({ show }) =>
        show
            ? css`
                  visibility: visible;
              `
            : css`
                  visibility: hidden;
              `};
`

export const ModalContent = styled.div`
    border-radius: 20px;
    border: 1px solid rgb(29 28 28);
    background-color: rgba(22, 21, 22);
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 28%;

    width: 40%;
    max-width: 580px;
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        width: 50%;
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        width: 80%;
    }

    @media screen and (max-width: ${defaultTheme.mediaSize.xs}px) {
        height: 28%;
    }
`

export const ModalHeader = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    flex: 1;
    width: 80%;
`

export const ModalFooter = styled(Row)`
    justify-content: center;
    align-items: flex-start;
    flex: 0.4;
    transition: 0;
`
