import React from 'react'
// type
import { IconProps } from 'types/components/Icon'

// -----------------------------------------------
const BasketballIcon: React.FC<IconProps> = ({
    iColor = 'white',
    iSize = { x: 40, y: 40 },
}) => {
    return (
        <svg
            version="1.0"
            xmlns="http://www.w3.org/2000/svg"
            width={iSize.x}
            height={iSize.y}
            viewBox="0 0 980.000000 984.000000"
            preserveAspectRatio="xMidYMid meet"
        >
            <g
                transform="translate(0.000000,984.000000) scale(0.100000,-0.100000)"
                fill={iColor}
                stroke="none"
            >
                <path
                    d="M4665 9828 c-692 -24 -1429 -232 -2110 -596 -351 -188 -834 -558
   -1175 -901 -597 -600 -1046 -1412 -1263 -2286 -97 -390 -117 -572 -117 -1075
   0 -688 39 -974 202 -1495 138 -440 497 -1145 742 -1455 220 -279 780 -846
   1039 -1053 99 -79 537 -339 762 -453 545 -275 1163 -445 1798 -495 194 -15
   678 -7 857 15 460 56 903 166 1295 321 166 65 533 247 693 344 703 424 1308
   1027 1724 1721 345 576 586 1271 664 1920 21 176 29 698 15 954 -21 369 -101
   758 -244 1181 -131 386 -338 809 -562 1145 -398 599 -1007 1183 -1563 1498
   -394 224 -823 417 -1120 506 -531 158 -1062 224 -1637 204z m745 -462 c306
   -29 524 -75 887 -186 203 -63 344 -115 447 -167 l78 -39 -170 -73 c-341 -145
   -783 -373 -1153 -597 l-187 -112 -103 65 c-57 35 -135 90 -173 121 -101 81
   -321 304 -388 392 -91 120 -286 467 -313 557 -8 29 18 40 119 53 108 13 765 4
   956 -14z m-1537 -94 c9 -10 50 -91 90 -179 110 -239 201 -390 358 -593 109
   -141 316 -348 471 -472 l137 -110 -82 -67 c-149 -121 -332 -267 -344 -275 -7
   -4 -96 28 -205 74 -733 309 -1421 492 -2108 560 -216 21 -290 34 -290 49 0 13
   108 111 200 182 293 227 634 432 970 584 147 67 709 257 781 264 3 1 13 -7 22
   -17z m3573 -653 c216 -145 438 -335 679 -580 289 -295 485 -549 629 -818 31
   -58 56 -106 56 -107 0 -2 -57 24 -127 57 -462 219 -655 276 -1693 504 -547
   120 -674 149 -880 207 -203 57 -282 87 -278 107 10 53 621 366 1063 543 180
   73 410 157 427 158 9 0 65 -32 124 -71z m-5447 -825 c545 -46 1066 -151 1570
   -316 187 -61 570 -203 583 -216 3 -4 -41 -59 -99 -122 -289 -316 -510 -576
   -653 -769 l-81 -110 -94 65 c-242 167 -487 232 -840 221 -251 -7 -439 -42
   -705 -129 -258 -84 -680 -282 -974 -457 -94 -56 -176 -101 -183 -101 -15 0
   -12 19 32 195 90 355 250 752 444 1100 85 152 268 428 377 569 l77 99 186 -7
   c102 -4 264 -14 360 -22z m3576 -190 c438 -153 669 -214 1520 -398 422 -91
   564 -125 780 -188 550 -158 898 -364 1183 -701 140 -165 185 -265 237 -522
   165 -822 74 -1770 -250 -2590 -100 -254 -347 -720 -429 -808 -44 -47 -48 -38
   -112 208 -353 1355 -1040 2569 -1994 3525 -404 405 -792 715 -1307 1044 -187
   119 -273 182 -273 198 0 15 281 232 343 266 67 36 119 30 302 -34z m-828 -643
   c1136 -628 2096 -1632 2736 -2861 215 -414 394 -875 553 -1425 87 -298 133
   -505 178 -792 6 -40 -438 -426 -697 -606 -328 -229 -650 -403 -997 -539 -308
   -122 -721 -229 -811 -211 -40 7 -175 65 -239 101 -236 135 -411 360 -559 721
   -143 349 -238 739 -445 1846 -182 971 -264 1341 -381 1734 -110 369 -205 586
   -384 881 -59 96 -66 116 -57 165 14 73 197 309 491 630 184 201 425 445 441
   445 5 0 82 -40 171 -89z m-2175 -836 c203 -35 478 -167 478 -228 0 -13 -32
   -77 -71 -143 -532 -890 -871 -1798 -1028 -2749 -55 -332 -87 -662 -111 -1150
   -7 -121 -13 -221 -14 -223 -4 -3 -245 254 -331 353 -430 492 -770 1150 -945
   1829 -91 350 -108 518 -126 1196 l-6 256 253 161 c494 313 794 472 1114 588
   305 110 572 148 787 110z m858 -695 c228 -455 339 -872 594 -2240 203 -1084
   324 -1583 483 -1985 99 -251 191 -411 364 -632 49 -63 89 -118 89 -122 0 -41
   -561 1 -935 70 -528 97 -1151 352 -1648 672 -104 67 -107 70 -103 101 3 17 8
   168 11 336 6 348 20 693 36 890 36 476 200 1187 399 1734 157 431 399 938 587
   1231 l45 69 20 -24 c11 -14 37 -59 58 -100z"
                />
            </g>
        </svg>
    )
}
export default BasketballIcon
