import _ from 'lodash'
const operators = {
    IN: 'IN',
    EQ: 'EQ',
    NE: 'NE',
    LT: 'LT',
    LTE: 'LTE',
    GT: 'GT',
    GTE: 'GTE',
    CONTAINS: 'CONTAINS',
    NOTCONTAINS: 'NOTCONTAINS',
}

const analyticsAttribute = {
    IMPRESSION_ID: 'IMPRESSION_ID',
    ACTIVE_PLAYER_STARTUPTIME: 'ACTIVE_PLAYER_STARTUPTIME',
    AD: 'AD',
    ANALYTICS_VERSION: 'ANALYTICS_VERSION',
    AUDIO_BITRATE: 'AUDIO_BITRATE',
    AUDIO_CODEC: 'AUDIO_CODEC',
    AUTOPLAY: 'AUTOPLAY',
    BROWSER: 'BROWSER',
    BROWSER_VERSION_MAJOR: 'BROWSER_VERSION_MAJOR',
    BROWSER_IS_BOT: 'BROWSER_IS_BOT',
    BUFFERED: 'BUFFERED',
    CDN_PROVIDER: 'CDN_PROVIDER',
    CAST_TECH: 'CAST_TECH',
    CITY: 'CITY',
    CLIENT_TIME: 'CLIENT_TIME',
    COUNTRY: 'COUNTRY',
    CUSTOM_DATA_1: 'CUSTOM_DATA_1',
    CUSTOM_DATA_2: 'CUSTOM_DATA_2',
    CUSTOM_DATA_3: 'CUSTOM_DATA_3',
    CUSTOM_DATA_4: 'CUSTOM_DATA_4',
    CUSTOM_DATA_5: 'CUSTOM_DATA_5',
    CUSTOM_DATA_6: 'CUSTOM_DATA_6',
    CUSTOM_DATA_7: 'CUSTOM_DATA_7',
    CUSTOM_DATA_8: 'CUSTOM_DATA_8',
    CUSTOM_DATA_9: 'CUSTOM_DATA_9',
    CUSTOM_DATA_10: 'CUSTOM_DATA_10',
    CUSTOM_DATA_11: 'CUSTOM_DATA_11',
    CUSTOM_DATA_12: 'CUSTOM_DATA_12',
    CUSTOM_DATA_13: 'CUSTOM_DATA_13',
    CUSTOM_DATA_14: 'CUSTOM_DATA_14',
    CUSTOM_DATA_15: 'CUSTOM_DATA_15',
    CUSTOM_DATA_16: 'CUSTOM_DATA_16',
    CUSTOM_DATA_17: 'CUSTOM_DATA_17',
    CUSTOM_DATA_18: 'CUSTOM_DATA_18',
    CUSTOM_DATA_19: 'CUSTOM_DATA_19',
    CUSTOM_DATA_20: 'CUSTOM_DATA_20',
    CUSTOM_DATA_21: 'CUSTOM_DATA_21',
    CUSTOM_DATA_22: 'CUSTOM_DATA_22',
    CUSTOM_DATA_23: 'CUSTOM_DATA_23',
    CUSTOM_DATA_24: 'CUSTOM_DATA_24',
    CUSTOM_DATA_25: 'CUSTOM_DATA_25',
    CUSTOM_DATA_26: 'CUSTOM_DATA_26',
    CUSTOM_DATA_27: 'CUSTOM_DATA_27',
    CUSTOM_DATA_28: 'CUSTOM_DATA_28',
    CUSTOM_DATA_29: 'CUSTOM_DATA_29',
    CUSTOM_DATA_30: 'CUSTOM_DATA_30',
    CUSTOM_USER_ID: 'CUSTOM_USER_ID',
    DAY: 'DAY',
    DEVICE_CLASS: 'DEVICE_CLASS',
    DEVICE_TYPE: 'DEVICE_TYPE',
    DOMAIN: 'DOMAIN',
    DRM_LOAD_TIME: 'DRM_LOAD_TIME',
    DRM_TYPE: 'DRM_TYPE',
    DROPPED_FRAMES: 'DROPPED_FRAMES',
    DURATION: 'DURATION',
    ERROR_CODE: 'ERROR_CODE',
    ERROR_MESSAGE: 'ERROR_MESSAGE',
    ERROR_RATE: 'ERROR_RATE',
    EXPERIMENT_NAME: 'EXPERIMENT_NAME',
    FUNCTION: 'FUNCTION',
    HOUR: 'HOUR',
    INITIAL_TIME_TO_TARGET_LATENCY: 'INITIAL_TIME_TO_TARGET_LATENCY',
    IP_ADDRESS: 'IP_ADDRESS',
    IS_CASTING: 'IS_CASTING',
    IS_LIVE: 'IS_LIVE',
    IS_LOW_LATENCY: 'IS_LOW_LATENCY',
    IS_MUTED: 'IS_MUTED',
    ISP: 'ISP',
    LANGUAGE: 'LANGUAGE',
    LATENCY: 'LATENCY',
    LICENSE_KEY: 'LICENSE_KEY',
    M3U8_URL: 'M3U8_URL',
    MINUTE: 'MINUTE',
    MONTH: 'MONTH',
    MPD_URL: 'MPD_URL',
    OPERATINGSYSTEM: 'OPERATINGSYSTEM',
    OPERATINGSYSTEM_VERSION_MAJOR: 'OPERATINGSYSTEM_VERSION_MAJOR',
    PAGE_LOAD_TIME: 'PAGE_LOAD_TIME',
    PAGE_LOAD_TYPE: 'PAGE_LOAD_TYPE',
    PATH: 'PATH',
    PAUSED: 'PAUSED',
    PLATFORM: 'PLATFORM',
    PLAYED: 'PLAYED',
    PLAYER: 'PLAYER',
    PLAYER_KEY: 'PLAYER_KEY',
    PLAYER_STARTUPTIME: 'PLAYER_STARTUPTIME',
    PLAYER_TECH: 'PLAYER_TECH',
    PLAYER_VERSION: 'PLAYER_VERSION',
    PROG_URL: 'PROG_URL',
    REBUFFER_PERCENTAGE: 'REBUFFER_PERCENTAGE',
    REGION: 'REGION',
    SCALE_FACTOR: 'SCALE_FACTOR',
    SCREEN_HEIGHT: 'SCREEN_HEIGHT',
    SCREEN_WIDTH: 'SCREEN_WIDTH',
    SEEKED: 'SEEKED',
    SEQUENCE_NUMBER: 'SEQUENCE_NUMBER',
    SIZE: 'SIZE',
    STARTUPTIME: 'STARTUPTIME',
    STREAM_FORMAT: 'STREAM_FORMAT',
    SUPPORTED_VIDEO_CODECS: 'SUPPORTED_VIDEO_CODECS',
    TARGET_LATENCY: 'TARGET_LATENCY',
    TARGET_LATENCY_DELTA: 'TARGET_LATENCY_DELTA',
    TIME: 'TIME',
    TIME_TO_TARGET_LATENCY: 'TIME_TO_TARGET_LATENCY',
    USER_ID: 'USER_ID',
    VIDEO_BITRATE: 'VIDEO_BITRATE',
    VIDEO_CODEC: 'VIDEO_CODEC',
    VIDEO_DURATION: 'VIDEO_DURATION',
    VIDEO_ID: 'VIDEO_ID',
    VIDEO_PLAYBACK_HEIGHT: 'VIDEO_PLAYBACK_HEIGHT',
    VIDEO_PLAYBACK_WIDTH: 'VIDEO_PLAYBACK_WIDTH',
    VIDEO_STARTUPTIME: 'VIDEO_STARTUPTIME',
    VIDEO_TITLE: 'VIDEO_TITLE',
    VIDEO_WINDOW_HEIGHT: 'VIDEO_WINDOW_HEIGHT',
    VIDEO_WINDOW_WIDTH: 'VIDEO_WINDOW_WIDTH',
    VIDEOTIME_END: 'VIDEOTIME_END',
    VIDEOTIME_START: 'VIDEOTIME_START',
    VIDEOSTART_FAILED: 'VIDEOSTART_FAILED',
    VIDEOSTART_FAILED_REASON: 'VIDEOSTART_FAILED_REASON',
}

/** Types */
export type AnalyticsAttributeType = keyof typeof analyticsAttribute
export type AnalyticsQueryOperatorType = keyof typeof operators
export type PlaysType = [string, number][]

export const getPlaysById = (
    input: string,
    dataSet: PlaysType | undefined,
): number | null => {
    const result = dataSet?.find(([key]) => key === input)
    return result ? result[1] : null
}

export const getSumPlays = (arr: [string, number][] | undefined): string => {
    return _.sumBy(arr, ([, plays]) => plays).toString()
}

export const getDateRange = (
    numDays: number,
): { startDate: string; endDate: string } => {
    const days = numDays === 24 ? 1 : numDays

    let start = new Date()
    start.setDate(start.getDate() - days)
    const startDate = start.toISOString()
    const endDate = new Date().toISOString()

    return { startDate, endDate }
}
