import useMediaQuery from 'hooks/useMediaQuery'

import { TheWestAustralianLogo } from 'assets/icon'
import {
    GlobalHeaderWrapper,
    StyledTWLogoWrapHeading,
    TheWestAustralianLogoWrapper,
} from './GlobalHeader.style'

// -------------------------------------------------------------------

const GlobalHeader = () => {
    const isMobile = useMediaQuery(768)
    const logoSize = isMobile ? { x: 152, y: 14 } : { x: 200, y: 17 }

    return (
        <GlobalHeaderWrapper>
            <StyledTWLogoWrapHeading>
                <a
                    href="https://www.thewest.com.au/?utm_medium=referral&utm_campaign=streamer&utm_content=header#logo"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    <TheWestAustralianLogoWrapper>
                        <TheWestAustralianLogo iSize={logoSize} />
                    </TheWestAustralianLogoWrapper>
                </a>
            </StyledTWLogoWrapHeading>
        </GlobalHeaderWrapper>
    )
}

export default GlobalHeader
