import { gql } from '@apollo/client'

const SUB_MATCHES = gql`
    subscription MySubscription(
        $where: matches_bool_exp = {}
        $order_by: [matches_order_by!] = { start_datetime: asc }
    ) {
        matches(where: $where, order_by: $order_by) {
            away_club_id
            away_club_name
            away_team_id
            away_team_name
            club_id
            created_at
            home_team_id
            home_team_name
            id
            league_id
            league_name
            name
            round
            round_name
            start_datetime
            sports_id
            updated_at
            url
            ext_managed
            ext_scoring
            ads_id
            custom_opponent
            is_event
            sport {
                id
                name
                background_image {
                    id
                    name
                    url
                }
            }
            away_team {
                id
                image
                name
                division
                club {
                    id
                    logo
                    name
                    display_name
                    slug
                }
            }
            home_team {
                id
                image
                name
                division
                sport {
                    name
                }
                club {
                    id
                    logo
                    name
                    display_name
                    slug
                }
            }
            league {
                id
                logo
                name
                slug
                background_image {
                    id
                    name
                    url
                }
            }
            ads_setting {
                id
                vast_url
            }
            stream_info {
                id
                stream_id
                stream_key
                video_asset_id
                static_file_status
                is_historic
                status
                network_status
                archived
            }
        }
    }
`

const SUB_FILTER_MATCHES = gql`
    subscription myFilteredMatchSubscription($where: matches_bool_exp = {}) {
        matches(order_by: { start_datetime: asc }, where: $where) {
            away_club_id
            away_club_name
            away_team_id
            away_team_name
            club_id
            created_at
            home_team_id
            home_team_name
            id
            league_id
            league_name
            name
            round
            round_name
            start_datetime
            thumbnail_url
            updated_at
            url
            ext_managed
            ext_scoring
            custom_opponent
            is_event
            sport {
                id
                name
                background_image {
                    id
                    name
                    url
                }
            }
            stream_info {
                id
                is_historic
                status
                stream_id
                stream_key
                video_asset_id
            }
            away_team {
                id
                image
                name
                division
                club {
                    id
                    logo
                    name
                    display_name
                    slug
                }
            }
            home_team {
                id
                image
                name
                division
                sport {
                    name
                }
                club {
                    id
                    logo
                    name
                    display_name
                    slug
                }
            }
            league {
                logo
                name
                slug
                background_image {
                    id
                    name
                    url
                }
                sports {
                    name
                }
            }
        }
    }
`

const SUB_SAVED_MATCHES = gql`
    subscription SubSavedMatches($where: saved_matches_bool_exp = {}) {
        saved_matches(order_by: { created_at: desc }, where: $where) {
            id
            match {
                away_club_id
                away_club_name
                away_team_id
                away_team_name
                club_id
                created_at
                home_team_id
                home_team_name
                id
                league_id
                league_name
                name
                round
                round_name
                start_datetime
                thumbnail_url
                updated_at
                url
                ext_managed
                ext_scoring
                is_event
                stream_info {
                    id
                    is_historic
                    status
                    stream_id
                    stream_key
                    video_asset_id
                }
                league {
                    logo
                    name
                    slug
                }
                home_team {
                    id
                    image
                    name
                    division
                    sport {
                        name
                    }
                    club {
                        id
                        logo
                        name
                        display_name
                        slug
                    }
                }
                away_team {
                    id
                    image
                    name
                    division
                    club {
                        id
                        logo
                        name
                        display_name
                        slug
                    }
                }
            }
        }
    }
`

const SUB_EVENT_STREAMS = gql`
    subscription EventStreamsSub(
        $where: event_streams_bool_exp = {}
        $order_by: [event_streams_order_by!] = { start_datetime: asc }
    ) {
        event_streams(where: $where, order_by: $order_by) {
            id
            stream_id
            ext_managed
            club_id
            start_datetime
            name
            slug
            league_id
            is_private
            image
            event_type
            stream_info {
                id
                is_historic
                network_status
                static_file_status
                status
                stream_id
                stream_key
                video_asset_id
                archived
            }
            club {
                id
                logo
                name
                display_name
                slug
                sport {
                    id
                    name
                    background_image {
                        id
                        name
                        url
                    }
                }
            }
            league {
                id
                slug
                name
                logo
                background_image {
                    id
                    name
                    url
                }
                sports {
                    id
                    name
                    background_image {
                        id
                        name
                        url
                    }
                }
            }
        }
    }
`

const SUB_MATCH_PAGE_UPDATES = gql`
    subscription MatchPageUpdatesSubscription($where: matches_bool_exp = {}) {
        matches(where: $where) {
            stream_info {
                archived
                status
                video_asset_id
            }
        }
    }
`

const SUB_EVENT_PAGE_UPDATES = gql`
    subscription EventPageUpdatesSubscription(
        $where: event_streams_bool_exp = {}
    ) {
        event_streams(where: $where) {
            stream_info {
                archived
                status
                video_asset_id
            }
        }
    }
`

// // ---------
export default {
    SUB_MATCHES,
    SUB_FILTER_MATCHES,
    SUB_SAVED_MATCHES,
    SUB_EVENT_STREAMS,
    SUB_MATCH_PAGE_UPDATES,
    SUB_EVENT_PAGE_UPDATES,
}
