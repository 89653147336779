import styled from 'styled-components'
import { defaultTheme } from 'theme'

//  define
export const ContentWrapper = styled.div`
    height: 100%;
    display: flex;
`

export const Content = styled.div`
    height: 100%;
    width: 75%;
    @media screen and (max-width: ${defaultTheme.mediaSize.md}px) {
        width: 100%;
    }
    padding: 0px !important;
`
