// styled system
import { themeGet } from '@styled-system/theme-get'
import styled from 'styled-components'
// -----------------------------------------------
import { Text } from 'components/Text'
import { defaultTheme } from 'theme'

export const CopyRightSectionWrapper = styled.div`
    display: flex;
    font-size: 11px;
    padding: 50px 30px;
    justify-content: center;

    ::after {
        content: ' ';
        width: 100%;
        height: 1px;
        background: ${themeGet('colors.gray.300')};
        position: absolute;
        top: 0;
        left: 0;
    }

    // Desktop
    @media screen and (min-width: ${defaultTheme.mediaSize.lg}px) {
        flex-direction: row;
    }
    // Tablet
    @media screen and (max-width: ${defaultTheme.mediaSize.lg}px) {
        flex-direction: column;
    }
    // Mobile
    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        padding: 30px 10px;
    }
`

export const LinksContainer = styled.div`
    display: flex;
    justify-content: space-around;
    padding-top: 10px;
    margin-right: 60px;

    @media screen and (max-width: ${defaultTheme.mediaSize.lg}px) {
        margin-bottom: 50px;
        margin-right: 0px;
    }
    @media screen and (max-width: ${defaultTheme.mediaSize.sm}px) {
        flex-wrap: wrap;
        justify-content: left;
        margin: auto;
        width: 340px; // 2 * the width of the LinkColumnContainer. Important for mobile spacing
        margin-bottom: 70px;
    }
`

export const LinkColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 170px;
    margin-bottom: 35px;
    font-size: 16px;
`

export const LinkColumnHeader = styled(Text)`
    color: ${themeGet('colors.gray.500')};
    padding-bottom: 6px;
    font-size: 16px;
`

export const SocialIconsContainer = styled.div`
    display: flex;
    gap: 20px;
    padding-top: 8px;
`

export const StreamerSectionWrapper = styled.div`
    min-width: 300px;

    @media screen and (max-width: ${defaultTheme.mediaSize.lg}px) {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
`

export const StreamerLogoWrapper = styled.div`
    width: 182px;
    height: 68px;
    margin-bottom: 6px;
    cursor: pointer;
`
