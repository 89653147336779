import * as t from 'redux/types/user'

export const setUserDataCSVExport = (users: any) => ({
    type: t.SET_USER_DATA_CSV_EXPORT,
    payload: users,
})

export const setUserInfo = (users: any) => ({
    type: t.SET_USER_INFO,
    payload: users,
})
