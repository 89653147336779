import { FlexContainer } from 'components/Container'
import { Row } from 'components/Layout'
import React from 'react'
import CSVButton from './CSVButton'
import DFPButton from './DFPButton'
import NumberOfDigitalFans from './DigitalFans'
import NumberOfFollowers from './Followers'
import TableBody from './TableBody'
import TableHead from './TableHead'
import { AntTab } from './display.style'

const DisplaySection: React.FC = () => {
    return (
        <AntTab
            defaultActiveKey="1"
            size={'large'}
            items={[
                {
                    label: `Community`,
                    key: '1',
                    children: (
                        <div>
                            <Row style={{ overflow: 'auto' }}>
                                <FlexContainer
                                    align="flex-end"
                                    justify="space-around"
                                    direction="row"
                                    smDirection="column"
                                >
                                    <FlexContainer
                                        direction="row"
                                        margin="0px 0px 10px 0px"
                                    >
                                        <NumberOfFollowers />
                                        <NumberOfDigitalFans />
                                    </FlexContainer>

                                    <FlexContainer
                                        justify="flex-end"
                                        direction="row"
                                        margin="0px 0px 10px 0px"
                                    >
                                        <DFPButton />
                                        <CSVButton />
                                    </FlexContainer>
                                </FlexContainer>
                            </Row>

                            <div style={{ overflow: 'auto' }}>
                                <table
                                    style={{
                                        borderSpacing: 0,
                                        marginTop: 20,
                                    }}
                                >
                                    <TableHead />
                                    <TableBody />
                                </table>
                            </div>
                        </div>
                    ),
                },
            ]}
        />
    )
}

export default DisplaySection
