import React, { useState } from 'react'
//
import UpcomingSection from './Upcoming'
import PreviousSection from './Previous'
import EventsSection from './Events'
//  styled component
import { AntTab } from './display.style'

const DisplaySection: React.FC = () => {
    return (
        <AntTab
            defaultActiveKey="1"
            size={'large'}
            items={[
                {
                    label: `Upcoming`,
                    key: '1',
                    children: <UpcomingSection />,
                },
                {
                    label: `Previous`,
                    key: '2',
                    children: <PreviousSection />,
                },
                {
                    label: `Events`,
                    key: '3',
                    children: <EventsSection />,
                },
            ]}
        />
    )
}

export default DisplaySection
