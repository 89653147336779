import { useContext, useState } from 'react'
//  component
import { Button } from 'components/Button'
import { Col, Row } from 'components/Layout'
import { UpcomingModal } from 'components/Modal'
import { Table } from 'components/Table'

import { useSubscription } from '@apollo/client'
import { connect } from 'react-redux'

//  import react icons
import { BsPlus } from 'react-icons/bs'
//  styled component
import { DisplayWrapper } from './upcoming.style'
// asset
import { subscribe } from 'graphql/match'
import { LeagueContext } from 'pages/league/[league_slug]/admin'
import { isServer } from 'utils/helper'
import { datetimeToLocal } from 'utils/helper-date'

const UpcomingSection = (props: any) => {
    const league = useContext(LeagueContext)

    const [show, setShow] = useState<boolean>(false)
    const [data, setData] = useState([])
    const [datas, setDatas] = useState<any>([])
    const [item, setItem] = useState<any>({})

    useSubscription(subscribe.SUB_MATCHES, {
        skip: !league.id || isServer,
        variables: {
            where: {
                league_id: { _eq: league.id },

                stream_info: {
                    status: { _neq: 'completed' },
                    is_historic: { _eq: false },
                    archived: {
                        _neq: 'true',
                    },
                },
            },
        },
        onData: ({ data: { data, loading } }) => {
            !loading &&
                data.matches &&
                setData(
                    data.matches.map(
                        (match: {
                            start_datetime: string
                            league: { name: any }
                            round_name: any
                            home_team: { club: { name: any }; name: any }
                            custom_opponent: { club_name: any }
                            away_team: { club: { name: any }; name: any }
                            ext_scoring: any
                            ext_managed: any
                            stream_info: { stream_key: any }
                        }) => ({
                            Date: datetimeToLocal(match.start_datetime, {
                                hideTime: true,
                                options: { month: 'short' },
                            }),
                            Time: datetimeToLocal(match.start_datetime, {
                                hideDate: true,
                                hideTimezone: true,
                            }),
                            League: match.league.name,
                            'Round Name': match.round_name,
                            'Home Club': match.home_team.club.name,
                            'Home Team': match?.home_team?.name,
                            'Opposition Club': match?.custom_opponent
                                ? match?.custom_opponent?.club_name
                                : match?.away_team?.club?.name,
                            'Opposition Team': match?.custom_opponent
                                ? match?.custom_opponent?.club_name
                                : match?.away_team?.name,
                            Scoring: match.ext_scoring ? 'External' : 'Managed',
                            Stream: match.ext_managed ? 'External' : 'Managed',
                            'Stream Key': match.stream_info.stream_key,
                        }),
                    ),
                )
            data.matches && setDatas(data.matches)
        },
    })

    const onModal = (flag: boolean, type?: string) => {
        type && datas && setItem({ ...datas, type: 'create' })
        setShow(flag)
    }

    const onHandleClick = (e: { [x: string]: any; type: string }) => {
        if (e.type === 'edit') {
            datas &&
                setItem({
                    ...datas.filter(
                        (detail: { stream_info: { stream_key: any } }) =>
                            detail.stream_info.stream_key === e['Stream Key'],
                    )[0],
                    type: 'edit',
                })
            onModal(true)
        }
    }

    return (
        <DisplayWrapper>
            <Row flexDirection="column" gap={20}>
                <Col item={24}>
                    <Row
                        justifyContent="flex-end"
                        style={`
                            flex-wrap: wrap;
                        `}
                    >
                        <Button
                            bColor="primary"
                            bSize="small"
                            icon={<BsPlus />}
                            onClick={() => onModal(true, 'create')}
                            ariaLabel="add-upcoming-match-button"
                        >
                            {'Add Upcoming Match'}
                        </Button>
                    </Row>
                </Col>
                <Col item={24}>
                    <Table
                        data={data}
                        page="schedule"
                        onHandleClick={(e: any) => onHandleClick(e)}
                    />
                </Col>
            </Row>
            <UpcomingModal
                show={show}
                item={item}
                handleClose={() => onModal(false)}
            />
        </DisplayWrapper>
    )
}

const mapStateToProps = (state: { club: { info: any } }) => ({
    clubInfo: state.club.info,
})

// @ts-ignore
export default connect(mapStateToProps)(UpcomingSection)
