import { useLazyQuery } from '@apollo/client'
import CirclePlayIcon from 'assets/icon/circle_play'
import { CommonDiv } from 'components/Container'
import { Row } from 'components/Layout'
import { LoadingIcon } from 'components/Loading'
import { Text } from 'components/Text'
import _ from 'lodash'
import { useContext, useEffect, useState } from 'react'
import {
    RowItem,
    StyledText,
    TableBody,
    TableHead,
    TableRow,
    UniqueViewersDiv,
    VidThumbnail,
    ViewsDiv,
} from '../display.style'

import { query } from 'graphql/analytics'
import { AnalyticsAdminContext } from '..'

import { useSelector } from 'react-redux'
import {
    PlaysType,
    getDateRange,
    getPlaysById,
    getSumPlays,
} from 'utils/bitmovin-analytics'

const ITEMS_PER_PAGE = 10

import VisibilitySensor from 'react-visibility-sensor'
import { RootState } from 'redux/reducers/rootReducer'
import { getClubEventAnalyticStats } from 'restful-client/clubsAndLeagues'
import { datetimeToLocal } from 'utils/helper-date'

interface MatchItem {
    playbackID: string
    roundName: string
    matchDateTime: string
    totalViews: number
}

const EventsView = () => {
    const { range } = useContext(AnalyticsAdminContext)
    const [eventsData, setMatchData] = useState<MatchItem[]>([])
    const [total, setTotal] = useState({ match: '---', unique: '---' })
    const [loading, setLoading] = useState(true)
    const [currentPage, setCurrentPage] = useState(1)
    const [hasMore, setHasMore] = useState(false)
    const [plays, setPlays] = useState<PlaysType | undefined>([])

    const club = useSelector((state: RootState) => state.club.info)

    /** get Top Views  */

    async function fetchTopViews() {
        const { startDate, endDate } = getDateRange(range || 7)

        const result = await getClubEventAnalyticStats(
            club.id,
            startDate,
            endDate,
            'VIDEO_ID',
        )

        if (result !== undefined) {
            setPlays(result?.totalViews)
            setTotal({
                match: getSumPlays(result?.totalViews),
                unique: getSumPlays(result?.uniqueViews),
            })
        }
    }

    const [pullData] = useLazyQuery(query.GET_EVENTS, {
        fetchPolicy: 'cache-and-network',
        async onCompleted(data) {
            if (!_.isEmpty(data.event_streams)) {
                /** format data */
                const d = data.event_streams.map(
                    (itm: {
                        stream_info: { video_asset_id: string }
                        name: any
                        start_datetime: string
                    }) => ({
                        playbackID: itm.stream_info.video_asset_id,
                        roundName: itm.name,
                        matchDateTime: datetimeToLocal(itm.start_datetime, {
                            hideTime: true,
                            options: { month: 'short' },
                        }),
                        totalViews:
                            getPlaysById(
                                itm.stream_info.video_asset_id,
                                plays,
                            ) || 0,
                    }),
                )

                /** format data */
                setMatchData((data) => {
                    return [...data, ...d]
                })

                setCurrentPage((page) => page + 1)
                setHasMore(data.event_streams.length === ITEMS_PER_PAGE)
            } else {
                setHasMore(false)
            }

            setLoading(false)
        },
    })

    const fetchData = (offset: number) => {
        pullData({
            variables: {
                limit: ITEMS_PER_PAGE,
                offset,
                where: {
                    club_id: { _eq: club.id },
                    stream_info: {
                        status: { _eq: 'completed' },
                        video_asset_id: { _neq: 'null' },
                    },
                },
            },
        })
    }

    const loadMore = (isVisible: any) => {
        const offset = (currentPage - 1) * ITEMS_PER_PAGE
        isVisible && fetchData(offset)
    }

    useEffect(() => {
        /** reset flags */
        setMatchData([])
        setHasMore(false)
        setCurrentPage(1)
        setLoading(true)
        setTotal({ match: '---', unique: '---' })
        setPlays([])

        if (club.id) {
            fetchTopViews()
            fetchData(0)
        }
    }, [range, club])

    if (loading) {
        return (
            <CommonDiv
                justify="center"
                direction="row"
                padding="200px 0px 0px 0px"
            >
                <LoadingIcon size={100} color="#F93737" />
            </CommonDiv>
        )
    }

    return (
        <div style={{ overflowY: 'hidden' }}>
            <Row>
                <ViewsDiv>
                    <Text
                        fSize={0.9}
                        fWeight={600}
                        style={{ marginRight: 'auto' }}
                    >
                        Total Event Views
                    </Text>
                    <Text
                        fSize={1.5}
                        fWeight={600}
                        style={{ marginRight: 'auto' }}
                    >
                        {total.match}
                    </Text>
                </ViewsDiv>

                <UniqueViewersDiv>
                    <Text
                        fSize={0.9}
                        fWeight={600}
                        style={{ marginRight: 'auto' }}
                    >
                        Unique Viewers
                    </Text>
                    <Text
                        fSize={1.5}
                        fWeight={600}
                        style={{ marginRight: 'auto' }}
                    >
                        {total.unique}
                    </Text>
                </UniqueViewersDiv>
            </Row>
            {/* table */}
            <div
                style={{ overflow: 'auto', overflowY: 'hidden', width: '100%' }}
            >
                <TableHead>
                    <RowItem>
                        <StyledText fSize={1}>Event</StyledText>
                    </RowItem>
                    <RowItem>
                        <StyledText fSize={1}>Event Name</StyledText>
                    </RowItem>
                    <RowItem>
                        <StyledText fSize={1}>Match Date/Time</StyledText>
                    </RowItem>
                    <RowItem flex=".35">
                        <StyledText fSize={1}>Total Views</StyledText>
                    </RowItem>
                </TableHead>

                <TableBody>
                    {!_.isNull(eventsData) && eventsData.length > 0
                        ? eventsData.map((item: any, index) => (
                              <TableRow key={item.playbackID}>
                                  <RowItem>
                                      <VidThumbnail
                                          playbackID={item.playbackID}
                                      >
                                          <CirclePlayIcon />
                                      </VidThumbnail>
                                  </RowItem>

                                  <RowItem>
                                      <Text
                                          fColor="white"
                                          fSize={1.2}
                                          fWeight={510}
                                      >
                                          {item.roundName}
                                      </Text>
                                  </RowItem>
                                  <RowItem>
                                      <Text
                                          fColor="white"
                                          fSize={1.2}
                                          fWeight={510}
                                      >
                                          {item.matchDateTime}
                                      </Text>
                                  </RowItem>
                                  <RowItem flex=".32">
                                      <Text
                                          fColor="white"
                                          fSize={1.2}
                                          fWeight={510}
                                      >
                                          {item.totalViews}
                                      </Text>
                                  </RowItem>
                              </TableRow>
                          ))
                        : !false && (
                              <CommonDiv
                                  justify="center"
                                  direction="row"
                                  padding="180px 0px 0px 0px"
                              >
                                  <Text
                                      fSize={2}
                                      fColor={'white'}
                                      fWeight={500}
                                  >
                                      NO EVENT AVAILABLE
                                  </Text>
                              </CommonDiv>
                          )}

                    {hasMore && (
                        <CommonDiv align="center" margin="0px 0px 20px 0px">
                            <VisibilitySensor
                                active={hasMore}
                                delayedCall={true}
                                onChange={loadMore}
                                offset={{ top: 200 }}
                            >
                                <CommonDiv
                                    justify="center"
                                    direction="row"
                                    align="center"
                                >
                                    <LoadingIcon size={30} color="#F93737" />
                                    <Text
                                        fSize={1.5}
                                        fColor={'white'}
                                        fWeight={500}
                                        padding="0px 10px"
                                    >
                                        Fetching...
                                    </Text>
                                </CommonDiv>
                            </VisibilitySensor>
                        </CommonDiv>
                    )}
                </TableBody>
            </div>
        </div>
    )
}

export default EventsView
